import React, { ReactElement } from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';

import Keycloak from 'keycloak-js';

interface Props {
    children: ReactElement;
}

let auth = new Keycloak('/keycloak.json');

// eslint-disable-next-line no-console
console.log(window.location.hostname);
if (window.location.hostname.includes('edge-ts-afwerx')) {
    auth = new Keycloak('/keycloak-afwerx-edge.json');
} else if (window.location.hostname.includes('qa-ts-afwerx')) {
    auth = new Keycloak('/keycloak-afwerx-qa.json');
} else if (window.location.hostname.includes('uat-ts-afwerx')) {
    auth = new Keycloak('/keycloak-afwerx-uat.json');
} else if (window.location.hostname.includes('portal.afwerx')) {
    auth = new Keycloak('/keycloak-afwerx-prod.json');
}

export const Providers = ({ children }: Props): ReactElement => {
    // https://www.npmjs.com/package/@react-keycloak/web
    return <ReactKeycloakProvider authClient={auth}>{children}</ReactKeycloakProvider>;
};

export default Providers;
