import React, { lazy, ReactElement, Suspense, useEffect, useState } from 'react';

import { Container } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

import { DiscussionIcon } from 'assets/icons';
import AddDiscussion from 'pages/add-discussion/AddDiscussion';
import Logo from 'pages/landing/assets/AFWERX-Email-logo.png';
import LandingPage from 'pages/landing/LandingPage';
import JoinOrganization from 'pages/organization/JoinOrganization';
import PrivateRoute from 'PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { updateAlert } from 'state/store/actions/Alert';
import { resetBlockLink } from 'state/store/actions/Blocks';
import { clearFilters } from 'state/store/actions/ExplorerSearch';
import { resetTechnology } from 'state/store/actions/Technology';
import { setUserState } from 'state/store/actions/User';
import { RootStore } from 'state/store/reducers';
import { UserState } from 'state/store/reducers/User';
import { useGetUserLazyQuery } from 'typings/generated';
import { hasCapability, hasGroupMembership, isUserBlocked, userRequiresOnboarding } from 'utilities/utils';

type QuadruplyIndexedUrlType = (arg1: string, arg2: string, arg3: string, arg4: string) => string;
type TriplyIndexedUrlType = (id: string, childId: string, grandChildId: string) => string;
type DoublyIndexedUrlType = (id: string, childId: string) => string;
type IndexedUrlType = (id: string) => string;
type NullableIndexedUrlType = (id?: string | undefined) => string;
interface routeTypes<TNullableIndexibleUrl, TIndexibleUrl, TDoubleIndex, TTripleIndex, TQuadruplyIndexedUrlType> {
    forum: {
        add: TNullableIndexibleUrl;
        edit: TIndexibleUrl;
        view: {
            discussion: {
                root: TIndexibleUrl;
                toPost: TDoubleIndex;
                toReply: TTripleIndex;
                block: TIndexibleUrl;
                reports: TIndexibleUrl;
                participants: TIndexibleUrl;
            };
        };
    };
    dashboard: {
        root: string;
    };
    technology: {
        add: string;
        edit: TIndexibleUrl;
        view: {
            list: string;
            technology: {
                root: TIndexibleUrl;
                trl: TIndexibleUrl;
                block: TIndexibleUrl;
                reports: TIndexibleUrl;
            };
        };
    };
    company: {
        add: string;
        edit: TDoubleIndex;
        view: {
            root: TIndexibleUrl;
            reports: TIndexibleUrl;
            block: TIndexibleUrl;
        };
    };
    organization: {
        add: string;
        join: string;
        edit: TDoubleIndex;
        view: {
            root: TIndexibleUrl;
            reports: TIndexibleUrl;
            block: TIndexibleUrl;
        };
    };
    profile: {
        view: {
            root: TIndexibleUrl;
            following: TIndexibleUrl;
            notifications: TIndexibleUrl;
            discussions: TIndexibleUrl;
            reports: TIndexibleUrl;
            block: TIndexibleUrl;
        };
        edit: { root: string; preferences: string; organizations: string; initiateMerge: string };
        mergeProfiles: TIndexibleUrl;
        addEmail: string;
    };
    explorer: {
        discussions: string;
        technologies: string;
        organizations: string;
        companies: string;
        governmentPeople: string;
        companyPeople: string;
    };
    admin: {
        root: string;
        blocks: string;
        invites: string;
        users: string;
        communities: string;
        identityManagement: string;
        unauthorized: string;
    };
    onboarding: {
        v1: string;
        checklist: string;
        admin: {
            dashboard: string;
            content: string;
            permissions: string;
        };
    };
    about: {
        root: string;
        afwerx: string;
        afventures: string;
        spark: string;
        prime: string;
        spacewerx: string;
    };
    community: {
        list: string;
        add: string;
        join: string;
        view: {
            resources: TIndexibleUrl;
            admin: TIndexibleUrl;
            root: TIndexibleUrl;
            announcements: {
                list: TIndexibleUrl;
            };
            discussions: {
                list: TIndexibleUrl;
                view: {
                    root: TDoubleIndex;
                    participants: TDoubleIndex;
                    toPost: TTripleIndex;
                    toReply: TQuadruplyIndexedUrlType;
                };
            };
            members: {
                list: TIndexibleUrl;
            };
        };
    };
}

export const appRoutes: routeTypes<string, string, string, string, string> = {
    forum: {
        add: '/add-discussion/:slug?',
        edit: '/editdiscussion/:id',
        view: {
            discussion: {
                root: '/discussion/:id/:slug?',
                toPost: '/discussion/:id/:slug?',
                toReply: '/discussion/:id/:slug?',
                block: '/discussion/:id/:slug?',
                reports: '/discussion/:id/:slug?',
                participants: '/discussion/:id/:slug?',
            },
        },
    },
    dashboard: {
        root: '/dashboard',
    },
    technology: {
        add: '/addtechnology',
        edit: '/edittechnology/:id',
        view: {
            list: '/explorer/Technologies',
            technology: {
                root: '/technology/:id/:slug?',
                trl: '/technolofy/:id/:slug?',
                block: '/technology/:id/:slug?',
                reports: '/technology/:id/:slug?',
            },
        },
    },
    company: {
        add: '/addorganization',
        view: { root: '/company/:id/:slug?', reports: '/company/:id/:slug?', block: '/company/:id/:slug?' },
        edit: 'editcompany/:id/:slug?',
    },
    organization: {
        add: '/addorganization',
        join: '/joinorganization',
        view: {
            root: '/organization/:id/:slug?',
            reports: '/organization/:id/:slug?',
            block: '/organization/:id/:slug?',
        },
        edit: 'editorganization/:id/:slug?',
    },
    profile: {
        view: {
            root: '/personprofile/:id/:slug?',
            following: '/personprofile/:id/:slug?',
            notifications: '/personprofile/:id/:slug?',
            discussions: '/personprofile/:id/:slug?',
            reports: '/personprofile/:id/:slug?',
            block: '/personprofile/:id/:slug?',
        },
        edit: {
            root: '/editprofile',
            preferences: '/editprofile/:slug?',
            organizations: '/editprofile/:slug?',
            initiateMerge: '/editprofile/:slug?',
        },
        mergeProfiles: '/mergeProfiles',
        addEmail: '/add-email',
    },
    explorer: {
        discussions: 'explorer/Discussions',
        technologies: 'explorer/Technologies',
        organizations: 'explorer/Organizations',
        companies: 'explorer/Companies',
        governmentPeople: 'explorer/GovernmentPeople',
        companyPeople: 'explorer/CompanyPeople',
    },
    admin: {
        root: '/admin',
        blocks: '/admin/:slug?',
        invites: '/admin/:slug?',
        users: '/admin/:slug?',
        communities: '/admin/:slug?',
        identityManagement: '/admin/:slug?',
        unauthorized: '/unauthorized',
    },
    onboarding: {
        v1: '/afwerx-onboarding',
        checklist: '/afwerx-onboarding-checklist',
        admin: {
            dashboard: '/afwerx-onboarding/admin/:slug?',
            content: '/afwerx-onboarding/admin/:slug?',
            permissions: '/afwerx-onboarding/admin/:slug?',
        },
    },
    about: {
        root: '/about',
        afwerx: '/about/afwerx',
        afventures: '/about/afventures',
        spark: '/about/spark',
        prime: '/about/prime',
        spacewerx: '/about/spacewerx',
    },
    community: {
        add: '/add-community',
        list: '/communities',
        join: '/join-community',
        view: {
            resources: '/community/:id/:slug?',
            admin: '/community/:id/:slug?',
            root: '/community/:id/:slug?',
            announcements: {
                list: '/community/:id/:slug?',
            },
            discussions: {
                list: '/community/:id/:slug?',
                view: {
                    root: '/community/:id/discussions/:discussionId/:slug?',
                    participants: '/community/:id/discussions/:discussionId/:slug?',
                    toPost: '/community/:id/discussions/:discussionId/:slug?',
                    toReply: '/community/:id/discussions/:discussionId/:slug?',
                },
            },
            members: {
                list: '/community/:id/:slug?',
            },
        },
    },
};

export const linkTo: routeTypes<
    NullableIndexedUrlType,
    IndexedUrlType,
    DoublyIndexedUrlType,
    TriplyIndexedUrlType,
    QuadruplyIndexedUrlType
> = {
    forum: {
        add: (communityId: string | undefined = undefined) =>
            communityId ? `/add-discussion/${communityId}` : `/add-discussion`,
        edit: (id: string) => `/editdiscussion/${id}`,
        view: {
            discussion: {
                root: (discussionId: string) => `/discussion/${discussionId}`,
                toPost: (discussionId: string, postId: string) => `/discussion/${discussionId}?post=${postId}`,
                toReply: (discussionId: string, postId: string, replyId: string) =>
                    `/discussion/${discussionId}?post=${postId}&reply=${replyId}`,
                block: (discussionId: string) => `/discussion/${discussionId}/block`,
                reports: (discussionId: string) => `/discussion/${discussionId}/reports`,
                participants: (discussionId: string) => `/discussion/${discussionId}/participants`,
            },
        },
    },
    dashboard: {
        root: '/dashboard',
    },
    technology: {
        add: '/addtechnology',
        edit: (id: string) => `/edittechnology/${id}`,
        view: {
            list: '/explorer/Technologies',
            technology: {
                root: (id: string) => `/technology/${id}`,
                trl: (id: string) => `/technology/${id}/trl`,
                block: (id: string) => `/technology/${id}/block`,
                reports: (id: string) => `/technology/${id}/reports`,
            },
        },
    },
    company: {
        add: '/onboarding/organization',
        view: {
            root: (id: string) => `/company/${id}`,
            reports: (id: string) => `/company/${id}/reports`,
            block: (id: string) => `/company/${id}/block`,
        },
        edit: (id: string | undefined, slug = '') => `/editcompany/${id}${slug ? `/${slug}` : ''}`,
    },
    organization: {
        // add: 'pages/organization/CreateOrganization',
        add: '/onboarding/organization',
        join: '/joinorganization',
        view: {
            root: (id: string) => `/organization/${id}`,
            reports: (id: string) => `/organization/${id}/reports`,
            block: (id: string) => `/organization/${id}/block`,
        },
        edit: (id: string | undefined, slug = '') => `/editorganization/${id}${slug ? `/${slug}` : ''}`,
    },
    profile: {
        view: {
            root: (id: string) => `/personprofile/${id}`,
            following: (id: string) => `/personprofile/${id}/following`,
            notifications: (id: string) => `/personprofile/${id}/notifications`,
            discussions: (id: string) => `/personprofile/${id}/discussions`,
            reports: (id: string) => `/personprofile/${id}/reports`,
            block: (id: string) => `/personprofile/${id}/block`,
        },
        edit: {
            root: '/editprofile',
            preferences: '/editprofile/preferences',
            organizations: '/editprofile/organizations',
            initiateMerge: '/editprofile/initiateMerge',
        },
        mergeProfiles: (verificationId: string) => `/mergeprofiles?targetMerge=${verificationId}`,
        addEmail: '/add-email',
    },
    explorer: {
        discussions: '/explorer/Discussions',
        technologies: '/explorer/Technologies',
        organizations: '/explorer/Organizations',
        companies: '/explorer/Companies',
        governmentPeople: '/explorer/GovernmentPeople',
        companyPeople: '/explorer/CompanyPeople',
    },
    admin: {
        root: '/admin',
        blocks: '/admin/blocks',
        invites: '/admin/invites/',
        users: '/admin/users/',
        communities: '/admin/communities/',
        identityManagement: '/admin/identityManagement/',
        unauthorized: 'unauthorized',
    },
    onboarding: {
        v1: '/afwerx-onboarding',
        checklist: '/afwerx-onboarding-checklist',
        admin: {
            dashboard: '/afwerx-onboarding/admin/dashboard',
            content: '/afwerx-onboarding/admin/content',
            permissions: '/afwerx-onboarding/admin/permissions',
        },
    },
    about: {
        root: '/about',
        afwerx: '/about/afwerx',
        afventures: '/about/afventures',
        spark: '/about/spark',
        prime: '/about/prime',
        spacewerx: '/about/spacewerx',
    },
    community: {
        add: '/add-community',
        list: '/communities',
        join: '/join-community',
        view: {
            resources: (id: string) => `/community/${id}/resources`,
            admin: (id: string) => `/community/${id}/admin`,
            root: (id: string) => `/community/${id}/`,
            announcements: {
                list: (id: string) => `/community/${id}/announcements`,
            },
            discussions: {
                list: (id: string) => `/community/${id}/discussions`,
                view: {
                    root: (communityId: string, discussionId: string) =>
                        `/community/${communityId}/discussions/${discussionId}`,
                    participants: (communityId: string, discussionId: string) =>
                        `/community/${communityId}/discussions/${discussionId}/participants`,
                    toPost: (communityId: string, discussionId: string, postId: string) =>
                        `/community/${communityId}/discussions/${discussionId}?post=${postId}`,
                    toReply: (communityId: string, discussionId: string, postId: string, replyId: string) =>
                        `/community/${communityId}/discussions/${discussionId}?post=${postId}&reply=${replyId}`,
                },
            },
            members: {
                list: (id: string) => `/community/${id}/members`,
            },
        },
    },
};

// this does chunking
const Home = lazy(() => import('pages/home/Home'));
const Messages = lazy(() => import('pages/messages/Messages'));
const Explorer = lazy(() => import('pages/explore/Explorer'));
const About = lazy(() => import('pages/about/About'));
const Blocked = lazy(() => import('pages/blocked/Blocked'));
const OnboardingUser = lazy(() => import('pages/onboarding/OnboardingUser'));
const AfwerxOnboarding = lazy(() => import('pages/afwerx/AfwerxOnboarding'));
const AfwerxOnboardingv2 = lazy(() => import('pages/afwerx/AfwerxOnboarding-v2'));
const AfwerxOnboardingDashboard = lazy(() => import('pages/afwerx/AfwerxOnboardingDashboard'));
const CreateOrganization = lazy(() => import('pages/organization/CreateOrganization'));
const AddTechnology = lazy(() => import('pages/add-technology/AddTechnology'));
const EditTechnology = lazy(() => import('pages/edit-technology/EditTechnology'));
const QrCode = lazy(() => import('pages/qr-code/QrCode'));

const Organization = lazy(() => import('pages/organization/Organization'));
const EditOrganization = lazy(() => import('pages/organization-edit/EditOrganization'));
// const EditGovtOrganization = lazy(() => import('pages/organization-edit/EditGovtOrganization'));
const PersonProfile = lazy(() => import('pages/people/PersonProfile'));
const Technology = lazy(() => import('pages/technology/Technology'));
const EditPersonProfile = lazy(() => import('pages/personal-profile/EditPersonProfile'));
const AddEmail = lazy(() => import('pages/personal-profile/AddEmail'));

const Events = lazy(() => import('pages/events/Events'));

const Admin = lazy(() => import('pages/admin/Admin'));

const Discussion = lazy(() => import('pages/discussion/Discussion'));
const EditDiscussion = lazy(() => import('pages/edit-discussion/EditDiscussion'));

const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Verification = lazy(() => import('pages/verification/Verification'));
const MergeProfilesProvider = lazy(() => import('pages/merge-profiles/MergeProfilesProvider'));

const Community = lazy(() => import('pages/community/Community'));
const CreateCommunity = lazy(() => import('pages/community/CreateCommunity'));
const CommunityAdmin = lazy(() => import('pages/community/CommunityAdmin'));
const MyCommunities = lazy(() => import('pages/community/MyCommunities'));
const CommunityDiscussion = lazy(() => import('pages/community/CommunityDiscussion'));

function useFilterClearer(newPath: string, userId: string) {
    const dispatch = useDispatch();
    const explorerSearch = useSelector((state: RootStore) => state.ExplorerSearch);
    const [lastFilterableUrl, setLastFilterableUrl] = useState('');
    const filterableUrls = [
        linkTo.explorer.technologies,
        linkTo.explorer.companies,
        linkTo.explorer.companyPeople,
        linkTo.explorer.governmentPeople,
        linkTo.explorer.organizations,
        linkTo.explorer.discussions,
        linkTo.community.list,
        linkTo.admin.root,
        linkTo.profile.view.following(userId),
        linkTo.profile.view.discussions(userId),
        linkTo.profile.view.notifications(userId),
        linkTo.profile.view.reports(userId),
    ];
    const regexUrls = [/reports/, /\/discussions/, /\/community/];
    const isPaginatedUrl = filterableUrls.includes(newPath) || regexUrls.some(x => newPath.match(x));
    if (isPaginatedUrl && newPath !== lastFilterableUrl) {
        if (lastFilterableUrl && lastFilterableUrl !== newPath && explorerSearch.retainForUrl !== newPath) {
            dispatch(clearFilters());
        }
        setLastFilterableUrl(newPath);
    }
}
function useRequireOnboarding(newPath: string, user: UserState) {
    const history = useHistory();
    if (userRequiresOnboarding(user) && !newPath.startsWith('/onboarding')) {
        history.push('/onboarding');
    }
}
export const ReactRouter = ({ user }: { user: UserState }): ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { keycloak, initialized } = useKeycloak();
    const [showInitial, setInitial] = useState(false);
    const [redirected, setRedirected] = useState(false);
    const [allowKeycloakRedirect, setAllowKeycloakRedirect] = useState(false);
    const location = useLocation();
    useFilterClearer(location.pathname, user?.id || '');
    useRequireOnboarding(location.pathname, user);
    useEffect(() => {
        if (redirected) {
            setRedirected(false);
        }
    }, [redirected]);

    const [getUser] = useGetUserLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        async function getUserProfileAndUser() {
            dispatch(resetTechnology());
            dispatch(resetBlockLink());
            if (initialized && keycloak.token) {
                // await getUserProfile(keycloak);
                const getUserResult = await getUser();
                const currentUser = { ...getUserResult.data?.user } as UserState;
                dispatch(setUserState(currentUser));
                setInitial(true);
                if (isUserBlocked(currentUser) && !history.location.pathname.startsWith('/blocked')) {
                    history.replace('/blocked');
                } else if (currentUser.onboarded === false && !location.pathname.startsWith('/onboarding')) {
                    history.push('/onboarding');
                }
            } else {
                if (history.location.pathname.startsWith('/login') || history.location.pathname.startsWith('/signup')) {
                    setAllowKeycloakRedirect(true);
                    history.push('/');
                } else {
                    setAllowKeycloakRedirect(true);
                }
                setInitial(true);
            }
        }
        getUserProfileAndUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // }, [keycloak, initialized, getUser, history, dispatch, allowKeycloakRedirect, location.pathname]);

    const canEditTechnology = (): boolean => {
        // todo  check props  see canEditOrganization
        const canAccess = hasCapability(user, ['ManageTechnology']);
        if (!canAccess) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Unable to perform this action',
                    severity: 'error',
                })
            );
        }
        return canAccess;
    };

    const canAddTechnology = (): boolean => {
        // todo  check props  see canEditOrganization
        let canAccess = hasCapability(user, ['ManageTechnology']);
        if (user.isGovernment) {
            canAccess = false;
        }

        if (!canAccess) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Unable to perform this action',
                    severity: 'error',
                })
            );
        }
        return canAccess;
    };

    const canEditOrganization = (props: any): boolean => {
        const canAccess = hasCapability(user, ['ManageOrganization'], props.match.params.id);
        if (!canAccess) {
            dispatch(
                updateAlert({
                    visible: true,
                    message: 'Unable to perform this action',
                    severity: 'error',
                })
            );
        }
        return canAccess;
    };

    const ExplorerRouter = (): ReactElement => {
        const { slug } = useParams<{ slug: string }>();
        return (
            <Switch>
                {slug === 'Discussions' ? (
                    <PrivateRoute
                        user={user}
                        path="/explorer/:slug?"
                        component={Explorer}
                        loginModal
                        topic="discussions"
                        message="Create, view, and participate in discussions to make connections with subject matter experts."
                        icon={<DiscussionIcon />}
                    />
                ) : (
                    <Route path="/explorer/:slug?" component={Explorer} />
                )}
            </Switch>
        );
    };

    const iFrameRoute = (path: string, url: string, title: string) => {
        return (
            <Route
                path={path}
                component={() => {
                    return (
                        <div
                            style={{
                                position: 'absolute',
                                left: '0',
                                right: '0',
                                top: '60px',
                                bottom: !user ? '0' : '50px',
                            }}
                        >
                            <iframe style={{ width: '100%', height: '100%' }} title={title} src={url} />
                        </div>
                    );
                }}
            />
        );
    };

    const augmenteeRoute = iFrameRoute(
        '/augmentee',
        'https://docs.google.com/forms/d/e/1FAIpQLScNOOObR1qx-Vv_xrmmOJKwFfM-_RaKg7sNZI25jG-l46T6ew/viewform',
        'AFWERX Augmentee Bench'
    );

    const momentumRoute = iFrameRoute(
        '/momentum',
        'https://tables.area120.google.com/u/0/form/8Ubj9FXlTCgeylmxhFqk3n/t/8njV6UsSk0C8AuTqsLm4-W9BGy1bJCtVL2u-tzkiiO5v8_6lsGa6lykcVkqaUAgkZl',
        'AFWERX Momentum'
    );
    const exitSurveyRoute = iFrameRoute(
        '/exit-interview',
        'https://docs.google.com/forms/d/1AIH-f4qwjrTERZrx9EdTBXDAMBx44ng4X48x9jrRIhA/viewform?pli=1&pli=1&edit_requested=true&edit_requested=true',
        'AFWERX Exit Interview'
    );
    const calendarRoute = (
        <Route
            path="/calendar"
            component={() => (
                <iframe
                    title="Calendar"
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&title=P1%20Open%20Topic%20Events&mode=MONTH&showNav=1&showDate=1&showPrint=0&showTabs=0&showCalendars=0&showTz=1&showTitle=0&src=Y180aTl0dXZxcGptcjVmNW1wc2hua2drMnV0NEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23E67C73"
                    style={{ width: '100%', borderWidth: '0' }}
                    height="600"
                    scrolling="no"
                />
            )}
        />
    );
    const portalLogoRoute = (
        <Route
            path="/portalLogo"
            component={() => (
                <iframe title="Logo" src={Logo} style={{ width: '100%', borderWidth: '0', height: '80%' }} />
            )}
        />
    );
    const routes = (
        <>
            {!user ? (
                <Switch>
                    {showInitial && allowKeycloakRedirect && !redirected && (
                        <Route
                            path="/login"
                            component={() => {
                                window.location.href = keycloak.createLoginUrl();
                                setRedirected(true);
                                return null;
                            }}
                        />
                    )}
                    {showInitial && allowKeycloakRedirect && (
                        <Route
                            path="/signup"
                            component={() => {
                                window.location.href = keycloak.createRegisterUrl({
                                    redirectUri: `${window.location.origin}/onboarding`,
                                });
                                setRedirected(true);
                                return null;
                            }}
                        />
                    )}
                    {augmenteeRoute}
                    {momentumRoute}
                    {calendarRoute}
                    {exitSurveyRoute}
                    {portalLogoRoute}
                    <Route path="/" component={LandingPage} />
                </Switch>
            ) : (
                <div>
                    <Container>
                        <Switch>
                            <PrivateRoute user={user} path="/blocked" component={Blocked} exact />
                            <Route path="/" component={Home} exact />
                            {augmenteeRoute}
                            {momentumRoute}
                            {calendarRoute}
                            {exitSurveyRoute}
                            <Route path="/explorer/:slug?" component={ExplorerRouter} />
                            <Route path="/about/:slug?" component={About} exact />
                            <Route path="/events" component={Events} />
                            {hasGroupMembership(user, 'rg.newhire@afwerx.af.mil') && (
                                <Route path={appRoutes.onboarding.v1} component={AfwerxOnboarding} exact />
                            )}
                            {hasGroupMembership(user, 'rg.newhire@afwerx.af.mil') && (
                                <Route path={appRoutes.onboarding.checklist} component={AfwerxOnboardingv2} exact />
                            )}
                            <PrivateRoute
                                user={user}
                                capabilities={['ManageAFWERXOnboarding.', 'SuperAdmin']}
                                path={appRoutes.onboarding.admin.dashboard}
                                component={AfwerxOnboardingDashboard}
                                exact
                            />
                            <Route path="/logout" render={() => <Redirect to="/" />} />
                            <PrivateRoute user={user} path="/onboarding" component={OnboardingUser} exact />
                            <PrivateRoute
                                user={user}
                                path="/onboarding/joinorganization"
                                component={JoinOrganization}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.organization.join}
                                component={JoinOrganization}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.organization.add}
                                component={CreateOrganization}
                                exact
                            />
                            <PrivateRoute user={user} path={appRoutes.forum.add} component={AddDiscussion} exact />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.admin.root}
                                capabilities={['Moderator', 'SuperAdmin']}
                                requiresAdmin
                                component={Admin}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.admin.blocks}
                                capabilities={['Moderator', 'SuperAdmin']}
                                requiresAdmin
                                component={Admin}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.admin.identityManagement}
                                capabilities={['SuperAdmin']}
                                requiresAdmin
                                component={Admin}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.admin.unauthorized}
                                component={() => <div>Unauthorized</div>}
                                exact
                            />
                            <PrivateRoute user={user} path={appRoutes.admin.invites} component={Admin} exact />
                            <PrivateRoute user={user} path={appRoutes.admin.users} component={Admin} exact />
                            <PrivateRoute user={user} path={appRoutes.admin.communities} component={Admin} exact />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.community.view.root}
                                component={Community}
                                exact
                            />

                            <PrivateRoute
                                user={user}
                                path={appRoutes.community.add}
                                component={CreateCommunity}
                                capabilities={['SuperAdmin']}
                                requiresAdmin
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.community.view.admin}
                                component={CommunityAdmin}
                                exact
                                community
                            />
                            <PrivateRoute user={user} path={appRoutes.community.list} component={MyCommunities} exact />
                            <PrivateRoute user={user} path={appRoutes.forum.edit} component={EditDiscussion} exact />
                            <PrivateRoute
                                user={user}
                                path="/addtechnology"
                                component={AddTechnology}
                                callback={() => canAddTechnology()}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path="/edittechnology/:id"
                                component={EditTechnology}
                                callback={() => canEditTechnology()}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path="/editcompany/:id/:slug?"
                                component={EditOrganization}
                                callback={(props: any) => canEditOrganization(props)}
                                exact
                            />
                            {/* <PrivateRoute
                                path="/editorganization/:id"
                                component={EditGovtOrganization}
                                capabilities={['ManageOrganization']}
                                callback={(props: any, hasCapability: boolean) =>
                                    canEditGovtOrganization(props, hasCapability)
                                }
                                exact
                            /> */}
                            <PrivateRoute
                                user={user}
                                path={appRoutes.dashboard.root}
                                component={Dashboard}
                                exact
                                requiresGovt
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.profile.mergeProfiles}
                                component={MergeProfilesProvider}
                                exact
                            />
                            <PrivateRoute user={user} path={appRoutes.profile.addEmail} component={AddEmail} exact />
                            <PrivateRoute user={user} path="/verification" component={Verification} exact />
                            <PrivateRoute user={user} path="/qr-code" component={QrCode} exact />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.organization.view.root}
                                component={Organization}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.company.view.root}
                                component={Organization}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.profile.view.root}
                                component={PersonProfile}
                                exact
                            />
                            <PrivateRoute user={user} path="/editprofile/:slug?" component={EditPersonProfile} exact />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.technology.view.technology.root}
                                component={Technology}
                                exact
                            />
                            <PrivateRoute user={user} path="/messages/:conversationId?/:filter?" component={Messages} />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.forum.view.discussion.root}
                                component={Discussion}
                                exact
                            />
                            <PrivateRoute
                                user={user}
                                path={appRoutes.community.view.discussions.view.root}
                                component={CommunityDiscussion}
                                exact
                            />
                            <Route render={() => <Redirect to="/" />} />
                        </Switch>
                    </Container>
                </div>
            )}
        </>
    );

    const noRoutes = <div className="root_content">Loading...</div>;
    return (
        <Suspense fallback={<div>...Loading page</div>}>
            {isUserBlocked(user) ? (
                <Switch>
                    <PrivateRoute user={user} path="/blocked" component={Blocked} exact />
                </Switch>
            ) : (
                <>{showInitial ? routes : noRoutes}</>
            )}
        </Suspense>
    );
};

export default React.memo(ReactRouter);
