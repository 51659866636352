import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AboutTechnologyInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export type AddAffiliationInput = {
  identityId: Scalars['String'];
  userId: Scalars['String'];
  affiliationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  role: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
  relation: Relation;
};

export type AddAnnouncementInput = {
  communityId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  categories: Array<Scalars['String']>;
  attachedResources: Array<CommunityAnnouncementResourceInput>;
};

export type AddAutoRoleUserInput = {
  userEmail: Scalars['String'];
  roleName: Scalars['String'];
};

export type AddCommunityAppInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export type AddCommunityModuleInput = {
  communityId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type AddCommunityModuleItemInput = {
  moduleId: Scalars['String'];
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  embedUrl?: Maybe<Scalars['String']>;
  attachedResources: Array<CommunityTabContentResourceInput>;
};

export type AddCommunitySectionInput = {
  community: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type Affiliation = {
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  relation?: Maybe<Relation>;
  approvalPending: Scalars['Boolean'];
  identity: Identity;
  organization: Organization;
  identityAffiliationStatus: IdentityAffiliationStatus;
};

export type AffiliationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Affiliation>;
};

export type Announcement = {
  id: Scalars['ID'];
  communityId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createDate: Scalars['DateTime'];
  shares?: Maybe<Array<AnnouncementShare>>;
  views?: Maybe<Array<AnnouncementView>>;
  likes?: Maybe<Array<AnnouncementLike>>;
  bookmarks?: Maybe<Array<AnnouncementBookmark>>;
  attachedResources?: Maybe<Array<AnnouncementResource>>;
  categories?: Maybe<Array<Category>>;
  currentlyPinned: Scalars['Boolean'];
};

export type AnnouncementBookmark = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  announcement?: Maybe<Announcement>;
  user?: Maybe<User>;
};

export type AnnouncementLike = {
  id: Scalars['ID'];
  announcement?: Maybe<Announcement>;
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
};

export type AnnouncementResource = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ItemType;
  linkedFile?: Maybe<CommunityBlob>;
  social?: Maybe<CommunitySocial>;
  link?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  bookmarks?: Maybe<Array<ResourceBookmark>>;
};

export type AnnouncementResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Announcement>;
};

export type AnnouncementShare = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  announcement?: Maybe<AnnouncementShare>;
};

export type AnnouncementView = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  announcement?: Maybe<Announcement>;
  user?: Maybe<User>;
};

export type AppCapabilities = {
  capabilityId: Scalars['ID'];
  capabilityName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  app?: Maybe<Apps>;
  appRoleCapabilities?: Maybe<Array<AppRoleCapabilities>>;
};

export type Appeal = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Scalars['String']>;
  responseDate: Scalars['DateTime'];
  moderatedBy?: Maybe<User>;
  released?: Maybe<Scalars['Boolean']>;
  appealedBy?: Maybe<User>;
  block: Block;
};

export type AppOrgSettings = {
  settingId: Scalars['ID'];
  app?: Maybe<Apps>;
  organization?: Maybe<Organization>;
};

export type AppRoleCapabilities = {
  appRoleCapabilityId: Scalars['ID'];
  function?: Maybe<Scalars['String']>;
  appRole?: Maybe<AppRoles>;
  appCapabilities?: Maybe<AppCapabilities>;
};

export type AppRoles = {
  id: Scalars['ID'];
  roleName?: Maybe<Scalars['String']>;
  dateModified?: Maybe<Scalars['String']>;
  userModified?: Maybe<Scalars['String']>;
  app?: Maybe<Apps>;
  appRoleCapabilities?: Maybe<Array<AppRoleCapabilities>>;
  userOrgAccess?: Maybe<Array<AppUserOrgAccess>>;
};

export type ApproveAffiliationInput = {
  identityId: Scalars['String'];
  userId: Scalars['String'];
  affiliationId?: Maybe<Scalars['String']>;
};

export type Apps = {
  appId: Scalars['ID'];
  appSettings?: Maybe<Array<AppsSettings>>;
  roles?: Maybe<Array<AppRoles>>;
  appUserSettings?: Maybe<Array<AppUserSettings>>;
  appOrgSettings?: Maybe<Array<AppOrgSettings>>;
  appCapabilities?: Maybe<Array<AppCapabilities>>;
  invites?: Maybe<Array<Invite>>;
  users?: Maybe<Array<User>>;
};

export type AppsSettings = {
  settingId: Scalars['ID'];
  app?: Maybe<Apps>;
};

export type AppUserOrgAccess = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  role?: Maybe<AppRoles>;
};

export type AppUserSettings = {
  settingId: Scalars['ID'];
  settingName?: Maybe<Scalars['String']>;
  settingValue?: Maybe<Scalars['String']>;
  app?: Maybe<Apps>;
  user?: Maybe<User>;
};

export type Audience = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  supportEmail: Scalars['String'];
  createdDate: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
  contact: Contact;
  segment: Segment;
  campaign: Campaign;
};

export type AutoAppRole = {
  id: Scalars['ID'];
  appRole?: Maybe<AppRoles>;
  userEmail: Scalars['String'];
};

export type Block = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type BlocksResponse = {
  items: Array<Block>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
  unresolvedCount: Scalars['Int'];
  resolvedCount: Scalars['Int'];
  blockCount: Scalars['Int'];
};

export type Bookmark = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
};

export type BooleanResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
};

export enum BounceType {
  None = 'NONE',
  Permanent = 'PERMANENT',
  Transient = 'TRANSIENT',
  Undetermined = 'UNDETERMINED'
}

export type Campaign = {
  id: Scalars['Int'];
  title: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  templateTypeId?: Maybe<Scalars['Int']>;
  isArchived: Scalars['Boolean'];
  isSent: Scalars['Boolean'];
  sendDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
  templateType: TemplateType;
  campaignTemplateDatas: Array<CampaignTemplateData>;
  emailTrackings: Array<EmailTracking>;
  linkClicks: Array<CampaignLinkRollup>;
  campaignAnalytics?: Maybe<CampaignRollup>;
  segments: Array<Segment>;
  audience: Audience;
  emailQueue: EmailQueue;
};

export type CampaignInput = {
  id?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  subject: Scalars['String'];
  senderEmail: Scalars['String'];
  templateTypeId?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isSent?: Maybe<Scalars['Boolean']>;
  sendDate?: Maybe<Scalars['DateTime']>;
  campaignTemplateInputs?: Maybe<Array<CampaignTemplateInput>>;
  emailTracking?: Maybe<EmailTrackingInput>;
  segmentIds: Array<Scalars['Float']>;
  audienceId: Scalars['Int'];
};

export type CampaignLinkRollup = {
  id: Scalars['Int'];
  campaign: Campaign;
  clickCount?: Maybe<Scalars['Int']>;
  uniqueClickCount?: Maybe<Scalars['Int']>;
  clickAddress: Scalars['String'];
};

export type CampaignRollup = {
  id: Scalars['Int'];
  sentCount?: Maybe<Scalars['Int']>;
  deliveredCount?: Maybe<Scalars['Int']>;
  openedCount?: Maybe<Scalars['Int']>;
  withClicksCount?: Maybe<Scalars['Int']>;
  hardBounceCount?: Maybe<Scalars['Int']>;
  complaintCount?: Maybe<Scalars['Int']>;
  unknownStatusCount?: Maybe<Scalars['Int']>;
  hasUnsubscribesCount?: Maybe<Scalars['Int']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
};

export type CampaignsWithCountResponse = {
  campaigns: Array<Campaign>;
  nonPaginatedCount: Scalars['Int'];
};

export type CampaignTemplateData = {
  id: Scalars['Int'];
  campaign: Campaign;
  groupNumber?: Maybe<Scalars['Int']>;
  inputDescription: Scalars['String'];
  inputValue: Scalars['String'];
  createdDate: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
};

export type CampaignTemplateInput = {
  inputDescription: Scalars['String'];
  inputValue: Scalars['String'];
  groupNumber?: Maybe<Scalars['Float']>;
};

export type CanLeaveOrganizationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  organization: Organization;
  leaveStatus: LeaveOrganizationStatus;
};

export type Category = {
  id: Scalars['ID'];
  name: Scalars['ID'];
  description: Scalars['String'];
  children: Array<Category>;
  parent?: Maybe<Category>;
};

export type CategoryList = {
  id: Scalars['ID'];
  name: Scalars['ID'];
  description: Scalars['String'];
  items: Array<Category>;
  associatedCommunities?: Maybe<Array<Community>>;
};

export type CategoryListResponse = {
  items: Array<Category>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type Comment = {
  id: Scalars['ID'];
  content: Scalars['String'];
  createdBy: User;
  commentsCount?: Maybe<Scalars['Float']>;
  comments: Array<Comment>;
  parentComment?: Maybe<Comment>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  lastCommentEditDate?: Maybe<Scalars['DateTime']>;
  reports?: Maybe<Array<CommentReport>>;
  blocks?: Maybe<Array<CommentBlock>>;
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
};

export type CommentBlock = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
  comment: Comment;
};

export type CommentReport = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
  comment?: Maybe<Comment>;
};

export type Community = {
  id: Scalars['ID'];
  description: Scalars['String'];
  name: Scalars['String'];
  groupKey: Scalars['String'];
  userCurrentMember?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  bannerHexColor?: Maybe<Scalars['String']>;
  isDiscoverable?: Maybe<Scalars['Boolean']>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  leftSections?: Maybe<Array<CommunitySection>>;
  rightSections?: Maybe<Array<CommunitySection>>;
  sections?: Maybe<Array<CommunitySection>>;
  announcements?: Maybe<Array<Announcement>>;
  contentModules?: Maybe<Array<CommunityContentModule>>;
  members: Array<GoogleGroupMembership>;
  groupCalendarUrl?: Maybe<Scalars['String']>;
  customHelpInstructions?: Maybe<Scalars['String']>;
  pointOfContacts?: Maybe<Array<User>>;
  helpContact?: Maybe<Scalars['String']>;
  discussions?: Maybe<Array<CommunityDiscussion>>;
  communityAppOrder?: Maybe<Array<CommunityAppOrder>>;
  features: CommunityFeatures;
  categories?: Maybe<Array<Category>>;
};

export type CommunityAnnouncementResourceInput = {
  title: Scalars['String'];
  orderNumber: Scalars['Float'];
  type: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  blobBase64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CommunityAppOrder = {
  id: Scalars['ID'];
  communityApps?: Maybe<CommunityApps>;
  community?: Maybe<Community>;
  orderNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
};

export type CommunityApps = {
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  communityAppOrder?: Maybe<Array<CommunityAppOrder>>;
  usageCount: Scalars['Float'];
  usageNames: Scalars['String'];
};

export type CommunityAttachedResourceInput = {
  title: Scalars['String'];
  orderNumber: Scalars['Float'];
  type: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  blobBase64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CommunityBlob = {
  id: Scalars['ID'];
  fileName: Scalars['String'];
  blobBase64: Scalars['String'];
  communityResource: CommunityResourceBase;
};

export type CommunityBlobInput = {
  fileName: Scalars['String'];
  documentBase64: Scalars['String'];
  wasChanged: Scalars['Boolean'];
};

export type CommunityContentModule = {
  id: Scalars['ID'];
  communityId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description: Scalars['String'];
  createDate: Scalars['DateTime'];
  items?: Maybe<Array<CommunityModuleItem>>;
  orderNumber: Scalars['Float'];
};

export type CommunityContentModuleReorderInput = {
  ranks: Array<EntityReorderInput>;
};

export type CommunityDiscussion = {
  id: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  communityId?: Maybe<Scalars['String']>;
  createdBy: User;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  participants?: Maybe<Array<User>>;
  postCount: Scalars['Float'];
  participantCount?: Maybe<Scalars['Float']>;
  categories?: Maybe<Array<Category>>;
  likeCount: Scalars['Float'];
  currentUserLike?: Maybe<Scalars['Boolean']>;
  currentUserFollows?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<DiscussionLike>>;
  viewCount: Scalars['Float'];
  views?: Maybe<Array<DiscussionView>>;
  notifications?: Maybe<Array<Notification>>;
  shares?: Maybe<Array<DiscussionShare>>;
  discussionFollows?: Maybe<Array<DiscussionFollow>>;
  reports?: Maybe<Array<DiscussionReport>>;
  blocks?: Maybe<Array<DiscussionBlock>>;
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  currentlyPinned: Scalars['Boolean'];
  community?: Maybe<Community>;
};

export type CommunityFeatures = {
  id: Scalars['ID'];
  community: Community;
  apps?: Maybe<Scalars['Boolean']>;
  announcements?: Maybe<Scalars['Boolean']>;
  resourcesTab?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Scalars['Boolean']>;
  calendar?: Maybe<Scalars['Boolean']>;
  discussions?: Maybe<Scalars['Boolean']>;
};

export type CommunityFeaturesInput = {
  apps: Scalars['Boolean'];
  announcements: Scalars['Boolean'];
  questions: Scalars['Boolean'];
  calendar: Scalars['Boolean'];
  discussions: Scalars['Boolean'];
  resourcesTab: Scalars['Boolean'];
};

export type CommunityHelp = {
  id: Scalars['ID'];
  community: Community;
  subject: Scalars['String'];
  body: Scalars['String'];
  createdBy: User;
  helpEmail: Scalars['String'];
  createDate: Scalars['DateTime'];
};

export type CommunityLayout = {
  id: Scalars['ID'];
  section?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['Float']>;
  columnNumber?: Maybe<Scalars['Float']>;
  createDate: Scalars['DateTime'];
};

export type CommunityModuleItem = {
  id: Scalars['ID'];
  moduleId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  embedUrl?: Maybe<Scalars['String']>;
  attachedResources?: Maybe<Array<ModuleItemResource>>;
  orderNumber: Scalars['Float'];
  categories?: Maybe<Array<Category>>;
};

export type CommunityModuleItemResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<CommunityModuleItem>;
};

export type CommunityModuleResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<CommunityContentModule>;
};

export type CommunityResourceBase = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ItemType;
  linkedFile?: Maybe<CommunityBlob>;
  social?: Maybe<CommunitySocial>;
  link?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  bookmarks?: Maybe<Array<ResourceBookmark>>;
};

export type CommunityResourceInputBase = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  link?: Maybe<Scalars['String']>;
  type: ItemType;
  linkedFile?: Maybe<Scalars['String']>;
  socialId?: Maybe<Scalars['String']>;
};

export type CommunityResourceReorderInput = {
  rankings: Array<CommunitySectionResourceRankInput>;
};

export type CommunitySection = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  resources?: Maybe<Array<SectionResource>>;
  sectionTitle: Scalars['String'];
  rowOrderNumber: Scalars['Float'];
  columnNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  community: Community;
};

export type CommunitySectionInput = {
  sectionTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CommunitySectionRankInput = {
  rowOrderNumber: Scalars['Float'];
  id: Scalars['String'];
  columnNumber?: Maybe<Scalars['Float']>;
};

export type CommunitySectionReorderInput = {
  ranks: Array<CommunitySectionRankInput>;
};

export type CommunitySectionResourceInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  link?: Maybe<Scalars['String']>;
  type: ItemType;
  linkedFile?: Maybe<Scalars['String']>;
  socialId?: Maybe<Scalars['String']>;
  sectionId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type CommunitySectionResourceRankInput = {
  orderNumber: Scalars['Float'];
  id: Scalars['String'];
};

export type CommunitySocial = {
  id: Scalars['ID'];
  groupHeader?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  communityResource: CommunityResourceBase;
};

export type CommunitySocialInput = {
  groupHeader: Scalars['String'];
  facebook?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CommunitySummary = {
  id: Scalars['ID'];
  description: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  groupKey: Scalars['String'];
  userCurrentMember?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  banner?: Maybe<Scalars['String']>;
  bannerHexColor?: Maybe<Scalars['String']>;
};

export type CommunityTabContentResourceInput = {
  title: Scalars['String'];
  orderNumber: Scalars['Float'];
  type: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  blobBase64?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Contact = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactSource?: Maybe<Scalars['String']>;
  dunsNumber?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<Scalars['String']>;
  companyCertifications?: Maybe<Scalars['String']>;
  techFocusAreas?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<ContactStatus>;
  isArchived: Scalars['Boolean'];
  createdDate: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
  unsubscribedDate?: Maybe<Scalars['DateTime']>;
  unsubscribedBy?: Maybe<Scalars['ID']>;
  emailTracking: EmailTracking;
  audience: Audience;
  emailQueue: EmailQueue;
  contactAudits: ContactAudit;
  segments: Array<Segment>;
};

export type ContactAudit = {
  contactAuditId: Scalars['Int'];
  contact: Contact;
  oldStatus: Scalars['String'];
  newStatus: Scalars['String'];
  createdDate: Scalars['DateTime'];
  createdBy?: Maybe<User>;
};

export type ContactInput = {
  id: Scalars['String'];
};

export type ContactResponse = {
  errors: Array<Scalars['String']>;
  contactList: Array<Contact>;
};

export enum ContactStatus {
  Subscribed = 'SUBSCRIBED',
  Cleaned = 'CLEANED',
  Pending = 'PENDING',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type Conversation = {
  id: Scalars['ID'];
  initiatorArchived: Scalars['Boolean'];
  participantArchived: Scalars['Boolean'];
  initiatorHasMuted: Scalars['Boolean'];
  participantHasMuted: Scalars['Boolean'];
  initiator: User;
  participant: User;
  initiatorUnReadCount?: Maybe<Scalars['Float']>;
  participantUnReadCount?: Maybe<Scalars['Float']>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  messages?: Maybe<Array<Message>>;
};

export type CreateCommunityHelpEmailInput = {
  communityId: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
};

export type CreateCommunityInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  groupKey: Scalars['String'];
  helpContact?: Maybe<Scalars['String']>;
  groupCalendarUrl?: Maybe<Scalars['String']>;
};

export type CreateContactInput = {
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactSource?: Maybe<Scalars['String']>;
  dunsNumber?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<Scalars['String']>;
  companyCertifications?: Maybe<Scalars['String']>;
  techFocusAreas?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<ContactStatus>;
  isArchived?: Maybe<Scalars['Boolean']>;
  audienceId: Scalars['Int'];
};

export type CreateContactListInput = {
  contactList: Array<CreateContactInput>;
  duplicateContacts: Array<UpdateContactInput>;
  updateDuplicates: Scalars['Boolean'];
};

export type CreateInviteInput = {
  emailAddressList: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type CreateMessageInput = {
  recipientUserId: Scalars['String'];
  message: Scalars['String'];
};

export type CreateOrganizationInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  administrationConfirmation: Scalars['Boolean'];
  autoJoinWithDomain: Scalars['Boolean'];
  emailDomains: Array<EmailDomainInput>;
  role: Scalars['String'];
  relation: Scalars['String'];
  orgEmail: OrgEmailInput;
};

export type CreateOrganizationResponse = {
  organization: Organization;
  user: CurrentUser;
};

export type CreateSegmentInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filterQuery?: Maybe<Scalars['JSON']>;
  isArchived: Scalars['Boolean'];
  audienceId: Scalars['Int'];
  contacts?: Maybe<Array<ContactInput>>;
};

export type CreateUserInput = {
  id: Scalars['String'];
  emailAddress: Scalars['String'];
  name: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  lastName: Scalars['String'];
  firstName: Scalars['String'];
  userName: Scalars['String'];
  onboarded?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  referralType?: Maybe<Scalars['String']>;
  referralComments?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  orgSymbol?: Maybe<Scalars['String']>;
  dutyTitle?: Maybe<Scalars['String']>;
  lastLogin: Scalars['DateTime'];
  createdDate: Scalars['DateTime'];
};

export type CurrentUser = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  onboarded: Scalars['Boolean'];
  userOrgAccess?: Maybe<Array<AppUserOrgAccess>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  allowEmails: Scalars['Boolean'];
  approvalPending: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  skills?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  publications?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  referralType?: Maybe<Scalars['String']>;
  referralComments?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  isGovernment: Scalars['Boolean'];
  isCtr: Scalars['Boolean'];
  govtOnlyDisplayEmail?: Maybe<Scalars['Boolean']>;
  govtOnlyDisplayPhoneNumber?: Maybe<Scalars['Boolean']>;
  orgSymbol?: Maybe<Scalars['String']>;
  dutyTitle?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  notifications?: Maybe<Array<Notification>>;
  invites?: Maybe<Array<Invite>>;
  shares?: Maybe<Array<UserShare>>;
  mergeRequests?: Maybe<Array<ProfileMergeVerification>>;
  technologyLikes?: Maybe<Array<TechnologyLike>>;
  organizationLikes?: Maybe<Array<OrganizationLike>>;
  announcementLikes?: Maybe<Array<AnnouncementLike>>;
  announcementBookmarks?: Maybe<Array<AnnouncementBookmark>>;
  resourceBookmarks?: Maybe<Array<ResourceBookmark>>;
  technologyViews?: Maybe<Array<TechnologyView>>;
  organizationViews?: Maybe<Array<OrganizationView>>;
  announcementViews?: Maybe<Array<AnnouncementView>>;
  eventViews?: Maybe<Array<EventView>>;
  discussionLikes?: Maybe<Array<DiscussionLike>>;
  eventLikes?: Maybe<Array<EventLike>>;
  discussionViews?: Maybe<Array<DiscussionView>>;
  postLikes?: Maybe<Array<PostLike>>;
  technologyFollowsCount?: Maybe<Scalars['Float']>;
  technologyFollows?: Maybe<Array<TechnologyFollow>>;
  organizationFollowsCount?: Maybe<Scalars['Float']>;
  organizationFollows?: Maybe<Array<OrganizationFollow>>;
  discussionFollows?: Maybe<Array<DiscussionFollow>>;
  discussionFollowsCount?: Maybe<Scalars['Float']>;
  eventFollows?: Maybe<Array<EventFollow>>;
  documents?: Maybe<Array<UserDocument>>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  appUserSettings?: Maybe<Array<AppUserSettings>>;
  segments?: Maybe<Array<Segment>>;
  campaigns?: Maybe<Array<Campaign>>;
  campaignTemplates?: Maybe<Array<CampaignTemplateData>>;
  contacts?: Maybe<Array<Contact>>;
  contactAudits?: Maybe<Array<ContactAudit>>;
  audienceMembers?: Maybe<Array<Audience>>;
  technologyPointOfContacts?: Maybe<Array<Technology>>;
  pointOfContacts?: Maybe<Array<Organization>>;
  discussions?: Maybe<Array<Discussion>>;
  communityPointOfContacts?: Maybe<Array<Community>>;
  categories?: Maybe<Array<Category>>;
  communityCategories?: Maybe<Array<Category>>;
  externalLinks?: Maybe<Array<UserExternalLink>>;
  applications?: Maybe<Array<Apps>>;
  submittedBlocks?: Maybe<Array<Block>>;
  submittedReports?: Maybe<Array<Report>>;
  blocks?: Maybe<Array<UserBlock>>;
  reports?: Maybe<Array<UserReport>>;
  identities?: Maybe<Array<Identity>>;
  completedOnboardingSteps?: Maybe<Array<OnboardingStepProgress>>;
  completedOnboardingSections?: Maybe<Array<OnboardingSectionProgress>>;
  primaryAffiliation?: Maybe<Affiliation>;
  userAffiliations?: Maybe<Array<Affiliation>>;
  googleGroups?: Maybe<Array<GoogleGroupMembership>>;
  profession: Scalars['String'];
  googleGroupPermissions?: Maybe<GoogleGroupPermissions>;
  groupPermissions?: Maybe<Array<GroupMembership>>;
  emailAddress: Scalars['String'];
};

export type CurrentUserResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<CurrentUser>;
};


export type DeleteContactListInput = {
  contactIds: Array<Scalars['String']>;
};

/** The dir type */
export enum DirType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Discussion = {
  id: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  communityId?: Maybe<Scalars['String']>;
  createdBy: User;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  participants?: Maybe<Array<User>>;
  postCount: Scalars['Float'];
  participantCount?: Maybe<Scalars['Float']>;
  categories?: Maybe<Array<Category>>;
  likeCount: Scalars['Float'];
  currentUserLike?: Maybe<Scalars['Boolean']>;
  currentUserFollows?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<DiscussionLike>>;
  viewCount: Scalars['Float'];
  views?: Maybe<Array<DiscussionView>>;
  notifications?: Maybe<Array<Notification>>;
  shares?: Maybe<Array<DiscussionShare>>;
  discussionFollows?: Maybe<Array<DiscussionFollow>>;
  reports?: Maybe<Array<DiscussionReport>>;
  blocks?: Maybe<Array<DiscussionBlock>>;
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  currentlyPinned: Scalars['Boolean'];
};

export type DiscussionBlock = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type DiscussionFollow = {
  id: Scalars['ID'];
  discussion: Discussion;
  user: User;
  createDate: Scalars['DateTime'];
};

export type DiscussionInput = {
  discussionId?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  content: Scalars['String'];
  title: Scalars['String'];
  categories: Array<Scalars['String']>;
};

export type DiscussionLike = {
  id: Scalars['ID'];
  discussion?: Maybe<Discussion>;
  user?: Maybe<User>;
};

export type DiscussionReport = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type DiscussionResponse = {
  items: Array<Discussion>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type DiscussionShare = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  discussion?: Maybe<DiscussionShare>;
};

export type DiscussionView = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  discussion?: Maybe<Discussion>;
  user?: Maybe<User>;
};

export type Document = {
  id: Scalars['ID'];
  sourceDocumentId: Scalars['String'];
  uploadedBy: Scalars['String'];
  originalFileName: Scalars['String'];
  fileName: Scalars['String'];
  fileExtension: Scalars['String'];
  path: Scalars['String'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  secure: Scalars['Boolean'];
  usersWithAccess?: Maybe<Array<User>>;
};

export type DocumentBlob = {
  id: Scalars['ID'];
  fileName: Scalars['String'];
  blobBase64: Scalars['String'];
  onboardingStep: OnboardingStep;
};

export type DocumentDownloadResponse = {
  url: Scalars['String'];
};

export type DocumentListResponse = {
  items: Array<DocumentBlob>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type EditAdminAffiliationInput = {
  userId: Scalars['String'];
  affiliationId: Scalars['String'];
  role: Scalars['String'];
  roleName: Scalars['String'];
  relation: Relation;
};

export type EditAdminAffiliationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Affiliation>;
  roleName: Scalars['String'];
};

export type EditAffiliationInput = {
  affiliationId?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  relation: Relation;
};

export type EmailDomain = {
  id: Scalars['ID'];
  domain: Scalars['String'];
  organization: Organization;
};

export type EmailDomainInput = {
  domain: Scalars['String'];
};

export type EmailEngagement = {
  id: Scalars['Int'];
  emailTracking: EmailTracking;
  timestamp: Scalars['DateTime'];
  eventType: EventType;
  clickAddress?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type EmailQueue = {
  id: Scalars['Int'];
  campaign: Campaign;
  contact: Contact;
  testEmail?: Maybe<Scalars['String']>;
  sentBy: Scalars['ID'];
  sendDate: Scalars['DateTime'];
  content: Scalars['String'];
  origin: Scalars['String'];
};

export type EmailTracking = {
  id: Scalars['Int'];
  contact: Contact;
  campaign: Campaign;
  wasSent: Scalars['Boolean'];
  bounce?: Maybe<BounceType>;
  transactionType?: Maybe<TransactionNames>;
  hasComplaint: Scalars['Boolean'];
  opens: Scalars['Int'];
  clicks: Scalars['Int'];
  wasDelivered: Scalars['Boolean'];
  hasUnsubscribed: Scalars['Boolean'];
  sentDate: Scalars['DateTime'];
  sentMessageId: Scalars['String'];
};

export type EmailTrackingInput = {
  wasSent: Scalars['Boolean'];
};

export type EmployeeRoleInput = {
  id: Scalars['String'];
  userRole: Scalars['String'];
};

export type EntityFilterInput = {
  discussionId?: Maybe<Scalars['String']>;
  technologyId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
};

export type EntityListReorderInput = {
  ranks: Array<EntityReorderInput>;
};

export type EntityReorderInput = {
  rowOrderNumber: Scalars['Float'];
  id: Scalars['String'];
};

export type Event = {
  id: Scalars['ID'];
  badge: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  isVirtual?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  createdBy: User;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  viewCount: Scalars['Float'];
  views?: Maybe<Array<EventView>>;
  followCount: Scalars['Float'];
  follows?: Maybe<Array<EventFollow>>;
  likeCount: Scalars['Float'];
  likes?: Maybe<Array<EventLike>>;
  shares?: Maybe<Array<EventShare>>;
};

export type EventFollow = {
  id: Scalars['ID'];
  event?: Maybe<Event>;
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
};

export type EventLike = {
  id: Scalars['ID'];
  event?: Maybe<Event>;
  user?: Maybe<User>;
};

export type EventShare = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  event?: Maybe<EventShare>;
};

export enum EventType {
  None = 'NONE',
  Click = 'CLICK',
  Open = 'OPEN',
  Delivery = 'DELIVERY',
  BounceTransient = 'BOUNCE_TRANSIENT',
  BouncePermanent = 'BOUNCE_PERMANENT',
  Complaint = 'COMPLAINT'
}

export type EventView = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  event?: Maybe<Event>;
  user?: Maybe<User>;
};

export type GetCommunityAppResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<CommunityApps>;
};

export type GetCommunityModulesResponse = {
  items: Array<CommunityContentModule>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type GetCommunityResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Community>;
};

export type GetConversationsResponse = {
  items: Array<Conversation>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
  activeCount: Scalars['Int'];
  archivedCount: Scalars['Int'];
  mutedCount: Scalars['Int'];
};

export type GetDiscussionFollowsResponse = {
  items: Array<DiscussionFollow>;
  totalCount: Scalars['Int'];
};

export type GetManyCommunityAppResponse = {
  items: Array<CommunityApps>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type GetMessagesByConversationResponse = {
  items: Array<Message>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type GetNotificationsResponse = {
  items: Array<Notification>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
  allCount: Scalars['Int'];
  readCount: Scalars['Int'];
  unreadCount: Scalars['Int'];
  flaggedCount: Scalars['Int'];
};

export type GetOrganizationFollowsResponse = {
  items: Array<OrganizationFollow>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type GetTechnologyFollowsResponse = {
  items: Array<TechnologyFollow>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type GetUrlParserResponse = {
  result?: Maybe<MetadataResponse>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type GiveFeedbackInput = {
  type: Scalars['String'];
  message: Scalars['String'];
  location: Scalars['String'];
};

export type GoogleGroupMembership = {
  id: Scalars['ID'];
  communityId?: Maybe<Scalars['String']>;
  identity: Identity;
  groupKey: Scalars['String'];
  community?: Maybe<Community>;
  groupRole: Scalars['String'];
};

export type GoogleGroupPermissions = {
  teamAtLargeGroup: Scalars['Boolean'];
  rgCoreGroup: Scalars['Boolean'];
  newHire: Scalars['Boolean'];
};

export type GroupMembership = {
  groupKey: Scalars['String'];
  groupRole: Scalars['String'];
  communityId?: Maybe<Scalars['String']>;
};

export type HomeHelpMessage = {
  id: Scalars['ID'];
  header: Scalars['String'];
  description: Scalars['String'];
  active: Scalars['Boolean'];
  externalLinkLabel?: Maybe<Scalars['String']>;
  externalLinkUrl?: Maybe<Scalars['String']>;
  lackingCommunityMembership?: Maybe<Scalars['String']>;
};

export type HomeHelpMessageResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<HomeHelpMessage>;
};

export type Identity = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  user?: Maybe<User>;
  affiliations: Array<Affiliation>;
  googleGroups: Array<GoogleGroupMembership>;
  verification?: Maybe<Verification>;
};

/** The dir type */
export enum IdentityAffiliationStatus {
  UnverifiedUser = 'UNVERIFIED_USER',
  ApprovalPending = 'APPROVAL_PENDING',
  Validated = 'VALIDATED'
}

export type IdentityInput = {
  userId?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type IdentityResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Identity>;
};

export type IdFilterInput = {
  ids: Array<Scalars['ID']>;
};

export type Invite = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  role: Scalars['String'];
  inviteStatus?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  app: Apps;
};

/** The resource item type */
export enum ItemType {
  Header = 'HEADER',
  Link = 'LINK',
  File = 'FILE',
  Social = 'SOCIAL',
  Richtext = 'RICHTEXT'
}

export type JoinAffiliationInput = {
  identityId?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  roleName: Scalars['String'];
  relation: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type JoinOrganizationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  organization: Organization;
  user: CurrentUser;
  responseStatus: JoinOrganizationStatus;
};

export enum JoinOrganizationStatus {
  RequestSent = 'RequestSent',
  AutoApproved = 'AutoApproved',
  CouldNotJoin = 'CouldNotJoin',
  UserNotVerified = 'UserNotVerified'
}



export type JuicerPost = {
  id: Scalars['Int'];
  full_url: Scalars['String'];
  external_created_at: Scalars['String'];
  comment_count: Scalars['Int'];
  like_count: Scalars['Int'];
  feed: Scalars['String'];
  likes: Scalars['Int'];
  message: Scalars['String'];
  unformatted_message: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  poster_url?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['String']>;
  external_location_id?: Maybe<Scalars['String']>;
  deleted_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  poster_id?: Maybe<Scalars['String']>;
  edit?: Maybe<Scalars['String']>;
  additional_photos?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['String']>;
  tagged_users?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  poster_image?: Maybe<Scalars['String']>;
  poster_name?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type JuicerPostsResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<PaginatedResponseClass>;
  page?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LeaveOrganizationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  organization: Organization;
  user: CurrentUser;
  leaveSuccessful: Scalars['Boolean'];
};

export enum LeaveOrganizationStatus {
  CanLeave = 'CanLeave',
  LastOrgAdmin = 'LastOrgAdmin',
  LastOrgMember = 'LastOrgMember'
}

export type Log = {
  id: Scalars['Int'];
  message: Scalars['String'];
  type: MessageType;
  source: SourceType;
  createdDate: Scalars['DateTime'];
};

export type MergeProfileInitiationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  mergeValid: Scalars['Boolean'];
  mergeInitiatorId: Scalars['String'];
  verificationId: Scalars['String'];
  emailToMerge: Scalars['String'];
};

export type MergeProfileReadyResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  mergeReady: Scalars['Boolean'];
  verificationId: Scalars['String'];
};

export type MergeProfilesResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  mergedUser: CurrentUser;
  mergeSuccessful: Scalars['Boolean'];
};

export type MergeProfileValidationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  mergeValid: Scalars['Boolean'];
  mergeInitiatorId: Scalars['String'];
  verificationId: Scalars['String'];
};

export type Message = {
  id: Scalars['ID'];
  text: Scalars['String'];
  sender: User;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  initiatorRead: Scalars['Boolean'];
  participantRead: Scalars['Boolean'];
  conversation?: Maybe<Conversation>;
};

export type MessageConversation = {
  id: Scalars['ID'];
  initiatorArchived: Scalars['Boolean'];
  participantArchived: Scalars['Boolean'];
  initiatorHasMuted: Scalars['Boolean'];
  participantHasMuted: Scalars['Boolean'];
  initiatorUnReadCount?: Maybe<Scalars['Float']>;
  participantUnReadCount?: Maybe<Scalars['Float']>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  initiator: MessageSubscriptionUser;
  participant: MessageSubscriptionUser;
};

export type MessageSubscription = {
  id: Scalars['ID'];
  text: Scalars['ID'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  initiatorRead: Scalars['Boolean'];
  participantRead: Scalars['Boolean'];
  sender: MessageSubscriptionUser;
  conversation: MessageConversation;
};

export type MessageSubscriptionUser = {
  id: Scalars['ID'];
  name: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export enum MessageType {
  Debug = 'DEBUG',
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR',
  Fatal = 'FATAL',
  S3Success = 'S3_SUCCESS'
}

export type MetadataResponse = {
  meta?: Maybe<MetaResponse>;
  og?: Maybe<MetaResponse>;
  images?: Maybe<Array<MetaImage>>;
};

export type MetaImage = {
  src?: Maybe<Scalars['String']>;
};

export type MetaResponse = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  site_name?: Maybe<Scalars['String']>;
};

export type ModuleItemResource = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ItemType;
  linkedFile?: Maybe<CommunityBlob>;
  social?: Maybe<CommunitySocial>;
  link?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  bookmarks?: Maybe<Array<ResourceBookmark>>;
};

export type Mutation = {
  removeRole: Scalars['Boolean'];
  addRole: Scalars['Boolean'];
  removeAutoAppUser: Scalars['Boolean'];
  addAutoAppUser: Scalars['Boolean'];
  validateIdentityAsAdmin: Identity;
  resendValidationAsAdmin: Identity;
  addAffiliationAsPrimaryOrganizationAsAdmin: Scalars['Boolean'];
  clearUserCache: Scalars['Boolean'];
  addAffiliationAsAdmin: AffiliationResponse;
  editAffiliationAsAdmin: AffiliationResponse;
  removeAffiliationAsAdmin: BooleanResponse;
  approveAffiliationAsAdmin: BooleanResponse;
  removeIdentityAsAdmin: BooleanResponse;
  createIdentityAsAdmin: IdentityResponse;
  updateOnboardingProgress: OnboardingSectionListResponse;
  updateOnboardingUserAdminDetails: UpdateOnboardingAdminDetailsResponse;
  setup: Scalars['Boolean'];
  teardown: Scalars['Boolean'];
  createCommunityDiscussion: Discussion;
  createCommunity: GetCommunityResponse;
  addCommunityApp: GetCommunityAppResponse;
  updateCommunityApp: GetCommunityAppResponse;
  deleteCommunityApp: Scalars['Boolean'];
  addCommunityPOC: GetCommunityResponse;
  deleteCommunity: BooleanResponse;
  deleteCommunityPOC: GetCommunityResponse;
  updateCommunityInformation: BooleanResponse;
  updateCommunityAdministration: BooleanResponse;
  updateCommunityBanner: BooleanResponse;
  updateCommunityAvatar: BooleanResponse;
  createCommunityHelpEmail: BooleanResponse;
  createAnnouncement: BooleanResponse;
  createModuleItem: BooleanResponse;
  createModule: BooleanResponse;
  updateAnnouncement: BooleanResponse;
  updateModuleItem: BooleanResponse;
  updateModule: BooleanResponse;
  deleteAnnouncement: BooleanResponse;
  updateCommunitySection: BooleanResponse;
  updateCommunitySectionResource: BooleanResponse;
  deleteCommunityResource: BooleanResponse;
  deleteCommunitySection: BooleanResponse;
  deleteModule: BooleanResponse;
  deleteModuleItem: BooleanResponse;
  addCommunitySection: GetCommunityResponse;
  addCommunityResource: BooleanResponse;
  reorderCommunitySectionRank: BooleanResponse;
  reorderCommunityContentModules: BooleanResponse;
  reorderCommunityModuleItems: BooleanResponse;
  reorderCommunitySectionResourceRank: BooleanResponse;
  pinAnnouncement: BooleanResponse;
  unpinAnnouncement: BooleanResponse;
  createFeedback: Scalars['Boolean'];
  syncGroups: Scalars['Boolean'];
  syncGroupKey: Scalars['Boolean'];
  createIdentity: IdentityResponse;
  validateIdentity: CurrentUserResponse;
  resendValidation: Identity;
  updateAffiliation: AffiliationResponse;
  removeIdentity: CurrentUserResponse;
  resolveDiscussionBlock: Discussion;
  resolvePostBlock: Post;
  resolveTechnologyBlock: Technology;
  resolveOrganizationBlock: Organization;
  resolveUserBlock: User;
  createAppeal: Appeal;
  resolveReport: Scalars['Boolean'];
  unresolveReport: Scalars['Boolean'];
  reportDiscussion: Discussion;
  blockDiscussion: Discussion;
  reportPost: Post;
  blockPost: Post;
  reportTechnology: Technology;
  blockTechnology: Technology;
  reportOrganization: Organization;
  blockOrganization: Organization;
  reportUser: User;
  blockUser: User;
  mergeProfiles: MergeProfilesResponse;
  initiateProfileMerge: MergeProfileInitiationResponse;
  resendProfileMergeEmail: MergeProfileValidationResponse;
  createMessage: Conversation;
  markConversationFilter: Conversation;
  updateConversationReadStatus: Conversation;
  likeDiscussion: DiscussionLike;
  unlikeDiscussion: Scalars['Boolean'];
  createDiscussion: Discussion;
  updateDiscussion: Discussion;
  updatePost: Post;
  createPost: PostCreatedResponse;
  deleteDiscussion: Scalars['Boolean'];
  createReply: PostCreatedResponse;
  deleteReply: Scalars['Boolean'];
  shareDiscussion: Scalars['Boolean'];
  followDiscussion: DiscussionFollow;
  unFollowDiscussion: Scalars['Boolean'];
  pinDiscussion: BooleanResponse;
  unpinDiscussion: BooleanResponse;
  saveCampaign: Campaign;
  sendCampaign: Campaign;
  sendTestCampaign: Campaign;
  createContact: Contact;
  createContactPublic: Contact;
  createAndUpdateContacts: ContactResponse;
  updateContact: Contact;
  updateContactPublic: Contact;
  deleteContact: Contact;
  deleteContacts: ContactResponse;
  unsubscribeContact: Contact;
  unsubscribeContactPublic: Contact;
  resubscribeContact: Contact;
  resubscribeContactPublic: Contact;
  subscribeContact: Contact;
  subscribeContactPublic: Contact;
  createSegment: Segment;
  updateSegment: Segment;
  deleteSegment: Segment;
  createInvites: Array<Invite>;
  createInvitesById: Array<Invite>;
  deleteInvite: Scalars['Boolean'];
  resendInvite: Scalars['Boolean'];
  updateNotificationStatus: Notification;
  removeNotification: Scalars['Boolean'];
  markAllAs: Scalars['Boolean'];
  deleteAll: Scalars['Boolean'];
  likeOrganization: OrganizationLike;
  unlikeOrganization: Scalars['Boolean'];
  createOrganization: CreateOrganizationResponse;
  joinOrganization: JoinOrganizationResponse;
  updateOrganization: Organization;
  updateOrganizationEmailDomains: Organization;
  updateOrganizationEmployees: Scalars['Boolean'];
  companyExists: OrganizationResponse;
  shareOrganization: Scalars['Boolean'];
  deleteOrganization: CurrentUser;
  followOrganization: OrganizationFollow;
  unFollowOrganization: Scalars['Boolean'];
  likePost: TechnologyLike;
  unlikePost: Scalars['Boolean'];
  likeTechnology: TechnologyLike;
  unlikeTechnology: Scalars['Boolean'];
  deleteTechnology: Scalars['Boolean'];
  createTechnology: Technology;
  updateTechnology: Technology;
  getDocumentDownloadLink: DocumentDownloadResponse;
  shareTechnology: Scalars['Boolean'];
  followTechnology: TechnologyFollow;
  unFollowTechnology: Scalars['Boolean'];
  singleUpload: Scalars['Boolean'];
  multipleUpload: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  approveUser: Scalars['Boolean'];
  onBoardUser: CurrentUser;
  updateUser: CurrentUser;
  addUserIdentity: CurrentUserResponse;
  updateUserPreferences: Scalars['Boolean'];
  setPrimaryOrganization: CurrentUserResponse;
  setPrimaryEmail: CurrentUserResponse;
  resendEmailValidation: CurrentUserResponse;
  leaveOrganization: LeaveOrganizationResponse;
  shareUser: Scalars['Boolean'];
};


export type MutationRemoveRoleArgs = {
  data: UpdateRoleInput;
};


export type MutationAddRoleArgs = {
  data: UpdateRoleInput;
};


export type MutationRemoveAutoAppUserArgs = {
  id: Scalars['String'];
};


export type MutationAddAutoAppUserArgs = {
  data: AddAutoRoleUserInput;
};


export type MutationValidateIdentityAsAdminArgs = {
  data: ValidateIdentityInput;
};


export type MutationResendValidationAsAdminArgs = {
  identityId: Scalars['String'];
};


export type MutationAddAffiliationAsPrimaryOrganizationAsAdminArgs = {
  data: AddAffiliationInput;
};


export type MutationClearUserCacheArgs = {
  emailAddress: Scalars['String'];
};


export type MutationAddAffiliationAsAdminArgs = {
  data: AddAffiliationInput;
};


export type MutationEditAffiliationAsAdminArgs = {
  data: EditAdminAffiliationInput;
};


export type MutationRemoveAffiliationAsAdminArgs = {
  data: RemoveAffiliationInput;
};


export type MutationApproveAffiliationAsAdminArgs = {
  data: ApproveAffiliationInput;
};


export type MutationRemoveIdentityAsAdminArgs = {
  data: RemoveIdentityInput;
};


export type MutationCreateIdentityAsAdminArgs = {
  data: IdentityInput;
};


export type MutationUpdateOnboardingProgressArgs = {
  input: UpdateOnboardingProgressInput;
};


export type MutationUpdateOnboardingUserAdminDetailsArgs = {
  input: UpdateOnboardingAdminDetailsInput;
};


export type MutationCreateCommunityDiscussionArgs = {
  communityId: Scalars['String'];
  data: DiscussionInput;
};


export type MutationCreateCommunityArgs = {
  input: CreateCommunityInput;
};


export type MutationAddCommunityAppArgs = {
  input: AddCommunityAppInput;
};


export type MutationUpdateCommunityAppArgs = {
  input: UpdateCommunityAppInput;
};


export type MutationDeleteCommunityAppArgs = {
  communityAppId: Scalars['String'];
};


export type MutationAddCommunityPocArgs = {
  poc: Array<Scalars['String']>;
  communityId: Scalars['String'];
};


export type MutationDeleteCommunityArgs = {
  communityId: Scalars['String'];
};


export type MutationDeleteCommunityPocArgs = {
  poc: Array<Scalars['String']>;
  communityId: Scalars['String'];
};


export type MutationUpdateCommunityInformationArgs = {
  input: UpdateCommunityInformationInput;
};


export type MutationUpdateCommunityAdministrationArgs = {
  input: UpdateCommunityAdministrationInput;
};


export type MutationUpdateCommunityBannerArgs = {
  input: UpdateBannerInput;
};


export type MutationUpdateCommunityAvatarArgs = {
  input: UpdateAvatarInput;
};


export type MutationCreateCommunityHelpEmailArgs = {
  input: CreateCommunityHelpEmailInput;
};


export type MutationCreateAnnouncementArgs = {
  input: AddAnnouncementInput;
};


export type MutationCreateModuleItemArgs = {
  input: AddCommunityModuleItemInput;
};


export type MutationCreateModuleArgs = {
  input: AddCommunityModuleInput;
};


export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput;
};


export type MutationUpdateModuleItemArgs = {
  input: UpdateCommunityModuleItemInput;
};


export type MutationUpdateModuleArgs = {
  input: UpdateCommunityModuleInput;
};


export type MutationDeleteAnnouncementArgs = {
  announcementId: Scalars['String'];
};


export type MutationUpdateCommunitySectionArgs = {
  input: UpdateCommunitySectionInput;
};


export type MutationUpdateCommunitySectionResourceArgs = {
  socialInput?: Maybe<CommunitySocialInput>;
  blobInput?: Maybe<CommunityBlobInput>;
  input: UpdateCommunitySectionResourceInput;
};


export type MutationDeleteCommunityResourceArgs = {
  resourceId: Scalars['String'];
};


export type MutationDeleteCommunitySectionArgs = {
  sectionId: Scalars['String'];
};


export type MutationDeleteModuleArgs = {
  moduleId: Scalars['String'];
};


export type MutationDeleteModuleItemArgs = {
  itemId: Scalars['String'];
};


export type MutationAddCommunitySectionArgs = {
  communityId: Scalars['String'];
  input: CommunitySectionInput;
};


export type MutationAddCommunityResourceArgs = {
  socialInput?: Maybe<CommunitySocialInput>;
  blobInput?: Maybe<CommunityBlobInput>;
  communityId: Scalars['String'];
  input: CommunitySectionResourceInput;
};


export type MutationReorderCommunitySectionRankArgs = {
  input: CommunitySectionReorderInput;
};


export type MutationReorderCommunityContentModulesArgs = {
  input: CommunityContentModuleReorderInput;
};


export type MutationReorderCommunityModuleItemsArgs = {
  input: EntityListReorderInput;
};


export type MutationReorderCommunitySectionResourceRankArgs = {
  input: CommunityResourceReorderInput;
};


export type MutationPinAnnouncementArgs = {
  announcementId: Scalars['String'];
};


export type MutationUnpinAnnouncementArgs = {
  announcementId: Scalars['String'];
};


export type MutationCreateFeedbackArgs = {
  data: GiveFeedbackInput;
};


export type MutationCreateIdentityArgs = {
  data: IdentityInput;
};


export type MutationValidateIdentityArgs = {
  data: ValidateIdentityInput;
};


export type MutationResendValidationArgs = {
  identityId: Scalars['String'];
};


export type MutationUpdateAffiliationArgs = {
  data: EditAffiliationInput;
};


export type MutationRemoveIdentityArgs = {
  data: RemoveIdentityInput;
};


export type MutationResolveDiscussionBlockArgs = {
  discussionId: Scalars['String'];
};


export type MutationResolvePostBlockArgs = {
  discussionId: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationResolveTechnologyBlockArgs = {
  technologyId: Scalars['String'];
};


export type MutationResolveOrganizationBlockArgs = {
  organizationId: Scalars['String'];
};


export type MutationResolveUserBlockArgs = {
  userId: Scalars['String'];
};


export type MutationCreateAppealArgs = {
  blockId: Scalars['String'];
  message: Scalars['String'];
};


export type MutationResolveReportArgs = {
  reportId: Scalars['String'];
};


export type MutationUnresolveReportArgs = {
  reportId: Scalars['String'];
};


export type MutationReportDiscussionArgs = {
  message: Scalars['String'];
  discussionId: Scalars['String'];
};


export type MutationBlockDiscussionArgs = {
  message: Scalars['String'];
  discussionId: Scalars['String'];
};


export type MutationReportPostArgs = {
  message: Scalars['String'];
  postId: Scalars['String'];
  discussionId: Scalars['String'];
};


export type MutationBlockPostArgs = {
  message: Scalars['String'];
  postId: Scalars['String'];
  discussionId: Scalars['String'];
};


export type MutationReportTechnologyArgs = {
  message: Scalars['String'];
  technologyId: Scalars['String'];
};


export type MutationBlockTechnologyArgs = {
  message: Scalars['String'];
  technologyId: Scalars['String'];
};


export type MutationReportOrganizationArgs = {
  message: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationBlockOrganizationArgs = {
  message: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationReportUserArgs = {
  message: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationBlockUserArgs = {
  message: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationMergeProfilesArgs = {
  verificationCode: Scalars['String'];
  verificationId: Scalars['String'];
};


export type MutationInitiateProfileMergeArgs = {
  targetEmail: Scalars['String'];
};


export type MutationResendProfileMergeEmailArgs = {
  verificationId: Scalars['String'];
};


export type MutationCreateMessageArgs = {
  data: CreateMessageInput;
};


export type MutationMarkConversationFilterArgs = {
  conversationFilter: Scalars['String'];
  conversationId: Scalars['String'];
};


export type MutationUpdateConversationReadStatusArgs = {
  conversationId: Scalars['String'];
};


export type MutationLikeDiscussionArgs = {
  id: Scalars['String'];
};


export type MutationUnlikeDiscussionArgs = {
  id: Scalars['String'];
};


export type MutationCreateDiscussionArgs = {
  data: DiscussionInput;
};


export type MutationUpdateDiscussionArgs = {
  data: DiscussionInput;
};


export type MutationUpdatePostArgs = {
  updatedContent: Scalars['String'];
  postId: Scalars['String'];
};


export type MutationCreatePostArgs = {
  data: PostInput;
};


export type MutationDeleteDiscussionArgs = {
  id: Scalars['String'];
};


export type MutationCreateReplyArgs = {
  data: ReplyInput;
};


export type MutationDeleteReplyArgs = {
  id: Scalars['String'];
};


export type MutationShareDiscussionArgs = {
  data: ShareInput;
};


export type MutationFollowDiscussionArgs = {
  discussionId: Scalars['String'];
};


export type MutationUnFollowDiscussionArgs = {
  discussionId: Scalars['String'];
};


export type MutationPinDiscussionArgs = {
  discussionId: Scalars['String'];
};


export type MutationUnpinDiscussionArgs = {
  discussionId: Scalars['String'];
};


export type MutationSaveCampaignArgs = {
  data: CampaignInput;
};


export type MutationSendCampaignArgs = {
  data: SendCampaignInput;
};


export type MutationSendTestCampaignArgs = {
  data: SendTestCampaignInput;
};


export type MutationCreateContactArgs = {
  data: CreateContactInput;
};


export type MutationCreateContactPublicArgs = {
  data: CreateContactInput;
};


export type MutationCreateAndUpdateContactsArgs = {
  data: CreateContactListInput;
};


export type MutationUpdateContactArgs = {
  data: UpdateContactInput;
  id: Scalars['String'];
};


export type MutationUpdateContactPublicArgs = {
  data: UpdateContactInput;
  id: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['String'];
};


export type MutationDeleteContactsArgs = {
  data: DeleteContactListInput;
};


export type MutationUnsubscribeContactArgs = {
  campaignId: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationUnsubscribeContactPublicArgs = {
  campaignId: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationResubscribeContactArgs = {
  id: Scalars['String'];
};


export type MutationResubscribeContactPublicArgs = {
  id: Scalars['String'];
};


export type MutationSubscribeContactArgs = {
  id: Scalars['String'];
};


export type MutationSubscribeContactPublicArgs = {
  id: Scalars['String'];
};


export type MutationCreateSegmentArgs = {
  data: CreateSegmentInput;
};


export type MutationUpdateSegmentArgs = {
  data: UpdateSegmentInput;
  id: Scalars['Float'];
};


export type MutationDeleteSegmentArgs = {
  id: Scalars['Float'];
};


export type MutationCreateInvitesArgs = {
  data: CreateInviteInput;
};


export type MutationCreateInvitesByIdArgs = {
  data: CreateInviteInput;
  userId: Scalars['String'];
};


export type MutationDeleteInviteArgs = {
  id: Scalars['String'];
};


export type MutationResendInviteArgs = {
  id: Scalars['String'];
};


export type MutationUpdateNotificationStatusArgs = {
  data: UpdateNotificationInput;
  id: Scalars['String'];
};


export type MutationRemoveNotificationArgs = {
  id: Scalars['String'];
};


export type MutationMarkAllAsArgs = {
  updateNotificationInput: UpdateNotificationInput;
};


export type MutationDeleteAllArgs = {
  notificationInput: NotificationInput;
};


export type MutationLikeOrganizationArgs = {
  organization: Scalars['String'];
};


export type MutationUnlikeOrganizationArgs = {
  organization: Scalars['String'];
};


export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};


export type MutationJoinOrganizationArgs = {
  data: JoinAffiliationInput;
};


export type MutationUpdateOrganizationArgs = {
  data: UpdateOrganizationInput;
  id: Scalars['String'];
};


export type MutationUpdateOrganizationEmailDomainsArgs = {
  data: UpdateOrgEmailDomainsInput;
  id: Scalars['String'];
};


export type MutationUpdateOrganizationEmployeesArgs = {
  data: UpdateOrganizationEmployeesInput;
};


export type MutationCompanyExistsArgs = {
  data: ValidateOrganizationInput;
};


export type MutationShareOrganizationArgs = {
  data: ShareInput;
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['String'];
};


export type MutationFollowOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationUnFollowOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationLikePostArgs = {
  id: Scalars['String'];
};


export type MutationUnlikePostArgs = {
  id: Scalars['String'];
};


export type MutationLikeTechnologyArgs = {
  technology: Scalars['String'];
};


export type MutationUnlikeTechnologyArgs = {
  technology: Scalars['String'];
};


export type MutationDeleteTechnologyArgs = {
  id: Scalars['String'];
};


export type MutationCreateTechnologyArgs = {
  files: Array<Scalars['Upload']>;
  data: TechnologyUpsertInput;
};


export type MutationUpdateTechnologyArgs = {
  files: Array<Scalars['Upload']>;
  data: TechnologyUpsertInput;
  id: Scalars['String'];
};


export type MutationGetDocumentDownloadLinkArgs = {
  documentId: Scalars['String'];
  technologyId: Scalars['String'];
};


export type MutationShareTechnologyArgs = {
  data: ShareInput;
};


export type MutationFollowTechnologyArgs = {
  technology: Scalars['String'];
};


export type MutationUnFollowTechnologyArgs = {
  technology: Scalars['String'];
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationMultipleUploadArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationApproveUserArgs = {
  data: PendingApprovalInput;
};


export type MutationOnBoardUserArgs = {
  data: OnboardUserInput;
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationAddUserIdentityArgs = {
  emailAddress: Scalars['String'];
};


export type MutationUpdateUserPreferencesArgs = {
  data: UpdateUserPreferencesInput;
};


export type MutationSetPrimaryOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetPrimaryEmailArgs = {
  emailAddress: Scalars['String'];
};


export type MutationResendEmailValidationArgs = {
  identityId: Scalars['String'];
};


export type MutationLeaveOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationShareUserArgs = {
  data: ShareInput;
};

export type Notification = {
  id: Scalars['ID'];
  notificationType?: Maybe<Scalars['String']>;
  notificationStatus?: Maybe<Scalars['String']>;
  notificationClassification?: Maybe<Scalars['String']>;
  actionUser?: Maybe<User>;
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  reply?: Maybe<Post>;
};

export type NotificationDiscussion = {
  id: Scalars['ID'];
  title: Scalars['String'];
  communityId?: Maybe<Scalars['ID']>;
};

export type NotificationInput = {
  types: Array<Scalars['String']>;
  status: Array<Scalars['String']>;
  classification: Array<Scalars['String']>;
};

export type NotificationOrganization = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isCompany: Scalars['Boolean'];
};

export type NotificationPost = {
  id: Scalars['ID'];
};

export type NotificationReply = {
  id: Scalars['ID'];
};

export type NotificationSubscription = {
  id: Scalars['ID'];
  reply?: Maybe<NotificationReply>;
  post?: Maybe<NotificationPost>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  notificationType?: Maybe<Scalars['String']>;
  notificationStatus?: Maybe<Scalars['String']>;
  notificationClassification?: Maybe<Scalars['String']>;
  actionUser?: Maybe<NotificationUser>;
  user?: Maybe<NotificationUser>;
  technology?: Maybe<NotificationTechnology>;
  organization?: Maybe<NotificationOrganization>;
  discussion?: Maybe<NotificationDiscussion>;
};

export type NotificationTechnology = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NotificationUser = {
  id: Scalars['ID'];
  name: Scalars['String'];
  emailAddress: Scalars['String'];
};

export type OnboardingSection = {
  id: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  isSupplementary?: Maybe<Scalars['Boolean']>;
  dateCompleted?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  shortTitle?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  steps?: Maybe<Array<OnboardingStep>>;
  pocs?: Maybe<Array<OnboardingSectionPoc>>;
};

export type OnboardingSectionListResponse = {
  items: Array<OnboardingSection>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type OnboardingSectionPoc = {
  id: Scalars['ID'];
  portalUser?: Maybe<User>;
  orderNumber: Scalars['Float'];
  manualEmail: Scalars['String'];
  name: Scalars['String'];
  parentSection: OnboardingSection;
};

export type OnboardingSectionProgress = {
  dateCompleted: Scalars['DateTime'];
};

export type OnboardingStep = {
  id: Scalars['ID'];
  title: Scalars['String'];
  orderNumber: Scalars['Float'];
  link?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  completable?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  dateCompleted?: Maybe<Scalars['DateTime']>;
  divideBefore?: Maybe<Scalars['Boolean']>;
  parentSection: OnboardingSection;
  linkedFile?: Maybe<DocumentBlob>;
};

export type OnboardingStepProgress = {
  dateCompleted: Scalars['DateTime'];
};

export type OnboardingUserProgress = {
  user: UserWithEmail;
  stepsCompleted: Scalars['Int'];
  totalSteps: Scalars['Int'];
  supervisorName: Scalars['String'];
  supervisorEmail: Scalars['String'];
  isNewGovernmentEmployee?: Maybe<Scalars['Boolean']>;
  updateDate: Scalars['DateTime'];
  completedDate?: Maybe<Scalars['DateTime']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  createDate: Scalars['DateTime'];
};

export type OnboardingUserProgressListResponse = {
  items: Array<OnboardingUserProgress>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type OnboardUserInput = {
  categories: Array<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  referralType?: Maybe<Scalars['String']>;
  referralComments?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  orgSymbol?: Maybe<Scalars['String']>;
  dutyTitle?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type Organization = {
  id: Scalars['ID'];
  verificationPending: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  missionStatement?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orgDescription?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  isCompany: Scalars['Boolean'];
  adminApprovalRequired: Scalars['Boolean'];
  autoJoinWithDomain: Scalars['Boolean'];
  emailDomains?: Maybe<Array<EmailDomain>>;
  likeCount: Scalars['Float'];
  technologies?: Maybe<Array<Technology>>;
  pointOfContacts?: Maybe<Array<User>>;
  likes?: Maybe<Array<OrganizationLike>>;
  currentUserLike?: Maybe<Scalars['Boolean']>;
  currentUserFollows?: Maybe<Scalars['Boolean']>;
  views?: Maybe<Array<OrganizationView>>;
  documents?: Maybe<Array<OrganizationDocument>>;
  shares?: Maybe<Array<OrganizationShare>>;
  notifications?: Maybe<Array<Notification>>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  user_org_access?: Maybe<Array<AppUserOrgAccess>>;
  app_org_settings?: Maybe<Array<AppOrgSettings>>;
  viewCount: Scalars['Float'];
  cageCode?: Maybe<Scalars['String']>;
  dunsNumber?: Maybe<Scalars['String']>;
  naicsCodes?: Maybe<Array<OrganizationNaicsCode>>;
  externalLinks?: Maybe<Array<OrganizationExternalLink>>;
  businessTypes?: Maybe<Array<OrganizationBusinessType>>;
  sbirSttrAwards?: Maybe<Array<OrganizationSbirSttrAward>>;
  principals?: Maybe<Array<OrganizationPrincipal>>;
  coreCompetencies?: Maybe<Array<OrganizationCompetency>>;
  children: Array<Organization>;
  childrenCount?: Maybe<Scalars['Float']>;
  parent?: Maybe<Organization>;
  governmentLevel?: Maybe<Scalars['String']>;
  organizationFollows?: Maybe<Array<OrganizationFollow>>;
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  reports?: Maybe<Array<OrganizationReport>>;
  blocks?: Maybe<Array<OrganizationBlock>>;
  affiliations?: Maybe<Array<Affiliation>>;
};

export type OrganizationBlock = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type OrganizationBusinessType = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  name: Scalars['String'];
};

export type OrganizationCompetency = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  name: Scalars['String'];
  description: Scalars['String'];
};

export type OrganizationDocument = {
  id: Scalars['ID'];
  sourceDocumentId: Scalars['String'];
  uploadedBy: Scalars['String'];
  originalFileName: Scalars['String'];
  fileName: Scalars['String'];
  fileExtension: Scalars['String'];
  path: Scalars['String'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  secure: Scalars['Boolean'];
  usersWithAccess?: Maybe<Array<User>>;
  organization?: Maybe<Organization>;
};

export type OrganizationExternalLink = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type OrganizationFollow = {
  id: Scalars['ID'];
  organization: Organization;
  user: User;
  createDate: Scalars['DateTime'];
};

export type OrganizationLike = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type OrganizationNaicsCode = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  code: Scalars['String'];
  descriptor: Scalars['String'];
};

export type OrganizationPrincipal = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  name: Scalars['String'];
  title: Scalars['String'];
};

export type OrganizationReport = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type OrganizationResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Organization>;
};

export type OrganizationSbirSttrAward = {
  id: Scalars['ID'];
  organization?: Maybe<Organization>;
  name: Scalars['String'];
  year: Scalars['String'];
  type: Scalars['String'];
  phase: Scalars['String'];
  link: Scalars['String'];
};

export type OrganizationShare = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  organization?: Maybe<OrganizationShare>;
};

export type OrganizationView = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type OrgEmailInput = {
  email: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type PaginatedResponseClass = {
  items: Array<JuicerPost>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type PaginationInput = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};

export type PendingApprovalInput = {
  userId: Scalars['String'];
  organizationId: Scalars['String'];
  approved: Scalars['Boolean'];
  denyMessage?: Maybe<Scalars['String']>;
};

export type Post = {
  id: Scalars['ID'];
  content: Scalars['String'];
  createdBy: User;
  repliesCount?: Maybe<Scalars['Float']>;
  replies: Array<Post>;
  parentPost?: Maybe<Post>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  lastPostEditDate?: Maybe<Scalars['DateTime']>;
  likeCount: Scalars['Float'];
  postLikes?: Maybe<Array<PostLike>>;
  notifications?: Maybe<Array<Notification>>;
  reports?: Maybe<Array<PostReport>>;
  blocks?: Maybe<Array<PostBlock>>;
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
};

export type PostBlock = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion: Discussion;
  post: Post;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type PostCreatedResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<Post>;
  communityId?: Maybe<Scalars['String']>;
};

export type PostInput = {
  discussionId: Scalars['String'];
  content: Scalars['String'];
};

export type PostLike = {
  id: Scalars['ID'];
  post?: Maybe<Post>;
  user?: Maybe<User>;
};

export type PostReport = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion: Discussion;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type PostsResponse = {
  items: Array<Post>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProfileMergeVerification = {
  id: Scalars['ID'];
  verified: Scalars['Boolean'];
  verificationToken: Scalars['String'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  tokenExpirationDate: Scalars['DateTime'];
  emailToMerge: Scalars['String'];
  mergeInitiator: User;
};

export type Query = {
  getModeratorSuperAdmins: Array<CurrentUser>;
  getAutoAppUsers: Array<AutoAppRole>;
  getOnboardingSections: OnboardingSectionListResponse;
  getOnboardingProgressList: OnboardingUserProgressListResponse;
  getDiscussionsForCommunity: DiscussionResponse;
  getHomeHelpMessage: HomeHelpMessageResponse;
  getMyCommunities: SearchCommunitiesResponse;
  getCommunityMembers: SearchUserResponse;
  searchCommunities: SearchCommunitiesResponse;
  getCommunityById: GetCommunityResponse;
  getCommunityBlobById: CommunityBlob;
  getSectionResources: Array<SectionResource>;
  searchAnnouncementsByCommunity: SearchAnnouncementsResponse;
  getCommunityModules: GetCommunityModulesResponse;
  searchCommunityModuleItems: SearchCommunityModuleItemsResponse;
  getAnnouncementsByCommunity: SearchAnnouncementsResponse;
  parseCommunityAppURL: GetUrlParserResponse;
  getCommunityApp: GetCommunityAppResponse;
  getCommunityApps: GetManyCommunityAppResponse;
  getCategoriesByCommunity: CategoryListResponse;
  getDocument: DocumentBlob;
  searchDocuments: DocumentListResponse;
  uploadDocument: DocumentBlob;
  getEvents: SearchEventsResponse;
  getUserIdentity: User;
  searchUserIdentities: SearchUserIdentitiesResponse;
  getBlocks: BlocksResponse;
  getActiveBlock: Block;
  getReports: ReportsResponse;
  getUnresolvedReportCount: Scalars['Float'];
  getEntityReportCount: ReportCountsResponse;
  userProfileByMergeRequestId: User;
  validateProfileMerge: MergeProfileReadyResponse;
  getUnReadMessageCount: Scalars['Float'];
  getUsersConversations: GetConversationsResponse;
  getConversation: Conversation;
  getMessagesByConversation: GetMessagesByConversationResponse;
  likesDiscussion: Scalars['Boolean'];
  getDiscussions: DiscussionResponse;
  getDiscussion: Discussion;
  getPostsByDiscussion: PostsResponse;
  getRepliesByPost: PostsResponse;
  getDiscussionFollows: GetDiscussionFollowsResponse;
  findEmailTracking: EmailTracking;
  findEmailTrackings: Array<EmailTracking>;
  findCampaign: Campaign;
  findCampaigns: Array<Campaign>;
  findCampaignsWithCount: CampaignsWithCountResponse;
  findCampaignByTitle: Scalars['Boolean'];
  getCampaignAnalytics: CampaignRollup;
  findContact: Contact;
  findContacts: Array<Contact>;
  findContactFromEmail: Contact;
  findContactsFromEmail: Array<Contact>;
  findContactsFromFilters: Array<Contact>;
  findContactsFromFiltersCount: Scalars['Float'];
  findContactsFromFiltersAndList: Array<Contact>;
  findSegment: Segment;
  findSegments: Array<Segment>;
  findSegmentsWithCount: SegmentsWithCountResponse;
  findSegmentContacts: Array<Contact>;
  findSegmentsContacts: Array<Contact>;
  findSegmentsContactsCount: Array<Scalars['Float']>;
  findEnvironmentVariables: Scalars['JSONObject'];
  invite: Invite;
  searchInvites: SearchInviteResponse;
  adminSearchInvites: SearchInviteResponse;
  getJuicerPosts: JuicerPostsResponse;
  states: Array<State>;
  getNewNotificationsCount: Scalars['Float'];
  getNotifications: GetNotificationsResponse;
  likesOrganization: Scalars['Boolean'];
  organization: Organization;
  organizationDetails: Organization;
  searchOrganizations: SearchOrganizationResponse;
  searchOnboardingOrganizations: Array<Organization>;
  searchOnboardingCompanies: SearchOrganizationResponse;
  getOrganizationFollows: GetOrganizationFollowsResponse;
  likesPost: Scalars['Boolean'];
  technologyCategories: Array<Category>;
  searchCategories: Array<Category>;
  getCategoriesByList: Array<Category>;
  likesTechnology: Scalars['Boolean'];
  technology: Technology;
  searchTechnologies: SearchTechnologyResponse;
  getTechnologiesByOrganization: Array<Technology>;
  getTechnologyFollows: GetTechnologyFollowsResponse;
  findTemplateType: TemplateType;
  findTemplateTypes: Array<TemplateType>;
  getTimeGraph: Scalars['JSON'];
  user: CurrentUser;
  getCurrentUserPermissions: UserPermissions;
  getUserGroupPermissions: UserGroupPermissions;
  userProfile: User;
  getUserLookup: SearchUserResponse;
  getUsersByOrganization: Array<User>;
  getUsersByOrganizationWithRoles: Array<User>;
  getPendingApprovals: Array<User>;
  getDiscussionsByUser: DiscussionResponse;
  searchUsers: SearchUserResponse;
  getUserFollowCounts: UserFollowCountsResponse;
  canLeaveOrganization: CanLeaveOrganizationResponse;
};


export type QueryGetDiscussionsForCommunityArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  categoryFilter?: Maybe<IdFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
  communityId: Scalars['String'];
};


export type QueryGetMyCommunitiesArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
};


export type QueryGetCommunityMembersArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  searchTerm: Scalars['String'];
  communityId: Scalars['String'];
};


export type QuerySearchCommunitiesArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryGetCommunityByIdArgs = {
  communityId: Scalars['String'];
};


export type QueryGetCommunityBlobByIdArgs = {
  fileId: Scalars['String'];
};


export type QueryGetSectionResourcesArgs = {
  communityId: Scalars['String'];
};


export type QuerySearchAnnouncementsByCommunityArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  categoryFilter?: Maybe<IdFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
  communityId: Scalars['String'];
};


export type QueryGetCommunityModulesArgs = {
  pagination?: Maybe<PaginationInput>;
  communityId: Scalars['String'];
};


export type QuerySearchCommunityModuleItemsArgs = {
  pagination?: Maybe<PaginationInput>;
  searchTerm: Scalars['String'];
  moduleId: Scalars['String'];
};


export type QueryGetAnnouncementsByCommunityArgs = {
  pagination?: Maybe<PaginationInput>;
  communityId: Scalars['String'];
};


export type QueryParseCommunityAppUrlArgs = {
  url: Scalars['String'];
};


export type QueryGetCommunityAppArgs = {
  communityAppId: Scalars['String'];
};


export type QueryGetCommunityAppsArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryGetCategoriesByCommunityArgs = {
  communityId: Scalars['String'];
};


export type QueryGetDocumentArgs = {
  documentId: Scalars['String'];
};


export type QuerySearchDocumentsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryUploadDocumentArgs = {
  documentBase64: Scalars['String'];
  documentName: Scalars['String'];
};


export type QueryGetEventsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryGetUserIdentityArgs = {
  userId: Scalars['String'];
};


export type QuerySearchUserIdentitiesArgs = {
  pagination?: Maybe<PaginationInput>;
  data: SearchIdentitiesInput;
};


export type QueryGetBlocksArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  statusFilter?: Maybe<StatusFilterInput>;
  typeFilter?: Maybe<TypeFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
  entityFilter?: Maybe<EntityFilterInput>;
};


export type QueryGetActiveBlockArgs = {
  entityFilter: EntityFilterInput;
};


export type QueryGetReportsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  statusFilter?: Maybe<StatusFilterInput>;
  typeFilter?: Maybe<TypeFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
  entityFilter?: Maybe<EntityFilterInput>;
};


export type QueryGetEntityReportCountArgs = {
  entityFilter?: Maybe<EntityFilterInput>;
};


export type QueryUserProfileByMergeRequestIdArgs = {
  id: Scalars['String'];
};


export type QueryValidateProfileMergeArgs = {
  verificationCode: Scalars['String'];
  verificationId: Scalars['String'];
};


export type QueryGetUsersConversationsArgs = {
  pagination?: Maybe<PaginationInput>;
  conversationId?: Maybe<Scalars['String']>;
  conversationFilter?: Maybe<Scalars['String']>;
};


export type QueryGetConversationArgs = {
  conversationId: Scalars['String'];
};


export type QueryGetMessagesByConversationArgs = {
  pagination?: Maybe<PaginationInput>;
  conversationId: Scalars['String'];
};


export type QueryLikesDiscussionArgs = {
  id: Scalars['String'];
};


export type QueryGetDiscussionsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  categoryFilter?: Maybe<IdFilterInput>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryGetDiscussionArgs = {
  id: Scalars['String'];
};


export type QueryGetPostsByDiscussionArgs = {
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
  postId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type QueryGetRepliesByPostArgs = {
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
  replyId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type QueryGetDiscussionFollowsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
};


export type QueryFindEmailTrackingArgs = {
  id: Scalars['Float'];
};


export type QueryFindCampaignArgs = {
  id: Scalars['Float'];
};


export type QueryFindCampaignsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindCampaignsWithCountArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindCampaignByTitleArgs = {
  title: Scalars['String'];
};


export type QueryGetCampaignAnalyticsArgs = {
  id: Scalars['Float'];
};


export type QueryFindContactArgs = {
  id: Scalars['String'];
};


export type QueryFindContactsArgs = {
  active: Scalars['Boolean'];
  audienceId: Scalars['Float'];
};


export type QueryFindContactFromEmailArgs = {
  emailAddress: Scalars['String'];
  audienceId: Scalars['Float'];
};


export type QueryFindContactsFromEmailArgs = {
  emailAddresses: Array<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindContactsFromFiltersArgs = {
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindContactsFromFiltersCountArgs = {
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindContactsFromFiltersAndListArgs = {
  emailAddresses?: Maybe<Array<Scalars['String']>>;
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindSegmentArgs = {
  id: Scalars['Float'];
};


export type QueryFindSegmentsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  isArchived: Scalars['Boolean'];
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindSegmentsWithCountArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  isArchived: Scalars['Boolean'];
  filters?: Maybe<Scalars['String']>;
  audienceId: Scalars['Float'];
};


export type QueryFindSegmentContactsArgs = {
  id?: Maybe<Scalars['Float']>;
};


export type QueryFindSegmentsContactsArgs = {
  segmentIds: Array<Scalars['Float']>;
};


export type QueryFindSegmentsContactsCountArgs = {
  segmentIds: Array<Scalars['Float']>;
};


export type QueryInviteArgs = {
  id: Scalars['String'];
};


export type QuerySearchInvitesArgs = {
  pagination?: Maybe<PaginationInput>;
  emailAddress?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryAdminSearchInvitesArgs = {
  pagination?: Maybe<PaginationInput>;
  data: SearchInvitesInput;
};


export type QueryGetJuicerPostsArgs = {
  pagination?: Maybe<PaginationInput>;
};


export type QueryStatesArgs = {
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationsArgs = {
  pagination?: Maybe<PaginationInput>;
  notificationInput: NotificationInput;
};


export type QueryLikesOrganizationArgs = {
  organization: Scalars['String'];
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationDetailsArgs = {
  id: Scalars['String'];
};


export type QuerySearchOrganizationsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  isCompany?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QuerySearchOnboardingOrganizationsArgs = {
  sort?: Maybe<SortInput>;
  parentId?: Maybe<Scalars['String']>;
  searchTerm: Scalars['String'];
};


export type QuerySearchOnboardingCompaniesArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  searchTerm: Scalars['String'];
};


export type QueryGetOrganizationFollowsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
};


export type QueryLikesPostArgs = {
  id: Scalars['String'];
};


export type QuerySearchCategoriesArgs = {
  communityIds: IdFilterInput;
};


export type QueryGetCategoriesByListArgs = {
  listId: Scalars['String'];
};


export type QueryLikesTechnologyArgs = {
  technology: Scalars['String'];
};


export type QueryTechnologyArgs = {
  id: Scalars['String'];
};


export type QuerySearchTechnologiesArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  trlFilter?: Maybe<RangeFilterInput>;
  mrlFilter?: Maybe<RangeFilterInput>;
  technologyFilter?: Maybe<IdFilterInput>;
  orgName?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryGetTechnologiesByOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetTechnologyFollowsArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
};


export type QueryFindTemplateTypeArgs = {
  id: Scalars['Float'];
};


export type QueryGetTimeGraphArgs = {
  id: Scalars['Float'];
};


export type QueryUserProfileArgs = {
  id: Scalars['String'];
};


export type QueryGetUserLookupArgs = {
  includeSelf?: Maybe<Scalars['Boolean']>;
  pagination?: Maybe<PaginationInput>;
  name: Scalars['String'];
};


export type QueryGetUsersByOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetUsersByOrganizationWithRolesArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetPendingApprovalsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetDiscussionsByUserArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
};


export type QuerySearchUsersArgs = {
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isGovernment?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
};


export type QueryCanLeaveOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type RangeFilterInput = {
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
};

export enum Relation {
  Employee = 'Employee',
  Contractor = 'Contractor',
  Other = 'Other'
}

export type RemoveAffiliationInput = {
  identityId: Scalars['String'];
  userId: Scalars['String'];
  affiliationId?: Maybe<Scalars['String']>;
};

export type RemoveIdentityInput = {
  identityId: Scalars['String'];
  userId: Scalars['String'];
};

export type ReplyInput = {
  postId: Scalars['String'];
  content: Scalars['String'];
};

export type Report = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type ReportCountsResponse = {
  unresolvedCount: Scalars['Int'];
  resolvedCount: Scalars['Int'];
  reportCount: Scalars['Int'];
};

export type ReportsResponse = {
  items: Array<Report>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
  unresolvedCount: Scalars['Int'];
  resolvedCount: Scalars['Int'];
  reportCount: Scalars['Int'];
};

export type ResourceBookmark = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  communityResource?: Maybe<SectionResource>;
  user?: Maybe<User>;
};

export type SearchAnnouncementsResponse = {
  items: Array<Announcement>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchCommunitiesResponse = {
  items: Array<CommunitySummary>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchCommunityModuleItemsResponse = {
  items: Array<CommunityModuleItem>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchEventsResponse = {
  items: Array<Event>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchIdentitiesInput = {
  searchTerm?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  sorting: Array<SortInput>;
};

export type SearchInviteResponse = {
  items: Array<Invite>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchInvitesInput = {
  emailAddress?: Maybe<Scalars['String']>;
  inviteStatus?: Maybe<Array<Scalars['Float']>>;
  sorting?: Maybe<Array<SortInput>>;
};

export type SearchOrganizationResponse = {
  items: Array<Organization>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchTechnologyResponse = {
  items: Array<Technology>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchUserIdentitiesResponse = {
  items: Array<UserWithEmail>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SearchUserResponse = {
  items: Array<User>;
  totalCount: Scalars['Int'];
  errorMessage?: Maybe<Scalars['String']>;
};

export type SectionResource = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ItemType;
  linkedFile?: Maybe<CommunityBlob>;
  social?: Maybe<CommunitySocial>;
  link?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  createDate: Scalars['DateTime'];
  bookmarks?: Maybe<Array<ResourceBookmark>>;
};

export type Segment = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filterQuery: Scalars['JSON'];
  isArchived: Scalars['Boolean'];
  createdDate: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<Scalars['ID']>;
  campaigns: Array<Campaign>;
  audience: Audience;
  contacts?: Maybe<Array<Contact>>;
  lastUsedDate?: Maybe<Scalars['DateTime']>;
};

export type SegmentsWithCountResponse = {
  segments: Array<Segment>;
  nonPaginatedCount: Scalars['Int'];
};

export type SendCampaignInput = {
  campaign: CampaignInput;
  html: Scalars['String'];
};

export type SendTestCampaignInput = {
  campaign: CampaignInput;
  html: Scalars['String'];
  testEmails: Array<Scalars['String']>;
};

export type Share = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
};

export type ShareInput = {
  id: Scalars['String'];
  emailAddressList: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type SortInput = {
  direction?: Maybe<DirType>;
  field: Scalars['String'];
};

export enum SourceType {
  Emailqueue = 'EMAILQUEUE',
  Gets3Data = 'GETS3DATA'
}

export type State = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type StatusFilterInput = {
  status: Scalars['String'];
};

export type Subscription = {
  messageSubscription: MessageSubscription;
  notificationSubscription: NotificationSubscription;
};

export type Technology = {
  id: Scalars['ID'];
  description: Scalars['String'];
  name: Scalars['String'];
  profileImagePath: Scalars['String'];
  likeCount: Scalars['Float'];
  currentUserLike?: Maybe<Scalars['Boolean']>;
  currentUserFollows?: Maybe<Scalars['Boolean']>;
  techReadinessLevel: Scalars['Float'];
  mfgReadinessLevel: Scalars['Float'];
  currentReadinessResults?: Maybe<Scalars['JSONObject']>;
  organization: Organization;
  categories: Array<Category>;
  pointOfContacts?: Maybe<Array<User>>;
  likes?: Maybe<Array<TechnologyLike>>;
  views?: Maybe<Array<TechnologyView>>;
  shares?: Maybe<Array<TechnologyShare>>;
  documents?: Maybe<Array<TechnologyDocument>>;
  notifications?: Maybe<Array<Notification>>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  viewCount: Scalars['Float'];
  externalLinks?: Maybe<Array<TechnologyExternalLink>>;
  patents?: Maybe<Array<TechnologyPatent>>;
  copyrights?: Maybe<Array<TechnologyCopyright>>;
  trademarks?: Maybe<Array<TechnologyTrademark>>;
  technologyFollows?: Maybe<Array<TechnologyFollow>>;
  reports?: Maybe<Array<TechnologyReport>>;
  blocks?: Maybe<Array<TechnologyBlock>>;
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
};

export type TechnologyBlock = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type TechnologyCopyright = {
  id: Scalars['ID'];
  technology?: Maybe<Technology>;
  name: Scalars['String'];
};

export type TechnologyDocument = {
  id: Scalars['ID'];
  sourceDocumentId: Scalars['String'];
  uploadedBy: Scalars['String'];
  originalFileName: Scalars['String'];
  fileName: Scalars['String'];
  fileExtension: Scalars['String'];
  path: Scalars['String'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  secure: Scalars['Boolean'];
  usersWithAccess?: Maybe<Array<User>>;
  technology?: Maybe<Technology>;
  technologyDocumentType: Scalars['String'];
};

export type TechnologyDocumentInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['Float'];
  secure?: Maybe<Scalars['Boolean']>;
  isNewFile?: Maybe<Scalars['Boolean']>;
};

export type TechnologyExternalLink = {
  id: Scalars['ID'];
  technology?: Maybe<Technology>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type TechnologyFollow = {
  id: Scalars['ID'];
  technology: Technology;
  user: User;
  createDate: Scalars['DateTime'];
};

export type TechnologyLike = {
  id: Scalars['ID'];
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
};

export type TechnologyPatent = {
  id: Scalars['ID'];
  technology?: Maybe<Technology>;
  name: Scalars['String'];
};

export type TechnologyReport = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type TechnologyShare = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  technology?: Maybe<Technology>;
};

export type TechnologyTrademark = {
  id: Scalars['ID'];
  technology?: Maybe<Technology>;
  name: Scalars['String'];
};

export type TechnologyUpsertInput = {
  aboutTechnology: AboutTechnologyInput;
  categories: Array<Scalars['String']>;
  techReadinessLevel: Scalars['Float'];
  mfgReadinessLevel: Scalars['Float'];
  currentReadinessResults?: Maybe<Scalars['JSONObject']>;
  pointsOfContact: Array<Scalars['String']>;
  externalLinks?: Maybe<Array<Scalars['JSONObject']>>;
  patents?: Maybe<Array<Scalars['JSONObject']>>;
  copyrights?: Maybe<Array<Scalars['JSONObject']>>;
  trademarks?: Maybe<Array<Scalars['JSONObject']>>;
  technologyDocuments?: Maybe<Array<TechnologyDocumentInput>>;
  organizationId: Scalars['ID'];
};

export type TechnologyView = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type TemplateType = {
  id: Scalars['Int'];
  description: Scalars['String'];
  campaign: Array<Campaign>;
};

export enum TransactionNames {
  PendingConfirmation = 'PENDING_CONFIRMATION',
  SubscribedConfirmation = 'SUBSCRIBED_CONFIRMATION',
  UpdateRequest = 'UPDATE_REQUEST',
  UpdatedConfirmation = 'UPDATED_CONFIRMATION',
  UnsubscribeRequest = 'UNSUBSCRIBE_REQUEST',
  UnsubscribedConfirmation = 'UNSUBSCRIBED_CONFIRMATION',
  ResubscribedConfirmation = 'RESUBSCRIBED_CONFIRMATION'
}

export type TypeFilterInput = {
  types: Array<Scalars['String']>;
};

export type UpdateAnnouncementInput = {
  communityId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  categories: Array<Scalars['String']>;
  attachedResources: Array<CommunityAnnouncementResourceInput>;
  announcementId: Scalars['String'];
};

export type UpdateAvatarInput = {
  communityId: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export type UpdateBannerInput = {
  communityId: Scalars['String'];
  banner?: Maybe<Scalars['String']>;
};

export type UpdateCampaignInput = {
  id: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  senderEmail?: Maybe<Scalars['String']>;
  templateTypeId?: Maybe<Scalars['Float']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isSent?: Maybe<Scalars['Boolean']>;
  campaignTemplateInputs?: Maybe<Array<CampaignTemplateInput>>;
  emailTracking?: Maybe<EmailTrackingInput>;
  segmentIds?: Maybe<Array<Scalars['Float']>>;
  audienceId?: Maybe<Scalars['Int']>;
};

export type UpdateCommunityAdministrationInput = {
  communityId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  bannerHexColor: Scalars['String'];
  groupKey: Scalars['String'];
  helpContact: Scalars['String'];
  groupCalendarUrl: Scalars['String'];
  features: CommunityFeaturesInput;
};

export type UpdateCommunityAppInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateCommunityInformationInput = {
  communityId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  bannerHexColor: Scalars['String'];
  helpContact: Scalars['String'];
  groupCalendarUrl: Scalars['String'];
  features: CommunityFeaturesInput;
};

export type UpdateCommunityModuleInput = {
  communityId: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  moduleId: Scalars['String'];
};

export type UpdateCommunityModuleItemInput = {
  moduleId: Scalars['String'];
  title: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  embedUrl?: Maybe<Scalars['String']>;
  attachedResources: Array<CommunityTabContentResourceInput>;
  itemId: Scalars['String'];
};

export type UpdateCommunitySectionInput = {
  sectionTitle: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateCommunitySectionResourceInput = {
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  orderNumber: Scalars['Float'];
  link?: Maybe<Scalars['String']>;
  type: ItemType;
  linkedFile?: Maybe<Scalars['String']>;
  socialId?: Maybe<Scalars['String']>;
  sectionId: Scalars['String'];
  id: Scalars['String'];
};

export type UpdateContactInput = {
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactSource?: Maybe<Scalars['String']>;
  dunsNumber?: Maybe<Scalars['String']>;
  naicsCode?: Maybe<Scalars['String']>;
  companyCertifications?: Maybe<Scalars['String']>;
  techFocusAreas?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<ContactStatus>;
  isArchived?: Maybe<Scalars['Boolean']>;
  audienceId: Scalars['Int'];
};

export type UpdateContactListInput = {
  contactList: Array<UpdateContactInput>;
};

export type UpdateLastUsedInput = {
  lastUsedDate?: Maybe<Scalars['DateTime']>;
};

export type UpdateNotificationInput = {
  updatedNotificationStatus?: Maybe<Scalars['Float']>;
  notificationStatus?: Maybe<Scalars['Float']>;
};

export type UpdateOnboardingAdminDetailsInput = {
  supervisorName?: Maybe<Scalars['String']>;
  supervisorEmail?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  isNewGovernmentEmployee: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type UpdateOnboardingAdminDetailsResponse = {
  errorMessage?: Maybe<Scalars['String']>;
  result?: Maybe<OnboardingUserProgress>;
  success: Scalars['Boolean'];
};

export type UpdateOnboardingProgressInput = {
  completedStepIds: Array<Scalars['String']>;
};

export type UpdateOrganizationEmployeesInput = {
  organizationId: Scalars['String'];
  updatedEmployeeRoles: Array<EmployeeRoleInput>;
  removedEmployees: Array<Scalars['ID']>;
  companyPointsOfContact: Array<Scalars['ID']>;
};

export type UpdateOrganizationInput = {
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  missionStatement?: Maybe<Scalars['String']>;
  cageCode?: Maybe<Scalars['String']>;
  dunsNumber?: Maybe<Scalars['String']>;
  naicsCodes?: Maybe<Array<Scalars['JSONObject']>>;
  externalLinks?: Maybe<Array<Scalars['JSONObject']>>;
  coreCompetencies?: Maybe<Array<Scalars['JSONObject']>>;
  sbirSttrAwards?: Maybe<Array<Scalars['JSONObject']>>;
  principals?: Maybe<Array<Scalars['JSONObject']>>;
};

export type UpdateOrgEmailDomainsInput = {
  emailDomains: Array<EmailDomainInput>;
};

export type UpdateRoleInput = {
  userId: Scalars['String'];
  roleName: Scalars['String'];
};

export type UpdateSegmentInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  filterQuery?: Maybe<Scalars['JSON']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  audienceId?: Maybe<Scalars['Int']>;
  contacts?: Maybe<Array<ContactInput>>;
};

export type UpdateUserInput = {
  id: Scalars['String'];
  categories: Array<Scalars['String']>;
  onboarded?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  skills?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  publications?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  orgSymbol?: Maybe<Scalars['String']>;
  dutyTitle?: Maybe<Scalars['String']>;
  externalLinks?: Maybe<Array<Scalars['JSONObject']>>;
};

export type UpdateUserPreferencesInput = {
  allowEmails?: Maybe<Scalars['Boolean']>;
};


export type User = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  onboarded: Scalars['Boolean'];
  userOrgAccess?: Maybe<Array<AppUserOrgAccess>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  allowEmails: Scalars['Boolean'];
  approvalPending: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  skills?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  publications?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  referralType?: Maybe<Scalars['String']>;
  referralComments?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  isGovernment: Scalars['Boolean'];
  isCtr: Scalars['Boolean'];
  govtOnlyDisplayEmail?: Maybe<Scalars['Boolean']>;
  govtOnlyDisplayPhoneNumber?: Maybe<Scalars['Boolean']>;
  orgSymbol?: Maybe<Scalars['String']>;
  dutyTitle?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  notifications?: Maybe<Array<Notification>>;
  invites?: Maybe<Array<Invite>>;
  shares?: Maybe<Array<UserShare>>;
  mergeRequests?: Maybe<Array<ProfileMergeVerification>>;
  technologyLikes?: Maybe<Array<TechnologyLike>>;
  organizationLikes?: Maybe<Array<OrganizationLike>>;
  announcementLikes?: Maybe<Array<AnnouncementLike>>;
  announcementBookmarks?: Maybe<Array<AnnouncementBookmark>>;
  resourceBookmarks?: Maybe<Array<ResourceBookmark>>;
  technologyViews?: Maybe<Array<TechnologyView>>;
  organizationViews?: Maybe<Array<OrganizationView>>;
  announcementViews?: Maybe<Array<AnnouncementView>>;
  eventViews?: Maybe<Array<EventView>>;
  discussionLikes?: Maybe<Array<DiscussionLike>>;
  eventLikes?: Maybe<Array<EventLike>>;
  discussionViews?: Maybe<Array<DiscussionView>>;
  postLikes?: Maybe<Array<PostLike>>;
  technologyFollowsCount?: Maybe<Scalars['Float']>;
  technologyFollows?: Maybe<Array<TechnologyFollow>>;
  organizationFollowsCount?: Maybe<Scalars['Float']>;
  organizationFollows?: Maybe<Array<OrganizationFollow>>;
  discussionFollows?: Maybe<Array<DiscussionFollow>>;
  discussionFollowsCount?: Maybe<Scalars['Float']>;
  eventFollows?: Maybe<Array<EventFollow>>;
  documents?: Maybe<Array<UserDocument>>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  appUserSettings?: Maybe<Array<AppUserSettings>>;
  segments?: Maybe<Array<Segment>>;
  campaigns?: Maybe<Array<Campaign>>;
  campaignTemplates?: Maybe<Array<CampaignTemplateData>>;
  contacts?: Maybe<Array<Contact>>;
  contactAudits?: Maybe<Array<ContactAudit>>;
  audienceMembers?: Maybe<Array<Audience>>;
  technologyPointOfContacts?: Maybe<Array<Technology>>;
  pointOfContacts?: Maybe<Array<Organization>>;
  discussions?: Maybe<Array<Discussion>>;
  communityPointOfContacts?: Maybe<Array<Community>>;
  categories?: Maybe<Array<Category>>;
  communityCategories?: Maybe<Array<Category>>;
  externalLinks?: Maybe<Array<UserExternalLink>>;
  applications?: Maybe<Array<Apps>>;
  submittedBlocks?: Maybe<Array<Block>>;
  submittedReports?: Maybe<Array<Report>>;
  blocks?: Maybe<Array<UserBlock>>;
  reports?: Maybe<Array<UserReport>>;
  identities?: Maybe<Array<Identity>>;
  completedOnboardingSteps?: Maybe<Array<OnboardingStepProgress>>;
  completedOnboardingSections?: Maybe<Array<OnboardingSectionProgress>>;
  primaryAffiliation?: Maybe<Affiliation>;
  userAffiliations?: Maybe<Array<Affiliation>>;
  googleGroups?: Maybe<Array<GoogleGroupMembership>>;
  profession: Scalars['String'];
  googleGroupPermissions?: Maybe<GoogleGroupPermissions>;
  groupPermissions?: Maybe<Array<GroupMembership>>;
};

export type UserBlock = {
  id: Scalars['ID'];
  parentBlock?: Maybe<Block>;
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  released?: Maybe<Scalars['Boolean']>;
  blockedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  appeals?: Maybe<Array<Appeal>>;
  organization?: Maybe<Organization>;
  technology?: Maybe<Technology>;
  user?: Maybe<User>;
};

export type UserDocument = {
  id: Scalars['ID'];
  sourceDocumentId: Scalars['String'];
  uploadedBy: Scalars['String'];
  originalFileName: Scalars['String'];
  fileName: Scalars['String'];
  fileExtension: Scalars['String'];
  path: Scalars['String'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  secure: Scalars['Boolean'];
  usersWithAccess?: Maybe<Array<User>>;
  user?: Maybe<User>;
};

export type UserExternalLink = {
  id: Scalars['ID'];
  user?: Maybe<User>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type UserFollowCountsResponse = {
  technologyFollows?: Maybe<Scalars['Int']>;
  organizationFollows?: Maybe<Scalars['Int']>;
  discussionFollows?: Maybe<Scalars['Int']>;
};

export type UserGroupPermissions = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  onboarded: Scalars['Boolean'];
  identities?: Maybe<Array<Identity>>;
  groupPermissions?: Maybe<Array<GroupMembership>>;
};

export type UserPermissions = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  onboarded: Scalars['Boolean'];
  userOrgAccess?: Maybe<Array<AppUserOrgAccess>>;
};

export type UserReport = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
  message: Scalars['String'];
  resolved?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<User>;
  type: Scalars['String'];
  discussion?: Maybe<Discussion>;
  post?: Maybe<Post>;
  technology?: Maybe<Technology>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

export type UserShare = {
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  message: Scalars['String'];
  user?: Maybe<User>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  sharedUser?: Maybe<User>;
};

export type UserSummary = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  onboarded: Scalars['Boolean'];
};

export type UserWithEmail = {
  id: Scalars['ID'];
  name: Scalars['String'];
  currentlyBlocked?: Maybe<Scalars['Boolean']>;
  onboarded: Scalars['Boolean'];
  userOrgAccess?: Maybe<Array<AppUserOrgAccess>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  allowEmails: Scalars['Boolean'];
  approvalPending: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  skills?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  publications?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  referralType?: Maybe<Scalars['String']>;
  referralComments?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  isGovernment: Scalars['Boolean'];
  isCtr: Scalars['Boolean'];
  govtOnlyDisplayEmail?: Maybe<Scalars['Boolean']>;
  govtOnlyDisplayPhoneNumber?: Maybe<Scalars['Boolean']>;
  orgSymbol?: Maybe<Scalars['String']>;
  dutyTitle?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  notifications?: Maybe<Array<Notification>>;
  invites?: Maybe<Array<Invite>>;
  shares?: Maybe<Array<UserShare>>;
  mergeRequests?: Maybe<Array<ProfileMergeVerification>>;
  technologyLikes?: Maybe<Array<TechnologyLike>>;
  organizationLikes?: Maybe<Array<OrganizationLike>>;
  announcementLikes?: Maybe<Array<AnnouncementLike>>;
  announcementBookmarks?: Maybe<Array<AnnouncementBookmark>>;
  resourceBookmarks?: Maybe<Array<ResourceBookmark>>;
  technologyViews?: Maybe<Array<TechnologyView>>;
  organizationViews?: Maybe<Array<OrganizationView>>;
  announcementViews?: Maybe<Array<AnnouncementView>>;
  eventViews?: Maybe<Array<EventView>>;
  discussionLikes?: Maybe<Array<DiscussionLike>>;
  eventLikes?: Maybe<Array<EventLike>>;
  discussionViews?: Maybe<Array<DiscussionView>>;
  postLikes?: Maybe<Array<PostLike>>;
  technologyFollowsCount?: Maybe<Scalars['Float']>;
  technologyFollows?: Maybe<Array<TechnologyFollow>>;
  organizationFollowsCount?: Maybe<Scalars['Float']>;
  organizationFollows?: Maybe<Array<OrganizationFollow>>;
  discussionFollows?: Maybe<Array<DiscussionFollow>>;
  discussionFollowsCount?: Maybe<Scalars['Float']>;
  eventFollows?: Maybe<Array<EventFollow>>;
  documents?: Maybe<Array<UserDocument>>;
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  latestActivityDate?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  appUserSettings?: Maybe<Array<AppUserSettings>>;
  segments?: Maybe<Array<Segment>>;
  campaigns?: Maybe<Array<Campaign>>;
  campaignTemplates?: Maybe<Array<CampaignTemplateData>>;
  contacts?: Maybe<Array<Contact>>;
  contactAudits?: Maybe<Array<ContactAudit>>;
  audienceMembers?: Maybe<Array<Audience>>;
  technologyPointOfContacts?: Maybe<Array<Technology>>;
  pointOfContacts?: Maybe<Array<Organization>>;
  discussions?: Maybe<Array<Discussion>>;
  communityPointOfContacts?: Maybe<Array<Community>>;
  categories?: Maybe<Array<Category>>;
  communityCategories?: Maybe<Array<Category>>;
  externalLinks?: Maybe<Array<UserExternalLink>>;
  applications?: Maybe<Array<Apps>>;
  submittedBlocks?: Maybe<Array<Block>>;
  submittedReports?: Maybe<Array<Report>>;
  blocks?: Maybe<Array<UserBlock>>;
  reports?: Maybe<Array<UserReport>>;
  identities?: Maybe<Array<Identity>>;
  completedOnboardingSteps?: Maybe<Array<OnboardingStepProgress>>;
  completedOnboardingSections?: Maybe<Array<OnboardingSectionProgress>>;
  primaryAffiliation?: Maybe<Affiliation>;
  userAffiliations?: Maybe<Array<Affiliation>>;
  googleGroups?: Maybe<Array<GoogleGroupMembership>>;
  profession: Scalars['String'];
  googleGroupPermissions?: Maybe<GoogleGroupPermissions>;
  groupPermissions?: Maybe<Array<GroupMembership>>;
  emailAddress: Scalars['String'];
};

export type ValidateIdentityInput = {
  token: Scalars['String'];
  identId: Scalars['String'];
};

export type ValidateOrganizationInput = {
  name: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
};

export type Verification = {
  id: Scalars['ID'];
  verified: Scalars['Boolean'];
  verificationToken: Scalars['String'];
  createDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  tokenExpirationDate: Scalars['DateTime'];
  identity: Identity;
};

export type View = {
  id: Scalars['ID'];
  createDate: Scalars['DateTime'];
};

export type CreateCommunityAppMutationVariables = Exact<{
  input: AddCommunityAppInput;
}>;


export type CreateCommunityAppMutation = { addCommunityApp: { errorMessage?: Maybe<string>, result?: Maybe<CommunityAppFragment> } };

export type UpdateCommunityAppMutationVariables = Exact<{
  input: UpdateCommunityAppInput;
}>;


export type UpdateCommunityAppMutation = { updateCommunityApp: { errorMessage?: Maybe<string>, result?: Maybe<CommunityAppFragment> } };

export type DeleteCommunityAppMutationVariables = Exact<{
  communityAppId: Scalars['String'];
}>;


export type DeleteCommunityAppMutation = { deleteCommunityApp: boolean };

export type GetOnboardingSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnboardingSectionsQuery = { results: { totalCount: number, items: Array<OnboardingSectionFragment> } };

export type UpdateOnboardingProgressMutationVariables = Exact<{
  input: UpdateOnboardingProgressInput;
}>;


export type UpdateOnboardingProgressMutation = { updateOnboardingProgress: { totalCount: number, items: Array<OnboardingSectionFragment> } };

export type UpdateOnboardingUserAdminDetailsMutationVariables = Exact<{
  input: UpdateOnboardingAdminDetailsInput;
}>;


export type UpdateOnboardingUserAdminDetailsMutation = { updateOnboardingUserAdminDetails: { success: boolean } };

export type GetOnboardingProgressListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnboardingProgressListQuery = { getOnboardingProgressList: { totalCount: number, items: Array<UserOnboardingProgressFragment> } };

export type GetMyCommunitiesQueryVariables = Exact<{
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetMyCommunitiesQuery = { getMyCommunities: { totalCount: number, items: Array<CommunitySummaryFragment> } };

export type GetDiscussionsForCommunityQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  categoryFilter: Array<Scalars['ID']> | Scalars['ID'];
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  communityId: Scalars['String'];
}>;


export type GetDiscussionsForCommunityQuery = { getDiscussionsForCommunity: { totalCount: number, items: Array<DiscussionFragment> } };

export type GetCommunityMembersQueryVariables = Exact<{
  communityId: Scalars['String'];
  searchTerm: Scalars['String'];
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetCommunityMembersQuery = { getCommunityMembers: { totalCount: number, items: Array<CommunityMembersFragment> } };

export type SearchCommunitiesQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchCommunitiesQuery = { searchCommunities: { totalCount: number, items: Array<CommunitySummaryFragment> } };

export type GetCommunityBlobByIdQueryVariables = Exact<{
  fileId: Scalars['String'];
}>;


export type GetCommunityBlobByIdQuery = { getCommunityBlobById: CommunityBlobFragment };

export type GetCommunityByIdQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type GetCommunityByIdQuery = { getCommunityById: GetCommunityResponseFragment };

export type CreateAnnouncementMutationVariables = Exact<{
  input: AddAnnouncementInput;
}>;


export type CreateAnnouncementMutation = { createAnnouncement: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type CreateCommunityHelpEmailMutationVariables = Exact<{
  input: CreateCommunityHelpEmailInput;
}>;


export type CreateCommunityHelpEmailMutation = { createCommunityHelpEmail: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateAnnouncementMutationVariables = Exact<{
  input: UpdateAnnouncementInput;
}>;


export type UpdateAnnouncementMutation = { updateAnnouncement: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateModuleMutationVariables = Exact<{
  input: UpdateCommunityModuleInput;
}>;


export type UpdateModuleMutation = { updateModule: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateModuleItemMutationVariables = Exact<{
  input: UpdateCommunityModuleItemInput;
}>;


export type UpdateModuleItemMutation = { updateModuleItem: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type GetAnnouncementsByCommunityQueryVariables = Exact<{
  communityId: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type GetAnnouncementsByCommunityQuery = { getAnnouncementsByCommunity: { totalCount: number, items: Array<AnnouncementFragment> } };

export type DeleteAnnouncementMutationVariables = Exact<{
  announcementId: Scalars['String'];
}>;


export type DeleteAnnouncementMutation = { deleteAnnouncement: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type AddCommunitySectionMutationVariables = Exact<{
  input: CommunitySectionInput;
  communityId: Scalars['String'];
}>;


export type AddCommunitySectionMutation = { addCommunitySection: GetCommunityResponseFragment };

export type AddCommunitySocialResourceMutationVariables = Exact<{
  input: CommunitySectionResourceInput;
  communityId: Scalars['String'];
  socialInput: CommunitySocialInput;
}>;


export type AddCommunitySocialResourceMutation = { addCommunityResource: BooleanResponseFragment };

export type UpdateCommunitySocialResourceMutationVariables = Exact<{
  input: UpdateCommunitySectionResourceInput;
  socialInput: CommunitySocialInput;
}>;


export type UpdateCommunitySocialResourceMutation = { updateCommunitySectionResource: BooleanResponseFragment };

export type AddCommunityFileResourceMutationVariables = Exact<{
  input: CommunitySectionResourceInput;
  communityId: Scalars['String'];
  blobInput: CommunityBlobInput;
}>;


export type AddCommunityFileResourceMutation = { addCommunityResource: BooleanResponseFragment };

export type UpdateCommunityFileSectionResourceMutationVariables = Exact<{
  input: UpdateCommunitySectionResourceInput;
  blobInput: CommunityBlobInput;
}>;


export type UpdateCommunityFileSectionResourceMutation = { updateCommunitySectionResource: BooleanResponseFragment };

export type AddCommunityHeaderResourceMutationVariables = Exact<{
  input: CommunitySectionResourceInput;
  communityId: Scalars['String'];
}>;


export type AddCommunityHeaderResourceMutation = { addCommunityResource: BooleanResponseFragment };

export type UpdateCommunityHeaderResourceMutationVariables = Exact<{
  input: UpdateCommunitySectionResourceInput;
}>;


export type UpdateCommunityHeaderResourceMutation = { updateCommunitySectionResource: BooleanResponseFragment };

export type AddCommunityLinkResourceMutationVariables = Exact<{
  input: CommunitySectionResourceInput;
  communityId: Scalars['String'];
}>;


export type AddCommunityLinkResourceMutation = { addCommunityResource: BooleanResponseFragment };

export type UpdateCommunityLinkResourceMutationVariables = Exact<{
  input: UpdateCommunitySectionResourceInput;
}>;


export type UpdateCommunityLinkResourceMutation = { updateCommunitySectionResource: BooleanResponseFragment };

export type UpdateCommunitySectionMutationVariables = Exact<{
  input: UpdateCommunitySectionInput;
}>;


export type UpdateCommunitySectionMutation = { updateCommunitySection: BooleanResponseFragment };

export type ReorderCommunitySectionRankMutationVariables = Exact<{
  input: CommunitySectionReorderInput;
}>;


export type ReorderCommunitySectionRankMutation = { reorderCommunitySectionRank: BooleanResponseFragment };

export type ReorderCommunityContentModulesMutationVariables = Exact<{
  input: CommunityContentModuleReorderInput;
}>;


export type ReorderCommunityContentModulesMutation = { reorderCommunityContentModules: BooleanResponseFragment };

export type ReorderCommunityModuleItemsMutationVariables = Exact<{
  input: EntityListReorderInput;
}>;


export type ReorderCommunityModuleItemsMutation = { reorderCommunityModuleItems: BooleanResponseFragment };

export type ReorderCommunitySectionResourceRankMutationVariables = Exact<{
  input: CommunityResourceReorderInput;
}>;


export type ReorderCommunitySectionResourceRankMutation = { reorderCommunitySectionResourceRank: BooleanResponseFragment };

export type DeleteCommunitySectionMutationVariables = Exact<{
  sectionId: Scalars['String'];
}>;


export type DeleteCommunitySectionMutation = { deleteCommunitySection: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type DeleteModuleMutationVariables = Exact<{
  moduleId: Scalars['String'];
}>;


export type DeleteModuleMutation = { deleteModule: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type DeleteModuleItemMutationVariables = Exact<{
  itemId: Scalars['String'];
}>;


export type DeleteModuleItemMutation = { deleteModuleItem: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type DeleteCommunityResourceMutationVariables = Exact<{
  resourceId: Scalars['String'];
}>;


export type DeleteCommunityResourceMutation = { deleteCommunityResource: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateCommunityInformationMutationVariables = Exact<{
  input: UpdateCommunityInformationInput;
}>;


export type UpdateCommunityInformationMutation = { updateCommunityInformation: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateCommunityAdministrationMutationVariables = Exact<{
  input: UpdateCommunityAdministrationInput;
}>;


export type UpdateCommunityAdministrationMutation = { updateCommunityAdministration: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateCommunityBannerMutationVariables = Exact<{
  input: UpdateBannerInput;
}>;


export type UpdateCommunityBannerMutation = { updateCommunityBanner: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UpdateCommunityAvatarMutationVariables = Exact<{
  input: UpdateAvatarInput;
}>;


export type UpdateCommunityAvatarMutation = { updateCommunityAvatar: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type CreateCommunityMutationVariables = Exact<{
  input: CreateCommunityInput;
}>;


export type CreateCommunityMutation = { createCommunity: { errorMessage?: Maybe<string>, result?: Maybe<CommunityFragment> } };

export type GetHomeHelpMessageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeHelpMessageQuery = { getHomeHelpMessage: { errorMessage?: Maybe<string>, result?: Maybe<HomeHelpMessageFragment> } };

export type CreateModuleMutationVariables = Exact<{
  input: AddCommunityModuleInput;
}>;


export type CreateModuleMutation = { createModule: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type CreateModuleItemMutationVariables = Exact<{
  input: AddCommunityModuleItemInput;
}>;


export type CreateModuleItemMutation = { createModuleItem: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type GetCommunityModulesQueryVariables = Exact<{
  communityId: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type GetCommunityModulesQuery = { getCommunityModules: { errorMessage?: Maybe<string>, totalCount: number, items: Array<CommunityContentModuleFragment> } };

export type SearchCommunityModuleItemsQueryVariables = Exact<{
  moduleId: Scalars['String'];
  searchTerm: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchCommunityModuleItemsQuery = { searchCommunityModuleItems: { errorMessage?: Maybe<string>, totalCount: number, items: Array<CommunityModuleItemFragment> } };

export type GetCommunityAppsQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
}>;


export type GetCommunityAppsQuery = { getCommunityApps: { totalCount: number, items: Array<CommunityAppFragment> } };

export type DeleteCommunityMutationVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type DeleteCommunityMutation = { deleteCommunity: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type SearchAnnouncementsByCommunityQueryVariables = Exact<{
  communityId: Scalars['String'];
  searchTerm?: Maybe<Scalars['String']>;
  categoryFilter?: Maybe<IdFilterInput>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchAnnouncementsByCommunityQuery = { searchAnnouncementsByCommunity: { totalCount: number, items: Array<AnnouncementFragment> } };

export type ParseCommunityAppUrlQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type ParseCommunityAppUrlQuery = { parseCommunityAppURL: { errorMessage?: Maybe<string>, result?: Maybe<{ meta?: Maybe<{ title?: Maybe<string>, description?: Maybe<string>, image?: Maybe<string> }>, og?: Maybe<{ title?: Maybe<string>, description?: Maybe<string>, image?: Maybe<string> }>, images?: Maybe<Array<{ src?: Maybe<string> }>> }> } };

export type GetCategoriesByCommunityQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type GetCategoriesByCommunityQuery = { getCategoriesByCommunity: { items: Array<CategoryFragment> } };

export type PinAnnouncementMutationVariables = Exact<{
  announcementId: Scalars['String'];
}>;


export type PinAnnouncementMutation = { pinAnnouncement: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UnpinAnnouncementMutationVariables = Exact<{
  announcementId: Scalars['String'];
}>;


export type UnpinAnnouncementMutation = { unpinAnnouncement: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type GetUnReadMessageCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnReadMessageCountQuery = { getUnReadMessageCount: number };

export type CreateMessageMutationVariables = Exact<{
  data: CreateMessageInput;
}>;


export type CreateMessageMutation = { createMessage: { id: string } };

export type GetUsersConversationsQueryVariables = Exact<{
  conversationId?: Maybe<Scalars['String']>;
  conversationFilter: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type GetUsersConversationsQuery = { getUsersConversations: { totalCount: number, activeCount: number, archivedCount: number, mutedCount: number, items: Array<ConversationFragment> } };

export type GetConversationQueryVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type GetConversationQuery = { getConversation: ConversationFragment };

export type GetMessagesByConversationQueryVariables = Exact<{
  conversationId: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
}>;


export type GetMessagesByConversationQuery = { messagesByConversation: { totalCount: number, items: Array<MessageFragment> } };

export type MarkConversationFilterMutationVariables = Exact<{
  conversationId: Scalars['String'];
  conversationFilter: Scalars['String'];
}>;


export type MarkConversationFilterMutation = { markConversationFilter: ConversationFragment };

export type UpdateConversationReadStatusMutationVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type UpdateConversationReadStatusMutation = { updateConversationReadStatus: ConversationFragment };

export type OnMessageSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnMessageSubscription = { messageSubscription: MessageSubscriptionFragment };

export type GetDiscussionsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  categoryFilter: Array<Scalars['ID']> | Scalars['ID'];
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetDiscussionsQuery = { getDiscussions: { totalCount: number, items: Array<DiscussionFragment> } };

export type GetDiscussionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDiscussionQuery = { getDiscussion: DiscussionFragment };

export type CreateDiscussionMutationVariables = Exact<{
  data: DiscussionInput;
}>;


export type CreateDiscussionMutation = { createDiscussion: DiscussionFragment };

export type CreateCommunityDiscussionMutationVariables = Exact<{
  data: DiscussionInput;
  communityId: Scalars['String'];
}>;


export type CreateCommunityDiscussionMutation = { createCommunityDiscussion: DiscussionFragment };

export type UpdateDiscussionMutationVariables = Exact<{
  data: DiscussionInput;
}>;


export type UpdateDiscussionMutation = { updateDiscussion: DiscussionFragment };

export type DeleteDiscussionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDiscussionMutation = { deleteDiscussion: boolean };

export type LikeDiscussionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type LikeDiscussionMutation = { likeDiscussion: { id: string, user?: Maybe<{ id: string, name: string }> } };

export type DeleteDiscussionLikeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDiscussionLikeMutation = { unlikeDiscussion: boolean };

export type ShareDiscussionMutationVariables = Exact<{
  data: ShareInput;
}>;


export type ShareDiscussionMutation = { shareDiscussion: boolean };

export type GetPostsByDiscussionQueryVariables = Exact<{
  id: Scalars['String'];
  postId?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
}>;


export type GetPostsByDiscussionQuery = { getPostsByDiscussion: { totalCount: number, page?: Maybe<number>, offset?: Maybe<number>, items: Array<PostFragment> } };

export type CreatePostMutationVariables = Exact<{
  data: PostInput;
}>;


export type CreatePostMutation = { createPost: { communityId?: Maybe<string>, errorMessage?: Maybe<string>, result?: Maybe<{ id: string, content: string }> } };

export type DeleteReplyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteReplyMutation = { deleteReply: boolean };

export type LikePostMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type LikePostMutation = { likePost: { id: string, user?: Maybe<{ id: string, name: string }> } };

export type DeletePostLikeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePostLikeMutation = { unlikePost: boolean };

export type CreateReplyMutationVariables = Exact<{
  data: ReplyInput;
}>;


export type CreateReplyMutation = { createReply: { communityId?: Maybe<string>, errorMessage?: Maybe<string>, result?: Maybe<{ id: string, content: string }> } };

export type UpdatePostMutationVariables = Exact<{
  postId: Scalars['String'];
  updatedContent: Scalars['String'];
}>;


export type UpdatePostMutation = { updatePost: PostFragment };

export type GetRepliesByPostQueryVariables = Exact<{
  id: Scalars['String'];
  replyId?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
}>;


export type GetRepliesByPostQuery = { getRepliesByPost: { totalCount: number, page?: Maybe<number>, items: Array<PostFragment> } };

export type FollowDiscussionMutationVariables = Exact<{
  discussionId: Scalars['String'];
}>;


export type FollowDiscussionMutation = { followDiscussion: { id: string, user: { id: string, name: string } } };

export type UnFollowDiscussionMutationVariables = Exact<{
  discussionId: Scalars['String'];
}>;


export type UnFollowDiscussionMutation = { unFollowDiscussion: boolean };

export type GetDiscussionFollowsQueryVariables = Exact<{
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetDiscussionFollowsQuery = { getDiscussionFollows: { totalCount: number, items: Array<DiscussionFollowFragment> } };

export type PinDiscussionMutationVariables = Exact<{
  discussionId: Scalars['String'];
}>;


export type PinDiscussionMutation = { pinDiscussion: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type UnpinDiscussionMutationVariables = Exact<{
  discussionId: Scalars['String'];
}>;


export type UnpinDiscussionMutation = { unpinDiscussion: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type GetDocumentQueryVariables = Exact<{
  documentId: Scalars['String'];
}>;


export type GetDocumentQuery = { getDocument: { fileName: string, blobBase64: string } };

export type UploadDocumentQueryVariables = Exact<{
  documentName: Scalars['String'];
  documentBase64: Scalars['String'];
}>;


export type UploadDocumentQuery = { uploadDocument: { fileName: string, blobBase64: string } };

export type SearchDocumentsQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchDocumentsQuery = { results: { totalCount: number, items: Array<DocumentFragment> } };

export type CreateFeedbackMutationVariables = Exact<{
  data: GiveFeedbackInput;
}>;


export type CreateFeedbackMutation = { createFeedback: boolean };

export type OnboardingSectionFragment = { id: string, title: string, shortTitle?: Maybe<string>, subtitle?: Maybe<string>, isSupplementary?: Maybe<boolean>, completed?: Maybe<boolean>, dateCompleted?: Maybe<any>, footer?: Maybe<string>, orderNumber: number, createDate: any, updateDate: any, steps?: Maybe<Array<OnboardingStepFragment>>, pocs?: Maybe<Array<OnboardingSectionPocFragment>> };

export type OnboardingSectionPocFragment = { id: string, manualEmail: string, name: string, portalUser?: Maybe<UserFragment> };

export type OnboardingStepFragment = { id: string, title: string, orderNumber: number, link?: Maybe<string>, linkText?: Maybe<string>, description?: Maybe<string>, header?: Maybe<string>, completable?: Maybe<boolean>, completed?: Maybe<boolean>, dateCompleted?: Maybe<any>, divideBefore?: Maybe<boolean>, linkedFile?: Maybe<DocumentFragment> };

export type UserOnboardingProgressFragment = { stepsCompleted: number, totalSteps: number, supervisorName: string, supervisorEmail: string, completedDate?: Maybe<any>, effectiveDate?: Maybe<any>, isNewGovernmentEmployee?: Maybe<boolean>, updateDate: any, user: UserWithEmailFragment };

export type CommunityAppFragment = { id: string, name: string, url: string, avatar?: Maybe<string>, usageCount: number, usageNames: string };

export type CommunityBlobSummaryFragment = { id: string, fileName: string };

export type CommunityBlobFragment = (
  { blobBase64: string }
  & CommunityBlobSummaryFragment
);

export type CommunityResourceBaseFragment = { id: string, title: string, link?: Maybe<string>, orderNumber: number, type: ItemType, createDate: any, linkedFile?: Maybe<CommunityBlobSummaryFragment>, social?: Maybe<CommunitySocialFragment> };

export type CommunitySocialFragment = { id: string, groupHeader?: Maybe<string>, facebook?: Maybe<string>, twitter?: Maybe<string>, linkedin?: Maybe<string>, instagram?: Maybe<string> };

export type SectionResourceFragment = { id: string, title: string, link?: Maybe<string>, orderNumber: number, type: ItemType, createDate: any, linkedFile?: Maybe<CommunityBlobSummaryFragment>, social?: Maybe<CommunitySocialFragment> };

export type AnnouncementResourceFragment = { id: string, title: string, link?: Maybe<string>, orderNumber: number, type: ItemType, createDate: any, linkedFile?: Maybe<CommunityBlobSummaryFragment> };

export type ModuleItemResourceFragment = { id: string, title: string, link?: Maybe<string>, orderNumber: number, type: ItemType, linkedFile?: Maybe<CommunityBlobSummaryFragment> };

export type CommunityModuleItemFragment = { id: string, title: string, content?: Maybe<string>, embedUrl?: Maybe<string>, orderNumber: number, attachedResources?: Maybe<Array<ModuleItemResourceFragment>> };

export type CommunityContentModuleFragment = { id: string, description: string, title: string, orderNumber: number, items?: Maybe<Array<CommunityModuleItemFragment>> };

export type CommunitySectionFragment = { id: string, description?: Maybe<string>, sectionTitle: string, rowOrderNumber: number, columnNumber: number, resources?: Maybe<Array<SectionResourceFragment>> };

export type CommunitySummaryFragment = { name?: Maybe<string>, id: string, description: string, avatar?: Maybe<string>, banner?: Maybe<string>, bannerHexColor?: Maybe<string> };

export type HomeHelpMessageFragment = { id: string, header: string, description: string, active: boolean, externalLinkLabel?: Maybe<string>, externalLinkUrl?: Maybe<string>, lackingCommunityMembership?: Maybe<string> };

export type CommunityFeaturesFragment = { apps?: Maybe<boolean>, announcements?: Maybe<boolean>, questions?: Maybe<boolean>, calendar?: Maybe<boolean>, discussions?: Maybe<boolean>, resourcesTab?: Maybe<boolean> };

export type CommunityFragment = { groupKey: string, groupCalendarUrl?: Maybe<string>, helpContact?: Maybe<string>, updateDate: any, name: string, id: string, description: string, customHelpInstructions?: Maybe<string>, avatar?: Maybe<string>, banner?: Maybe<string>, bannerHexColor?: Maybe<string>, features: CommunityFeaturesFragment, sections?: Maybe<Array<CommunitySectionFragment>>, categories?: Maybe<Array<CategoryFragment>> };

export type GetCommunityResponseFragment = { errorMessage?: Maybe<string>, result?: Maybe<CommunityFragment> };

export type AnnouncementFragment = { id: string, title: string, description: string, createDate: any, currentlyPinned: boolean, attachedResources?: Maybe<Array<AnnouncementResourceFragment>>, categories?: Maybe<Array<CategoryFragment>> };

export type CommunityMembersFragment = { id: string, name: string, avatar?: Maybe<string>, firstName: string, lastName: string, isGovernment: boolean, currentlyBlocked?: Maybe<boolean> };

export type ConversationFragment = { id: string, initiatorUnReadCount?: Maybe<number>, participantUnReadCount?: Maybe<number>, initiatorArchived: boolean, participantArchived: boolean, initiatorHasMuted: boolean, participantHasMuted: boolean, updateDate: any, initiator: MessageUserFragment, participant: MessageUserFragment };

export type MessageFragment = { id: string, text: string, initiatorRead: boolean, participantRead: boolean, createDate: any, sender: MessageUserFragment };

export type MessageSubscriptionUserFragment = { id: string, name: string, firstName: string, lastName: string, avatar?: Maybe<string> };

export type MessageSubscriptionFragment = { id: string, text: string, createDate: any, updateDate: any, initiatorRead: boolean, participantRead: boolean, sender: MessageSubscriptionUserFragment, conversation: { id: string, initiatorUnReadCount?: Maybe<number>, participantUnReadCount?: Maybe<number>, initiatorArchived: boolean, participantArchived: boolean, initiatorHasMuted: boolean, participantHasMuted: boolean, updateDate: any, initiator: MessageSubscriptionUserFragment, participant: MessageSubscriptionUserFragment } };

export type DiscussionSummaryFragment = { id: string, title: string, content: string, isPrivate?: Maybe<boolean>, createDate: any, latestActivityDate?: Maybe<any>, updateDate: any, currentlyPinned: boolean, participantCount?: Maybe<number>, createdBy: UserFragment, categories?: Maybe<Array<{ id: string, name: string, description: string }>> };

export type DiscussionFragment = (
  { postCount: number, viewCount: number, likeCount: number, currentUserLike?: Maybe<boolean>, currentUserFollows?: Maybe<boolean>, currentlyBlocked?: Maybe<boolean>, communityId?: Maybe<string>, currentlyPinned: boolean, participantCount?: Maybe<number>, participants?: Maybe<Array<DiscussionParticipantFragment>>, categories?: Maybe<Array<{ id: string, name: string, description: string }>> }
  & DiscussionSummaryFragment
);

export type PostFragment = { id: string, content: string, currentlyBlocked?: Maybe<boolean>, createDate: any, repliesCount?: Maybe<number>, updateDate: any, lastPostEditDate?: Maybe<any>, createdBy: UserFragment, parentPost?: Maybe<{ id: string }> };

export type DiscussionParticipantFragment = { id: string, name: string, avatar?: Maybe<string>, firstName: string, lastName: string, currentlyBlocked?: Maybe<boolean> };

export type DiscussionFollowFragment = { user: UserFragment, discussion: DiscussionFragment };

export type ReportFragment = { id: string, message: string, createDate: any, resolved?: Maybe<boolean>, type: string, discussion?: Maybe<DiscussionSummaryFragment>, technology?: Maybe<TechnologySummaryFragment>, post?: Maybe<PostFragment>, organization?: Maybe<OrganizationSummaryFragment>, user?: Maybe<UserSummaryFragment>, reportedBy?: Maybe<UserSummaryFragment> };

export type BlockSummaryFragment = { id: string, message: string, createDate: any, resolved?: Maybe<boolean>, type: string, blockedBy?: Maybe<{ firstName: string, lastName: string, id: string }> };

export type BlockFragment = { id: string, message: string, createDate: any, resolved?: Maybe<boolean>, released?: Maybe<boolean>, type: string, parentBlock?: Maybe<{ type: string }>, discussion?: Maybe<DiscussionSummaryFragment>, technology?: Maybe<TechnologySummaryFragment>, post?: Maybe<PostFragment>, organization?: Maybe<OrganizationSummaryFragment>, user?: Maybe<UserSummaryFragment>, blockedBy?: Maybe<UserSummaryFragment> };

export type DocumentFragment = { id: string, fileName: string, blobBase64: string };

export type GroupMembershipFragment = { groupKey: string, groupRole: string, communityId?: Maybe<string> };

export type VerificationFragment = { id: string, verified: boolean, verificationToken: string, tokenExpirationDate: any };

export type AffiliationFragment = { id: string, role?: Maybe<string>, relation?: Maybe<Relation>, approvalPending: boolean, identityAffiliationStatus: IdentityAffiliationStatus, organization: OrganizationSummaryFragment };

export type IdentityFragment = { id: string, name: string, firstName: string, lastName: string, emailAddress: string, emailVerified: boolean, verification?: Maybe<VerificationFragment>, user?: Maybe<UserFragment>, affiliations: Array<AffiliationFragment> };

export type JuicerPostSummaryFragment = { id: number, full_url: string, video?: Maybe<string>, image?: Maybe<string>, unformatted_message: string, message: string, external_created_at: string };

export type StateFragment = { code: string, name: string };

export type NotificationFragment = { id: string, notificationType?: Maybe<string>, notificationStatus?: Maybe<string>, notificationClassification?: Maybe<string>, createDate: any, updateDate: any, actionUser?: Maybe<{ id: string, name: string }>, technology?: Maybe<{ id: string, name: string }>, organization?: Maybe<{ id: string, name: string, isCompany: boolean }>, discussion?: Maybe<{ id: string, title: string, communityId?: Maybe<string> }>, reply?: Maybe<{ id: string }>, post?: Maybe<{ id: string }>, user?: Maybe<{ id: string, name: string }> };

export type NotificationSubscriptionFragment = { id: string, notificationType?: Maybe<string>, notificationStatus?: Maybe<string>, notificationClassification?: Maybe<string>, createDate: any, updateDate: any, actionUser?: Maybe<{ id: string, name: string }>, technology?: Maybe<{ id: string, name: string }>, organization?: Maybe<{ id: string, name: string, isCompany: boolean }>, discussion?: Maybe<{ id: string, title: string, communityId?: Maybe<string> }>, reply?: Maybe<{ id: string }>, post?: Maybe<{ id: string }>, user?: Maybe<{ id: string, name: string }> };

export type NotificationStatusFragment = { id: string, notificationType?: Maybe<string>, notificationStatus?: Maybe<string>, notificationClassification?: Maybe<string>, createDate: any, updateDate: any };

export type OrganizationFragment = (
  { missionStatement?: Maybe<string>, adminApprovalRequired: boolean, currentlyBlocked?: Maybe<boolean>, autoJoinWithDomain: boolean, createDate: any, latestActivityDate?: Maybe<any>, cageCode?: Maybe<string>, dunsNumber?: Maybe<string> }
  & OrganizationSearchResultsFragment
);

export type OrganizationSearchResultsFragment = (
  { likeCount: number, viewCount: number, currentUserLike?: Maybe<boolean>, currentUserFollows?: Maybe<boolean> }
  & OrganizationSearchOnboardingResultsFragment
);

export type OrganizationSummaryFragment = { currentlyBlocked?: Maybe<boolean>, avatar?: Maybe<string>, name: string, id: string, description?: Maybe<string>, isCompany: boolean, city: string, state: string };

export type OrganizationSearchOnboardingResultsFragment = (
  { governmentLevel?: Maybe<string>, childrenCount?: Maybe<number>, city: string, state: string, websiteUrl?: Maybe<string> }
  & OrganizationSummaryFragment
);

export type ExternalLinkFragment = { id: string, title: string, url: string };

export type NaicsCodeFragment = { id: string, code: string, descriptor: string };

export type OrganizationBusinessTypeFragment = { id: string, name: string };

export type EmailDomainFragment = { id: string, domain: string };

export type OrganizationSbirSttrAwardFragment = { id: string, name: string, year: string, type: string, phase: string, link: string };

export type OrganizationPrincipalFragment = { id: string, name: string, title: string };

export type OrganizationCompetencyFragment = { id: string, name: string, description: string };

export type OrganizationDetailsFragment = { id: string, name: string, description?: Maybe<string>, orgDescription?: Maybe<string>, missionStatement?: Maybe<string>, currentlyBlocked?: Maybe<boolean>, isCompany: boolean, city: string, state: string, websiteUrl?: Maybe<string>, avatar?: Maybe<string>, createDate: any, latestActivityDate?: Maybe<any>, cageCode?: Maybe<string>, dunsNumber?: Maybe<string>, viewCount: number, likeCount: number, currentUserLike?: Maybe<boolean>, currentUserFollows?: Maybe<boolean>, governmentLevel?: Maybe<string>, externalLinks?: Maybe<Array<ExternalLinkFragment>>, naicsCodes?: Maybe<Array<NaicsCodeFragment>>, businessTypes?: Maybe<Array<OrganizationBusinessTypeFragment>>, emailDomains?: Maybe<Array<EmailDomainFragment>>, sbirSttrAwards?: Maybe<Array<OrganizationSbirSttrAwardFragment>>, principals?: Maybe<Array<OrganizationPrincipalFragment>>, coreCompetencies?: Maybe<Array<OrganizationCompetencyFragment>>, technologies?: Maybe<Array<{ categories: Array<{ id: string, name: string }> }>>, pointOfContacts?: Maybe<Array<UserFragment>>, OrganizationParent?: Maybe<{ id: string, name: string, description?: Maybe<string>, avatar?: Maybe<string>, governmentLevel?: Maybe<string> }>, OrganizationChildren: Array<OrganizationChildrenFragment> };

export type OrganizationChildrenFragment = { id: string, name: string, description?: Maybe<string>, orgDescription?: Maybe<string>, isCompany: boolean, currentUserLike?: Maybe<boolean>, likeCount: number, avatar?: Maybe<string>, governmentLevel?: Maybe<string>, currentUserFollows?: Maybe<boolean>, currentlyBlocked?: Maybe<boolean> };

export type OrganizationFollowFragment = { user: UserFragment, organization: OrganizationFragment };

export type BooleanResponseFragment = { result?: Maybe<boolean>, errorMessage?: Maybe<string> };

export type TechnologySummaryFragment = { id: string, name: string, profileImagePath: string, organization: OrganizationSummaryFragment };

export type TechnologyFragment = (
  { likeCount: number, viewCount: number, currentlyBlocked?: Maybe<boolean>, currentUserLike?: Maybe<boolean>, currentUserFollows?: Maybe<boolean>, description: string, profileImagePath: string, mfgReadinessLevel: number, techReadinessLevel: number, currentReadinessResults?: Maybe<any>, createDate: any, latestActivityDate?: Maybe<any>, documents?: Maybe<Array<TechnologyDocumentFragment>>, pointOfContacts?: Maybe<Array<UserFragment>>, categories: Array<{ id: string, name: string, description: string, parent?: Maybe<{ id: string, name: string, description: string }> }>, externalLinks?: Maybe<Array<{ id: string, title: string, url: string }>>, patents?: Maybe<Array<{ id: string, name: string }>>, copyrights?: Maybe<Array<{ id: string, name: string }>>, trademarks?: Maybe<Array<{ id: string, name: string }>> }
  & TechnologySummaryFragment
);

export type QueryTechnologyFragment = { id: string, name: string, description: string, likeCount: number, viewCount: number, currentUserLike?: Maybe<boolean>, currentUserFollows?: Maybe<boolean>, profileImagePath: string, currentlyBlocked?: Maybe<boolean>, techReadinessLevel: number, mfgReadinessLevel: number, organization: OrganizationSummaryFragment, categories: Array<{ id: string, name: string }> };

export type TechnologiesByOrganizationFragment = { id: string, name: string, likeCount: number, viewCount: number, currentlyBlocked?: Maybe<boolean>, description: string, profileImagePath: string, mfgReadinessLevel: number, techReadinessLevel: number, currentUserLike?: Maybe<boolean>, categories: Array<{ id: string, name: string }> };

export type CategoryFragment = { id: string, name: string, description: string };

export type ParentCategoryFragment = (
  { children: Array<ChildCategoryFragment> }
  & CategoryFragment
);

export type ChildCategoryFragment = (
  { parent?: Maybe<CategoryFragment> }
  & CategoryFragment
);

export type TechnologyDocumentFragment = { id: string, fileName: string, originalFileName: string, fileExtension: string, path: string, technologyDocumentType: string, secure: boolean, usersWithAccess?: Maybe<Array<{ id: string }>> };

export type TechnologyFollowFragment = { user: UserFragment, technology: TechnologyFragment };

export type UserIdentityFragment = (
  { userOrgAccess?: Maybe<Array<{ id: string, organizationId?: Maybe<string>, role?: Maybe<{ id: string, roleName?: Maybe<string> }> }>>, identities?: Maybe<Array<IdentityFragment>>, primaryAffiliation?: Maybe<AffiliationFragment>, userAffiliations?: Maybe<Array<AffiliationFragment>> }
  & UserFragment
);

export type UserWithEmailFragment = { id: string, name: string, firstName: string, lastName: string, emailAddress: string, currentlyBlocked?: Maybe<boolean>, approvalPending: boolean, allowEmails: boolean, onboarded: boolean, govtOnlyDisplayEmail?: Maybe<boolean>, govtOnlyDisplayPhoneNumber?: Maybe<boolean>, phone?: Maybe<string>, bio?: Maybe<string>, experience?: Maybe<string>, skills?: Maybe<string>, education?: Maybe<string>, publications?: Maybe<string>, linkedin?: Maybe<string>, referralType?: Maybe<string>, referralComments?: Maybe<string>, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, isGovernment: boolean, isCtr: boolean, orgSymbol?: Maybe<string>, dutyTitle?: Maybe<string>, externalLinks?: Maybe<Array<{ id: string, title: string, url: string }>>, organization?: Maybe<OrganizationSummaryFragment>, invites?: Maybe<Array<GetUserInvitesFragment>>, technologyLikes?: Maybe<Array<UserTechnologyLikesFragment>>, organizationLikes?: Maybe<Array<UserOrganizationLikesFragment>>, categories?: Maybe<Array<ChildCategoryFragment>>, identities?: Maybe<Array<IdentityFragment>>, primaryAffiliation?: Maybe<AffiliationFragment>, userAffiliations?: Maybe<Array<AffiliationFragment>>, userOrgAccess?: Maybe<Array<{ id: string, organizationId?: Maybe<string>, role?: Maybe<{ id: string, roleName?: Maybe<string>, appRoleCapabilities?: Maybe<Array<{ function?: Maybe<string>, appCapabilities?: Maybe<{ capabilityName?: Maybe<string> }> }>> }> }>> };

export type CurrentUserFragment = { id: string, name: string, firstName: string, lastName: string, emailAddress: string, currentlyBlocked?: Maybe<boolean>, approvalPending: boolean, allowEmails: boolean, onboarded: boolean, govtOnlyDisplayEmail?: Maybe<boolean>, govtOnlyDisplayPhoneNumber?: Maybe<boolean>, phone?: Maybe<string>, bio?: Maybe<string>, experience?: Maybe<string>, skills?: Maybe<string>, education?: Maybe<string>, publications?: Maybe<string>, linkedin?: Maybe<string>, referralType?: Maybe<string>, referralComments?: Maybe<string>, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, isGovernment: boolean, isCtr: boolean, orgSymbol?: Maybe<string>, dutyTitle?: Maybe<string>, externalLinks?: Maybe<Array<{ id: string, title: string, url: string }>>, groupPermissions?: Maybe<Array<GroupMembershipFragment>>, organization?: Maybe<OrganizationSummaryFragment>, invites?: Maybe<Array<GetUserInvitesFragment>>, technologyLikes?: Maybe<Array<UserTechnologyLikesFragment>>, organizationLikes?: Maybe<Array<UserOrganizationLikesFragment>>, categories?: Maybe<Array<ChildCategoryFragment>>, googleGroupPermissions?: Maybe<{ teamAtLargeGroup: boolean, rgCoreGroup: boolean, newHire: boolean }>, identities?: Maybe<Array<IdentityFragment>>, primaryAffiliation?: Maybe<AffiliationFragment>, userAffiliations?: Maybe<Array<AffiliationFragment>>, userOrgAccess?: Maybe<Array<{ id: string, organizationId?: Maybe<string>, role?: Maybe<{ id: string, roleName?: Maybe<string>, appRoleCapabilities?: Maybe<Array<{ function?: Maybe<string>, appCapabilities?: Maybe<{ capabilityName?: Maybe<string> }> }>> }> }>> };

export type UserFragment = { role?: Maybe<string>, profession: string, id: string, name: string, firstName: string, isGovernment: boolean, isCtr: boolean, lastName: string, emailVerified: boolean, avatar?: Maybe<string>, currentlyBlocked?: Maybe<boolean>, approvalPending: boolean, onboarded: boolean, phone?: Maybe<string>, experience?: Maybe<string>, linkedin?: Maybe<string>, bio?: Maybe<string>, referralType?: Maybe<string>, referralComments?: Maybe<string>, govtOnlyDisplayEmail?: Maybe<boolean>, govtOnlyDisplayPhoneNumber?: Maybe<boolean>, orgSymbol?: Maybe<string>, dutyTitle?: Maybe<string>, primaryAffiliation?: Maybe<(
    AffiliationFragment
    & AffiliationFragment
  )>, userAffiliations?: Maybe<Array<AffiliationFragment>>, organization?: Maybe<OrganizationSummaryFragment> };

export type AdminUserFragment = { id: string, name: string, firstName: string, lastName: string, emailAddress: string, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, currentlyBlocked?: Maybe<boolean>, isGovernment: boolean, isCtr: boolean, userOrgAccess?: Maybe<Array<{ id: string, role?: Maybe<{ id: string, roleName?: Maybe<string> }> }>>, organization?: Maybe<{ id: string, name: string }> };

export type MessageUserFragment = { id: string, name: string, firstName: string, lastName: string, avatar?: Maybe<string> };

export type UserSummaryFragment = { id: string, name: string, firstName: string, isGovernment: boolean, isCtr: boolean, profession: string, lastName: string, avatar?: Maybe<string>, organization?: Maybe<OrganizationSummaryFragment> };

export type UserProfileFragment = { id: string, name: string, firstName: string, lastName: string, createDate: any, latestActivityDate?: Maybe<any>, currentlyBlocked?: Maybe<boolean>, approvalPending: boolean, emailVerified: boolean, allowEmails: boolean, onboarded: boolean, phone?: Maybe<string>, bio?: Maybe<string>, experience?: Maybe<string>, skills?: Maybe<string>, education?: Maybe<string>, publications?: Maybe<string>, linkedin?: Maybe<string>, referralType?: Maybe<string>, referralComments?: Maybe<string>, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, isGovernment: boolean, isCtr: boolean, orgSymbol?: Maybe<string>, dutyTitle?: Maybe<string>, externalLinks?: Maybe<Array<{ id: string, title: string, url: string }>>, organization?: Maybe<OrganizationSummaryFragment>, invites?: Maybe<Array<{ id: string }>>, technologyLikes?: Maybe<Array<{ id: string, technology?: Maybe<{ id: string, name: string }> }>>, organizationLikes?: Maybe<Array<UserOrganizationLikesFragment>>, categories?: Maybe<Array<ChildCategoryFragment>>, pointOfContacts?: Maybe<Array<{ id: string, name: string, avatar?: Maybe<string>, city: string, state: string, isCompany: boolean }>>, technologyPointOfContacts?: Maybe<Array<{ id: string, name: string, profileImagePath: string, techReadinessLevel: number, mfgReadinessLevel: number, viewCount: number }>>, identities?: Maybe<Array<IdentityFragment>>, primaryAffiliation?: Maybe<AffiliationFragment>, userAffiliations?: Maybe<Array<AffiliationFragment>> };

export type OrgUserFragment = { id: string, name: string, firstName: string, lastName: string, currentlyBlocked?: Maybe<boolean>, onboarded: boolean, phone?: Maybe<string>, bio?: Maybe<string>, experience?: Maybe<string>, linkedin?: Maybe<string>, referralType?: Maybe<string>, referralComments?: Maybe<string>, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, isGovernment: boolean, isCtr: boolean, orgSymbol?: Maybe<string>, dutyTitle?: Maybe<string>, emailVerified: boolean, createDate: any, updateDate: any, latestActivityDate?: Maybe<any>, approvalPending: boolean, allowEmails: boolean };

export type OrgUserWithRolesFragment = { id: string, name: string, firstName: string, lastName: string, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, currentlyBlocked?: Maybe<boolean>, isGovernment: boolean, isCtr: boolean, pointOfContacts?: Maybe<Array<{ id: string, name: string }>>, technologyPointOfContacts?: Maybe<Array<{ id: string, name: string }>>, userOrgAccess?: Maybe<Array<{ id: string, organizationId?: Maybe<string>, role?: Maybe<{ id: string, roleName?: Maybe<string> }> }>> };

export type PendingApprovalUserFragment = { id: string, name: string, firstName: string, lastName: string, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, organization?: Maybe<OrganizationSummaryFragment> };

export type UsersFragment = { id: string, name: string, firstName: string, lastName: string, onboarded: boolean, bio?: Maybe<string>, experience?: Maybe<string>, linkedin?: Maybe<string>, referralType?: Maybe<string>, govtOnlyDisplayEmail?: Maybe<boolean>, currentlyBlocked?: Maybe<boolean>, govtOnlyDisplayPhoneNumber?: Maybe<boolean>, referralComments?: Maybe<string>, role?: Maybe<string>, profession: string, avatar?: Maybe<string>, isGovernment: boolean, isCtr: boolean, orgSymbol?: Maybe<string>, dutyTitle?: Maybe<string>, organization?: Maybe<OrganizationSummaryFragment>, technologyLikes?: Maybe<Array<UserTechnologyLikesFragment>>, organizationLikes?: Maybe<Array<UserOrganizationLikesFragment>> };

export type UserLookupFragment = { id: string, name: string, firstName: string, lastName: string, avatar?: Maybe<string>, organization?: Maybe<OrganizationSummaryFragment> };

export type InvitedUserFragment = { id: string, emailAddress: string, inviteStatus?: Maybe<string> };

export type UserTechnologyLikesFragment = { id: string, technology?: Maybe<{ id: string, name: string }> };

export type UserOrganizationLikesFragment = { id: string, organization?: Maybe<OrganizationSummaryFragment> };

export type GetUserInvitesFragment = { id: string };

export type SyncGroupsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncGroupsMutation = { syncSuccessful: boolean };

export type SearchUserIdentitiesQueryVariables = Exact<{
  data: SearchIdentitiesInput;
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchUserIdentitiesQuery = { results: { totalCount: number, items: Array<UserWithEmailFragment> } };

export type GetUserIdentityQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserIdentityQuery = { userIdentity: UserIdentityFragment };

export type CreateIdentityMutationVariables = Exact<{
  data: IdentityInput;
}>;


export type CreateIdentityMutation = { createIdentity: { errorMessage?: Maybe<string>, result?: Maybe<IdentityFragment> } };

export type CreateIdentityAsAdminMutationVariables = Exact<{
  data: IdentityInput;
}>;


export type CreateIdentityAsAdminMutation = { createIdentityAsAdmin: { errorMessage?: Maybe<string>, result?: Maybe<IdentityFragment> } };

export type ValidateIdentityMutationVariables = Exact<{
  data: ValidateIdentityInput;
}>;


export type ValidateIdentityMutation = { validateIdentity: { errorMessage?: Maybe<string>, result?: Maybe<CurrentUserFragment> } };

export type ResendValidationMutationVariables = Exact<{
  identityId: Scalars['String'];
}>;


export type ResendValidationMutation = { resendValidation: IdentityFragment };

export type ValidateIdentityAsAdminMutationVariables = Exact<{
  data: ValidateIdentityInput;
}>;


export type ValidateIdentityAsAdminMutation = { validateIdentityAsAdmin: IdentityFragment };

export type ResendValidationAsAdminMutationVariables = Exact<{
  identityId: Scalars['String'];
}>;


export type ResendValidationAsAdminMutation = { identity: IdentityFragment };

export type UpdateAffiliationMutationVariables = Exact<{
  data: EditAffiliationInput;
}>;


export type UpdateAffiliationMutation = { updateAffiliation: { errorMessage?: Maybe<string>, result?: Maybe<AffiliationFragment> } };

export type ClearUserCacheMutationVariables = Exact<{
  emailAddress: Scalars['String'];
}>;


export type ClearUserCacheMutation = { clearUserCache: boolean };

export type AddAffiliationAsPrimaryOrganizationAsAdminMutationVariables = Exact<{
  data: AddAffiliationInput;
}>;


export type AddAffiliationAsPrimaryOrganizationAsAdminMutation = { addAffiliationAsPrimaryOrganizationAsAdmin: boolean };

export type AddAffiliationAsAdminMutationVariables = Exact<{
  data: AddAffiliationInput;
}>;


export type AddAffiliationAsAdminMutation = { addAffiliationAsAdmin: { errorMessage?: Maybe<string>, result?: Maybe<AffiliationFragment> } };

export type EditAffiliationAsAdminMutationVariables = Exact<{
  data: EditAdminAffiliationInput;
}>;


export type EditAffiliationAsAdminMutation = { affiliationResponse: { errorMessage?: Maybe<string>, result?: Maybe<AffiliationFragment> } };

export type RemoveAffiliationAsAdminMutationVariables = Exact<{
  data: RemoveAffiliationInput;
}>;


export type RemoveAffiliationAsAdminMutation = { removeAffiliationAsAdmin: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type ApproveAffiliationAsAdminMutationVariables = Exact<{
  data: ApproveAffiliationInput;
}>;


export type ApproveAffiliationAsAdminMutation = { approveAffiliationAsAdmin: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type RemoveIdentityAsAdminMutationVariables = Exact<{
  data: RemoveIdentityInput;
}>;


export type RemoveIdentityAsAdminMutation = { removeIdentityAsAdmin: { result?: Maybe<boolean>, errorMessage?: Maybe<string> } };

export type RemoveIdentityMutationVariables = Exact<{
  data: RemoveIdentityInput;
}>;


export type RemoveIdentityMutation = { response: { errorMessage?: Maybe<string>, updatedUser?: Maybe<CurrentUserFragment> } };

export type AdminSearchInvitesQueryVariables = Exact<{
  data: SearchInvitesInput;
  pagination?: Maybe<PaginationInput>;
}>;


export type AdminSearchInvitesQuery = { results: { totalCount: number, items: Array<{ id: string, emailAddress: string, inviteStatus?: Maybe<string>, createDate: any, updateDate: any, user?: Maybe<{ id: string, name: string, firstName: string, lastName: string, organization?: Maybe<{ id: string, name: string }> }> }> } };

export type DeleteInviteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteInviteMutation = { deleteInvite: boolean };

export type ResendInviteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResendInviteMutation = { resendInvite: boolean };

export type GetJuicerPostsQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
}>;


export type GetJuicerPostsQuery = { response: { errorMessage?: Maybe<string>, result?: Maybe<{ totalCount: number, items: Array<JuicerPostSummaryFragment> }> } };

export type GetStatesQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type GetStatesQuery = { states: Array<StateFragment> };

export type GetUserByMergeRequestIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserByMergeRequestIdQuery = { userProfileByMergeRequestId: UserFragment };

export type MergeProfilesMutationVariables = Exact<{
  verificationId: Scalars['String'];
  verificationCode: Scalars['String'];
}>;


export type MergeProfilesMutation = { response: { mergeSuccessful: boolean, errorMessage?: Maybe<string>, mergedUser: CurrentUserFragment } };

export type ValidateProfileMergeQueryVariables = Exact<{
  verificationId: Scalars['String'];
  verificationCode: Scalars['String'];
}>;


export type ValidateProfileMergeQuery = { response: { mergeReady: boolean, verificationId: string, errorMessage?: Maybe<string> } };

export type InitiateProfileMergeMutationVariables = Exact<{
  targetEmail: Scalars['String'];
}>;


export type InitiateProfileMergeMutation = { response: { mergeValid: boolean, mergeInitiatorId: string, verificationId: string, emailToMerge: string, errorMessage?: Maybe<string> } };

export type ResendProfileMergeEmailMutationVariables = Exact<{
  verificationId: Scalars['String'];
}>;


export type ResendProfileMergeEmailMutation = { response: { mergeValid: boolean, verificationId: string, errorMessage?: Maybe<string> } };

export type GetUnresolvedReportCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnresolvedReportCountQuery = { getUnresolvedReportCount: number };

export type GetEntityReportCountQueryVariables = Exact<{
  entityFilter?: Maybe<EntityFilterInput>;
}>;


export type GetEntityReportCountQuery = { getEntityReportCount: { reportCount: number, unresolvedCount: number, resolvedCount: number } };

export type GetReportsQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  entityFilter?: Maybe<EntityFilterInput>;
  typeFilter?: Maybe<TypeFilterInput>;
  statusFilter?: Maybe<StatusFilterInput>;
  searchTerm: Scalars['String'];
}>;


export type GetReportsQuery = { reports: { unresolvedCount: number, resolvedCount: number, totalCount: number, reportCount: number, items: Array<ReportFragment> } };

export type GetBlocksQueryVariables = Exact<{
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<SortInput>;
  entityFilter?: Maybe<EntityFilterInput>;
  typeFilter?: Maybe<TypeFilterInput>;
  statusFilter?: Maybe<StatusFilterInput>;
  searchTerm: Scalars['String'];
}>;


export type GetBlocksQuery = { blocks: { totalCount: number, unresolvedCount: number, resolvedCount: number, blockCount: number, items: Array<BlockFragment> } };

export type ReportDiscussionMutationVariables = Exact<{
  discussionId: Scalars['String'];
  message: Scalars['String'];
}>;


export type ReportDiscussionMutation = { reportDiscussion: DiscussionFragment };

export type BlockDiscussionMutationVariables = Exact<{
  discussionId: Scalars['String'];
  message: Scalars['String'];
}>;


export type BlockDiscussionMutation = { blockDiscussion: DiscussionFragment };

export type ReportPostMutationVariables = Exact<{
  discussionId: Scalars['String'];
  postId: Scalars['String'];
  message: Scalars['String'];
}>;


export type ReportPostMutation = { reportPost: PostFragment };

export type ReportTechnologyMutationVariables = Exact<{
  technologyId: Scalars['String'];
  message: Scalars['String'];
}>;


export type ReportTechnologyMutation = { reportTechnology: TechnologyFragment };

export type BlockPostMutationVariables = Exact<{
  discussionId: Scalars['String'];
  postId: Scalars['String'];
  message: Scalars['String'];
}>;


export type BlockPostMutation = { blockPost: PostFragment };

export type BlockTechnologyMutationVariables = Exact<{
  technologyId: Scalars['String'];
  message: Scalars['String'];
}>;


export type BlockTechnologyMutation = { blockTechnology: TechnologyFragment };

export type ReportOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  message: Scalars['String'];
}>;


export type ReportOrganizationMutation = { reportOrganization: OrganizationFragment };

export type BlockOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
  message: Scalars['String'];
}>;


export type BlockOrganizationMutation = { blockOrganization: OrganizationFragment };

export type ReportUserMutationVariables = Exact<{
  userId: Scalars['String'];
  message: Scalars['String'];
}>;


export type ReportUserMutation = { reportUser: UserFragment };

export type BlockUserMutationVariables = Exact<{
  userId: Scalars['String'];
  message: Scalars['String'];
}>;


export type BlockUserMutation = { blockUser: UserFragment };

export type ResolveReportMutationVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type ResolveReportMutation = { resolveReport: boolean };

export type UnresolveReportMutationVariables = Exact<{
  reportId: Scalars['String'];
}>;


export type UnresolveReportMutation = { unresolveReport: boolean };

export type GetActiveBlockQueryVariables = Exact<{
  entityFilter: EntityFilterInput;
}>;


export type GetActiveBlockQuery = { getActiveBlock: BlockFragment };

export type ResolveDiscussionBlockMutationVariables = Exact<{
  discussionId: Scalars['String'];
}>;


export type ResolveDiscussionBlockMutation = { resolveDiscussionBlock: DiscussionFragment };

export type ResolvePostBlockMutationVariables = Exact<{
  postId: Scalars['String'];
  discussionId: Scalars['String'];
}>;


export type ResolvePostBlockMutation = { resolvePostBlock: PostFragment };

export type ResolveUserBlockMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ResolveUserBlockMutation = { resolveUserBlock: UserFragment };

export type ResolveTechnologyBlockMutationVariables = Exact<{
  technologyId: Scalars['String'];
}>;


export type ResolveTechnologyBlockMutation = { resolveTechnologyBlock: TechnologyFragment };

export type ResolveOrganizationBlockMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type ResolveOrganizationBlockMutation = { resolveOrganizationBlock: OrganizationFragment };

export type GetNewNotificationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNewNotificationsCountQuery = { getNewNotificationsCount: number };

export type GetNotificationsQueryVariables = Exact<{
  notificationInput: NotificationInput;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetNotificationsQuery = { results: { totalCount: number, allCount: number, readCount: number, unreadCount: number, flaggedCount: number, items: Array<NotificationFragment> } };

export type UpdateNotificationStatusMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateNotificationInput;
}>;


export type UpdateNotificationStatusMutation = { updateNotificationStatus: NotificationStatusFragment };

export type MarkAllAsMutationVariables = Exact<{
  updateNotificationInput: UpdateNotificationInput;
}>;


export type MarkAllAsMutation = { markAllAs: boolean };

export type DeleteAllMutationVariables = Exact<{
  notificationInput: NotificationInput;
}>;


export type DeleteAllMutation = { deleteAll: boolean };

export type RemoveNotificationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveNotificationMutation = { removeNotification: boolean };

export type OnNotificationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnNotificationSubscription = { notificationSubscription: NotificationSubscriptionFragment };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOrganizationQuery = { organization: OrganizationFragment };

export type OrganizationSearchQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  isCompany?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type OrganizationSearchQuery = { results: { totalCount: number, items: Array<OrganizationFragment> } };

export type SearchOnboardingOrganizationsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  sort?: Maybe<SortInput>;
}>;


export type SearchOnboardingOrganizationsQuery = { results: Array<OrganizationFragment> };

export type SearchOnboardingCompaniesQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchOnboardingCompaniesQuery = { results: { totalCount: number, items: Array<OrganizationFragment> } };

export type CompanyExistsMutationVariables = Exact<{
  data: ValidateOrganizationInput;
}>;


export type CompanyExistsMutation = { companyExists: { errorMessage?: Maybe<string>, organization?: Maybe<OrganizationFragment> } };

export type LeaveOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type LeaveOrganizationMutation = { response: { leaveSuccessful: boolean, errorMessage?: Maybe<string>, updatedUser: CurrentUserFragment, organization: OrganizationSummaryFragment } };

export type CanLeaveOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type CanLeaveOrganizationQuery = { result: { leaveStatus: LeaveOrganizationStatus, organization: OrganizationSummaryFragment } };

export type JoinOrganizationMutationVariables = Exact<{
  data: JoinAffiliationInput;
}>;


export type JoinOrganizationMutation = { response: { responseStatus: JoinOrganizationStatus, errorMessage?: Maybe<string>, updatedUser: CurrentUserFragment, organization: OrganizationSummaryFragment } };

export type DeleteOrganizationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteOrganizationMutation = { updatedUser: CurrentUserFragment };

export type LikeOrganizationMutationVariables = Exact<{
  organization: Scalars['String'];
}>;


export type LikeOrganizationMutation = { likeOrganization: { id: string, user?: Maybe<{ id: string, name: string }>, organization?: Maybe<{ id: string, name: string }> } };

export type DeleteOrganizationLikeMutationVariables = Exact<{
  organization: Scalars['String'];
}>;


export type DeleteOrganizationLikeMutation = { unlikeOrganization: boolean };

export type GetOrganizationDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOrganizationDetailsQuery = { organizationDetails: OrganizationDetailsFragment };

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateOrganizationInput;
}>;


export type UpdateOrganizationMutation = { updateOrganization: OrganizationDetailsFragment };

export type UpdateOrganizationEmailDomainsMutationVariables = Exact<{
  id: Scalars['String'];
  data: UpdateOrgEmailDomainsInput;
}>;


export type UpdateOrganizationEmailDomainsMutation = { updateOrganizationEmailDomains: OrganizationDetailsFragment };

export type CreateOrganizationMutationVariables = Exact<{
  data: CreateOrganizationInput;
}>;


export type CreateOrganizationMutation = { response: { user: CurrentUserFragment, organization: OrganizationFragment } };

export type ShareOrganizationMutationVariables = Exact<{
  data: ShareInput;
}>;


export type ShareOrganizationMutation = { shareOrganization: boolean };

export type UpdateOrganizationEmployeesMutationVariables = Exact<{
  data: UpdateOrganizationEmployeesInput;
}>;


export type UpdateOrganizationEmployeesMutation = { updateOrganizationEmployees: boolean };

export type FollowOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type FollowOrganizationMutation = { followOrganization: { id: string, user: { id: string, name: string }, organization: { id: string, name: string } } };

export type UnFollowOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type UnFollowOrganizationMutation = { unFollowOrganization: boolean };

export type GetOrganizationFollowsQueryVariables = Exact<{
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetOrganizationFollowsQuery = { getOrganizationFollows: { totalCount: number, items: Array<OrganizationFollowFragment> } };

export type GetTechnologyByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTechnologyByIdQuery = { technology: TechnologyFragment };

export type GetTechnologiesByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type GetTechnologiesByOrganizationQuery = { getTechnologiesByOrganization: Array<TechnologyFragment> };

export type ExplorerSearchQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  technologyFilter: Array<Scalars['ID']> | Scalars['ID'];
  trlFilter?: Maybe<RangeFilterInput>;
  mrlFilter?: Maybe<RangeFilterInput>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type ExplorerSearchQuery = { results: { totalCount: number, items: Array<QueryTechnologyFragment> } };

export type CreateTechnologyMutationVariables = Exact<{
  data: TechnologyUpsertInput;
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type CreateTechnologyMutation = { technology: { id: string } };

export type DeleteTechnologyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTechnologyMutation = { deleteTechnology: boolean };

export type UpdateTechnologyMutationVariables = Exact<{
  id: Scalars['String'];
  data: TechnologyUpsertInput;
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type UpdateTechnologyMutation = { updateTechnology: TechnologyFragment };

export type LikeTechnologyMutationVariables = Exact<{
  technology: Scalars['String'];
}>;


export type LikeTechnologyMutation = { likeTechnology: { id: string, user?: Maybe<{ id: string, name: string }>, technology?: Maybe<{ id: string, name: string }> } };

export type DeleteTechnologyLikeMutationVariables = Exact<{
  technology: Scalars['String'];
}>;


export type DeleteTechnologyLikeMutation = { unlikeTechnology: boolean };

export type GetTechnologyDocumentMutationVariables = Exact<{
  technologyId: Scalars['String'];
  documentId: Scalars['String'];
}>;


export type GetTechnologyDocumentMutation = { getDocumentDownloadLink: { url: string } };

export type GetTechnologyCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTechnologyCategoriesQuery = { technologyCategories: Array<ParentCategoryFragment> };

export type ShareTechnologyMutationVariables = Exact<{
  data: ShareInput;
}>;


export type ShareTechnologyMutation = { shareTechnology: boolean };

export type FollowTechnologyMutationVariables = Exact<{
  technology: Scalars['String'];
}>;


export type FollowTechnologyMutation = { followTechnology: { id: string, user: { id: string, name: string }, technology: { id: string, name: string } } };

export type UnFollowTechnologyMutationVariables = Exact<{
  technology: Scalars['String'];
}>;


export type UnFollowTechnologyMutation = { unFollowTechnology: boolean };

export type GetTechnologyFollowsQueryVariables = Exact<{
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetTechnologyFollowsQuery = { getTechnologyFollows: { totalCount: number, items: Array<TechnologyFollowFragment> } };

export type SingleUploadMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type SingleUploadMutation = { singleUpload: boolean };

export type MultipleUploadMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type MultipleUploadMutation = { multipleUpload: boolean };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: boolean };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { user: CurrentUserFragment };

export type OnBoardUserMutationVariables = Exact<{
  data: OnboardUserInput;
}>;


export type OnBoardUserMutation = { onBoardUser: CurrentUserFragment };

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: CurrentUserFragment };

export type AddUserIdentityMutationVariables = Exact<{
  emailAddress: Scalars['String'];
}>;


export type AddUserIdentityMutation = { addUserIdentity: { errorMessage?: Maybe<string>, result?: Maybe<CurrentUserFragment> } };

export type SetPrimaryEmailMutationVariables = Exact<{
  emailAddress: Scalars['String'];
}>;


export type SetPrimaryEmailMutation = { response: { errorMessage?: Maybe<string>, result?: Maybe<CurrentUserFragment> } };

export type SetPrimaryOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type SetPrimaryOrganizationMutation = { response: { errorMessage?: Maybe<string>, result?: Maybe<CurrentUserFragment> } };

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserByIdQuery = { userProfile: UserProfileFragment };

export type GetModeratorSuperAdminsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModeratorSuperAdminsQuery = { getModeratorSuperAdmins: Array<AdminUserFragment> };

export type GetAutoAppUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAutoAppUsersQuery = { getAutoAppUsers: Array<{ id: string, userEmail: string, appRole?: Maybe<{ roleName?: Maybe<string> }> }> };

export type GetUsersByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type GetUsersByOrganizationQuery = { getUsersByOrganization: Array<OrgUserFragment> };

export type GetUsersByOrganizationWithRolesQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type GetUsersByOrganizationWithRolesQuery = { getUsersByOrganizationWithRoles: Array<OrgUserWithRolesFragment> };

export type GetPendingApprovalsQueryVariables = Exact<{
  organizationId: Scalars['String'];
}>;


export type GetPendingApprovalsQuery = { getPendingApprovals: Array<PendingApprovalUserFragment> };

export type UpdateUserPreferencesMutationVariables = Exact<{
  data: UpdateUserPreferencesInput;
}>;


export type UpdateUserPreferencesMutation = { updateUserPreferences: boolean };

export type ApproveUserMutationVariables = Exact<{
  data: PendingApprovalInput;
}>;


export type ApproveUserMutation = { approveUser: boolean };

export type RemoveRoleMutationVariables = Exact<{
  data: UpdateRoleInput;
}>;


export type RemoveRoleMutation = { removeRole: boolean };

export type AddRoleMutationVariables = Exact<{
  data: UpdateRoleInput;
}>;


export type AddRoleMutation = { addRole: boolean };

export type RemoveAutoAppUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveAutoAppUserMutation = { removeAutoAppUser: boolean };

export type AddAutoAppUserMutationVariables = Exact<{
  data: AddAutoRoleUserInput;
}>;


export type AddAutoAppUserMutation = { addAutoAppUser: boolean };

export type SearchUsersQueryVariables = Exact<{
  searchTerm?: Maybe<Scalars['String']>;
  isGovernment?: Maybe<Scalars['Boolean']>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type SearchUsersQuery = { results: { totalCount: number, items: Array<UsersFragment> } };

export type CreateInvitesMutationVariables = Exact<{
  data: CreateInviteInput;
}>;


export type CreateInvitesMutation = { createInvites: Array<InvitedUserFragment> };

export type ShareUserMutationVariables = Exact<{
  data: ShareInput;
}>;


export type ShareUserMutation = { shareUser: boolean };

export type GetUserLookupQueryVariables = Exact<{
  name: Scalars['String'];
  pagination?: Maybe<PaginationInput>;
  includeSelf?: Maybe<Scalars['Boolean']>;
}>;


export type GetUserLookupQuery = { getUserLookup: { totalCount: number, items: Array<UserLookupFragment> } };

export type GetDiscussionsByUserQueryVariables = Exact<{
  sort?: Maybe<SortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type GetDiscussionsByUserQuery = { getDiscussionsByUser: { totalCount: number, items: Array<DiscussionFragment> } };

export type GetUserFollowCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFollowCountsQuery = { getUserFollowCounts: { technologyFollows?: Maybe<number>, organizationFollows?: Maybe<number>, discussionFollows?: Maybe<number> } };

export type ResendEmailValidationMutationVariables = Exact<{
  identityId: Scalars['String'];
}>;


export type ResendEmailValidationMutation = { response: { errorMessage?: Maybe<string>, result?: Maybe<CurrentUserFragment> } };

export const DocumentFragmentDoc = gql`
    fragment Document on DocumentBlob {
  id
  fileName
  blobBase64
}
    `;
export const OnboardingStepFragmentDoc = gql`
    fragment OnboardingStep on OnboardingStep {
  id
  title
  orderNumber
  link
  linkedFile {
    ...Document
  }
  linkText
  description
  header
  completable
  completed
  dateCompleted
  divideBefore
}
    ${DocumentFragmentDoc}`;
export const OrganizationSummaryFragmentDoc = gql`
    fragment OrganizationSummary on Organization {
  currentlyBlocked
  avatar
  name
  id
  description
  isCompany
  city
  state
}
    `;
export const AffiliationFragmentDoc = gql`
    fragment Affiliation on Affiliation {
  id
  role
  relation
  approvalPending
  identityAffiliationStatus
  organization {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  role
  profession
  id
  name
  firstName
  isGovernment
  isCtr
  lastName
  emailVerified
  avatar
  currentlyBlocked
  approvalPending
  onboarded
  phone
  experience
  linkedin
  bio
  referralType
  referralComments
  govtOnlyDisplayEmail
  govtOnlyDisplayPhoneNumber
  orgSymbol
  dutyTitle
  primaryAffiliation {
    ...Affiliation
    ...Affiliation
  }
  userAffiliations {
    ...Affiliation
  }
  organization {
    ...OrganizationSummary
  }
  primaryAffiliation {
    ...Affiliation
  }
}
    ${AffiliationFragmentDoc}
${OrganizationSummaryFragmentDoc}`;
export const OnboardingSectionPocFragmentDoc = gql`
    fragment OnboardingSectionPOC on OnboardingSectionPOC {
  id
  portalUser {
    ...User
  }
  manualEmail
  name
}
    ${UserFragmentDoc}`;
export const OnboardingSectionFragmentDoc = gql`
    fragment OnboardingSection on OnboardingSection {
  id
  title
  shortTitle
  subtitle
  isSupplementary
  completed
  dateCompleted
  footer
  orderNumber
  createDate
  updateDate
  steps {
    ...OnboardingStep
  }
  pocs {
    ...OnboardingSectionPOC
  }
}
    ${OnboardingStepFragmentDoc}
${OnboardingSectionPocFragmentDoc}`;
export const GetUserInvitesFragmentDoc = gql`
    fragment GetUserInvites on Invite {
  id
}
    `;
export const UserTechnologyLikesFragmentDoc = gql`
    fragment UserTechnologyLikes on TechnologyLike {
  id
  technology {
    id
    name
  }
}
    `;
export const UserOrganizationLikesFragmentDoc = gql`
    fragment UserOrganizationLikes on OrganizationLike {
  id
  organization {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  name
  description
}
    `;
export const ChildCategoryFragmentDoc = gql`
    fragment ChildCategory on Category {
  ...Category
  parent {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export const VerificationFragmentDoc = gql`
    fragment Verification on Verification {
  id
  verified
  verificationToken
  tokenExpirationDate
}
    `;
export const IdentityFragmentDoc = gql`
    fragment Identity on Identity {
  id
  name
  firstName
  lastName
  emailAddress
  emailVerified
  verification {
    ...Verification
  }
  user {
    ...User
  }
  affiliations {
    ...Affiliation
  }
}
    ${VerificationFragmentDoc}
${UserFragmentDoc}
${AffiliationFragmentDoc}`;
export const UserWithEmailFragmentDoc = gql`
    fragment UserWithEmail on UserWithEmail {
  id
  name
  firstName
  lastName
  emailAddress
  currentlyBlocked
  approvalPending
  allowEmails
  onboarded
  govtOnlyDisplayEmail
  govtOnlyDisplayPhoneNumber
  phone
  bio
  experience
  skills
  education
  publications
  linkedin
  externalLinks {
    id
    title
    url
  }
  referralType
  referralComments
  role
  profession
  avatar
  isGovernment
  isCtr
  orgSymbol
  dutyTitle
  organization {
    ...OrganizationSummary
  }
  invites {
    ...GetUserInvites
  }
  technologyLikes {
    ...UserTechnologyLikes
  }
  organizationLikes {
    ...UserOrganizationLikes
  }
  categories {
    ...ChildCategory
  }
  identities {
    ...Identity
  }
  primaryAffiliation {
    ...Affiliation
  }
  userAffiliations {
    ...Affiliation
  }
  userOrgAccess {
    id
    role {
      id
      roleName
      appRoleCapabilities {
        function
        appCapabilities {
          capabilityName
        }
      }
    }
    organizationId
  }
}
    ${OrganizationSummaryFragmentDoc}
${GetUserInvitesFragmentDoc}
${UserTechnologyLikesFragmentDoc}
${UserOrganizationLikesFragmentDoc}
${ChildCategoryFragmentDoc}
${IdentityFragmentDoc}
${AffiliationFragmentDoc}`;
export const UserOnboardingProgressFragmentDoc = gql`
    fragment UserOnboardingProgress on OnboardingUserProgress {
  stepsCompleted
  totalSteps
  user {
    ...UserWithEmail
  }
  supervisorName
  supervisorEmail
  completedDate
  effectiveDate
  isNewGovernmentEmployee
  updateDate
}
    ${UserWithEmailFragmentDoc}`;
export const CommunityAppFragmentDoc = gql`
    fragment CommunityApp on CommunityApps {
  id
  name
  url
  avatar
  usageCount
  usageNames
}
    `;
export const CommunityBlobSummaryFragmentDoc = gql`
    fragment CommunityBlobSummary on CommunityBlob {
  id
  fileName
}
    `;
export const CommunityBlobFragmentDoc = gql`
    fragment CommunityBlob on CommunityBlob {
  ...CommunityBlobSummary
  blobBase64
}
    ${CommunityBlobSummaryFragmentDoc}`;
export const CommunitySocialFragmentDoc = gql`
    fragment CommunitySocial on CommunitySocial {
  id
  groupHeader
  facebook
  twitter
  linkedin
  instagram
}
    `;
export const CommunityResourceBaseFragmentDoc = gql`
    fragment CommunityResourceBase on CommunityResourceBase {
  id
  linkedFile {
    ...CommunityBlobSummary
  }
  social {
    ...CommunitySocial
  }
  title
  link
  orderNumber
  type
  createDate
}
    ${CommunityBlobSummaryFragmentDoc}
${CommunitySocialFragmentDoc}`;
export const ModuleItemResourceFragmentDoc = gql`
    fragment ModuleItemResource on ModuleItemResource {
  id
  linkedFile {
    ...CommunityBlobSummary
  }
  title
  link
  orderNumber
  type
}
    ${CommunityBlobSummaryFragmentDoc}`;
export const CommunityModuleItemFragmentDoc = gql`
    fragment CommunityModuleItem on CommunityModuleItem {
  id
  title
  content
  embedUrl
  orderNumber
  attachedResources {
    ...ModuleItemResource
  }
}
    ${ModuleItemResourceFragmentDoc}`;
export const CommunityContentModuleFragmentDoc = gql`
    fragment CommunityContentModule on CommunityContentModule {
  id
  items {
    ...CommunityModuleItem
  }
  description
  title
  orderNumber
}
    ${CommunityModuleItemFragmentDoc}`;
export const CommunitySummaryFragmentDoc = gql`
    fragment CommunitySummary on CommunitySummary {
  name
  id
  description
  avatar
  banner
  bannerHexColor
}
    `;
export const HomeHelpMessageFragmentDoc = gql`
    fragment HomeHelpMessage on HomeHelpMessage {
  id
  header
  description
  active
  externalLinkLabel
  externalLinkUrl
  lackingCommunityMembership
}
    `;
export const CommunityFeaturesFragmentDoc = gql`
    fragment CommunityFeatures on CommunityFeatures {
  apps
  announcements
  questions
  calendar
  discussions
  resourcesTab
}
    `;
export const SectionResourceFragmentDoc = gql`
    fragment SectionResource on SectionResource {
  id
  linkedFile {
    ...CommunityBlobSummary
  }
  social {
    ...CommunitySocial
  }
  title
  link
  orderNumber
  type
  createDate
}
    ${CommunityBlobSummaryFragmentDoc}
${CommunitySocialFragmentDoc}`;
export const CommunitySectionFragmentDoc = gql`
    fragment CommunitySection on CommunitySection {
  id
  description
  sectionTitle
  rowOrderNumber
  columnNumber
  resources {
    ...SectionResource
  }
}
    ${SectionResourceFragmentDoc}`;
export const CommunityFragmentDoc = gql`
    fragment Community on Community {
  groupKey
  groupCalendarUrl
  helpContact
  updateDate
  name
  id
  description
  customHelpInstructions
  avatar
  banner
  bannerHexColor
  features {
    ...CommunityFeatures
  }
  sections {
    ...CommunitySection
  }
  categories {
    ...Category
  }
}
    ${CommunityFeaturesFragmentDoc}
${CommunitySectionFragmentDoc}
${CategoryFragmentDoc}`;
export const GetCommunityResponseFragmentDoc = gql`
    fragment GetCommunityResponse on GetCommunityResponse {
  result {
    ...Community
  }
  errorMessage
}
    ${CommunityFragmentDoc}`;
export const AnnouncementResourceFragmentDoc = gql`
    fragment AnnouncementResource on AnnouncementResource {
  id
  linkedFile {
    ...CommunityBlobSummary
  }
  title
  link
  orderNumber
  type
  createDate
}
    ${CommunityBlobSummaryFragmentDoc}`;
export const AnnouncementFragmentDoc = gql`
    fragment Announcement on Announcement {
  id
  title
  description
  createDate
  currentlyPinned
  attachedResources {
    ...AnnouncementResource
  }
  categories {
    ...Category
  }
}
    ${AnnouncementResourceFragmentDoc}
${CategoryFragmentDoc}`;
export const CommunityMembersFragmentDoc = gql`
    fragment CommunityMembers on User {
  id
  name
  avatar
  firstName
  lastName
  isGovernment
  currentlyBlocked
}
    `;
export const MessageUserFragmentDoc = gql`
    fragment MessageUser on User {
  id
  name
  firstName
  lastName
  avatar
}
    `;
export const ConversationFragmentDoc = gql`
    fragment Conversation on Conversation {
  id
  initiatorUnReadCount
  participantUnReadCount
  initiatorArchived
  participantArchived
  initiatorHasMuted
  participantHasMuted
  updateDate
  initiator {
    ...MessageUser
  }
  participant {
    ...MessageUser
  }
}
    ${MessageUserFragmentDoc}`;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  id
  text
  initiatorRead
  participantRead
  createDate
  sender {
    ...MessageUser
  }
}
    ${MessageUserFragmentDoc}`;
export const MessageSubscriptionUserFragmentDoc = gql`
    fragment MessageSubscriptionUser on MessageSubscriptionUser {
  id
  name
  firstName
  lastName
  avatar
}
    `;
export const MessageSubscriptionFragmentDoc = gql`
    fragment MessageSubscription on MessageSubscription {
  id
  text
  createDate
  updateDate
  initiatorRead
  participantRead
  sender {
    ...MessageSubscriptionUser
  }
  conversation {
    id
    initiatorUnReadCount
    participantUnReadCount
    initiatorArchived
    participantArchived
    initiatorHasMuted
    participantHasMuted
    updateDate
    initiator {
      ...MessageSubscriptionUser
    }
    participant {
      ...MessageSubscriptionUser
    }
  }
}
    ${MessageSubscriptionUserFragmentDoc}`;
export const DiscussionSummaryFragmentDoc = gql`
    fragment DiscussionSummary on Discussion {
  id
  title
  content
  isPrivate
  createDate
  latestActivityDate
  updateDate
  currentlyPinned
  participantCount
  createdBy {
    ...User
  }
  categories {
    id
    name
    description
  }
}
    ${UserFragmentDoc}`;
export const DiscussionParticipantFragmentDoc = gql`
    fragment DiscussionParticipant on User {
  id
  name
  avatar
  firstName
  lastName
  currentlyBlocked
}
    `;
export const DiscussionFragmentDoc = gql`
    fragment Discussion on Discussion {
  ...DiscussionSummary
  postCount
  viewCount
  likeCount
  currentUserLike
  currentUserFollows
  currentlyBlocked
  communityId
  currentlyPinned
  participantCount
  participants {
    ...DiscussionParticipant
  }
  categories {
    id
    name
    description
  }
}
    ${DiscussionSummaryFragmentDoc}
${DiscussionParticipantFragmentDoc}`;
export const DiscussionFollowFragmentDoc = gql`
    fragment DiscussionFollow on DiscussionFollow {
  user {
    ...User
  }
  discussion {
    ...Discussion
  }
}
    ${UserFragmentDoc}
${DiscussionFragmentDoc}`;
export const TechnologySummaryFragmentDoc = gql`
    fragment TechnologySummary on Technology {
  id
  name
  profileImagePath
  organization {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  content
  currentlyBlocked
  createDate
  repliesCount
  updateDate
  lastPostEditDate
  createdBy {
    ...User
  }
  parentPost {
    id
  }
}
    ${UserFragmentDoc}`;
export const UserSummaryFragmentDoc = gql`
    fragment UserSummary on User {
  id
  name
  firstName
  isGovernment
  isCtr
  profession
  lastName
  avatar
  organization {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  id
  message
  createDate
  resolved
  type
  discussion {
    ...DiscussionSummary
  }
  technology {
    ...TechnologySummary
  }
  post {
    ...Post
  }
  organization {
    ...OrganizationSummary
  }
  user {
    ...UserSummary
  }
  reportedBy {
    ...UserSummary
  }
}
    ${DiscussionSummaryFragmentDoc}
${TechnologySummaryFragmentDoc}
${PostFragmentDoc}
${OrganizationSummaryFragmentDoc}
${UserSummaryFragmentDoc}`;
export const BlockSummaryFragmentDoc = gql`
    fragment BlockSummary on Block {
  id
  message
  createDate
  resolved
  type
  blockedBy {
    firstName
    lastName
    id
  }
}
    `;
export const BlockFragmentDoc = gql`
    fragment Block on Block {
  id
  message
  createDate
  resolved
  released
  type
  parentBlock {
    type
  }
  discussion {
    ...DiscussionSummary
  }
  technology {
    ...TechnologySummary
  }
  post {
    ...Post
  }
  organization {
    ...OrganizationSummary
  }
  user {
    ...UserSummary
  }
  blockedBy {
    ...UserSummary
  }
}
    ${DiscussionSummaryFragmentDoc}
${TechnologySummaryFragmentDoc}
${PostFragmentDoc}
${OrganizationSummaryFragmentDoc}
${UserSummaryFragmentDoc}`;
export const JuicerPostSummaryFragmentDoc = gql`
    fragment JuicerPostSummary on JuicerPost {
  id
  full_url
  video
  image
  unformatted_message
  message
  external_created_at
}
    `;
export const StateFragmentDoc = gql`
    fragment State on State {
  code
  name
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  id
  notificationType
  notificationStatus
  notificationClassification
  createDate
  updateDate
  actionUser {
    id
    name
  }
  technology {
    id
    name
  }
  organization {
    id
    name
    isCompany
  }
  discussion {
    id
    title
    communityId
  }
  reply {
    id
  }
  post {
    id
  }
  user {
    id
    name
  }
}
    `;
export const NotificationSubscriptionFragmentDoc = gql`
    fragment NotificationSubscription on NotificationSubscription {
  id
  notificationType
  notificationStatus
  notificationClassification
  createDate
  updateDate
  actionUser {
    id
    name
  }
  technology {
    id
    name
  }
  organization {
    id
    name
    isCompany
  }
  discussion {
    id
    title
    communityId
  }
  reply {
    id
  }
  post {
    id
  }
  user {
    id
    name
  }
}
    `;
export const NotificationStatusFragmentDoc = gql`
    fragment NotificationStatus on Notification {
  id
  notificationType
  notificationStatus
  notificationClassification
  createDate
  updateDate
}
    `;
export const ExternalLinkFragmentDoc = gql`
    fragment ExternalLink on OrganizationExternalLink {
  id
  title
  url
}
    `;
export const NaicsCodeFragmentDoc = gql`
    fragment NaicsCode on OrganizationNaicsCode {
  id
  code
  descriptor
}
    `;
export const OrganizationBusinessTypeFragmentDoc = gql`
    fragment OrganizationBusinessType on OrganizationBusinessType {
  id
  name
}
    `;
export const EmailDomainFragmentDoc = gql`
    fragment EmailDomain on EmailDomain {
  id
  domain
}
    `;
export const OrganizationSbirSttrAwardFragmentDoc = gql`
    fragment OrganizationSbirSttrAward on OrganizationSbirSttrAward {
  id
  name
  year
  type
  phase
  link
}
    `;
export const OrganizationPrincipalFragmentDoc = gql`
    fragment OrganizationPrincipal on OrganizationPrincipal {
  id
  name
  title
}
    `;
export const OrganizationCompetencyFragmentDoc = gql`
    fragment OrganizationCompetency on OrganizationCompetency {
  id
  name
  description
}
    `;
export const OrganizationChildrenFragmentDoc = gql`
    fragment OrganizationChildren on Organization {
  id
  name
  description
  orgDescription
  isCompany
  currentUserLike
  likeCount
  avatar
  governmentLevel
  currentUserFollows
  currentlyBlocked
}
    `;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on Organization {
  id
  name
  description
  orgDescription
  missionStatement
  currentlyBlocked
  isCompany
  city
  state
  websiteUrl
  avatar
  createDate
  latestActivityDate
  externalLinks {
    ...ExternalLink
  }
  cageCode
  dunsNumber
  naicsCodes {
    ...NaicsCode
  }
  businessTypes {
    ...OrganizationBusinessType
  }
  emailDomains {
    ...EmailDomain
  }
  sbirSttrAwards {
    ...OrganizationSbirSttrAward
  }
  principals {
    ...OrganizationPrincipal
  }
  coreCompetencies {
    ...OrganizationCompetency
  }
  viewCount
  likeCount
  currentUserLike
  currentUserFollows
  technologies {
    categories {
      id
      name
    }
  }
  pointOfContacts {
    ...User
  }
  governmentLevel
  OrganizationParent: parent {
    id
    name
    description
    avatar
    governmentLevel
  }
  OrganizationChildren: children {
    ...OrganizationChildren
  }
}
    ${ExternalLinkFragmentDoc}
${NaicsCodeFragmentDoc}
${OrganizationBusinessTypeFragmentDoc}
${EmailDomainFragmentDoc}
${OrganizationSbirSttrAwardFragmentDoc}
${OrganizationPrincipalFragmentDoc}
${OrganizationCompetencyFragmentDoc}
${UserFragmentDoc}
${OrganizationChildrenFragmentDoc}`;
export const OrganizationSearchOnboardingResultsFragmentDoc = gql`
    fragment OrganizationSearchOnboardingResults on Organization {
  ...OrganizationSummary
  governmentLevel
  childrenCount
  city
  state
  websiteUrl
}
    ${OrganizationSummaryFragmentDoc}`;
export const OrganizationSearchResultsFragmentDoc = gql`
    fragment OrganizationSearchResults on Organization {
  ...OrganizationSearchOnboardingResults
  likeCount
  viewCount
  currentUserLike
  currentUserFollows
}
    ${OrganizationSearchOnboardingResultsFragmentDoc}`;
export const OrganizationFragmentDoc = gql`
    fragment Organization on Organization {
  missionStatement
  adminApprovalRequired
  currentlyBlocked
  autoJoinWithDomain
  createDate
  latestActivityDate
  cageCode
  dunsNumber
  ...OrganizationSearchResults
}
    ${OrganizationSearchResultsFragmentDoc}`;
export const OrganizationFollowFragmentDoc = gql`
    fragment OrganizationFollow on OrganizationFollow {
  user {
    ...User
  }
  organization {
    ...Organization
  }
}
    ${UserFragmentDoc}
${OrganizationFragmentDoc}`;
export const BooleanResponseFragmentDoc = gql`
    fragment BooleanResponse on BooleanResponse {
  result
  errorMessage
}
    `;
export const QueryTechnologyFragmentDoc = gql`
    fragment QueryTechnology on Technology {
  id
  name
  description
  likeCount
  viewCount
  currentUserLike
  currentUserFollows
  profileImagePath
  currentlyBlocked
  techReadinessLevel
  mfgReadinessLevel
  organization {
    ...OrganizationSummary
  }
  categories {
    id
    name
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const TechnologiesByOrganizationFragmentDoc = gql`
    fragment TechnologiesByOrganization on Technology {
  id
  name
  likeCount
  viewCount
  currentlyBlocked
  description
  profileImagePath
  mfgReadinessLevel
  techReadinessLevel
  currentUserLike
  categories {
    id
    name
  }
}
    `;
export const ParentCategoryFragmentDoc = gql`
    fragment ParentCategory on Category {
  ...Category
  children {
    ...ChildCategory
  }
}
    ${CategoryFragmentDoc}
${ChildCategoryFragmentDoc}`;
export const TechnologyDocumentFragmentDoc = gql`
    fragment TechnologyDocument on TechnologyDocument {
  id
  fileName
  originalFileName
  fileExtension
  path
  technologyDocumentType
  secure
  usersWithAccess {
    id
  }
}
    `;
export const TechnologyFragmentDoc = gql`
    fragment Technology on Technology {
  ...TechnologySummary
  likeCount
  viewCount
  currentlyBlocked
  currentUserLike
  currentUserFollows
  description
  currentlyBlocked
  profileImagePath
  mfgReadinessLevel
  techReadinessLevel
  currentReadinessResults
  createDate
  latestActivityDate
  documents {
    ...TechnologyDocument
  }
  pointOfContacts {
    ...User
  }
  categories {
    id
    name
    description
    parent {
      id
      name
      description
    }
  }
  externalLinks {
    id
    title
    url
  }
  patents {
    id
    name
  }
  copyrights {
    id
    name
  }
  trademarks {
    id
    name
  }
}
    ${TechnologySummaryFragmentDoc}
${TechnologyDocumentFragmentDoc}
${UserFragmentDoc}`;
export const TechnologyFollowFragmentDoc = gql`
    fragment TechnologyFollow on TechnologyFollow {
  user {
    ...User
  }
  technology {
    ...Technology
  }
}
    ${UserFragmentDoc}
${TechnologyFragmentDoc}`;
export const UserIdentityFragmentDoc = gql`
    fragment UserIdentity on User {
  ...User
  userOrgAccess {
    id
    role {
      id
      roleName
    }
    organizationId
  }
  identities {
    ...Identity
  }
  primaryAffiliation {
    ...Affiliation
  }
  userAffiliations {
    ...Affiliation
  }
}
    ${UserFragmentDoc}
${IdentityFragmentDoc}
${AffiliationFragmentDoc}`;
export const GroupMembershipFragmentDoc = gql`
    fragment GroupMembership on GroupMembership {
  groupKey
  groupRole
  communityId
}
    `;
export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on CurrentUser {
  id
  name
  firstName
  lastName
  emailAddress
  currentlyBlocked
  approvalPending
  allowEmails
  onboarded
  govtOnlyDisplayEmail
  govtOnlyDisplayPhoneNumber
  phone
  bio
  experience
  skills
  education
  publications
  linkedin
  externalLinks {
    id
    title
    url
  }
  referralType
  referralComments
  role
  profession
  groupPermissions {
    ...GroupMembership
  }
  avatar
  isGovernment
  isCtr
  orgSymbol
  dutyTitle
  organization {
    ...OrganizationSummary
  }
  invites {
    ...GetUserInvites
  }
  technologyLikes {
    ...UserTechnologyLikes
  }
  organizationLikes {
    ...UserOrganizationLikes
  }
  categories {
    ...ChildCategory
  }
  googleGroupPermissions {
    teamAtLargeGroup
    rgCoreGroup
    newHire
  }
  identities {
    ...Identity
  }
  primaryAffiliation {
    ...Affiliation
  }
  userAffiliations {
    ...Affiliation
  }
  userOrgAccess {
    id
    role {
      id
      roleName
      appRoleCapabilities {
        function
        appCapabilities {
          capabilityName
        }
      }
    }
    organizationId
  }
}
    ${GroupMembershipFragmentDoc}
${OrganizationSummaryFragmentDoc}
${GetUserInvitesFragmentDoc}
${UserTechnologyLikesFragmentDoc}
${UserOrganizationLikesFragmentDoc}
${ChildCategoryFragmentDoc}
${IdentityFragmentDoc}
${AffiliationFragmentDoc}`;
export const AdminUserFragmentDoc = gql`
    fragment AdminUser on CurrentUser {
  id
  name
  firstName
  lastName
  emailAddress
  role
  profession
  avatar
  currentlyBlocked
  isGovernment
  isCtr
  userOrgAccess {
    id
    role {
      id
      roleName
    }
  }
  organization {
    id
    name
  }
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on User {
  id
  name
  firstName
  lastName
  createDate
  latestActivityDate
  currentlyBlocked
  approvalPending
  emailVerified
  allowEmails
  onboarded
  phone
  bio
  experience
  skills
  education
  publications
  linkedin
  externalLinks {
    id
    title
    url
  }
  referralType
  referralComments
  role
  profession
  avatar
  isGovernment
  isCtr
  orgSymbol
  dutyTitle
  organization {
    ...OrganizationSummary
  }
  invites {
    id
  }
  technologyLikes {
    id
    technology {
      id
      name
    }
  }
  organizationLikes {
    ...UserOrganizationLikes
  }
  categories {
    ...ChildCategory
  }
  pointOfContacts {
    id
    name
    avatar
    city
    state
    isCompany
  }
  technologyPointOfContacts {
    id
    name
    profileImagePath
    techReadinessLevel
    mfgReadinessLevel
    viewCount
  }
  identities {
    ...Identity
  }
  primaryAffiliation {
    ...Affiliation
  }
  userAffiliations {
    ...Affiliation
  }
}
    ${OrganizationSummaryFragmentDoc}
${UserOrganizationLikesFragmentDoc}
${ChildCategoryFragmentDoc}
${IdentityFragmentDoc}
${AffiliationFragmentDoc}`;
export const OrgUserFragmentDoc = gql`
    fragment OrgUser on User {
  id
  name
  firstName
  lastName
  currentlyBlocked
  onboarded
  phone
  bio
  experience
  linkedin
  referralType
  referralComments
  role
  profession
  avatar
  isGovernment
  isCtr
  orgSymbol
  dutyTitle
  profession
  emailVerified
  createDate
  updateDate
  latestActivityDate
  approvalPending
  allowEmails
}
    `;
export const OrgUserWithRolesFragmentDoc = gql`
    fragment OrgUserWithRoles on User {
  id
  name
  firstName
  lastName
  role
  profession
  avatar
  currentlyBlocked
  isGovernment
  isCtr
  pointOfContacts {
    id
    name
  }
  technologyPointOfContacts {
    id
    name
  }
  userOrgAccess {
    id
    role {
      id
      roleName
    }
    organizationId
  }
}
    `;
export const PendingApprovalUserFragmentDoc = gql`
    fragment PendingApprovalUser on User {
  id
  name
  firstName
  lastName
  role
  profession
  avatar
  organization {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const UsersFragmentDoc = gql`
    fragment Users on User {
  id
  name
  firstName
  lastName
  onboarded
  bio
  experience
  linkedin
  referralType
  govtOnlyDisplayEmail
  currentlyBlocked
  govtOnlyDisplayPhoneNumber
  referralComments
  role
  profession
  avatar
  isGovernment
  isCtr
  orgSymbol
  dutyTitle
  organization {
    ...OrganizationSummary
  }
  technologyLikes {
    ...UserTechnologyLikes
  }
  organizationLikes {
    ...UserOrganizationLikes
  }
}
    ${OrganizationSummaryFragmentDoc}
${UserTechnologyLikesFragmentDoc}
${UserOrganizationLikesFragmentDoc}`;
export const UserLookupFragmentDoc = gql`
    fragment UserLookup on User {
  id
  name
  firstName
  lastName
  avatar
  organization {
    ...OrganizationSummary
  }
}
    ${OrganizationSummaryFragmentDoc}`;
export const InvitedUserFragmentDoc = gql`
    fragment InvitedUser on Invite {
  id
  emailAddress
  inviteStatus
}
    `;
export const CreateCommunityAppDocument = gql`
    mutation createCommunityApp($input: AddCommunityAppInput!) {
  addCommunityApp(input: $input) {
    errorMessage
    result {
      ...CommunityApp
    }
  }
}
    ${CommunityAppFragmentDoc}`;
export type CreateCommunityAppMutationFn = Apollo.MutationFunction<CreateCommunityAppMutation, CreateCommunityAppMutationVariables>;

/**
 * __useCreateCommunityAppMutation__
 *
 * To run a mutation, you first call `useCreateCommunityAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityAppMutation, { data, loading, error }] = useCreateCommunityAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityAppMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityAppMutation, CreateCommunityAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityAppMutation, CreateCommunityAppMutationVariables>(CreateCommunityAppDocument, options);
      }
export type CreateCommunityAppMutationHookResult = ReturnType<typeof useCreateCommunityAppMutation>;
export type CreateCommunityAppMutationResult = Apollo.MutationResult<CreateCommunityAppMutation>;
export type CreateCommunityAppMutationOptions = Apollo.BaseMutationOptions<CreateCommunityAppMutation, CreateCommunityAppMutationVariables>;
export const UpdateCommunityAppDocument = gql`
    mutation updateCommunityApp($input: UpdateCommunityAppInput!) {
  updateCommunityApp(input: $input) {
    errorMessage
    result {
      ...CommunityApp
    }
  }
}
    ${CommunityAppFragmentDoc}`;
export type UpdateCommunityAppMutationFn = Apollo.MutationFunction<UpdateCommunityAppMutation, UpdateCommunityAppMutationVariables>;

/**
 * __useUpdateCommunityAppMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityAppMutation, { data, loading, error }] = useUpdateCommunityAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityAppMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityAppMutation, UpdateCommunityAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityAppMutation, UpdateCommunityAppMutationVariables>(UpdateCommunityAppDocument, options);
      }
export type UpdateCommunityAppMutationHookResult = ReturnType<typeof useUpdateCommunityAppMutation>;
export type UpdateCommunityAppMutationResult = Apollo.MutationResult<UpdateCommunityAppMutation>;
export type UpdateCommunityAppMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityAppMutation, UpdateCommunityAppMutationVariables>;
export const DeleteCommunityAppDocument = gql`
    mutation deleteCommunityApp($communityAppId: String!) {
  deleteCommunityApp(communityAppId: $communityAppId)
}
    `;
export type DeleteCommunityAppMutationFn = Apollo.MutationFunction<DeleteCommunityAppMutation, DeleteCommunityAppMutationVariables>;

/**
 * __useDeleteCommunityAppMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityAppMutation, { data, loading, error }] = useDeleteCommunityAppMutation({
 *   variables: {
 *      communityAppId: // value for 'communityAppId'
 *   },
 * });
 */
export function useDeleteCommunityAppMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityAppMutation, DeleteCommunityAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityAppMutation, DeleteCommunityAppMutationVariables>(DeleteCommunityAppDocument, options);
      }
export type DeleteCommunityAppMutationHookResult = ReturnType<typeof useDeleteCommunityAppMutation>;
export type DeleteCommunityAppMutationResult = Apollo.MutationResult<DeleteCommunityAppMutation>;
export type DeleteCommunityAppMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityAppMutation, DeleteCommunityAppMutationVariables>;
export const GetOnboardingSectionsDocument = gql`
    query getOnboardingSections {
  results: getOnboardingSections {
    totalCount
    items {
      ...OnboardingSection
    }
  }
}
    ${OnboardingSectionFragmentDoc}`;

/**
 * __useGetOnboardingSectionsQuery__
 *
 * To run a query within a React component, call `useGetOnboardingSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingSectionsQuery(baseOptions?: Apollo.QueryHookOptions<GetOnboardingSectionsQuery, GetOnboardingSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingSectionsQuery, GetOnboardingSectionsQueryVariables>(GetOnboardingSectionsDocument, options);
      }
export function useGetOnboardingSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingSectionsQuery, GetOnboardingSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingSectionsQuery, GetOnboardingSectionsQueryVariables>(GetOnboardingSectionsDocument, options);
        }
export type GetOnboardingSectionsQueryHookResult = ReturnType<typeof useGetOnboardingSectionsQuery>;
export type GetOnboardingSectionsLazyQueryHookResult = ReturnType<typeof useGetOnboardingSectionsLazyQuery>;
export type GetOnboardingSectionsQueryResult = Apollo.QueryResult<GetOnboardingSectionsQuery, GetOnboardingSectionsQueryVariables>;
export const UpdateOnboardingProgressDocument = gql`
    mutation updateOnboardingProgress($input: UpdateOnboardingProgressInput!) {
  updateOnboardingProgress(input: $input) {
    totalCount
    items {
      ...OnboardingSection
    }
  }
}
    ${OnboardingSectionFragmentDoc}`;
export type UpdateOnboardingProgressMutationFn = Apollo.MutationFunction<UpdateOnboardingProgressMutation, UpdateOnboardingProgressMutationVariables>;

/**
 * __useUpdateOnboardingProgressMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingProgressMutation, { data, loading, error }] = useUpdateOnboardingProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingProgressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingProgressMutation, UpdateOnboardingProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingProgressMutation, UpdateOnboardingProgressMutationVariables>(UpdateOnboardingProgressDocument, options);
      }
export type UpdateOnboardingProgressMutationHookResult = ReturnType<typeof useUpdateOnboardingProgressMutation>;
export type UpdateOnboardingProgressMutationResult = Apollo.MutationResult<UpdateOnboardingProgressMutation>;
export type UpdateOnboardingProgressMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingProgressMutation, UpdateOnboardingProgressMutationVariables>;
export const UpdateOnboardingUserAdminDetailsDocument = gql`
    mutation UpdateOnboardingUserAdminDetails($input: UpdateOnboardingAdminDetailsInput!) {
  updateOnboardingUserAdminDetails(input: $input) {
    success
  }
}
    `;
export type UpdateOnboardingUserAdminDetailsMutationFn = Apollo.MutationFunction<UpdateOnboardingUserAdminDetailsMutation, UpdateOnboardingUserAdminDetailsMutationVariables>;

/**
 * __useUpdateOnboardingUserAdminDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateOnboardingUserAdminDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnboardingUserAdminDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnboardingUserAdminDetailsMutation, { data, loading, error }] = useUpdateOnboardingUserAdminDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnboardingUserAdminDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingUserAdminDetailsMutation, UpdateOnboardingUserAdminDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingUserAdminDetailsMutation, UpdateOnboardingUserAdminDetailsMutationVariables>(UpdateOnboardingUserAdminDetailsDocument, options);
      }
export type UpdateOnboardingUserAdminDetailsMutationHookResult = ReturnType<typeof useUpdateOnboardingUserAdminDetailsMutation>;
export type UpdateOnboardingUserAdminDetailsMutationResult = Apollo.MutationResult<UpdateOnboardingUserAdminDetailsMutation>;
export type UpdateOnboardingUserAdminDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateOnboardingUserAdminDetailsMutation, UpdateOnboardingUserAdminDetailsMutationVariables>;
export const GetOnboardingProgressListDocument = gql`
    query getOnboardingProgressList {
  getOnboardingProgressList {
    totalCount
    items {
      ...UserOnboardingProgress
    }
  }
}
    ${UserOnboardingProgressFragmentDoc}`;

/**
 * __useGetOnboardingProgressListQuery__
 *
 * To run a query within a React component, call `useGetOnboardingProgressListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingProgressListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingProgressListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingProgressListQuery(baseOptions?: Apollo.QueryHookOptions<GetOnboardingProgressListQuery, GetOnboardingProgressListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingProgressListQuery, GetOnboardingProgressListQueryVariables>(GetOnboardingProgressListDocument, options);
      }
export function useGetOnboardingProgressListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingProgressListQuery, GetOnboardingProgressListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingProgressListQuery, GetOnboardingProgressListQueryVariables>(GetOnboardingProgressListDocument, options);
        }
export type GetOnboardingProgressListQueryHookResult = ReturnType<typeof useGetOnboardingProgressListQuery>;
export type GetOnboardingProgressListLazyQueryHookResult = ReturnType<typeof useGetOnboardingProgressListLazyQuery>;
export type GetOnboardingProgressListQueryResult = Apollo.QueryResult<GetOnboardingProgressListQuery, GetOnboardingProgressListQueryVariables>;
export const GetMyCommunitiesDocument = gql`
    query getMyCommunities($sort: SortInput, $pagination: PaginationInput) {
  getMyCommunities(sort: $sort, pagination: $pagination) {
    items {
      ...CommunitySummary
    }
    totalCount
  }
}
    ${CommunitySummaryFragmentDoc}`;

/**
 * __useGetMyCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetMyCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCommunitiesQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetMyCommunitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>(GetMyCommunitiesDocument, options);
      }
export function useGetMyCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>(GetMyCommunitiesDocument, options);
        }
export type GetMyCommunitiesQueryHookResult = ReturnType<typeof useGetMyCommunitiesQuery>;
export type GetMyCommunitiesLazyQueryHookResult = ReturnType<typeof useGetMyCommunitiesLazyQuery>;
export type GetMyCommunitiesQueryResult = Apollo.QueryResult<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>;
export const GetDiscussionsForCommunityDocument = gql`
    query getDiscussionsForCommunity($searchTerm: String!, $categoryFilter: [ID!]!, $pagination: PaginationInput, $sort: SortInput, $communityId: String!) {
  getDiscussionsForCommunity(searchTerm: $searchTerm, categoryFilter: {ids: $categoryFilter}, pagination: $pagination, sort: $sort, communityId: $communityId) {
    totalCount
    items {
      ...Discussion
    }
  }
}
    ${DiscussionFragmentDoc}`;

/**
 * __useGetDiscussionsForCommunityQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsForCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsForCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsForCommunityQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      categoryFilter: // value for 'categoryFilter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetDiscussionsForCommunityQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionsForCommunityQuery, GetDiscussionsForCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsForCommunityQuery, GetDiscussionsForCommunityQueryVariables>(GetDiscussionsForCommunityDocument, options);
      }
export function useGetDiscussionsForCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsForCommunityQuery, GetDiscussionsForCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsForCommunityQuery, GetDiscussionsForCommunityQueryVariables>(GetDiscussionsForCommunityDocument, options);
        }
export type GetDiscussionsForCommunityQueryHookResult = ReturnType<typeof useGetDiscussionsForCommunityQuery>;
export type GetDiscussionsForCommunityLazyQueryHookResult = ReturnType<typeof useGetDiscussionsForCommunityLazyQuery>;
export type GetDiscussionsForCommunityQueryResult = Apollo.QueryResult<GetDiscussionsForCommunityQuery, GetDiscussionsForCommunityQueryVariables>;
export const GetCommunityMembersDocument = gql`
    query getCommunityMembers($communityId: String!, $searchTerm: String!, $sort: SortInput, $pagination: PaginationInput) {
  getCommunityMembers(communityId: $communityId, searchTerm: $searchTerm, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...CommunityMembers
    }
  }
}
    ${CommunityMembersFragmentDoc}`;

/**
 * __useGetCommunityMembersQuery__
 *
 * To run a query within a React component, call `useGetCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityMembersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      searchTerm: // value for 'searchTerm'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCommunityMembersQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>(GetCommunityMembersDocument, options);
      }
export function useGetCommunityMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>(GetCommunityMembersDocument, options);
        }
export type GetCommunityMembersQueryHookResult = ReturnType<typeof useGetCommunityMembersQuery>;
export type GetCommunityMembersLazyQueryHookResult = ReturnType<typeof useGetCommunityMembersLazyQuery>;
export type GetCommunityMembersQueryResult = Apollo.QueryResult<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>;
export const SearchCommunitiesDocument = gql`
    query searchCommunities($searchTerm: String!, $sort: SortInput, $pagination: PaginationInput) {
  searchCommunities(searchTerm: $searchTerm, sort: $sort, pagination: $pagination) {
    items {
      ...CommunitySummary
    }
    totalCount
  }
}
    ${CommunitySummaryFragmentDoc}`;

/**
 * __useSearchCommunitiesQuery__
 *
 * To run a query within a React component, call `useSearchCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCommunitiesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchCommunitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchCommunitiesQuery, SearchCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCommunitiesQuery, SearchCommunitiesQueryVariables>(SearchCommunitiesDocument, options);
      }
export function useSearchCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCommunitiesQuery, SearchCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCommunitiesQuery, SearchCommunitiesQueryVariables>(SearchCommunitiesDocument, options);
        }
export type SearchCommunitiesQueryHookResult = ReturnType<typeof useSearchCommunitiesQuery>;
export type SearchCommunitiesLazyQueryHookResult = ReturnType<typeof useSearchCommunitiesLazyQuery>;
export type SearchCommunitiesQueryResult = Apollo.QueryResult<SearchCommunitiesQuery, SearchCommunitiesQueryVariables>;
export const GetCommunityBlobByIdDocument = gql`
    query getCommunityBlobById($fileId: String!) {
  getCommunityBlobById(fileId: $fileId) {
    ...CommunityBlob
  }
}
    ${CommunityBlobFragmentDoc}`;

/**
 * __useGetCommunityBlobByIdQuery__
 *
 * To run a query within a React component, call `useGetCommunityBlobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityBlobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityBlobByIdQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetCommunityBlobByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityBlobByIdQuery, GetCommunityBlobByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityBlobByIdQuery, GetCommunityBlobByIdQueryVariables>(GetCommunityBlobByIdDocument, options);
      }
export function useGetCommunityBlobByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityBlobByIdQuery, GetCommunityBlobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityBlobByIdQuery, GetCommunityBlobByIdQueryVariables>(GetCommunityBlobByIdDocument, options);
        }
export type GetCommunityBlobByIdQueryHookResult = ReturnType<typeof useGetCommunityBlobByIdQuery>;
export type GetCommunityBlobByIdLazyQueryHookResult = ReturnType<typeof useGetCommunityBlobByIdLazyQuery>;
export type GetCommunityBlobByIdQueryResult = Apollo.QueryResult<GetCommunityBlobByIdQuery, GetCommunityBlobByIdQueryVariables>;
export const GetCommunityByIdDocument = gql`
    query getCommunityById($communityId: String!) {
  getCommunityById(communityId: $communityId) {
    ...GetCommunityResponse
  }
}
    ${GetCommunityResponseFragmentDoc}`;

/**
 * __useGetCommunityByIdQuery__
 *
 * To run a query within a React component, call `useGetCommunityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityByIdQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityByIdQuery, GetCommunityByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityByIdQuery, GetCommunityByIdQueryVariables>(GetCommunityByIdDocument, options);
      }
export function useGetCommunityByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityByIdQuery, GetCommunityByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityByIdQuery, GetCommunityByIdQueryVariables>(GetCommunityByIdDocument, options);
        }
export type GetCommunityByIdQueryHookResult = ReturnType<typeof useGetCommunityByIdQuery>;
export type GetCommunityByIdLazyQueryHookResult = ReturnType<typeof useGetCommunityByIdLazyQuery>;
export type GetCommunityByIdQueryResult = Apollo.QueryResult<GetCommunityByIdQuery, GetCommunityByIdQueryVariables>;
export const CreateAnnouncementDocument = gql`
    mutation createAnnouncement($input: AddAnnouncementInput!) {
  createAnnouncement(input: $input) {
    result
    errorMessage
  }
}
    `;
export type CreateAnnouncementMutationFn = Apollo.MutationFunction<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>;

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnouncementMutation, { data, loading, error }] = useCreateAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>(CreateAnnouncementDocument, options);
      }
export type CreateAnnouncementMutationHookResult = ReturnType<typeof useCreateAnnouncementMutation>;
export type CreateAnnouncementMutationResult = Apollo.MutationResult<CreateAnnouncementMutation>;
export type CreateAnnouncementMutationOptions = Apollo.BaseMutationOptions<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>;
export const CreateCommunityHelpEmailDocument = gql`
    mutation createCommunityHelpEmail($input: CreateCommunityHelpEmailInput!) {
  createCommunityHelpEmail(input: $input) {
    result
    errorMessage
  }
}
    `;
export type CreateCommunityHelpEmailMutationFn = Apollo.MutationFunction<CreateCommunityHelpEmailMutation, CreateCommunityHelpEmailMutationVariables>;

/**
 * __useCreateCommunityHelpEmailMutation__
 *
 * To run a mutation, you first call `useCreateCommunityHelpEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityHelpEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityHelpEmailMutation, { data, loading, error }] = useCreateCommunityHelpEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityHelpEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityHelpEmailMutation, CreateCommunityHelpEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityHelpEmailMutation, CreateCommunityHelpEmailMutationVariables>(CreateCommunityHelpEmailDocument, options);
      }
export type CreateCommunityHelpEmailMutationHookResult = ReturnType<typeof useCreateCommunityHelpEmailMutation>;
export type CreateCommunityHelpEmailMutationResult = Apollo.MutationResult<CreateCommunityHelpEmailMutation>;
export type CreateCommunityHelpEmailMutationOptions = Apollo.BaseMutationOptions<CreateCommunityHelpEmailMutation, CreateCommunityHelpEmailMutationVariables>;
export const UpdateAnnouncementDocument = gql`
    mutation updateAnnouncement($input: UpdateAnnouncementInput!) {
  updateAnnouncement(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateAnnouncementMutationFn = Apollo.MutationFunction<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>;

/**
 * __useUpdateAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementMutation, { data, loading, error }] = useUpdateAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>(UpdateAnnouncementDocument, options);
      }
export type UpdateAnnouncementMutationHookResult = ReturnType<typeof useUpdateAnnouncementMutation>;
export type UpdateAnnouncementMutationResult = Apollo.MutationResult<UpdateAnnouncementMutation>;
export type UpdateAnnouncementMutationOptions = Apollo.BaseMutationOptions<UpdateAnnouncementMutation, UpdateAnnouncementMutationVariables>;
export const UpdateModuleDocument = gql`
    mutation updateModule($input: UpdateCommunityModuleInput!) {
  updateModule(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
      }
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<UpdateModuleMutation, UpdateModuleMutationVariables>;
export const UpdateModuleItemDocument = gql`
    mutation updateModuleItem($input: UpdateCommunityModuleItemInput!) {
  updateModuleItem(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateModuleItemMutationFn = Apollo.MutationFunction<UpdateModuleItemMutation, UpdateModuleItemMutationVariables>;

/**
 * __useUpdateModuleItemMutation__
 *
 * To run a mutation, you first call `useUpdateModuleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleItemMutation, { data, loading, error }] = useUpdateModuleItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleItemMutation, UpdateModuleItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleItemMutation, UpdateModuleItemMutationVariables>(UpdateModuleItemDocument, options);
      }
export type UpdateModuleItemMutationHookResult = ReturnType<typeof useUpdateModuleItemMutation>;
export type UpdateModuleItemMutationResult = Apollo.MutationResult<UpdateModuleItemMutation>;
export type UpdateModuleItemMutationOptions = Apollo.BaseMutationOptions<UpdateModuleItemMutation, UpdateModuleItemMutationVariables>;
export const GetAnnouncementsByCommunityDocument = gql`
    query getAnnouncementsByCommunity($communityId: String!, $pagination: PaginationInput) {
  getAnnouncementsByCommunity(communityId: $communityId, pagination: $pagination) {
    items {
      ...Announcement
    }
    totalCount
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useGetAnnouncementsByCommunityQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsByCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAnnouncementsByCommunityQuery(baseOptions: Apollo.QueryHookOptions<GetAnnouncementsByCommunityQuery, GetAnnouncementsByCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnouncementsByCommunityQuery, GetAnnouncementsByCommunityQueryVariables>(GetAnnouncementsByCommunityDocument, options);
      }
export function useGetAnnouncementsByCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsByCommunityQuery, GetAnnouncementsByCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnouncementsByCommunityQuery, GetAnnouncementsByCommunityQueryVariables>(GetAnnouncementsByCommunityDocument, options);
        }
export type GetAnnouncementsByCommunityQueryHookResult = ReturnType<typeof useGetAnnouncementsByCommunityQuery>;
export type GetAnnouncementsByCommunityLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsByCommunityLazyQuery>;
export type GetAnnouncementsByCommunityQueryResult = Apollo.QueryResult<GetAnnouncementsByCommunityQuery, GetAnnouncementsByCommunityQueryVariables>;
export const DeleteAnnouncementDocument = gql`
    mutation deleteAnnouncement($announcementId: String!) {
  deleteAnnouncement(announcementId: $announcementId) {
    result
    errorMessage
  }
}
    `;
export type DeleteAnnouncementMutationFn = Apollo.MutationFunction<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>;

/**
 * __useDeleteAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementMutation, { data, loading, error }] = useDeleteAnnouncementMutation({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function useDeleteAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>(DeleteAnnouncementDocument, options);
      }
export type DeleteAnnouncementMutationHookResult = ReturnType<typeof useDeleteAnnouncementMutation>;
export type DeleteAnnouncementMutationResult = Apollo.MutationResult<DeleteAnnouncementMutation>;
export type DeleteAnnouncementMutationOptions = Apollo.BaseMutationOptions<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>;
export const AddCommunitySectionDocument = gql`
    mutation addCommunitySection($input: CommunitySectionInput!, $communityId: String!) {
  addCommunitySection(input: $input, communityId: $communityId) {
    ...GetCommunityResponse
  }
}
    ${GetCommunityResponseFragmentDoc}`;
export type AddCommunitySectionMutationFn = Apollo.MutationFunction<AddCommunitySectionMutation, AddCommunitySectionMutationVariables>;

/**
 * __useAddCommunitySectionMutation__
 *
 * To run a mutation, you first call `useAddCommunitySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunitySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunitySectionMutation, { data, loading, error }] = useAddCommunitySectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useAddCommunitySectionMutation(baseOptions?: Apollo.MutationHookOptions<AddCommunitySectionMutation, AddCommunitySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommunitySectionMutation, AddCommunitySectionMutationVariables>(AddCommunitySectionDocument, options);
      }
export type AddCommunitySectionMutationHookResult = ReturnType<typeof useAddCommunitySectionMutation>;
export type AddCommunitySectionMutationResult = Apollo.MutationResult<AddCommunitySectionMutation>;
export type AddCommunitySectionMutationOptions = Apollo.BaseMutationOptions<AddCommunitySectionMutation, AddCommunitySectionMutationVariables>;
export const AddCommunitySocialResourceDocument = gql`
    mutation addCommunitySocialResource($input: CommunitySectionResourceInput!, $communityId: String!, $socialInput: CommunitySocialInput!) {
  addCommunityResource(input: $input, communityId: $communityId, socialInput: $socialInput) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type AddCommunitySocialResourceMutationFn = Apollo.MutationFunction<AddCommunitySocialResourceMutation, AddCommunitySocialResourceMutationVariables>;

/**
 * __useAddCommunitySocialResourceMutation__
 *
 * To run a mutation, you first call `useAddCommunitySocialResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunitySocialResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunitySocialResourceMutation, { data, loading, error }] = useAddCommunitySocialResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      communityId: // value for 'communityId'
 *      socialInput: // value for 'socialInput'
 *   },
 * });
 */
export function useAddCommunitySocialResourceMutation(baseOptions?: Apollo.MutationHookOptions<AddCommunitySocialResourceMutation, AddCommunitySocialResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommunitySocialResourceMutation, AddCommunitySocialResourceMutationVariables>(AddCommunitySocialResourceDocument, options);
      }
export type AddCommunitySocialResourceMutationHookResult = ReturnType<typeof useAddCommunitySocialResourceMutation>;
export type AddCommunitySocialResourceMutationResult = Apollo.MutationResult<AddCommunitySocialResourceMutation>;
export type AddCommunitySocialResourceMutationOptions = Apollo.BaseMutationOptions<AddCommunitySocialResourceMutation, AddCommunitySocialResourceMutationVariables>;
export const UpdateCommunitySocialResourceDocument = gql`
    mutation updateCommunitySocialResource($input: UpdateCommunitySectionResourceInput!, $socialInput: CommunitySocialInput!) {
  updateCommunitySectionResource(input: $input, socialInput: $socialInput) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type UpdateCommunitySocialResourceMutationFn = Apollo.MutationFunction<UpdateCommunitySocialResourceMutation, UpdateCommunitySocialResourceMutationVariables>;

/**
 * __useUpdateCommunitySocialResourceMutation__
 *
 * To run a mutation, you first call `useUpdateCommunitySocialResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunitySocialResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunitySocialResourceMutation, { data, loading, error }] = useUpdateCommunitySocialResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      socialInput: // value for 'socialInput'
 *   },
 * });
 */
export function useUpdateCommunitySocialResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunitySocialResourceMutation, UpdateCommunitySocialResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunitySocialResourceMutation, UpdateCommunitySocialResourceMutationVariables>(UpdateCommunitySocialResourceDocument, options);
      }
export type UpdateCommunitySocialResourceMutationHookResult = ReturnType<typeof useUpdateCommunitySocialResourceMutation>;
export type UpdateCommunitySocialResourceMutationResult = Apollo.MutationResult<UpdateCommunitySocialResourceMutation>;
export type UpdateCommunitySocialResourceMutationOptions = Apollo.BaseMutationOptions<UpdateCommunitySocialResourceMutation, UpdateCommunitySocialResourceMutationVariables>;
export const AddCommunityFileResourceDocument = gql`
    mutation addCommunityFileResource($input: CommunitySectionResourceInput!, $communityId: String!, $blobInput: CommunityBlobInput!) {
  addCommunityResource(input: $input, communityId: $communityId, blobInput: $blobInput) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type AddCommunityFileResourceMutationFn = Apollo.MutationFunction<AddCommunityFileResourceMutation, AddCommunityFileResourceMutationVariables>;

/**
 * __useAddCommunityFileResourceMutation__
 *
 * To run a mutation, you first call `useAddCommunityFileResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunityFileResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunityFileResourceMutation, { data, loading, error }] = useAddCommunityFileResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      communityId: // value for 'communityId'
 *      blobInput: // value for 'blobInput'
 *   },
 * });
 */
export function useAddCommunityFileResourceMutation(baseOptions?: Apollo.MutationHookOptions<AddCommunityFileResourceMutation, AddCommunityFileResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommunityFileResourceMutation, AddCommunityFileResourceMutationVariables>(AddCommunityFileResourceDocument, options);
      }
export type AddCommunityFileResourceMutationHookResult = ReturnType<typeof useAddCommunityFileResourceMutation>;
export type AddCommunityFileResourceMutationResult = Apollo.MutationResult<AddCommunityFileResourceMutation>;
export type AddCommunityFileResourceMutationOptions = Apollo.BaseMutationOptions<AddCommunityFileResourceMutation, AddCommunityFileResourceMutationVariables>;
export const UpdateCommunityFileSectionResourceDocument = gql`
    mutation updateCommunityFileSectionResource($input: UpdateCommunitySectionResourceInput!, $blobInput: CommunityBlobInput!) {
  updateCommunitySectionResource(input: $input, blobInput: $blobInput) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type UpdateCommunityFileSectionResourceMutationFn = Apollo.MutationFunction<UpdateCommunityFileSectionResourceMutation, UpdateCommunityFileSectionResourceMutationVariables>;

/**
 * __useUpdateCommunityFileSectionResourceMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityFileSectionResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityFileSectionResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityFileSectionResourceMutation, { data, loading, error }] = useUpdateCommunityFileSectionResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      blobInput: // value for 'blobInput'
 *   },
 * });
 */
export function useUpdateCommunityFileSectionResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityFileSectionResourceMutation, UpdateCommunityFileSectionResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityFileSectionResourceMutation, UpdateCommunityFileSectionResourceMutationVariables>(UpdateCommunityFileSectionResourceDocument, options);
      }
export type UpdateCommunityFileSectionResourceMutationHookResult = ReturnType<typeof useUpdateCommunityFileSectionResourceMutation>;
export type UpdateCommunityFileSectionResourceMutationResult = Apollo.MutationResult<UpdateCommunityFileSectionResourceMutation>;
export type UpdateCommunityFileSectionResourceMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityFileSectionResourceMutation, UpdateCommunityFileSectionResourceMutationVariables>;
export const AddCommunityHeaderResourceDocument = gql`
    mutation addCommunityHeaderResource($input: CommunitySectionResourceInput!, $communityId: String!) {
  addCommunityResource(input: $input, communityId: $communityId) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type AddCommunityHeaderResourceMutationFn = Apollo.MutationFunction<AddCommunityHeaderResourceMutation, AddCommunityHeaderResourceMutationVariables>;

/**
 * __useAddCommunityHeaderResourceMutation__
 *
 * To run a mutation, you first call `useAddCommunityHeaderResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunityHeaderResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunityHeaderResourceMutation, { data, loading, error }] = useAddCommunityHeaderResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useAddCommunityHeaderResourceMutation(baseOptions?: Apollo.MutationHookOptions<AddCommunityHeaderResourceMutation, AddCommunityHeaderResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommunityHeaderResourceMutation, AddCommunityHeaderResourceMutationVariables>(AddCommunityHeaderResourceDocument, options);
      }
export type AddCommunityHeaderResourceMutationHookResult = ReturnType<typeof useAddCommunityHeaderResourceMutation>;
export type AddCommunityHeaderResourceMutationResult = Apollo.MutationResult<AddCommunityHeaderResourceMutation>;
export type AddCommunityHeaderResourceMutationOptions = Apollo.BaseMutationOptions<AddCommunityHeaderResourceMutation, AddCommunityHeaderResourceMutationVariables>;
export const UpdateCommunityHeaderResourceDocument = gql`
    mutation updateCommunityHeaderResource($input: UpdateCommunitySectionResourceInput!) {
  updateCommunitySectionResource(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type UpdateCommunityHeaderResourceMutationFn = Apollo.MutationFunction<UpdateCommunityHeaderResourceMutation, UpdateCommunityHeaderResourceMutationVariables>;

/**
 * __useUpdateCommunityHeaderResourceMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityHeaderResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityHeaderResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityHeaderResourceMutation, { data, loading, error }] = useUpdateCommunityHeaderResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityHeaderResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityHeaderResourceMutation, UpdateCommunityHeaderResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityHeaderResourceMutation, UpdateCommunityHeaderResourceMutationVariables>(UpdateCommunityHeaderResourceDocument, options);
      }
export type UpdateCommunityHeaderResourceMutationHookResult = ReturnType<typeof useUpdateCommunityHeaderResourceMutation>;
export type UpdateCommunityHeaderResourceMutationResult = Apollo.MutationResult<UpdateCommunityHeaderResourceMutation>;
export type UpdateCommunityHeaderResourceMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityHeaderResourceMutation, UpdateCommunityHeaderResourceMutationVariables>;
export const AddCommunityLinkResourceDocument = gql`
    mutation addCommunityLinkResource($input: CommunitySectionResourceInput!, $communityId: String!) {
  addCommunityResource(input: $input, communityId: $communityId) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type AddCommunityLinkResourceMutationFn = Apollo.MutationFunction<AddCommunityLinkResourceMutation, AddCommunityLinkResourceMutationVariables>;

/**
 * __useAddCommunityLinkResourceMutation__
 *
 * To run a mutation, you first call `useAddCommunityLinkResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommunityLinkResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommunityLinkResourceMutation, { data, loading, error }] = useAddCommunityLinkResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useAddCommunityLinkResourceMutation(baseOptions?: Apollo.MutationHookOptions<AddCommunityLinkResourceMutation, AddCommunityLinkResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommunityLinkResourceMutation, AddCommunityLinkResourceMutationVariables>(AddCommunityLinkResourceDocument, options);
      }
export type AddCommunityLinkResourceMutationHookResult = ReturnType<typeof useAddCommunityLinkResourceMutation>;
export type AddCommunityLinkResourceMutationResult = Apollo.MutationResult<AddCommunityLinkResourceMutation>;
export type AddCommunityLinkResourceMutationOptions = Apollo.BaseMutationOptions<AddCommunityLinkResourceMutation, AddCommunityLinkResourceMutationVariables>;
export const UpdateCommunityLinkResourceDocument = gql`
    mutation updateCommunityLinkResource($input: UpdateCommunitySectionResourceInput!) {
  updateCommunitySectionResource(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type UpdateCommunityLinkResourceMutationFn = Apollo.MutationFunction<UpdateCommunityLinkResourceMutation, UpdateCommunityLinkResourceMutationVariables>;

/**
 * __useUpdateCommunityLinkResourceMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityLinkResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityLinkResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityLinkResourceMutation, { data, loading, error }] = useUpdateCommunityLinkResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityLinkResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityLinkResourceMutation, UpdateCommunityLinkResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityLinkResourceMutation, UpdateCommunityLinkResourceMutationVariables>(UpdateCommunityLinkResourceDocument, options);
      }
export type UpdateCommunityLinkResourceMutationHookResult = ReturnType<typeof useUpdateCommunityLinkResourceMutation>;
export type UpdateCommunityLinkResourceMutationResult = Apollo.MutationResult<UpdateCommunityLinkResourceMutation>;
export type UpdateCommunityLinkResourceMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityLinkResourceMutation, UpdateCommunityLinkResourceMutationVariables>;
export const UpdateCommunitySectionDocument = gql`
    mutation updateCommunitySection($input: UpdateCommunitySectionInput!) {
  updateCommunitySection(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type UpdateCommunitySectionMutationFn = Apollo.MutationFunction<UpdateCommunitySectionMutation, UpdateCommunitySectionMutationVariables>;

/**
 * __useUpdateCommunitySectionMutation__
 *
 * To run a mutation, you first call `useUpdateCommunitySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunitySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunitySectionMutation, { data, loading, error }] = useUpdateCommunitySectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunitySectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunitySectionMutation, UpdateCommunitySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunitySectionMutation, UpdateCommunitySectionMutationVariables>(UpdateCommunitySectionDocument, options);
      }
export type UpdateCommunitySectionMutationHookResult = ReturnType<typeof useUpdateCommunitySectionMutation>;
export type UpdateCommunitySectionMutationResult = Apollo.MutationResult<UpdateCommunitySectionMutation>;
export type UpdateCommunitySectionMutationOptions = Apollo.BaseMutationOptions<UpdateCommunitySectionMutation, UpdateCommunitySectionMutationVariables>;
export const ReorderCommunitySectionRankDocument = gql`
    mutation reorderCommunitySectionRank($input: CommunitySectionReorderInput!) {
  reorderCommunitySectionRank(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type ReorderCommunitySectionRankMutationFn = Apollo.MutationFunction<ReorderCommunitySectionRankMutation, ReorderCommunitySectionRankMutationVariables>;

/**
 * __useReorderCommunitySectionRankMutation__
 *
 * To run a mutation, you first call `useReorderCommunitySectionRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderCommunitySectionRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderCommunitySectionRankMutation, { data, loading, error }] = useReorderCommunitySectionRankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderCommunitySectionRankMutation(baseOptions?: Apollo.MutationHookOptions<ReorderCommunitySectionRankMutation, ReorderCommunitySectionRankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderCommunitySectionRankMutation, ReorderCommunitySectionRankMutationVariables>(ReorderCommunitySectionRankDocument, options);
      }
export type ReorderCommunitySectionRankMutationHookResult = ReturnType<typeof useReorderCommunitySectionRankMutation>;
export type ReorderCommunitySectionRankMutationResult = Apollo.MutationResult<ReorderCommunitySectionRankMutation>;
export type ReorderCommunitySectionRankMutationOptions = Apollo.BaseMutationOptions<ReorderCommunitySectionRankMutation, ReorderCommunitySectionRankMutationVariables>;
export const ReorderCommunityContentModulesDocument = gql`
    mutation reorderCommunityContentModules($input: CommunityContentModuleReorderInput!) {
  reorderCommunityContentModules(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type ReorderCommunityContentModulesMutationFn = Apollo.MutationFunction<ReorderCommunityContentModulesMutation, ReorderCommunityContentModulesMutationVariables>;

/**
 * __useReorderCommunityContentModulesMutation__
 *
 * To run a mutation, you first call `useReorderCommunityContentModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderCommunityContentModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderCommunityContentModulesMutation, { data, loading, error }] = useReorderCommunityContentModulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderCommunityContentModulesMutation(baseOptions?: Apollo.MutationHookOptions<ReorderCommunityContentModulesMutation, ReorderCommunityContentModulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderCommunityContentModulesMutation, ReorderCommunityContentModulesMutationVariables>(ReorderCommunityContentModulesDocument, options);
      }
export type ReorderCommunityContentModulesMutationHookResult = ReturnType<typeof useReorderCommunityContentModulesMutation>;
export type ReorderCommunityContentModulesMutationResult = Apollo.MutationResult<ReorderCommunityContentModulesMutation>;
export type ReorderCommunityContentModulesMutationOptions = Apollo.BaseMutationOptions<ReorderCommunityContentModulesMutation, ReorderCommunityContentModulesMutationVariables>;
export const ReorderCommunityModuleItemsDocument = gql`
    mutation reorderCommunityModuleItems($input: EntityListReorderInput!) {
  reorderCommunityModuleItems(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type ReorderCommunityModuleItemsMutationFn = Apollo.MutationFunction<ReorderCommunityModuleItemsMutation, ReorderCommunityModuleItemsMutationVariables>;

/**
 * __useReorderCommunityModuleItemsMutation__
 *
 * To run a mutation, you first call `useReorderCommunityModuleItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderCommunityModuleItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderCommunityModuleItemsMutation, { data, loading, error }] = useReorderCommunityModuleItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderCommunityModuleItemsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderCommunityModuleItemsMutation, ReorderCommunityModuleItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderCommunityModuleItemsMutation, ReorderCommunityModuleItemsMutationVariables>(ReorderCommunityModuleItemsDocument, options);
      }
export type ReorderCommunityModuleItemsMutationHookResult = ReturnType<typeof useReorderCommunityModuleItemsMutation>;
export type ReorderCommunityModuleItemsMutationResult = Apollo.MutationResult<ReorderCommunityModuleItemsMutation>;
export type ReorderCommunityModuleItemsMutationOptions = Apollo.BaseMutationOptions<ReorderCommunityModuleItemsMutation, ReorderCommunityModuleItemsMutationVariables>;
export const ReorderCommunitySectionResourceRankDocument = gql`
    mutation reorderCommunitySectionResourceRank($input: CommunityResourceReorderInput!) {
  reorderCommunitySectionResourceRank(input: $input) {
    ...BooleanResponse
  }
}
    ${BooleanResponseFragmentDoc}`;
export type ReorderCommunitySectionResourceRankMutationFn = Apollo.MutationFunction<ReorderCommunitySectionResourceRankMutation, ReorderCommunitySectionResourceRankMutationVariables>;

/**
 * __useReorderCommunitySectionResourceRankMutation__
 *
 * To run a mutation, you first call `useReorderCommunitySectionResourceRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderCommunitySectionResourceRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderCommunitySectionResourceRankMutation, { data, loading, error }] = useReorderCommunitySectionResourceRankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderCommunitySectionResourceRankMutation(baseOptions?: Apollo.MutationHookOptions<ReorderCommunitySectionResourceRankMutation, ReorderCommunitySectionResourceRankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderCommunitySectionResourceRankMutation, ReorderCommunitySectionResourceRankMutationVariables>(ReorderCommunitySectionResourceRankDocument, options);
      }
export type ReorderCommunitySectionResourceRankMutationHookResult = ReturnType<typeof useReorderCommunitySectionResourceRankMutation>;
export type ReorderCommunitySectionResourceRankMutationResult = Apollo.MutationResult<ReorderCommunitySectionResourceRankMutation>;
export type ReorderCommunitySectionResourceRankMutationOptions = Apollo.BaseMutationOptions<ReorderCommunitySectionResourceRankMutation, ReorderCommunitySectionResourceRankMutationVariables>;
export const DeleteCommunitySectionDocument = gql`
    mutation deleteCommunitySection($sectionId: String!) {
  deleteCommunitySection(sectionId: $sectionId) {
    result
    errorMessage
  }
}
    `;
export type DeleteCommunitySectionMutationFn = Apollo.MutationFunction<DeleteCommunitySectionMutation, DeleteCommunitySectionMutationVariables>;

/**
 * __useDeleteCommunitySectionMutation__
 *
 * To run a mutation, you first call `useDeleteCommunitySectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunitySectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunitySectionMutation, { data, loading, error }] = useDeleteCommunitySectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useDeleteCommunitySectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunitySectionMutation, DeleteCommunitySectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunitySectionMutation, DeleteCommunitySectionMutationVariables>(DeleteCommunitySectionDocument, options);
      }
export type DeleteCommunitySectionMutationHookResult = ReturnType<typeof useDeleteCommunitySectionMutation>;
export type DeleteCommunitySectionMutationResult = Apollo.MutationResult<DeleteCommunitySectionMutation>;
export type DeleteCommunitySectionMutationOptions = Apollo.BaseMutationOptions<DeleteCommunitySectionMutation, DeleteCommunitySectionMutationVariables>;
export const DeleteModuleDocument = gql`
    mutation deleteModule($moduleId: String!) {
  deleteModule(moduleId: $moduleId) {
    result
    errorMessage
  }
}
    `;
export type DeleteModuleMutationFn = Apollo.MutationFunction<DeleteModuleMutation, DeleteModuleMutationVariables>;

/**
 * __useDeleteModuleMutation__
 *
 * To run a mutation, you first call `useDeleteModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleMutation, { data, loading, error }] = useDeleteModuleMutation({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useDeleteModuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModuleMutation, DeleteModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModuleMutation, DeleteModuleMutationVariables>(DeleteModuleDocument, options);
      }
export type DeleteModuleMutationHookResult = ReturnType<typeof useDeleteModuleMutation>;
export type DeleteModuleMutationResult = Apollo.MutationResult<DeleteModuleMutation>;
export type DeleteModuleMutationOptions = Apollo.BaseMutationOptions<DeleteModuleMutation, DeleteModuleMutationVariables>;
export const DeleteModuleItemDocument = gql`
    mutation deleteModuleItem($itemId: String!) {
  deleteModuleItem(itemId: $itemId) {
    result
    errorMessage
  }
}
    `;
export type DeleteModuleItemMutationFn = Apollo.MutationFunction<DeleteModuleItemMutation, DeleteModuleItemMutationVariables>;

/**
 * __useDeleteModuleItemMutation__
 *
 * To run a mutation, you first call `useDeleteModuleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleItemMutation, { data, loading, error }] = useDeleteModuleItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useDeleteModuleItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModuleItemMutation, DeleteModuleItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModuleItemMutation, DeleteModuleItemMutationVariables>(DeleteModuleItemDocument, options);
      }
export type DeleteModuleItemMutationHookResult = ReturnType<typeof useDeleteModuleItemMutation>;
export type DeleteModuleItemMutationResult = Apollo.MutationResult<DeleteModuleItemMutation>;
export type DeleteModuleItemMutationOptions = Apollo.BaseMutationOptions<DeleteModuleItemMutation, DeleteModuleItemMutationVariables>;
export const DeleteCommunityResourceDocument = gql`
    mutation deleteCommunityResource($resourceId: String!) {
  deleteCommunityResource(resourceId: $resourceId) {
    result
    errorMessage
  }
}
    `;
export type DeleteCommunityResourceMutationFn = Apollo.MutationFunction<DeleteCommunityResourceMutation, DeleteCommunityResourceMutationVariables>;

/**
 * __useDeleteCommunityResourceMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityResourceMutation, { data, loading, error }] = useDeleteCommunityResourceMutation({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useDeleteCommunityResourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityResourceMutation, DeleteCommunityResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityResourceMutation, DeleteCommunityResourceMutationVariables>(DeleteCommunityResourceDocument, options);
      }
export type DeleteCommunityResourceMutationHookResult = ReturnType<typeof useDeleteCommunityResourceMutation>;
export type DeleteCommunityResourceMutationResult = Apollo.MutationResult<DeleteCommunityResourceMutation>;
export type DeleteCommunityResourceMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityResourceMutation, DeleteCommunityResourceMutationVariables>;
export const UpdateCommunityInformationDocument = gql`
    mutation updateCommunityInformation($input: UpdateCommunityInformationInput!) {
  updateCommunityInformation(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateCommunityInformationMutationFn = Apollo.MutationFunction<UpdateCommunityInformationMutation, UpdateCommunityInformationMutationVariables>;

/**
 * __useUpdateCommunityInformationMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityInformationMutation, { data, loading, error }] = useUpdateCommunityInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityInformationMutation, UpdateCommunityInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityInformationMutation, UpdateCommunityInformationMutationVariables>(UpdateCommunityInformationDocument, options);
      }
export type UpdateCommunityInformationMutationHookResult = ReturnType<typeof useUpdateCommunityInformationMutation>;
export type UpdateCommunityInformationMutationResult = Apollo.MutationResult<UpdateCommunityInformationMutation>;
export type UpdateCommunityInformationMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityInformationMutation, UpdateCommunityInformationMutationVariables>;
export const UpdateCommunityAdministrationDocument = gql`
    mutation updateCommunityAdministration($input: UpdateCommunityAdministrationInput!) {
  updateCommunityAdministration(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateCommunityAdministrationMutationFn = Apollo.MutationFunction<UpdateCommunityAdministrationMutation, UpdateCommunityAdministrationMutationVariables>;

/**
 * __useUpdateCommunityAdministrationMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityAdministrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityAdministrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityAdministrationMutation, { data, loading, error }] = useUpdateCommunityAdministrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityAdministrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityAdministrationMutation, UpdateCommunityAdministrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityAdministrationMutation, UpdateCommunityAdministrationMutationVariables>(UpdateCommunityAdministrationDocument, options);
      }
export type UpdateCommunityAdministrationMutationHookResult = ReturnType<typeof useUpdateCommunityAdministrationMutation>;
export type UpdateCommunityAdministrationMutationResult = Apollo.MutationResult<UpdateCommunityAdministrationMutation>;
export type UpdateCommunityAdministrationMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityAdministrationMutation, UpdateCommunityAdministrationMutationVariables>;
export const UpdateCommunityBannerDocument = gql`
    mutation updateCommunityBanner($input: UpdateBannerInput!) {
  updateCommunityBanner(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateCommunityBannerMutationFn = Apollo.MutationFunction<UpdateCommunityBannerMutation, UpdateCommunityBannerMutationVariables>;

/**
 * __useUpdateCommunityBannerMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityBannerMutation, { data, loading, error }] = useUpdateCommunityBannerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityBannerMutation, UpdateCommunityBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityBannerMutation, UpdateCommunityBannerMutationVariables>(UpdateCommunityBannerDocument, options);
      }
export type UpdateCommunityBannerMutationHookResult = ReturnType<typeof useUpdateCommunityBannerMutation>;
export type UpdateCommunityBannerMutationResult = Apollo.MutationResult<UpdateCommunityBannerMutation>;
export type UpdateCommunityBannerMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityBannerMutation, UpdateCommunityBannerMutationVariables>;
export const UpdateCommunityAvatarDocument = gql`
    mutation updateCommunityAvatar($input: UpdateAvatarInput!) {
  updateCommunityAvatar(input: $input) {
    result
    errorMessage
  }
}
    `;
export type UpdateCommunityAvatarMutationFn = Apollo.MutationFunction<UpdateCommunityAvatarMutation, UpdateCommunityAvatarMutationVariables>;

/**
 * __useUpdateCommunityAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityAvatarMutation, { data, loading, error }] = useUpdateCommunityAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommunityAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityAvatarMutation, UpdateCommunityAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityAvatarMutation, UpdateCommunityAvatarMutationVariables>(UpdateCommunityAvatarDocument, options);
      }
export type UpdateCommunityAvatarMutationHookResult = ReturnType<typeof useUpdateCommunityAvatarMutation>;
export type UpdateCommunityAvatarMutationResult = Apollo.MutationResult<UpdateCommunityAvatarMutation>;
export type UpdateCommunityAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityAvatarMutation, UpdateCommunityAvatarMutationVariables>;
export const CreateCommunityDocument = gql`
    mutation createCommunity($input: CreateCommunityInput!) {
  createCommunity(input: $input) {
    result {
      ...Community
    }
    errorMessage
  }
}
    ${CommunityFragmentDoc}`;
export type CreateCommunityMutationFn = Apollo.MutationFunction<CreateCommunityMutation, CreateCommunityMutationVariables>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommunityMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityMutation, CreateCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(CreateCommunityDocument, options);
      }
export type CreateCommunityMutationHookResult = ReturnType<typeof useCreateCommunityMutation>;
export type CreateCommunityMutationResult = Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<CreateCommunityMutation, CreateCommunityMutationVariables>;
export const GetHomeHelpMessageDocument = gql`
    query getHomeHelpMessage {
  getHomeHelpMessage {
    result {
      ...HomeHelpMessage
    }
    errorMessage
  }
}
    ${HomeHelpMessageFragmentDoc}`;

/**
 * __useGetHomeHelpMessageQuery__
 *
 * To run a query within a React component, call `useGetHomeHelpMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeHelpMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeHelpMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeHelpMessageQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeHelpMessageQuery, GetHomeHelpMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeHelpMessageQuery, GetHomeHelpMessageQueryVariables>(GetHomeHelpMessageDocument, options);
      }
export function useGetHomeHelpMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeHelpMessageQuery, GetHomeHelpMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeHelpMessageQuery, GetHomeHelpMessageQueryVariables>(GetHomeHelpMessageDocument, options);
        }
export type GetHomeHelpMessageQueryHookResult = ReturnType<typeof useGetHomeHelpMessageQuery>;
export type GetHomeHelpMessageLazyQueryHookResult = ReturnType<typeof useGetHomeHelpMessageLazyQuery>;
export type GetHomeHelpMessageQueryResult = Apollo.QueryResult<GetHomeHelpMessageQuery, GetHomeHelpMessageQueryVariables>;
export const CreateModuleDocument = gql`
    mutation createModule($input: AddCommunityModuleInput!) {
  createModule(input: $input) {
    result
    errorMessage
  }
}
    `;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
      }
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<CreateModuleMutation, CreateModuleMutationVariables>;
export const CreateModuleItemDocument = gql`
    mutation createModuleItem($input: AddCommunityModuleItemInput!) {
  createModuleItem(input: $input) {
    result
    errorMessage
  }
}
    `;
export type CreateModuleItemMutationFn = Apollo.MutationFunction<CreateModuleItemMutation, CreateModuleItemMutationVariables>;

/**
 * __useCreateModuleItemMutation__
 *
 * To run a mutation, you first call `useCreateModuleItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleItemMutation, { data, loading, error }] = useCreateModuleItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateModuleItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateModuleItemMutation, CreateModuleItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateModuleItemMutation, CreateModuleItemMutationVariables>(CreateModuleItemDocument, options);
      }
export type CreateModuleItemMutationHookResult = ReturnType<typeof useCreateModuleItemMutation>;
export type CreateModuleItemMutationResult = Apollo.MutationResult<CreateModuleItemMutation>;
export type CreateModuleItemMutationOptions = Apollo.BaseMutationOptions<CreateModuleItemMutation, CreateModuleItemMutationVariables>;
export const GetCommunityModulesDocument = gql`
    query getCommunityModules($communityId: String!, $pagination: PaginationInput) {
  getCommunityModules(communityId: $communityId, pagination: $pagination) {
    items {
      ...CommunityContentModule
    }
    errorMessage
    totalCount
  }
}
    ${CommunityContentModuleFragmentDoc}`;

/**
 * __useGetCommunityModulesQuery__
 *
 * To run a query within a React component, call `useGetCommunityModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityModulesQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCommunityModulesQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityModulesQuery, GetCommunityModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityModulesQuery, GetCommunityModulesQueryVariables>(GetCommunityModulesDocument, options);
      }
export function useGetCommunityModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityModulesQuery, GetCommunityModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityModulesQuery, GetCommunityModulesQueryVariables>(GetCommunityModulesDocument, options);
        }
export type GetCommunityModulesQueryHookResult = ReturnType<typeof useGetCommunityModulesQuery>;
export type GetCommunityModulesLazyQueryHookResult = ReturnType<typeof useGetCommunityModulesLazyQuery>;
export type GetCommunityModulesQueryResult = Apollo.QueryResult<GetCommunityModulesQuery, GetCommunityModulesQueryVariables>;
export const SearchCommunityModuleItemsDocument = gql`
    query searchCommunityModuleItems($moduleId: String!, $searchTerm: String!, $pagination: PaginationInput) {
  searchCommunityModuleItems(moduleId: $moduleId, searchTerm: $searchTerm, pagination: $pagination) {
    items {
      ...CommunityModuleItem
    }
    errorMessage
    totalCount
  }
}
    ${CommunityModuleItemFragmentDoc}`;

/**
 * __useSearchCommunityModuleItemsQuery__
 *
 * To run a query within a React component, call `useSearchCommunityModuleItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCommunityModuleItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCommunityModuleItemsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      searchTerm: // value for 'searchTerm'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchCommunityModuleItemsQuery(baseOptions: Apollo.QueryHookOptions<SearchCommunityModuleItemsQuery, SearchCommunityModuleItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCommunityModuleItemsQuery, SearchCommunityModuleItemsQueryVariables>(SearchCommunityModuleItemsDocument, options);
      }
export function useSearchCommunityModuleItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCommunityModuleItemsQuery, SearchCommunityModuleItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCommunityModuleItemsQuery, SearchCommunityModuleItemsQueryVariables>(SearchCommunityModuleItemsDocument, options);
        }
export type SearchCommunityModuleItemsQueryHookResult = ReturnType<typeof useSearchCommunityModuleItemsQuery>;
export type SearchCommunityModuleItemsLazyQueryHookResult = ReturnType<typeof useSearchCommunityModuleItemsLazyQuery>;
export type SearchCommunityModuleItemsQueryResult = Apollo.QueryResult<SearchCommunityModuleItemsQuery, SearchCommunityModuleItemsQueryVariables>;
export const GetCommunityAppsDocument = gql`
    query getCommunityApps($pagination: PaginationInput) {
  getCommunityApps(pagination: $pagination) {
    items {
      ...CommunityApp
    }
    totalCount
  }
}
    ${CommunityAppFragmentDoc}`;

/**
 * __useGetCommunityAppsQuery__
 *
 * To run a query within a React component, call `useGetCommunityAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityAppsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetCommunityAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetCommunityAppsQuery, GetCommunityAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityAppsQuery, GetCommunityAppsQueryVariables>(GetCommunityAppsDocument, options);
      }
export function useGetCommunityAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityAppsQuery, GetCommunityAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityAppsQuery, GetCommunityAppsQueryVariables>(GetCommunityAppsDocument, options);
        }
export type GetCommunityAppsQueryHookResult = ReturnType<typeof useGetCommunityAppsQuery>;
export type GetCommunityAppsLazyQueryHookResult = ReturnType<typeof useGetCommunityAppsLazyQuery>;
export type GetCommunityAppsQueryResult = Apollo.QueryResult<GetCommunityAppsQuery, GetCommunityAppsQueryVariables>;
export const DeleteCommunityDocument = gql`
    mutation deleteCommunity($communityId: String!) {
  deleteCommunity(communityId: $communityId) {
    result
    errorMessage
  }
}
    `;
export type DeleteCommunityMutationFn = Apollo.MutationFunction<DeleteCommunityMutation, DeleteCommunityMutationVariables>;

/**
 * __useDeleteCommunityMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMutation, { data, loading, error }] = useDeleteCommunityMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useDeleteCommunityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityMutation, DeleteCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityMutation, DeleteCommunityMutationVariables>(DeleteCommunityDocument, options);
      }
export type DeleteCommunityMutationHookResult = ReturnType<typeof useDeleteCommunityMutation>;
export type DeleteCommunityMutationResult = Apollo.MutationResult<DeleteCommunityMutation>;
export type DeleteCommunityMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityMutation, DeleteCommunityMutationVariables>;
export const SearchAnnouncementsByCommunityDocument = gql`
    query searchAnnouncementsByCommunity($communityId: String!, $searchTerm: String, $categoryFilter: IdFilterInput, $sort: SortInput, $pagination: PaginationInput) {
  searchAnnouncementsByCommunity(communityId: $communityId, searchTerm: $searchTerm, categoryFilter: $categoryFilter, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...Announcement
    }
  }
}
    ${AnnouncementFragmentDoc}`;

/**
 * __useSearchAnnouncementsByCommunityQuery__
 *
 * To run a query within a React component, call `useSearchAnnouncementsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAnnouncementsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAnnouncementsByCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      searchTerm: // value for 'searchTerm'
 *      categoryFilter: // value for 'categoryFilter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchAnnouncementsByCommunityQuery(baseOptions: Apollo.QueryHookOptions<SearchAnnouncementsByCommunityQuery, SearchAnnouncementsByCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAnnouncementsByCommunityQuery, SearchAnnouncementsByCommunityQueryVariables>(SearchAnnouncementsByCommunityDocument, options);
      }
export function useSearchAnnouncementsByCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAnnouncementsByCommunityQuery, SearchAnnouncementsByCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAnnouncementsByCommunityQuery, SearchAnnouncementsByCommunityQueryVariables>(SearchAnnouncementsByCommunityDocument, options);
        }
export type SearchAnnouncementsByCommunityQueryHookResult = ReturnType<typeof useSearchAnnouncementsByCommunityQuery>;
export type SearchAnnouncementsByCommunityLazyQueryHookResult = ReturnType<typeof useSearchAnnouncementsByCommunityLazyQuery>;
export type SearchAnnouncementsByCommunityQueryResult = Apollo.QueryResult<SearchAnnouncementsByCommunityQuery, SearchAnnouncementsByCommunityQueryVariables>;
export const ParseCommunityAppUrlDocument = gql`
    query parseCommunityAppURL($url: String!) {
  parseCommunityAppURL(url: $url) {
    result {
      meta {
        title
        description
        image
      }
      og {
        title
        description
        image
      }
      images {
        src
      }
    }
    errorMessage
  }
}
    `;

/**
 * __useParseCommunityAppUrlQuery__
 *
 * To run a query within a React component, call `useParseCommunityAppUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useParseCommunityAppUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParseCommunityAppUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useParseCommunityAppUrlQuery(baseOptions: Apollo.QueryHookOptions<ParseCommunityAppUrlQuery, ParseCommunityAppUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParseCommunityAppUrlQuery, ParseCommunityAppUrlQueryVariables>(ParseCommunityAppUrlDocument, options);
      }
export function useParseCommunityAppUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParseCommunityAppUrlQuery, ParseCommunityAppUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParseCommunityAppUrlQuery, ParseCommunityAppUrlQueryVariables>(ParseCommunityAppUrlDocument, options);
        }
export type ParseCommunityAppUrlQueryHookResult = ReturnType<typeof useParseCommunityAppUrlQuery>;
export type ParseCommunityAppUrlLazyQueryHookResult = ReturnType<typeof useParseCommunityAppUrlLazyQuery>;
export type ParseCommunityAppUrlQueryResult = Apollo.QueryResult<ParseCommunityAppUrlQuery, ParseCommunityAppUrlQueryVariables>;
export const GetCategoriesByCommunityDocument = gql`
    query getCategoriesByCommunity($communityId: String!) {
  getCategoriesByCommunity(communityId: $communityId) {
    items {
      ...Category
    }
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetCategoriesByCommunityQuery__
 *
 * To run a query within a React component, call `useGetCategoriesByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesByCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCategoriesByCommunityQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesByCommunityQuery, GetCategoriesByCommunityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesByCommunityQuery, GetCategoriesByCommunityQueryVariables>(GetCategoriesByCommunityDocument, options);
      }
export function useGetCategoriesByCommunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesByCommunityQuery, GetCategoriesByCommunityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesByCommunityQuery, GetCategoriesByCommunityQueryVariables>(GetCategoriesByCommunityDocument, options);
        }
export type GetCategoriesByCommunityQueryHookResult = ReturnType<typeof useGetCategoriesByCommunityQuery>;
export type GetCategoriesByCommunityLazyQueryHookResult = ReturnType<typeof useGetCategoriesByCommunityLazyQuery>;
export type GetCategoriesByCommunityQueryResult = Apollo.QueryResult<GetCategoriesByCommunityQuery, GetCategoriesByCommunityQueryVariables>;
export const PinAnnouncementDocument = gql`
    mutation pinAnnouncement($announcementId: String!) {
  pinAnnouncement(announcementId: $announcementId) {
    result
    errorMessage
  }
}
    `;
export type PinAnnouncementMutationFn = Apollo.MutationFunction<PinAnnouncementMutation, PinAnnouncementMutationVariables>;

/**
 * __usePinAnnouncementMutation__
 *
 * To run a mutation, you first call `usePinAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinAnnouncementMutation, { data, loading, error }] = usePinAnnouncementMutation({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function usePinAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<PinAnnouncementMutation, PinAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinAnnouncementMutation, PinAnnouncementMutationVariables>(PinAnnouncementDocument, options);
      }
export type PinAnnouncementMutationHookResult = ReturnType<typeof usePinAnnouncementMutation>;
export type PinAnnouncementMutationResult = Apollo.MutationResult<PinAnnouncementMutation>;
export type PinAnnouncementMutationOptions = Apollo.BaseMutationOptions<PinAnnouncementMutation, PinAnnouncementMutationVariables>;
export const UnpinAnnouncementDocument = gql`
    mutation unpinAnnouncement($announcementId: String!) {
  unpinAnnouncement(announcementId: $announcementId) {
    result
    errorMessage
  }
}
    `;
export type UnpinAnnouncementMutationFn = Apollo.MutationFunction<UnpinAnnouncementMutation, UnpinAnnouncementMutationVariables>;

/**
 * __useUnpinAnnouncementMutation__
 *
 * To run a mutation, you first call `useUnpinAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinAnnouncementMutation, { data, loading, error }] = useUnpinAnnouncementMutation({
 *   variables: {
 *      announcementId: // value for 'announcementId'
 *   },
 * });
 */
export function useUnpinAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<UnpinAnnouncementMutation, UnpinAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinAnnouncementMutation, UnpinAnnouncementMutationVariables>(UnpinAnnouncementDocument, options);
      }
export type UnpinAnnouncementMutationHookResult = ReturnType<typeof useUnpinAnnouncementMutation>;
export type UnpinAnnouncementMutationResult = Apollo.MutationResult<UnpinAnnouncementMutation>;
export type UnpinAnnouncementMutationOptions = Apollo.BaseMutationOptions<UnpinAnnouncementMutation, UnpinAnnouncementMutationVariables>;
export const GetUnReadMessageCountDocument = gql`
    query getUnReadMessageCount {
  getUnReadMessageCount
}
    `;

/**
 * __useGetUnReadMessageCountQuery__
 *
 * To run a query within a React component, call `useGetUnReadMessageCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnReadMessageCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnReadMessageCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnReadMessageCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnReadMessageCountQuery, GetUnReadMessageCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnReadMessageCountQuery, GetUnReadMessageCountQueryVariables>(GetUnReadMessageCountDocument, options);
      }
export function useGetUnReadMessageCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnReadMessageCountQuery, GetUnReadMessageCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnReadMessageCountQuery, GetUnReadMessageCountQueryVariables>(GetUnReadMessageCountDocument, options);
        }
export type GetUnReadMessageCountQueryHookResult = ReturnType<typeof useGetUnReadMessageCountQuery>;
export type GetUnReadMessageCountLazyQueryHookResult = ReturnType<typeof useGetUnReadMessageCountLazyQuery>;
export type GetUnReadMessageCountQueryResult = Apollo.QueryResult<GetUnReadMessageCountQuery, GetUnReadMessageCountQueryVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($data: CreateMessageInput!) {
  createMessage(data: $data) {
    id
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const GetUsersConversationsDocument = gql`
    query getUsersConversations($conversationId: String, $conversationFilter: String!, $pagination: PaginationInput) {
  getUsersConversations(conversationId: $conversationId, conversationFilter: $conversationFilter, pagination: $pagination) {
    totalCount
    activeCount
    archivedCount
    mutedCount
    items {
      ...Conversation
    }
  }
}
    ${ConversationFragmentDoc}`;

/**
 * __useGetUsersConversationsQuery__
 *
 * To run a query within a React component, call `useGetUsersConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersConversationsQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      conversationFilter: // value for 'conversationFilter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetUsersConversationsQuery(baseOptions: Apollo.QueryHookOptions<GetUsersConversationsQuery, GetUsersConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersConversationsQuery, GetUsersConversationsQueryVariables>(GetUsersConversationsDocument, options);
      }
export function useGetUsersConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersConversationsQuery, GetUsersConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersConversationsQuery, GetUsersConversationsQueryVariables>(GetUsersConversationsDocument, options);
        }
export type GetUsersConversationsQueryHookResult = ReturnType<typeof useGetUsersConversationsQuery>;
export type GetUsersConversationsLazyQueryHookResult = ReturnType<typeof useGetUsersConversationsLazyQuery>;
export type GetUsersConversationsQueryResult = Apollo.QueryResult<GetUsersConversationsQuery, GetUsersConversationsQueryVariables>;
export const GetConversationDocument = gql`
    query getConversation($conversationId: String!) {
  getConversation(conversationId: $conversationId) {
    ...Conversation
  }
}
    ${ConversationFragmentDoc}`;

/**
 * __useGetConversationQuery__
 *
 * To run a query within a React component, call `useGetConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationQuery(baseOptions: Apollo.QueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
      }
export function useGetConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationQuery, GetConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationQuery, GetConversationQueryVariables>(GetConversationDocument, options);
        }
export type GetConversationQueryHookResult = ReturnType<typeof useGetConversationQuery>;
export type GetConversationLazyQueryHookResult = ReturnType<typeof useGetConversationLazyQuery>;
export type GetConversationQueryResult = Apollo.QueryResult<GetConversationQuery, GetConversationQueryVariables>;
export const GetMessagesByConversationDocument = gql`
    query getMessagesByConversation($conversationId: String!, $pagination: PaginationInput) {
  messagesByConversation: getMessagesByConversation(conversationId: $conversationId, pagination: $pagination) {
    totalCount
    items {
      ...Message
    }
  }
}
    ${MessageFragmentDoc}`;

/**
 * __useGetMessagesByConversationQuery__
 *
 * To run a query within a React component, call `useGetMessagesByConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessagesByConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessagesByConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetMessagesByConversationQuery(baseOptions: Apollo.QueryHookOptions<GetMessagesByConversationQuery, GetMessagesByConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessagesByConversationQuery, GetMessagesByConversationQueryVariables>(GetMessagesByConversationDocument, options);
      }
export function useGetMessagesByConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessagesByConversationQuery, GetMessagesByConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessagesByConversationQuery, GetMessagesByConversationQueryVariables>(GetMessagesByConversationDocument, options);
        }
export type GetMessagesByConversationQueryHookResult = ReturnType<typeof useGetMessagesByConversationQuery>;
export type GetMessagesByConversationLazyQueryHookResult = ReturnType<typeof useGetMessagesByConversationLazyQuery>;
export type GetMessagesByConversationQueryResult = Apollo.QueryResult<GetMessagesByConversationQuery, GetMessagesByConversationQueryVariables>;
export const MarkConversationFilterDocument = gql`
    mutation MarkConversationFilter($conversationId: String!, $conversationFilter: String!) {
  markConversationFilter(conversationId: $conversationId, conversationFilter: $conversationFilter) {
    ...Conversation
  }
}
    ${ConversationFragmentDoc}`;
export type MarkConversationFilterMutationFn = Apollo.MutationFunction<MarkConversationFilterMutation, MarkConversationFilterMutationVariables>;

/**
 * __useMarkConversationFilterMutation__
 *
 * To run a mutation, you first call `useMarkConversationFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationFilterMutation, { data, loading, error }] = useMarkConversationFilterMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      conversationFilter: // value for 'conversationFilter'
 *   },
 * });
 */
export function useMarkConversationFilterMutation(baseOptions?: Apollo.MutationHookOptions<MarkConversationFilterMutation, MarkConversationFilterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkConversationFilterMutation, MarkConversationFilterMutationVariables>(MarkConversationFilterDocument, options);
      }
export type MarkConversationFilterMutationHookResult = ReturnType<typeof useMarkConversationFilterMutation>;
export type MarkConversationFilterMutationResult = Apollo.MutationResult<MarkConversationFilterMutation>;
export type MarkConversationFilterMutationOptions = Apollo.BaseMutationOptions<MarkConversationFilterMutation, MarkConversationFilterMutationVariables>;
export const UpdateConversationReadStatusDocument = gql`
    mutation UpdateConversationReadStatus($conversationId: String!) {
  updateConversationReadStatus(conversationId: $conversationId) {
    ...Conversation
  }
}
    ${ConversationFragmentDoc}`;
export type UpdateConversationReadStatusMutationFn = Apollo.MutationFunction<UpdateConversationReadStatusMutation, UpdateConversationReadStatusMutationVariables>;

/**
 * __useUpdateConversationReadStatusMutation__
 *
 * To run a mutation, you first call `useUpdateConversationReadStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationReadStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationReadStatusMutation, { data, loading, error }] = useUpdateConversationReadStatusMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useUpdateConversationReadStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConversationReadStatusMutation, UpdateConversationReadStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConversationReadStatusMutation, UpdateConversationReadStatusMutationVariables>(UpdateConversationReadStatusDocument, options);
      }
export type UpdateConversationReadStatusMutationHookResult = ReturnType<typeof useUpdateConversationReadStatusMutation>;
export type UpdateConversationReadStatusMutationResult = Apollo.MutationResult<UpdateConversationReadStatusMutation>;
export type UpdateConversationReadStatusMutationOptions = Apollo.BaseMutationOptions<UpdateConversationReadStatusMutation, UpdateConversationReadStatusMutationVariables>;
export const OnMessageDocument = gql`
    subscription onMessage {
  messageSubscription {
    ...MessageSubscription
  }
}
    ${MessageSubscriptionFragmentDoc}`;

/**
 * __useOnMessageSubscription__
 *
 * To run a query within a React component, call `useOnMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnMessageSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnMessageSubscription, OnMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnMessageSubscription, OnMessageSubscriptionVariables>(OnMessageDocument, options);
      }
export type OnMessageSubscriptionHookResult = ReturnType<typeof useOnMessageSubscription>;
export type OnMessageSubscriptionResult = Apollo.SubscriptionResult<OnMessageSubscription>;
export const GetDiscussionsDocument = gql`
    query getDiscussions($searchTerm: String!, $categoryFilter: [ID!]!, $sort: SortInput, $pagination: PaginationInput) {
  getDiscussions(searchTerm: $searchTerm, categoryFilter: {ids: $categoryFilter}, sort: $sort, pagination: $pagination) {
    items {
      ...Discussion
    }
    totalCount
  }
}
    ${DiscussionFragmentDoc}`;

/**
 * __useGetDiscussionsQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      categoryFilter: // value for 'categoryFilter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDiscussionsQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
      }
export function useGetDiscussionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsQuery, GetDiscussionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsQuery, GetDiscussionsQueryVariables>(GetDiscussionsDocument, options);
        }
export type GetDiscussionsQueryHookResult = ReturnType<typeof useGetDiscussionsQuery>;
export type GetDiscussionsLazyQueryHookResult = ReturnType<typeof useGetDiscussionsLazyQuery>;
export type GetDiscussionsQueryResult = Apollo.QueryResult<GetDiscussionsQuery, GetDiscussionsQueryVariables>;
export const GetDiscussionDocument = gql`
    query getDiscussion($id: String!) {
  getDiscussion(id: $id) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;

/**
 * __useGetDiscussionQuery__
 *
 * To run a query within a React component, call `useGetDiscussionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDiscussionQuery(baseOptions: Apollo.QueryHookOptions<GetDiscussionQuery, GetDiscussionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionQuery, GetDiscussionQueryVariables>(GetDiscussionDocument, options);
      }
export function useGetDiscussionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionQuery, GetDiscussionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionQuery, GetDiscussionQueryVariables>(GetDiscussionDocument, options);
        }
export type GetDiscussionQueryHookResult = ReturnType<typeof useGetDiscussionQuery>;
export type GetDiscussionLazyQueryHookResult = ReturnType<typeof useGetDiscussionLazyQuery>;
export type GetDiscussionQueryResult = Apollo.QueryResult<GetDiscussionQuery, GetDiscussionQueryVariables>;
export const CreateDiscussionDocument = gql`
    mutation createDiscussion($data: DiscussionInput!) {
  createDiscussion(data: $data) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;
export type CreateDiscussionMutationFn = Apollo.MutationFunction<CreateDiscussionMutation, CreateDiscussionMutationVariables>;

/**
 * __useCreateDiscussionMutation__
 *
 * To run a mutation, you first call `useCreateDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscussionMutation, { data, loading, error }] = useCreateDiscussionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscussionMutation, CreateDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscussionMutation, CreateDiscussionMutationVariables>(CreateDiscussionDocument, options);
      }
export type CreateDiscussionMutationHookResult = ReturnType<typeof useCreateDiscussionMutation>;
export type CreateDiscussionMutationResult = Apollo.MutationResult<CreateDiscussionMutation>;
export type CreateDiscussionMutationOptions = Apollo.BaseMutationOptions<CreateDiscussionMutation, CreateDiscussionMutationVariables>;
export const CreateCommunityDiscussionDocument = gql`
    mutation createCommunityDiscussion($data: DiscussionInput!, $communityId: String!) {
  createCommunityDiscussion(data: $data, communityId: $communityId) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;
export type CreateCommunityDiscussionMutationFn = Apollo.MutationFunction<CreateCommunityDiscussionMutation, CreateCommunityDiscussionMutationVariables>;

/**
 * __useCreateCommunityDiscussionMutation__
 *
 * To run a mutation, you first call `useCreateCommunityDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityDiscussionMutation, { data, loading, error }] = useCreateCommunityDiscussionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useCreateCommunityDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityDiscussionMutation, CreateCommunityDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityDiscussionMutation, CreateCommunityDiscussionMutationVariables>(CreateCommunityDiscussionDocument, options);
      }
export type CreateCommunityDiscussionMutationHookResult = ReturnType<typeof useCreateCommunityDiscussionMutation>;
export type CreateCommunityDiscussionMutationResult = Apollo.MutationResult<CreateCommunityDiscussionMutation>;
export type CreateCommunityDiscussionMutationOptions = Apollo.BaseMutationOptions<CreateCommunityDiscussionMutation, CreateCommunityDiscussionMutationVariables>;
export const UpdateDiscussionDocument = gql`
    mutation updateDiscussion($data: DiscussionInput!) {
  updateDiscussion(data: $data) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;
export type UpdateDiscussionMutationFn = Apollo.MutationFunction<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>;

/**
 * __useUpdateDiscussionMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionMutation, { data, loading, error }] = useUpdateDiscussionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>(UpdateDiscussionDocument, options);
      }
export type UpdateDiscussionMutationHookResult = ReturnType<typeof useUpdateDiscussionMutation>;
export type UpdateDiscussionMutationResult = Apollo.MutationResult<UpdateDiscussionMutation>;
export type UpdateDiscussionMutationOptions = Apollo.BaseMutationOptions<UpdateDiscussionMutation, UpdateDiscussionMutationVariables>;
export const DeleteDiscussionDocument = gql`
    mutation deleteDiscussion($id: String!) {
  deleteDiscussion(id: $id)
}
    `;
export type DeleteDiscussionMutationFn = Apollo.MutationFunction<DeleteDiscussionMutation, DeleteDiscussionMutationVariables>;

/**
 * __useDeleteDiscussionMutation__
 *
 * To run a mutation, you first call `useDeleteDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscussionMutation, { data, loading, error }] = useDeleteDiscussionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscussionMutation, DeleteDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscussionMutation, DeleteDiscussionMutationVariables>(DeleteDiscussionDocument, options);
      }
export type DeleteDiscussionMutationHookResult = ReturnType<typeof useDeleteDiscussionMutation>;
export type DeleteDiscussionMutationResult = Apollo.MutationResult<DeleteDiscussionMutation>;
export type DeleteDiscussionMutationOptions = Apollo.BaseMutationOptions<DeleteDiscussionMutation, DeleteDiscussionMutationVariables>;
export const LikeDiscussionDocument = gql`
    mutation LikeDiscussion($id: String!) {
  likeDiscussion(id: $id) {
    id
    user {
      id
      name
    }
  }
}
    `;
export type LikeDiscussionMutationFn = Apollo.MutationFunction<LikeDiscussionMutation, LikeDiscussionMutationVariables>;

/**
 * __useLikeDiscussionMutation__
 *
 * To run a mutation, you first call `useLikeDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeDiscussionMutation, { data, loading, error }] = useLikeDiscussionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikeDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<LikeDiscussionMutation, LikeDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeDiscussionMutation, LikeDiscussionMutationVariables>(LikeDiscussionDocument, options);
      }
export type LikeDiscussionMutationHookResult = ReturnType<typeof useLikeDiscussionMutation>;
export type LikeDiscussionMutationResult = Apollo.MutationResult<LikeDiscussionMutation>;
export type LikeDiscussionMutationOptions = Apollo.BaseMutationOptions<LikeDiscussionMutation, LikeDiscussionMutationVariables>;
export const DeleteDiscussionLikeDocument = gql`
    mutation DeleteDiscussionLike($id: String!) {
  unlikeDiscussion(id: $id)
}
    `;
export type DeleteDiscussionLikeMutationFn = Apollo.MutationFunction<DeleteDiscussionLikeMutation, DeleteDiscussionLikeMutationVariables>;

/**
 * __useDeleteDiscussionLikeMutation__
 *
 * To run a mutation, you first call `useDeleteDiscussionLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscussionLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscussionLikeMutation, { data, loading, error }] = useDeleteDiscussionLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDiscussionLikeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscussionLikeMutation, DeleteDiscussionLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscussionLikeMutation, DeleteDiscussionLikeMutationVariables>(DeleteDiscussionLikeDocument, options);
      }
export type DeleteDiscussionLikeMutationHookResult = ReturnType<typeof useDeleteDiscussionLikeMutation>;
export type DeleteDiscussionLikeMutationResult = Apollo.MutationResult<DeleteDiscussionLikeMutation>;
export type DeleteDiscussionLikeMutationOptions = Apollo.BaseMutationOptions<DeleteDiscussionLikeMutation, DeleteDiscussionLikeMutationVariables>;
export const ShareDiscussionDocument = gql`
    mutation ShareDiscussion($data: ShareInput!) {
  shareDiscussion(data: $data)
}
    `;
export type ShareDiscussionMutationFn = Apollo.MutationFunction<ShareDiscussionMutation, ShareDiscussionMutationVariables>;

/**
 * __useShareDiscussionMutation__
 *
 * To run a mutation, you first call `useShareDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareDiscussionMutation, { data, loading, error }] = useShareDiscussionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useShareDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<ShareDiscussionMutation, ShareDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareDiscussionMutation, ShareDiscussionMutationVariables>(ShareDiscussionDocument, options);
      }
export type ShareDiscussionMutationHookResult = ReturnType<typeof useShareDiscussionMutation>;
export type ShareDiscussionMutationResult = Apollo.MutationResult<ShareDiscussionMutation>;
export type ShareDiscussionMutationOptions = Apollo.BaseMutationOptions<ShareDiscussionMutation, ShareDiscussionMutationVariables>;
export const GetPostsByDiscussionDocument = gql`
    query getPostsByDiscussion($id: String!, $postId: String, $pagination: PaginationInput, $sort: SortInput) {
  getPostsByDiscussion(id: $id, postId: $postId, pagination: $pagination, sort: $sort) {
    items {
      ...Post
    }
    totalCount
    page
    offset
  }
}
    ${PostFragmentDoc}`;

/**
 * __useGetPostsByDiscussionQuery__
 *
 * To run a query within a React component, call `useGetPostsByDiscussionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsByDiscussionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsByDiscussionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      postId: // value for 'postId'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPostsByDiscussionQuery(baseOptions: Apollo.QueryHookOptions<GetPostsByDiscussionQuery, GetPostsByDiscussionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsByDiscussionQuery, GetPostsByDiscussionQueryVariables>(GetPostsByDiscussionDocument, options);
      }
export function useGetPostsByDiscussionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsByDiscussionQuery, GetPostsByDiscussionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsByDiscussionQuery, GetPostsByDiscussionQueryVariables>(GetPostsByDiscussionDocument, options);
        }
export type GetPostsByDiscussionQueryHookResult = ReturnType<typeof useGetPostsByDiscussionQuery>;
export type GetPostsByDiscussionLazyQueryHookResult = ReturnType<typeof useGetPostsByDiscussionLazyQuery>;
export type GetPostsByDiscussionQueryResult = Apollo.QueryResult<GetPostsByDiscussionQuery, GetPostsByDiscussionQueryVariables>;
export const CreatePostDocument = gql`
    mutation createPost($data: PostInput!) {
  createPost(data: $data) {
    result {
      id
      content
    }
    communityId
    errorMessage
  }
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const DeleteReplyDocument = gql`
    mutation deleteReply($id: String!) {
  deleteReply(id: $id)
}
    `;
export type DeleteReplyMutationFn = Apollo.MutationFunction<DeleteReplyMutation, DeleteReplyMutationVariables>;

/**
 * __useDeleteReplyMutation__
 *
 * To run a mutation, you first call `useDeleteReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplyMutation, { data, loading, error }] = useDeleteReplyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReplyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReplyMutation, DeleteReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReplyMutation, DeleteReplyMutationVariables>(DeleteReplyDocument, options);
      }
export type DeleteReplyMutationHookResult = ReturnType<typeof useDeleteReplyMutation>;
export type DeleteReplyMutationResult = Apollo.MutationResult<DeleteReplyMutation>;
export type DeleteReplyMutationOptions = Apollo.BaseMutationOptions<DeleteReplyMutation, DeleteReplyMutationVariables>;
export const LikePostDocument = gql`
    mutation LikePost($id: String!) {
  likePost(id: $id) {
    id
    user {
      id
      name
    }
  }
}
    `;
export type LikePostMutationFn = Apollo.MutationFunction<LikePostMutation, LikePostMutationVariables>;

/**
 * __useLikePostMutation__
 *
 * To run a mutation, you first call `useLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostMutation, { data, loading, error }] = useLikePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikePostMutation(baseOptions?: Apollo.MutationHookOptions<LikePostMutation, LikePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikePostMutation, LikePostMutationVariables>(LikePostDocument, options);
      }
export type LikePostMutationHookResult = ReturnType<typeof useLikePostMutation>;
export type LikePostMutationResult = Apollo.MutationResult<LikePostMutation>;
export type LikePostMutationOptions = Apollo.BaseMutationOptions<LikePostMutation, LikePostMutationVariables>;
export const DeletePostLikeDocument = gql`
    mutation DeletePostLike($id: String!) {
  unlikePost(id: $id)
}
    `;
export type DeletePostLikeMutationFn = Apollo.MutationFunction<DeletePostLikeMutation, DeletePostLikeMutationVariables>;

/**
 * __useDeletePostLikeMutation__
 *
 * To run a mutation, you first call `useDeletePostLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostLikeMutation, { data, loading, error }] = useDeletePostLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostLikeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostLikeMutation, DeletePostLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostLikeMutation, DeletePostLikeMutationVariables>(DeletePostLikeDocument, options);
      }
export type DeletePostLikeMutationHookResult = ReturnType<typeof useDeletePostLikeMutation>;
export type DeletePostLikeMutationResult = Apollo.MutationResult<DeletePostLikeMutation>;
export type DeletePostLikeMutationOptions = Apollo.BaseMutationOptions<DeletePostLikeMutation, DeletePostLikeMutationVariables>;
export const CreateReplyDocument = gql`
    mutation createReply($data: ReplyInput!) {
  createReply(data: $data) {
    result {
      id
      content
    }
    communityId
    errorMessage
  }
}
    `;
export type CreateReplyMutationFn = Apollo.MutationFunction<CreateReplyMutation, CreateReplyMutationVariables>;

/**
 * __useCreateReplyMutation__
 *
 * To run a mutation, you first call `useCreateReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReplyMutation, { data, loading, error }] = useCreateReplyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReplyMutation(baseOptions?: Apollo.MutationHookOptions<CreateReplyMutation, CreateReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReplyMutation, CreateReplyMutationVariables>(CreateReplyDocument, options);
      }
export type CreateReplyMutationHookResult = ReturnType<typeof useCreateReplyMutation>;
export type CreateReplyMutationResult = Apollo.MutationResult<CreateReplyMutation>;
export type CreateReplyMutationOptions = Apollo.BaseMutationOptions<CreateReplyMutation, CreateReplyMutationVariables>;
export const UpdatePostDocument = gql`
    mutation updatePost($postId: String!, $updatedContent: String!) {
  updatePost(postId: $postId, updatedContent: $updatedContent) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      updatedContent: // value for 'updatedContent'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const GetRepliesByPostDocument = gql`
    query getRepliesByPost($id: String!, $replyId: String, $pagination: PaginationInput, $sort: SortInput) {
  getRepliesByPost(id: $id, replyId: $replyId, pagination: $pagination, sort: $sort) {
    items {
      ...Post
    }
    totalCount
    page
  }
}
    ${PostFragmentDoc}`;

/**
 * __useGetRepliesByPostQuery__
 *
 * To run a query within a React component, call `useGetRepliesByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepliesByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepliesByPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      replyId: // value for 'replyId'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetRepliesByPostQuery(baseOptions: Apollo.QueryHookOptions<GetRepliesByPostQuery, GetRepliesByPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRepliesByPostQuery, GetRepliesByPostQueryVariables>(GetRepliesByPostDocument, options);
      }
export function useGetRepliesByPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepliesByPostQuery, GetRepliesByPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRepliesByPostQuery, GetRepliesByPostQueryVariables>(GetRepliesByPostDocument, options);
        }
export type GetRepliesByPostQueryHookResult = ReturnType<typeof useGetRepliesByPostQuery>;
export type GetRepliesByPostLazyQueryHookResult = ReturnType<typeof useGetRepliesByPostLazyQuery>;
export type GetRepliesByPostQueryResult = Apollo.QueryResult<GetRepliesByPostQuery, GetRepliesByPostQueryVariables>;
export const FollowDiscussionDocument = gql`
    mutation followDiscussion($discussionId: String!) {
  followDiscussion(discussionId: $discussionId) {
    id
    user {
      id
      name
    }
  }
}
    `;
export type FollowDiscussionMutationFn = Apollo.MutationFunction<FollowDiscussionMutation, FollowDiscussionMutationVariables>;

/**
 * __useFollowDiscussionMutation__
 *
 * To run a mutation, you first call `useFollowDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followDiscussionMutation, { data, loading, error }] = useFollowDiscussionMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useFollowDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<FollowDiscussionMutation, FollowDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowDiscussionMutation, FollowDiscussionMutationVariables>(FollowDiscussionDocument, options);
      }
export type FollowDiscussionMutationHookResult = ReturnType<typeof useFollowDiscussionMutation>;
export type FollowDiscussionMutationResult = Apollo.MutationResult<FollowDiscussionMutation>;
export type FollowDiscussionMutationOptions = Apollo.BaseMutationOptions<FollowDiscussionMutation, FollowDiscussionMutationVariables>;
export const UnFollowDiscussionDocument = gql`
    mutation unFollowDiscussion($discussionId: String!) {
  unFollowDiscussion(discussionId: $discussionId)
}
    `;
export type UnFollowDiscussionMutationFn = Apollo.MutationFunction<UnFollowDiscussionMutation, UnFollowDiscussionMutationVariables>;

/**
 * __useUnFollowDiscussionMutation__
 *
 * To run a mutation, you first call `useUnFollowDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowDiscussionMutation, { data, loading, error }] = useUnFollowDiscussionMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useUnFollowDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<UnFollowDiscussionMutation, UnFollowDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnFollowDiscussionMutation, UnFollowDiscussionMutationVariables>(UnFollowDiscussionDocument, options);
      }
export type UnFollowDiscussionMutationHookResult = ReturnType<typeof useUnFollowDiscussionMutation>;
export type UnFollowDiscussionMutationResult = Apollo.MutationResult<UnFollowDiscussionMutation>;
export type UnFollowDiscussionMutationOptions = Apollo.BaseMutationOptions<UnFollowDiscussionMutation, UnFollowDiscussionMutationVariables>;
export const GetDiscussionFollowsDocument = gql`
    query GetDiscussionFollows($sort: SortInput, $pagination: PaginationInput) {
  getDiscussionFollows(sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...DiscussionFollow
    }
  }
}
    ${DiscussionFollowFragmentDoc}`;

/**
 * __useGetDiscussionFollowsQuery__
 *
 * To run a query within a React component, call `useGetDiscussionFollowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionFollowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionFollowsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDiscussionFollowsQuery(baseOptions?: Apollo.QueryHookOptions<GetDiscussionFollowsQuery, GetDiscussionFollowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionFollowsQuery, GetDiscussionFollowsQueryVariables>(GetDiscussionFollowsDocument, options);
      }
export function useGetDiscussionFollowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionFollowsQuery, GetDiscussionFollowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionFollowsQuery, GetDiscussionFollowsQueryVariables>(GetDiscussionFollowsDocument, options);
        }
export type GetDiscussionFollowsQueryHookResult = ReturnType<typeof useGetDiscussionFollowsQuery>;
export type GetDiscussionFollowsLazyQueryHookResult = ReturnType<typeof useGetDiscussionFollowsLazyQuery>;
export type GetDiscussionFollowsQueryResult = Apollo.QueryResult<GetDiscussionFollowsQuery, GetDiscussionFollowsQueryVariables>;
export const PinDiscussionDocument = gql`
    mutation pinDiscussion($discussionId: String!) {
  pinDiscussion(discussionId: $discussionId) {
    result
    errorMessage
  }
}
    `;
export type PinDiscussionMutationFn = Apollo.MutationFunction<PinDiscussionMutation, PinDiscussionMutationVariables>;

/**
 * __usePinDiscussionMutation__
 *
 * To run a mutation, you first call `usePinDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinDiscussionMutation, { data, loading, error }] = usePinDiscussionMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function usePinDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<PinDiscussionMutation, PinDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinDiscussionMutation, PinDiscussionMutationVariables>(PinDiscussionDocument, options);
      }
export type PinDiscussionMutationHookResult = ReturnType<typeof usePinDiscussionMutation>;
export type PinDiscussionMutationResult = Apollo.MutationResult<PinDiscussionMutation>;
export type PinDiscussionMutationOptions = Apollo.BaseMutationOptions<PinDiscussionMutation, PinDiscussionMutationVariables>;
export const UnpinDiscussionDocument = gql`
    mutation unpinDiscussion($discussionId: String!) {
  unpinDiscussion(discussionId: $discussionId) {
    result
    errorMessage
  }
}
    `;
export type UnpinDiscussionMutationFn = Apollo.MutationFunction<UnpinDiscussionMutation, UnpinDiscussionMutationVariables>;

/**
 * __useUnpinDiscussionMutation__
 *
 * To run a mutation, you first call `useUnpinDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinDiscussionMutation, { data, loading, error }] = useUnpinDiscussionMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useUnpinDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<UnpinDiscussionMutation, UnpinDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpinDiscussionMutation, UnpinDiscussionMutationVariables>(UnpinDiscussionDocument, options);
      }
export type UnpinDiscussionMutationHookResult = ReturnType<typeof useUnpinDiscussionMutation>;
export type UnpinDiscussionMutationResult = Apollo.MutationResult<UnpinDiscussionMutation>;
export type UnpinDiscussionMutationOptions = Apollo.BaseMutationOptions<UnpinDiscussionMutation, UnpinDiscussionMutationVariables>;
export const GetDocumentDocument = gql`
    query getDocument($documentId: String!) {
  getDocument(documentId: $documentId) {
    fileName
    blobBase64
  }
}
    `;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
      }
export function useGetDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
        }
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = Apollo.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export const UploadDocumentDocument = gql`
    query uploadDocument($documentName: String!, $documentBase64: String!) {
  uploadDocument(documentName: $documentName, documentBase64: $documentBase64) {
    fileName
    blobBase64
  }
}
    `;

/**
 * __useUploadDocumentQuery__
 *
 * To run a query within a React component, call `useUploadDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadDocumentQuery({
 *   variables: {
 *      documentName: // value for 'documentName'
 *      documentBase64: // value for 'documentBase64'
 *   },
 * });
 */
export function useUploadDocumentQuery(baseOptions: Apollo.QueryHookOptions<UploadDocumentQuery, UploadDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadDocumentQuery, UploadDocumentQueryVariables>(UploadDocumentDocument, options);
      }
export function useUploadDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadDocumentQuery, UploadDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadDocumentQuery, UploadDocumentQueryVariables>(UploadDocumentDocument, options);
        }
export type UploadDocumentQueryHookResult = ReturnType<typeof useUploadDocumentQuery>;
export type UploadDocumentLazyQueryHookResult = ReturnType<typeof useUploadDocumentLazyQuery>;
export type UploadDocumentQueryResult = Apollo.QueryResult<UploadDocumentQuery, UploadDocumentQueryVariables>;
export const SearchDocumentsDocument = gql`
    query searchDocuments($searchTerm: String, $sort: SortInput, $pagination: PaginationInput) {
  results: searchDocuments(searchTerm: $searchTerm, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useSearchDocumentsQuery__
 *
 * To run a query within a React component, call `useSearchDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocumentsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
      }
export function useSearchDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDocumentsQuery, SearchDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDocumentsQuery, SearchDocumentsQueryVariables>(SearchDocumentsDocument, options);
        }
export type SearchDocumentsQueryHookResult = ReturnType<typeof useSearchDocumentsQuery>;
export type SearchDocumentsLazyQueryHookResult = ReturnType<typeof useSearchDocumentsLazyQuery>;
export type SearchDocumentsQueryResult = Apollo.QueryResult<SearchDocumentsQuery, SearchDocumentsQueryVariables>;
export const CreateFeedbackDocument = gql`
    mutation CreateFeedback($data: GiveFeedbackInput!) {
  createFeedback(data: $data)
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const SyncGroupsDocument = gql`
    mutation syncGroups {
  syncSuccessful: syncGroups
}
    `;
export type SyncGroupsMutationFn = Apollo.MutationFunction<SyncGroupsMutation, SyncGroupsMutationVariables>;

/**
 * __useSyncGroupsMutation__
 *
 * To run a mutation, you first call `useSyncGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncGroupsMutation, { data, loading, error }] = useSyncGroupsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncGroupsMutation(baseOptions?: Apollo.MutationHookOptions<SyncGroupsMutation, SyncGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncGroupsMutation, SyncGroupsMutationVariables>(SyncGroupsDocument, options);
      }
export type SyncGroupsMutationHookResult = ReturnType<typeof useSyncGroupsMutation>;
export type SyncGroupsMutationResult = Apollo.MutationResult<SyncGroupsMutation>;
export type SyncGroupsMutationOptions = Apollo.BaseMutationOptions<SyncGroupsMutation, SyncGroupsMutationVariables>;
export const SearchUserIdentitiesDocument = gql`
    query searchUserIdentities($data: SearchIdentitiesInput!, $pagination: PaginationInput) {
  results: searchUserIdentities(data: $data, pagination: $pagination) {
    totalCount
    items {
      ...UserWithEmail
    }
  }
}
    ${UserWithEmailFragmentDoc}`;

/**
 * __useSearchUserIdentitiesQuery__
 *
 * To run a query within a React component, call `useSearchUserIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUserIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUserIdentitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchUserIdentitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchUserIdentitiesQuery, SearchUserIdentitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUserIdentitiesQuery, SearchUserIdentitiesQueryVariables>(SearchUserIdentitiesDocument, options);
      }
export function useSearchUserIdentitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUserIdentitiesQuery, SearchUserIdentitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUserIdentitiesQuery, SearchUserIdentitiesQueryVariables>(SearchUserIdentitiesDocument, options);
        }
export type SearchUserIdentitiesQueryHookResult = ReturnType<typeof useSearchUserIdentitiesQuery>;
export type SearchUserIdentitiesLazyQueryHookResult = ReturnType<typeof useSearchUserIdentitiesLazyQuery>;
export type SearchUserIdentitiesQueryResult = Apollo.QueryResult<SearchUserIdentitiesQuery, SearchUserIdentitiesQueryVariables>;
export const GetUserIdentityDocument = gql`
    query getUserIdentity($userId: String!) {
  userIdentity: getUserIdentity(userId: $userId) {
    ...UserIdentity
  }
}
    ${UserIdentityFragmentDoc}`;

/**
 * __useGetUserIdentityQuery__
 *
 * To run a query within a React component, call `useGetUserIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdentityQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserIdentityQuery(baseOptions: Apollo.QueryHookOptions<GetUserIdentityQuery, GetUserIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserIdentityQuery, GetUserIdentityQueryVariables>(GetUserIdentityDocument, options);
      }
export function useGetUserIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserIdentityQuery, GetUserIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserIdentityQuery, GetUserIdentityQueryVariables>(GetUserIdentityDocument, options);
        }
export type GetUserIdentityQueryHookResult = ReturnType<typeof useGetUserIdentityQuery>;
export type GetUserIdentityLazyQueryHookResult = ReturnType<typeof useGetUserIdentityLazyQuery>;
export type GetUserIdentityQueryResult = Apollo.QueryResult<GetUserIdentityQuery, GetUserIdentityQueryVariables>;
export const CreateIdentityDocument = gql`
    mutation createIdentity($data: IdentityInput!) {
  createIdentity(data: $data) {
    result {
      ...Identity
    }
    errorMessage
  }
}
    ${IdentityFragmentDoc}`;
export type CreateIdentityMutationFn = Apollo.MutationFunction<CreateIdentityMutation, CreateIdentityMutationVariables>;

/**
 * __useCreateIdentityMutation__
 *
 * To run a mutation, you first call `useCreateIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdentityMutation, { data, loading, error }] = useCreateIdentityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIdentityMutation(baseOptions?: Apollo.MutationHookOptions<CreateIdentityMutation, CreateIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIdentityMutation, CreateIdentityMutationVariables>(CreateIdentityDocument, options);
      }
export type CreateIdentityMutationHookResult = ReturnType<typeof useCreateIdentityMutation>;
export type CreateIdentityMutationResult = Apollo.MutationResult<CreateIdentityMutation>;
export type CreateIdentityMutationOptions = Apollo.BaseMutationOptions<CreateIdentityMutation, CreateIdentityMutationVariables>;
export const CreateIdentityAsAdminDocument = gql`
    mutation createIdentityAsAdmin($data: IdentityInput!) {
  createIdentityAsAdmin(data: $data) {
    result {
      ...Identity
    }
    errorMessage
  }
}
    ${IdentityFragmentDoc}`;
export type CreateIdentityAsAdminMutationFn = Apollo.MutationFunction<CreateIdentityAsAdminMutation, CreateIdentityAsAdminMutationVariables>;

/**
 * __useCreateIdentityAsAdminMutation__
 *
 * To run a mutation, you first call `useCreateIdentityAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdentityAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdentityAsAdminMutation, { data, loading, error }] = useCreateIdentityAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateIdentityAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateIdentityAsAdminMutation, CreateIdentityAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIdentityAsAdminMutation, CreateIdentityAsAdminMutationVariables>(CreateIdentityAsAdminDocument, options);
      }
export type CreateIdentityAsAdminMutationHookResult = ReturnType<typeof useCreateIdentityAsAdminMutation>;
export type CreateIdentityAsAdminMutationResult = Apollo.MutationResult<CreateIdentityAsAdminMutation>;
export type CreateIdentityAsAdminMutationOptions = Apollo.BaseMutationOptions<CreateIdentityAsAdminMutation, CreateIdentityAsAdminMutationVariables>;
export const ValidateIdentityDocument = gql`
    mutation validateIdentity($data: ValidateIdentityInput!) {
  validateIdentity(data: $data) {
    result {
      ...CurrentUser
    }
    errorMessage
  }
}
    ${CurrentUserFragmentDoc}`;
export type ValidateIdentityMutationFn = Apollo.MutationFunction<ValidateIdentityMutation, ValidateIdentityMutationVariables>;

/**
 * __useValidateIdentityMutation__
 *
 * To run a mutation, you first call `useValidateIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateIdentityMutation, { data, loading, error }] = useValidateIdentityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useValidateIdentityMutation(baseOptions?: Apollo.MutationHookOptions<ValidateIdentityMutation, ValidateIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateIdentityMutation, ValidateIdentityMutationVariables>(ValidateIdentityDocument, options);
      }
export type ValidateIdentityMutationHookResult = ReturnType<typeof useValidateIdentityMutation>;
export type ValidateIdentityMutationResult = Apollo.MutationResult<ValidateIdentityMutation>;
export type ValidateIdentityMutationOptions = Apollo.BaseMutationOptions<ValidateIdentityMutation, ValidateIdentityMutationVariables>;
export const ResendValidationDocument = gql`
    mutation resendValidation($identityId: String!) {
  resendValidation(identityId: $identityId) {
    ...Identity
  }
}
    ${IdentityFragmentDoc}`;
export type ResendValidationMutationFn = Apollo.MutationFunction<ResendValidationMutation, ResendValidationMutationVariables>;

/**
 * __useResendValidationMutation__
 *
 * To run a mutation, you first call `useResendValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendValidationMutation, { data, loading, error }] = useResendValidationMutation({
 *   variables: {
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useResendValidationMutation(baseOptions?: Apollo.MutationHookOptions<ResendValidationMutation, ResendValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendValidationMutation, ResendValidationMutationVariables>(ResendValidationDocument, options);
      }
export type ResendValidationMutationHookResult = ReturnType<typeof useResendValidationMutation>;
export type ResendValidationMutationResult = Apollo.MutationResult<ResendValidationMutation>;
export type ResendValidationMutationOptions = Apollo.BaseMutationOptions<ResendValidationMutation, ResendValidationMutationVariables>;
export const ValidateIdentityAsAdminDocument = gql`
    mutation validateIdentityAsAdmin($data: ValidateIdentityInput!) {
  validateIdentityAsAdmin(data: $data) {
    ...Identity
  }
}
    ${IdentityFragmentDoc}`;
export type ValidateIdentityAsAdminMutationFn = Apollo.MutationFunction<ValidateIdentityAsAdminMutation, ValidateIdentityAsAdminMutationVariables>;

/**
 * __useValidateIdentityAsAdminMutation__
 *
 * To run a mutation, you first call `useValidateIdentityAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateIdentityAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateIdentityAsAdminMutation, { data, loading, error }] = useValidateIdentityAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useValidateIdentityAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<ValidateIdentityAsAdminMutation, ValidateIdentityAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateIdentityAsAdminMutation, ValidateIdentityAsAdminMutationVariables>(ValidateIdentityAsAdminDocument, options);
      }
export type ValidateIdentityAsAdminMutationHookResult = ReturnType<typeof useValidateIdentityAsAdminMutation>;
export type ValidateIdentityAsAdminMutationResult = Apollo.MutationResult<ValidateIdentityAsAdminMutation>;
export type ValidateIdentityAsAdminMutationOptions = Apollo.BaseMutationOptions<ValidateIdentityAsAdminMutation, ValidateIdentityAsAdminMutationVariables>;
export const ResendValidationAsAdminDocument = gql`
    mutation resendValidationAsAdmin($identityId: String!) {
  identity: resendValidationAsAdmin(identityId: $identityId) {
    ...Identity
  }
}
    ${IdentityFragmentDoc}`;
export type ResendValidationAsAdminMutationFn = Apollo.MutationFunction<ResendValidationAsAdminMutation, ResendValidationAsAdminMutationVariables>;

/**
 * __useResendValidationAsAdminMutation__
 *
 * To run a mutation, you first call `useResendValidationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendValidationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendValidationAsAdminMutation, { data, loading, error }] = useResendValidationAsAdminMutation({
 *   variables: {
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useResendValidationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<ResendValidationAsAdminMutation, ResendValidationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendValidationAsAdminMutation, ResendValidationAsAdminMutationVariables>(ResendValidationAsAdminDocument, options);
      }
export type ResendValidationAsAdminMutationHookResult = ReturnType<typeof useResendValidationAsAdminMutation>;
export type ResendValidationAsAdminMutationResult = Apollo.MutationResult<ResendValidationAsAdminMutation>;
export type ResendValidationAsAdminMutationOptions = Apollo.BaseMutationOptions<ResendValidationAsAdminMutation, ResendValidationAsAdminMutationVariables>;
export const UpdateAffiliationDocument = gql`
    mutation updateAffiliation($data: EditAffiliationInput!) {
  updateAffiliation(data: $data) {
    result {
      ...Affiliation
    }
    errorMessage
  }
}
    ${AffiliationFragmentDoc}`;
export type UpdateAffiliationMutationFn = Apollo.MutationFunction<UpdateAffiliationMutation, UpdateAffiliationMutationVariables>;

/**
 * __useUpdateAffiliationMutation__
 *
 * To run a mutation, you first call `useUpdateAffiliationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAffiliationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAffiliationMutation, { data, loading, error }] = useUpdateAffiliationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAffiliationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAffiliationMutation, UpdateAffiliationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAffiliationMutation, UpdateAffiliationMutationVariables>(UpdateAffiliationDocument, options);
      }
export type UpdateAffiliationMutationHookResult = ReturnType<typeof useUpdateAffiliationMutation>;
export type UpdateAffiliationMutationResult = Apollo.MutationResult<UpdateAffiliationMutation>;
export type UpdateAffiliationMutationOptions = Apollo.BaseMutationOptions<UpdateAffiliationMutation, UpdateAffiliationMutationVariables>;
export const ClearUserCacheDocument = gql`
    mutation clearUserCache($emailAddress: String!) {
  clearUserCache(emailAddress: $emailAddress)
}
    `;
export type ClearUserCacheMutationFn = Apollo.MutationFunction<ClearUserCacheMutation, ClearUserCacheMutationVariables>;

/**
 * __useClearUserCacheMutation__
 *
 * To run a mutation, you first call `useClearUserCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUserCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUserCacheMutation, { data, loading, error }] = useClearUserCacheMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useClearUserCacheMutation(baseOptions?: Apollo.MutationHookOptions<ClearUserCacheMutation, ClearUserCacheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearUserCacheMutation, ClearUserCacheMutationVariables>(ClearUserCacheDocument, options);
      }
export type ClearUserCacheMutationHookResult = ReturnType<typeof useClearUserCacheMutation>;
export type ClearUserCacheMutationResult = Apollo.MutationResult<ClearUserCacheMutation>;
export type ClearUserCacheMutationOptions = Apollo.BaseMutationOptions<ClearUserCacheMutation, ClearUserCacheMutationVariables>;
export const AddAffiliationAsPrimaryOrganizationAsAdminDocument = gql`
    mutation addAffiliationAsPrimaryOrganizationAsAdmin($data: AddAffiliationInput!) {
  addAffiliationAsPrimaryOrganizationAsAdmin(data: $data)
}
    `;
export type AddAffiliationAsPrimaryOrganizationAsAdminMutationFn = Apollo.MutationFunction<AddAffiliationAsPrimaryOrganizationAsAdminMutation, AddAffiliationAsPrimaryOrganizationAsAdminMutationVariables>;

/**
 * __useAddAffiliationAsPrimaryOrganizationAsAdminMutation__
 *
 * To run a mutation, you first call `useAddAffiliationAsPrimaryOrganizationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAffiliationAsPrimaryOrganizationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAffiliationAsPrimaryOrganizationAsAdminMutation, { data, loading, error }] = useAddAffiliationAsPrimaryOrganizationAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAffiliationAsPrimaryOrganizationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<AddAffiliationAsPrimaryOrganizationAsAdminMutation, AddAffiliationAsPrimaryOrganizationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAffiliationAsPrimaryOrganizationAsAdminMutation, AddAffiliationAsPrimaryOrganizationAsAdminMutationVariables>(AddAffiliationAsPrimaryOrganizationAsAdminDocument, options);
      }
export type AddAffiliationAsPrimaryOrganizationAsAdminMutationHookResult = ReturnType<typeof useAddAffiliationAsPrimaryOrganizationAsAdminMutation>;
export type AddAffiliationAsPrimaryOrganizationAsAdminMutationResult = Apollo.MutationResult<AddAffiliationAsPrimaryOrganizationAsAdminMutation>;
export type AddAffiliationAsPrimaryOrganizationAsAdminMutationOptions = Apollo.BaseMutationOptions<AddAffiliationAsPrimaryOrganizationAsAdminMutation, AddAffiliationAsPrimaryOrganizationAsAdminMutationVariables>;
export const AddAffiliationAsAdminDocument = gql`
    mutation addAffiliationAsAdmin($data: AddAffiliationInput!) {
  addAffiliationAsAdmin(data: $data) {
    result {
      ...Affiliation
    }
    errorMessage
  }
}
    ${AffiliationFragmentDoc}`;
export type AddAffiliationAsAdminMutationFn = Apollo.MutationFunction<AddAffiliationAsAdminMutation, AddAffiliationAsAdminMutationVariables>;

/**
 * __useAddAffiliationAsAdminMutation__
 *
 * To run a mutation, you first call `useAddAffiliationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAffiliationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAffiliationAsAdminMutation, { data, loading, error }] = useAddAffiliationAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAffiliationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<AddAffiliationAsAdminMutation, AddAffiliationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAffiliationAsAdminMutation, AddAffiliationAsAdminMutationVariables>(AddAffiliationAsAdminDocument, options);
      }
export type AddAffiliationAsAdminMutationHookResult = ReturnType<typeof useAddAffiliationAsAdminMutation>;
export type AddAffiliationAsAdminMutationResult = Apollo.MutationResult<AddAffiliationAsAdminMutation>;
export type AddAffiliationAsAdminMutationOptions = Apollo.BaseMutationOptions<AddAffiliationAsAdminMutation, AddAffiliationAsAdminMutationVariables>;
export const EditAffiliationAsAdminDocument = gql`
    mutation editAffiliationAsAdmin($data: EditAdminAffiliationInput!) {
  affiliationResponse: editAffiliationAsAdmin(data: $data) {
    result {
      ...Affiliation
    }
    errorMessage
  }
}
    ${AffiliationFragmentDoc}`;
export type EditAffiliationAsAdminMutationFn = Apollo.MutationFunction<EditAffiliationAsAdminMutation, EditAffiliationAsAdminMutationVariables>;

/**
 * __useEditAffiliationAsAdminMutation__
 *
 * To run a mutation, you first call `useEditAffiliationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAffiliationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAffiliationAsAdminMutation, { data, loading, error }] = useEditAffiliationAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditAffiliationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<EditAffiliationAsAdminMutation, EditAffiliationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAffiliationAsAdminMutation, EditAffiliationAsAdminMutationVariables>(EditAffiliationAsAdminDocument, options);
      }
export type EditAffiliationAsAdminMutationHookResult = ReturnType<typeof useEditAffiliationAsAdminMutation>;
export type EditAffiliationAsAdminMutationResult = Apollo.MutationResult<EditAffiliationAsAdminMutation>;
export type EditAffiliationAsAdminMutationOptions = Apollo.BaseMutationOptions<EditAffiliationAsAdminMutation, EditAffiliationAsAdminMutationVariables>;
export const RemoveAffiliationAsAdminDocument = gql`
    mutation removeAffiliationAsAdmin($data: RemoveAffiliationInput!) {
  removeAffiliationAsAdmin(data: $data) {
    result
    errorMessage
  }
}
    `;
export type RemoveAffiliationAsAdminMutationFn = Apollo.MutationFunction<RemoveAffiliationAsAdminMutation, RemoveAffiliationAsAdminMutationVariables>;

/**
 * __useRemoveAffiliationAsAdminMutation__
 *
 * To run a mutation, you first call `useRemoveAffiliationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAffiliationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAffiliationAsAdminMutation, { data, loading, error }] = useRemoveAffiliationAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveAffiliationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAffiliationAsAdminMutation, RemoveAffiliationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAffiliationAsAdminMutation, RemoveAffiliationAsAdminMutationVariables>(RemoveAffiliationAsAdminDocument, options);
      }
export type RemoveAffiliationAsAdminMutationHookResult = ReturnType<typeof useRemoveAffiliationAsAdminMutation>;
export type RemoveAffiliationAsAdminMutationResult = Apollo.MutationResult<RemoveAffiliationAsAdminMutation>;
export type RemoveAffiliationAsAdminMutationOptions = Apollo.BaseMutationOptions<RemoveAffiliationAsAdminMutation, RemoveAffiliationAsAdminMutationVariables>;
export const ApproveAffiliationAsAdminDocument = gql`
    mutation approveAffiliationAsAdmin($data: ApproveAffiliationInput!) {
  approveAffiliationAsAdmin(data: $data) {
    result
    errorMessage
  }
}
    `;
export type ApproveAffiliationAsAdminMutationFn = Apollo.MutationFunction<ApproveAffiliationAsAdminMutation, ApproveAffiliationAsAdminMutationVariables>;

/**
 * __useApproveAffiliationAsAdminMutation__
 *
 * To run a mutation, you first call `useApproveAffiliationAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAffiliationAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAffiliationAsAdminMutation, { data, loading, error }] = useApproveAffiliationAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveAffiliationAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<ApproveAffiliationAsAdminMutation, ApproveAffiliationAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveAffiliationAsAdminMutation, ApproveAffiliationAsAdminMutationVariables>(ApproveAffiliationAsAdminDocument, options);
      }
export type ApproveAffiliationAsAdminMutationHookResult = ReturnType<typeof useApproveAffiliationAsAdminMutation>;
export type ApproveAffiliationAsAdminMutationResult = Apollo.MutationResult<ApproveAffiliationAsAdminMutation>;
export type ApproveAffiliationAsAdminMutationOptions = Apollo.BaseMutationOptions<ApproveAffiliationAsAdminMutation, ApproveAffiliationAsAdminMutationVariables>;
export const RemoveIdentityAsAdminDocument = gql`
    mutation removeIdentityAsAdmin($data: RemoveIdentityInput!) {
  removeIdentityAsAdmin(data: $data) {
    result
    errorMessage
  }
}
    `;
export type RemoveIdentityAsAdminMutationFn = Apollo.MutationFunction<RemoveIdentityAsAdminMutation, RemoveIdentityAsAdminMutationVariables>;

/**
 * __useRemoveIdentityAsAdminMutation__
 *
 * To run a mutation, you first call `useRemoveIdentityAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIdentityAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIdentityAsAdminMutation, { data, loading, error }] = useRemoveIdentityAsAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveIdentityAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<RemoveIdentityAsAdminMutation, RemoveIdentityAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveIdentityAsAdminMutation, RemoveIdentityAsAdminMutationVariables>(RemoveIdentityAsAdminDocument, options);
      }
export type RemoveIdentityAsAdminMutationHookResult = ReturnType<typeof useRemoveIdentityAsAdminMutation>;
export type RemoveIdentityAsAdminMutationResult = Apollo.MutationResult<RemoveIdentityAsAdminMutation>;
export type RemoveIdentityAsAdminMutationOptions = Apollo.BaseMutationOptions<RemoveIdentityAsAdminMutation, RemoveIdentityAsAdminMutationVariables>;
export const RemoveIdentityDocument = gql`
    mutation removeIdentity($data: RemoveIdentityInput!) {
  response: removeIdentity(data: $data) {
    updatedUser: result {
      ...CurrentUser
    }
    errorMessage
  }
}
    ${CurrentUserFragmentDoc}`;
export type RemoveIdentityMutationFn = Apollo.MutationFunction<RemoveIdentityMutation, RemoveIdentityMutationVariables>;

/**
 * __useRemoveIdentityMutation__
 *
 * To run a mutation, you first call `useRemoveIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIdentityMutation, { data, loading, error }] = useRemoveIdentityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveIdentityMutation(baseOptions?: Apollo.MutationHookOptions<RemoveIdentityMutation, RemoveIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveIdentityMutation, RemoveIdentityMutationVariables>(RemoveIdentityDocument, options);
      }
export type RemoveIdentityMutationHookResult = ReturnType<typeof useRemoveIdentityMutation>;
export type RemoveIdentityMutationResult = Apollo.MutationResult<RemoveIdentityMutation>;
export type RemoveIdentityMutationOptions = Apollo.BaseMutationOptions<RemoveIdentityMutation, RemoveIdentityMutationVariables>;
export const AdminSearchInvitesDocument = gql`
    query adminSearchInvites($data: SearchInvitesInput!, $pagination: PaginationInput) {
  results: adminSearchInvites(data: $data, pagination: $pagination) {
    totalCount
    items {
      id
      emailAddress
      inviteStatus
      createDate
      updateDate
      user {
        id
        name
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useAdminSearchInvitesQuery__
 *
 * To run a query within a React component, call `useAdminSearchInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSearchInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSearchInvitesQuery({
 *   variables: {
 *      data: // value for 'data'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminSearchInvitesQuery(baseOptions: Apollo.QueryHookOptions<AdminSearchInvitesQuery, AdminSearchInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSearchInvitesQuery, AdminSearchInvitesQueryVariables>(AdminSearchInvitesDocument, options);
      }
export function useAdminSearchInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSearchInvitesQuery, AdminSearchInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSearchInvitesQuery, AdminSearchInvitesQueryVariables>(AdminSearchInvitesDocument, options);
        }
export type AdminSearchInvitesQueryHookResult = ReturnType<typeof useAdminSearchInvitesQuery>;
export type AdminSearchInvitesLazyQueryHookResult = ReturnType<typeof useAdminSearchInvitesLazyQuery>;
export type AdminSearchInvitesQueryResult = Apollo.QueryResult<AdminSearchInvitesQuery, AdminSearchInvitesQueryVariables>;
export const DeleteInviteDocument = gql`
    mutation deleteInvite($id: String!) {
  deleteInvite(id: $id)
}
    `;
export type DeleteInviteMutationFn = Apollo.MutationFunction<DeleteInviteMutation, DeleteInviteMutationVariables>;

/**
 * __useDeleteInviteMutation__
 *
 * To run a mutation, you first call `useDeleteInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInviteMutation, { data, loading, error }] = useDeleteInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInviteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInviteMutation, DeleteInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument, options);
      }
export type DeleteInviteMutationHookResult = ReturnType<typeof useDeleteInviteMutation>;
export type DeleteInviteMutationResult = Apollo.MutationResult<DeleteInviteMutation>;
export type DeleteInviteMutationOptions = Apollo.BaseMutationOptions<DeleteInviteMutation, DeleteInviteMutationVariables>;
export const ResendInviteDocument = gql`
    mutation resendInvite($id: String!) {
  resendInvite(id: $id)
}
    `;
export type ResendInviteMutationFn = Apollo.MutationFunction<ResendInviteMutation, ResendInviteMutationVariables>;

/**
 * __useResendInviteMutation__
 *
 * To run a mutation, you first call `useResendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteMutation, { data, loading, error }] = useResendInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendInviteMutation, ResendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, options);
      }
export type ResendInviteMutationHookResult = ReturnType<typeof useResendInviteMutation>;
export type ResendInviteMutationResult = Apollo.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = Apollo.BaseMutationOptions<ResendInviteMutation, ResendInviteMutationVariables>;
export const GetJuicerPostsDocument = gql`
    query getJuicerPosts($pagination: PaginationInput) {
  response: getJuicerPosts(pagination: $pagination) {
    result {
      totalCount
      items {
        ...JuicerPostSummary
      }
    }
    errorMessage
  }
}
    ${JuicerPostSummaryFragmentDoc}`;

/**
 * __useGetJuicerPostsQuery__
 *
 * To run a query within a React component, call `useGetJuicerPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJuicerPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJuicerPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetJuicerPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetJuicerPostsQuery, GetJuicerPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJuicerPostsQuery, GetJuicerPostsQueryVariables>(GetJuicerPostsDocument, options);
      }
export function useGetJuicerPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJuicerPostsQuery, GetJuicerPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJuicerPostsQuery, GetJuicerPostsQueryVariables>(GetJuicerPostsDocument, options);
        }
export type GetJuicerPostsQueryHookResult = ReturnType<typeof useGetJuicerPostsQuery>;
export type GetJuicerPostsLazyQueryHookResult = ReturnType<typeof useGetJuicerPostsLazyQuery>;
export type GetJuicerPostsQueryResult = Apollo.QueryResult<GetJuicerPostsQuery, GetJuicerPostsQueryVariables>;
export const GetStatesDocument = gql`
    query getStates($searchTerm: String!) {
  states(searchTerm: $searchTerm) {
    ...State
  }
}
    ${StateFragmentDoc}`;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
      }
export function useGetStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
        }
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<GetStatesQuery, GetStatesQueryVariables>;
export const GetUserByMergeRequestIdDocument = gql`
    query GetUserByMergeRequestId($id: String!) {
  userProfileByMergeRequestId(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserByMergeRequestIdQuery__
 *
 * To run a query within a React component, call `useGetUserByMergeRequestIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByMergeRequestIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByMergeRequestIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByMergeRequestIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByMergeRequestIdQuery, GetUserByMergeRequestIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByMergeRequestIdQuery, GetUserByMergeRequestIdQueryVariables>(GetUserByMergeRequestIdDocument, options);
      }
export function useGetUserByMergeRequestIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByMergeRequestIdQuery, GetUserByMergeRequestIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByMergeRequestIdQuery, GetUserByMergeRequestIdQueryVariables>(GetUserByMergeRequestIdDocument, options);
        }
export type GetUserByMergeRequestIdQueryHookResult = ReturnType<typeof useGetUserByMergeRequestIdQuery>;
export type GetUserByMergeRequestIdLazyQueryHookResult = ReturnType<typeof useGetUserByMergeRequestIdLazyQuery>;
export type GetUserByMergeRequestIdQueryResult = Apollo.QueryResult<GetUserByMergeRequestIdQuery, GetUserByMergeRequestIdQueryVariables>;
export const MergeProfilesDocument = gql`
    mutation mergeProfiles($verificationId: String!, $verificationCode: String!) {
  response: mergeProfiles(verificationId: $verificationId, verificationCode: $verificationCode) {
    mergedUser {
      ...CurrentUser
    }
    mergeSuccessful
    errorMessage
  }
}
    ${CurrentUserFragmentDoc}`;
export type MergeProfilesMutationFn = Apollo.MutationFunction<MergeProfilesMutation, MergeProfilesMutationVariables>;

/**
 * __useMergeProfilesMutation__
 *
 * To run a mutation, you first call `useMergeProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeProfilesMutation, { data, loading, error }] = useMergeProfilesMutation({
 *   variables: {
 *      verificationId: // value for 'verificationId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useMergeProfilesMutation(baseOptions?: Apollo.MutationHookOptions<MergeProfilesMutation, MergeProfilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeProfilesMutation, MergeProfilesMutationVariables>(MergeProfilesDocument, options);
      }
export type MergeProfilesMutationHookResult = ReturnType<typeof useMergeProfilesMutation>;
export type MergeProfilesMutationResult = Apollo.MutationResult<MergeProfilesMutation>;
export type MergeProfilesMutationOptions = Apollo.BaseMutationOptions<MergeProfilesMutation, MergeProfilesMutationVariables>;
export const ValidateProfileMergeDocument = gql`
    query validateProfileMerge($verificationId: String!, $verificationCode: String!) {
  response: validateProfileMerge(verificationId: $verificationId, verificationCode: $verificationCode) {
    mergeReady
    verificationId
    errorMessage
  }
}
    `;

/**
 * __useValidateProfileMergeQuery__
 *
 * To run a query within a React component, call `useValidateProfileMergeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateProfileMergeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateProfileMergeQuery({
 *   variables: {
 *      verificationId: // value for 'verificationId'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useValidateProfileMergeQuery(baseOptions: Apollo.QueryHookOptions<ValidateProfileMergeQuery, ValidateProfileMergeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateProfileMergeQuery, ValidateProfileMergeQueryVariables>(ValidateProfileMergeDocument, options);
      }
export function useValidateProfileMergeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateProfileMergeQuery, ValidateProfileMergeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateProfileMergeQuery, ValidateProfileMergeQueryVariables>(ValidateProfileMergeDocument, options);
        }
export type ValidateProfileMergeQueryHookResult = ReturnType<typeof useValidateProfileMergeQuery>;
export type ValidateProfileMergeLazyQueryHookResult = ReturnType<typeof useValidateProfileMergeLazyQuery>;
export type ValidateProfileMergeQueryResult = Apollo.QueryResult<ValidateProfileMergeQuery, ValidateProfileMergeQueryVariables>;
export const InitiateProfileMergeDocument = gql`
    mutation initiateProfileMerge($targetEmail: String!) {
  response: initiateProfileMerge(targetEmail: $targetEmail) {
    mergeValid
    mergeInitiatorId
    verificationId
    emailToMerge
    errorMessage
  }
}
    `;
export type InitiateProfileMergeMutationFn = Apollo.MutationFunction<InitiateProfileMergeMutation, InitiateProfileMergeMutationVariables>;

/**
 * __useInitiateProfileMergeMutation__
 *
 * To run a mutation, you first call `useInitiateProfileMergeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateProfileMergeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateProfileMergeMutation, { data, loading, error }] = useInitiateProfileMergeMutation({
 *   variables: {
 *      targetEmail: // value for 'targetEmail'
 *   },
 * });
 */
export function useInitiateProfileMergeMutation(baseOptions?: Apollo.MutationHookOptions<InitiateProfileMergeMutation, InitiateProfileMergeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateProfileMergeMutation, InitiateProfileMergeMutationVariables>(InitiateProfileMergeDocument, options);
      }
export type InitiateProfileMergeMutationHookResult = ReturnType<typeof useInitiateProfileMergeMutation>;
export type InitiateProfileMergeMutationResult = Apollo.MutationResult<InitiateProfileMergeMutation>;
export type InitiateProfileMergeMutationOptions = Apollo.BaseMutationOptions<InitiateProfileMergeMutation, InitiateProfileMergeMutationVariables>;
export const ResendProfileMergeEmailDocument = gql`
    mutation resendProfileMergeEmail($verificationId: String!) {
  response: resendProfileMergeEmail(verificationId: $verificationId) {
    mergeValid
    verificationId
    errorMessage
  }
}
    `;
export type ResendProfileMergeEmailMutationFn = Apollo.MutationFunction<ResendProfileMergeEmailMutation, ResendProfileMergeEmailMutationVariables>;

/**
 * __useResendProfileMergeEmailMutation__
 *
 * To run a mutation, you first call `useResendProfileMergeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendProfileMergeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendProfileMergeEmailMutation, { data, loading, error }] = useResendProfileMergeEmailMutation({
 *   variables: {
 *      verificationId: // value for 'verificationId'
 *   },
 * });
 */
export function useResendProfileMergeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendProfileMergeEmailMutation, ResendProfileMergeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendProfileMergeEmailMutation, ResendProfileMergeEmailMutationVariables>(ResendProfileMergeEmailDocument, options);
      }
export type ResendProfileMergeEmailMutationHookResult = ReturnType<typeof useResendProfileMergeEmailMutation>;
export type ResendProfileMergeEmailMutationResult = Apollo.MutationResult<ResendProfileMergeEmailMutation>;
export type ResendProfileMergeEmailMutationOptions = Apollo.BaseMutationOptions<ResendProfileMergeEmailMutation, ResendProfileMergeEmailMutationVariables>;
export const GetUnresolvedReportCountDocument = gql`
    query getUnresolvedReportCount {
  getUnresolvedReportCount
}
    `;

/**
 * __useGetUnresolvedReportCountQuery__
 *
 * To run a query within a React component, call `useGetUnresolvedReportCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnresolvedReportCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnresolvedReportCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnresolvedReportCountQuery(baseOptions?: Apollo.QueryHookOptions<GetUnresolvedReportCountQuery, GetUnresolvedReportCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnresolvedReportCountQuery, GetUnresolvedReportCountQueryVariables>(GetUnresolvedReportCountDocument, options);
      }
export function useGetUnresolvedReportCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnresolvedReportCountQuery, GetUnresolvedReportCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnresolvedReportCountQuery, GetUnresolvedReportCountQueryVariables>(GetUnresolvedReportCountDocument, options);
        }
export type GetUnresolvedReportCountQueryHookResult = ReturnType<typeof useGetUnresolvedReportCountQuery>;
export type GetUnresolvedReportCountLazyQueryHookResult = ReturnType<typeof useGetUnresolvedReportCountLazyQuery>;
export type GetUnresolvedReportCountQueryResult = Apollo.QueryResult<GetUnresolvedReportCountQuery, GetUnresolvedReportCountQueryVariables>;
export const GetEntityReportCountDocument = gql`
    query getEntityReportCount($entityFilter: EntityFilterInput) {
  getEntityReportCount(entityFilter: $entityFilter) {
    reportCount
    unresolvedCount
    resolvedCount
  }
}
    `;

/**
 * __useGetEntityReportCountQuery__
 *
 * To run a query within a React component, call `useGetEntityReportCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityReportCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityReportCountQuery({
 *   variables: {
 *      entityFilter: // value for 'entityFilter'
 *   },
 * });
 */
export function useGetEntityReportCountQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityReportCountQuery, GetEntityReportCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityReportCountQuery, GetEntityReportCountQueryVariables>(GetEntityReportCountDocument, options);
      }
export function useGetEntityReportCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityReportCountQuery, GetEntityReportCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityReportCountQuery, GetEntityReportCountQueryVariables>(GetEntityReportCountDocument, options);
        }
export type GetEntityReportCountQueryHookResult = ReturnType<typeof useGetEntityReportCountQuery>;
export type GetEntityReportCountLazyQueryHookResult = ReturnType<typeof useGetEntityReportCountLazyQuery>;
export type GetEntityReportCountQueryResult = Apollo.QueryResult<GetEntityReportCountQuery, GetEntityReportCountQueryVariables>;
export const GetReportsDocument = gql`
    query getReports($pagination: PaginationInput, $sort: SortInput, $entityFilter: EntityFilterInput, $typeFilter: TypeFilterInput, $statusFilter: StatusFilterInput, $searchTerm: String!) {
  reports: getReports(pagination: $pagination, sort: $sort, typeFilter: $typeFilter, entityFilter: $entityFilter, statusFilter: $statusFilter, searchTerm: $searchTerm) {
    items {
      ...Report
    }
    unresolvedCount
    resolvedCount
    totalCount
    reportCount
  }
}
    ${ReportFragmentDoc}`;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      entityFilter: // value for 'entityFilter'
 *      typeFilter: // value for 'typeFilter'
 *      statusFilter: // value for 'statusFilter'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetReportsQuery(baseOptions: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
      }
export function useGetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetBlocksDocument = gql`
    query getBlocks($pagination: PaginationInput, $sort: SortInput, $entityFilter: EntityFilterInput, $typeFilter: TypeFilterInput, $statusFilter: StatusFilterInput, $searchTerm: String!) {
  blocks: getBlocks(pagination: $pagination, sort: $sort, typeFilter: $typeFilter, entityFilter: $entityFilter, statusFilter: $statusFilter, searchTerm: $searchTerm) {
    items {
      ...Block
    }
    totalCount
    unresolvedCount
    resolvedCount
    blockCount
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useGetBlocksQuery__
 *
 * To run a query within a React component, call `useGetBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlocksQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *      entityFilter: // value for 'entityFilter'
 *      typeFilter: // value for 'typeFilter'
 *      statusFilter: // value for 'statusFilter'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useGetBlocksQuery(baseOptions: Apollo.QueryHookOptions<GetBlocksQuery, GetBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlocksQuery, GetBlocksQueryVariables>(GetBlocksDocument, options);
      }
export function useGetBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlocksQuery, GetBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlocksQuery, GetBlocksQueryVariables>(GetBlocksDocument, options);
        }
export type GetBlocksQueryHookResult = ReturnType<typeof useGetBlocksQuery>;
export type GetBlocksLazyQueryHookResult = ReturnType<typeof useGetBlocksLazyQuery>;
export type GetBlocksQueryResult = Apollo.QueryResult<GetBlocksQuery, GetBlocksQueryVariables>;
export const ReportDiscussionDocument = gql`
    mutation reportDiscussion($discussionId: String!, $message: String!) {
  reportDiscussion(discussionId: $discussionId, message: $message) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;
export type ReportDiscussionMutationFn = Apollo.MutationFunction<ReportDiscussionMutation, ReportDiscussionMutationVariables>;

/**
 * __useReportDiscussionMutation__
 *
 * To run a mutation, you first call `useReportDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportDiscussionMutation, { data, loading, error }] = useReportDiscussionMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useReportDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<ReportDiscussionMutation, ReportDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportDiscussionMutation, ReportDiscussionMutationVariables>(ReportDiscussionDocument, options);
      }
export type ReportDiscussionMutationHookResult = ReturnType<typeof useReportDiscussionMutation>;
export type ReportDiscussionMutationResult = Apollo.MutationResult<ReportDiscussionMutation>;
export type ReportDiscussionMutationOptions = Apollo.BaseMutationOptions<ReportDiscussionMutation, ReportDiscussionMutationVariables>;
export const BlockDiscussionDocument = gql`
    mutation blockDiscussion($discussionId: String!, $message: String!) {
  blockDiscussion(discussionId: $discussionId, message: $message) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;
export type BlockDiscussionMutationFn = Apollo.MutationFunction<BlockDiscussionMutation, BlockDiscussionMutationVariables>;

/**
 * __useBlockDiscussionMutation__
 *
 * To run a mutation, you first call `useBlockDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockDiscussionMutation, { data, loading, error }] = useBlockDiscussionMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBlockDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<BlockDiscussionMutation, BlockDiscussionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockDiscussionMutation, BlockDiscussionMutationVariables>(BlockDiscussionDocument, options);
      }
export type BlockDiscussionMutationHookResult = ReturnType<typeof useBlockDiscussionMutation>;
export type BlockDiscussionMutationResult = Apollo.MutationResult<BlockDiscussionMutation>;
export type BlockDiscussionMutationOptions = Apollo.BaseMutationOptions<BlockDiscussionMutation, BlockDiscussionMutationVariables>;
export const ReportPostDocument = gql`
    mutation reportPost($discussionId: String!, $postId: String!, $message: String!) {
  reportPost(discussionId: $discussionId, postId: $postId, message: $message) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type ReportPostMutationFn = Apollo.MutationFunction<ReportPostMutation, ReportPostMutationVariables>;

/**
 * __useReportPostMutation__
 *
 * To run a mutation, you first call `useReportPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportPostMutation, { data, loading, error }] = useReportPostMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *      postId: // value for 'postId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useReportPostMutation(baseOptions?: Apollo.MutationHookOptions<ReportPostMutation, ReportPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportPostMutation, ReportPostMutationVariables>(ReportPostDocument, options);
      }
export type ReportPostMutationHookResult = ReturnType<typeof useReportPostMutation>;
export type ReportPostMutationResult = Apollo.MutationResult<ReportPostMutation>;
export type ReportPostMutationOptions = Apollo.BaseMutationOptions<ReportPostMutation, ReportPostMutationVariables>;
export const ReportTechnologyDocument = gql`
    mutation reportTechnology($technologyId: String!, $message: String!) {
  reportTechnology(technologyId: $technologyId, message: $message) {
    ...Technology
  }
}
    ${TechnologyFragmentDoc}`;
export type ReportTechnologyMutationFn = Apollo.MutationFunction<ReportTechnologyMutation, ReportTechnologyMutationVariables>;

/**
 * __useReportTechnologyMutation__
 *
 * To run a mutation, you first call `useReportTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportTechnologyMutation, { data, loading, error }] = useReportTechnologyMutation({
 *   variables: {
 *      technologyId: // value for 'technologyId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useReportTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<ReportTechnologyMutation, ReportTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportTechnologyMutation, ReportTechnologyMutationVariables>(ReportTechnologyDocument, options);
      }
export type ReportTechnologyMutationHookResult = ReturnType<typeof useReportTechnologyMutation>;
export type ReportTechnologyMutationResult = Apollo.MutationResult<ReportTechnologyMutation>;
export type ReportTechnologyMutationOptions = Apollo.BaseMutationOptions<ReportTechnologyMutation, ReportTechnologyMutationVariables>;
export const BlockPostDocument = gql`
    mutation blockPost($discussionId: String!, $postId: String!, $message: String!) {
  blockPost(discussionId: $discussionId, postId: $postId, message: $message) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type BlockPostMutationFn = Apollo.MutationFunction<BlockPostMutation, BlockPostMutationVariables>;

/**
 * __useBlockPostMutation__
 *
 * To run a mutation, you first call `useBlockPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockPostMutation, { data, loading, error }] = useBlockPostMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *      postId: // value for 'postId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBlockPostMutation(baseOptions?: Apollo.MutationHookOptions<BlockPostMutation, BlockPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockPostMutation, BlockPostMutationVariables>(BlockPostDocument, options);
      }
export type BlockPostMutationHookResult = ReturnType<typeof useBlockPostMutation>;
export type BlockPostMutationResult = Apollo.MutationResult<BlockPostMutation>;
export type BlockPostMutationOptions = Apollo.BaseMutationOptions<BlockPostMutation, BlockPostMutationVariables>;
export const BlockTechnologyDocument = gql`
    mutation blockTechnology($technologyId: String!, $message: String!) {
  blockTechnology(technologyId: $technologyId, message: $message) {
    ...Technology
  }
}
    ${TechnologyFragmentDoc}`;
export type BlockTechnologyMutationFn = Apollo.MutationFunction<BlockTechnologyMutation, BlockTechnologyMutationVariables>;

/**
 * __useBlockTechnologyMutation__
 *
 * To run a mutation, you first call `useBlockTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockTechnologyMutation, { data, loading, error }] = useBlockTechnologyMutation({
 *   variables: {
 *      technologyId: // value for 'technologyId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBlockTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<BlockTechnologyMutation, BlockTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockTechnologyMutation, BlockTechnologyMutationVariables>(BlockTechnologyDocument, options);
      }
export type BlockTechnologyMutationHookResult = ReturnType<typeof useBlockTechnologyMutation>;
export type BlockTechnologyMutationResult = Apollo.MutationResult<BlockTechnologyMutation>;
export type BlockTechnologyMutationOptions = Apollo.BaseMutationOptions<BlockTechnologyMutation, BlockTechnologyMutationVariables>;
export const ReportOrganizationDocument = gql`
    mutation reportOrganization($organizationId: String!, $message: String!) {
  reportOrganization(organizationId: $organizationId, message: $message) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type ReportOrganizationMutationFn = Apollo.MutationFunction<ReportOrganizationMutation, ReportOrganizationMutationVariables>;

/**
 * __useReportOrganizationMutation__
 *
 * To run a mutation, you first call `useReportOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportOrganizationMutation, { data, loading, error }] = useReportOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useReportOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ReportOrganizationMutation, ReportOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportOrganizationMutation, ReportOrganizationMutationVariables>(ReportOrganizationDocument, options);
      }
export type ReportOrganizationMutationHookResult = ReturnType<typeof useReportOrganizationMutation>;
export type ReportOrganizationMutationResult = Apollo.MutationResult<ReportOrganizationMutation>;
export type ReportOrganizationMutationOptions = Apollo.BaseMutationOptions<ReportOrganizationMutation, ReportOrganizationMutationVariables>;
export const BlockOrganizationDocument = gql`
    mutation blockOrganization($organizationId: String!, $message: String!) {
  blockOrganization(organizationId: $organizationId, message: $message) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type BlockOrganizationMutationFn = Apollo.MutationFunction<BlockOrganizationMutation, BlockOrganizationMutationVariables>;

/**
 * __useBlockOrganizationMutation__
 *
 * To run a mutation, you first call `useBlockOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockOrganizationMutation, { data, loading, error }] = useBlockOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBlockOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<BlockOrganizationMutation, BlockOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockOrganizationMutation, BlockOrganizationMutationVariables>(BlockOrganizationDocument, options);
      }
export type BlockOrganizationMutationHookResult = ReturnType<typeof useBlockOrganizationMutation>;
export type BlockOrganizationMutationResult = Apollo.MutationResult<BlockOrganizationMutation>;
export type BlockOrganizationMutationOptions = Apollo.BaseMutationOptions<BlockOrganizationMutation, BlockOrganizationMutationVariables>;
export const ReportUserDocument = gql`
    mutation reportUser($userId: String!, $message: String!) {
  reportUser(userId: $userId, message: $message) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type ReportUserMutationFn = Apollo.MutationFunction<ReportUserMutation, ReportUserMutationVariables>;

/**
 * __useReportUserMutation__
 *
 * To run a mutation, you first call `useReportUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportUserMutation, { data, loading, error }] = useReportUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useReportUserMutation(baseOptions?: Apollo.MutationHookOptions<ReportUserMutation, ReportUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportUserMutation, ReportUserMutationVariables>(ReportUserDocument, options);
      }
export type ReportUserMutationHookResult = ReturnType<typeof useReportUserMutation>;
export type ReportUserMutationResult = Apollo.MutationResult<ReportUserMutation>;
export type ReportUserMutationOptions = Apollo.BaseMutationOptions<ReportUserMutation, ReportUserMutationVariables>;
export const BlockUserDocument = gql`
    mutation blockUser($userId: String!, $message: String!) {
  blockUser(userId: $userId, message: $message) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type BlockUserMutationFn = Apollo.MutationFunction<BlockUserMutation, BlockUserMutationVariables>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(BlockUserDocument, options);
      }
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<BlockUserMutation, BlockUserMutationVariables>;
export const ResolveReportDocument = gql`
    mutation resolveReport($reportId: String!) {
  resolveReport(reportId: $reportId)
}
    `;
export type ResolveReportMutationFn = Apollo.MutationFunction<ResolveReportMutation, ResolveReportMutationVariables>;

/**
 * __useResolveReportMutation__
 *
 * To run a mutation, you first call `useResolveReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveReportMutation, { data, loading, error }] = useResolveReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useResolveReportMutation(baseOptions?: Apollo.MutationHookOptions<ResolveReportMutation, ResolveReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveReportMutation, ResolveReportMutationVariables>(ResolveReportDocument, options);
      }
export type ResolveReportMutationHookResult = ReturnType<typeof useResolveReportMutation>;
export type ResolveReportMutationResult = Apollo.MutationResult<ResolveReportMutation>;
export type ResolveReportMutationOptions = Apollo.BaseMutationOptions<ResolveReportMutation, ResolveReportMutationVariables>;
export const UnresolveReportDocument = gql`
    mutation unresolveReport($reportId: String!) {
  unresolveReport(reportId: $reportId)
}
    `;
export type UnresolveReportMutationFn = Apollo.MutationFunction<UnresolveReportMutation, UnresolveReportMutationVariables>;

/**
 * __useUnresolveReportMutation__
 *
 * To run a mutation, you first call `useUnresolveReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnresolveReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unresolveReportMutation, { data, loading, error }] = useUnresolveReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useUnresolveReportMutation(baseOptions?: Apollo.MutationHookOptions<UnresolveReportMutation, UnresolveReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnresolveReportMutation, UnresolveReportMutationVariables>(UnresolveReportDocument, options);
      }
export type UnresolveReportMutationHookResult = ReturnType<typeof useUnresolveReportMutation>;
export type UnresolveReportMutationResult = Apollo.MutationResult<UnresolveReportMutation>;
export type UnresolveReportMutationOptions = Apollo.BaseMutationOptions<UnresolveReportMutation, UnresolveReportMutationVariables>;
export const GetActiveBlockDocument = gql`
    query getActiveBlock($entityFilter: EntityFilterInput!) {
  getActiveBlock(entityFilter: $entityFilter) {
    ...Block
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useGetActiveBlockQuery__
 *
 * To run a query within a React component, call `useGetActiveBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveBlockQuery({
 *   variables: {
 *      entityFilter: // value for 'entityFilter'
 *   },
 * });
 */
export function useGetActiveBlockQuery(baseOptions: Apollo.QueryHookOptions<GetActiveBlockQuery, GetActiveBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveBlockQuery, GetActiveBlockQueryVariables>(GetActiveBlockDocument, options);
      }
export function useGetActiveBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveBlockQuery, GetActiveBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveBlockQuery, GetActiveBlockQueryVariables>(GetActiveBlockDocument, options);
        }
export type GetActiveBlockQueryHookResult = ReturnType<typeof useGetActiveBlockQuery>;
export type GetActiveBlockLazyQueryHookResult = ReturnType<typeof useGetActiveBlockLazyQuery>;
export type GetActiveBlockQueryResult = Apollo.QueryResult<GetActiveBlockQuery, GetActiveBlockQueryVariables>;
export const ResolveDiscussionBlockDocument = gql`
    mutation resolveDiscussionBlock($discussionId: String!) {
  resolveDiscussionBlock(discussionId: $discussionId) {
    ...Discussion
  }
}
    ${DiscussionFragmentDoc}`;
export type ResolveDiscussionBlockMutationFn = Apollo.MutationFunction<ResolveDiscussionBlockMutation, ResolveDiscussionBlockMutationVariables>;

/**
 * __useResolveDiscussionBlockMutation__
 *
 * To run a mutation, you first call `useResolveDiscussionBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveDiscussionBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveDiscussionBlockMutation, { data, loading, error }] = useResolveDiscussionBlockMutation({
 *   variables: {
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useResolveDiscussionBlockMutation(baseOptions?: Apollo.MutationHookOptions<ResolveDiscussionBlockMutation, ResolveDiscussionBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveDiscussionBlockMutation, ResolveDiscussionBlockMutationVariables>(ResolveDiscussionBlockDocument, options);
      }
export type ResolveDiscussionBlockMutationHookResult = ReturnType<typeof useResolveDiscussionBlockMutation>;
export type ResolveDiscussionBlockMutationResult = Apollo.MutationResult<ResolveDiscussionBlockMutation>;
export type ResolveDiscussionBlockMutationOptions = Apollo.BaseMutationOptions<ResolveDiscussionBlockMutation, ResolveDiscussionBlockMutationVariables>;
export const ResolvePostBlockDocument = gql`
    mutation resolvePostBlock($postId: String!, $discussionId: String!) {
  resolvePostBlock(postId: $postId, discussionId: $discussionId) {
    ...Post
  }
}
    ${PostFragmentDoc}`;
export type ResolvePostBlockMutationFn = Apollo.MutationFunction<ResolvePostBlockMutation, ResolvePostBlockMutationVariables>;

/**
 * __useResolvePostBlockMutation__
 *
 * To run a mutation, you first call `useResolvePostBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolvePostBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolvePostBlockMutation, { data, loading, error }] = useResolvePostBlockMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      discussionId: // value for 'discussionId'
 *   },
 * });
 */
export function useResolvePostBlockMutation(baseOptions?: Apollo.MutationHookOptions<ResolvePostBlockMutation, ResolvePostBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolvePostBlockMutation, ResolvePostBlockMutationVariables>(ResolvePostBlockDocument, options);
      }
export type ResolvePostBlockMutationHookResult = ReturnType<typeof useResolvePostBlockMutation>;
export type ResolvePostBlockMutationResult = Apollo.MutationResult<ResolvePostBlockMutation>;
export type ResolvePostBlockMutationOptions = Apollo.BaseMutationOptions<ResolvePostBlockMutation, ResolvePostBlockMutationVariables>;
export const ResolveUserBlockDocument = gql`
    mutation resolveUserBlock($userId: String!) {
  resolveUserBlock(userId: $userId) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type ResolveUserBlockMutationFn = Apollo.MutationFunction<ResolveUserBlockMutation, ResolveUserBlockMutationVariables>;

/**
 * __useResolveUserBlockMutation__
 *
 * To run a mutation, you first call `useResolveUserBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveUserBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveUserBlockMutation, { data, loading, error }] = useResolveUserBlockMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResolveUserBlockMutation(baseOptions?: Apollo.MutationHookOptions<ResolveUserBlockMutation, ResolveUserBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveUserBlockMutation, ResolveUserBlockMutationVariables>(ResolveUserBlockDocument, options);
      }
export type ResolveUserBlockMutationHookResult = ReturnType<typeof useResolveUserBlockMutation>;
export type ResolveUserBlockMutationResult = Apollo.MutationResult<ResolveUserBlockMutation>;
export type ResolveUserBlockMutationOptions = Apollo.BaseMutationOptions<ResolveUserBlockMutation, ResolveUserBlockMutationVariables>;
export const ResolveTechnologyBlockDocument = gql`
    mutation resolveTechnologyBlock($technologyId: String!) {
  resolveTechnologyBlock(technologyId: $technologyId) {
    ...Technology
  }
}
    ${TechnologyFragmentDoc}`;
export type ResolveTechnologyBlockMutationFn = Apollo.MutationFunction<ResolveTechnologyBlockMutation, ResolveTechnologyBlockMutationVariables>;

/**
 * __useResolveTechnologyBlockMutation__
 *
 * To run a mutation, you first call `useResolveTechnologyBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveTechnologyBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveTechnologyBlockMutation, { data, loading, error }] = useResolveTechnologyBlockMutation({
 *   variables: {
 *      technologyId: // value for 'technologyId'
 *   },
 * });
 */
export function useResolveTechnologyBlockMutation(baseOptions?: Apollo.MutationHookOptions<ResolveTechnologyBlockMutation, ResolveTechnologyBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveTechnologyBlockMutation, ResolveTechnologyBlockMutationVariables>(ResolveTechnologyBlockDocument, options);
      }
export type ResolveTechnologyBlockMutationHookResult = ReturnType<typeof useResolveTechnologyBlockMutation>;
export type ResolveTechnologyBlockMutationResult = Apollo.MutationResult<ResolveTechnologyBlockMutation>;
export type ResolveTechnologyBlockMutationOptions = Apollo.BaseMutationOptions<ResolveTechnologyBlockMutation, ResolveTechnologyBlockMutationVariables>;
export const ResolveOrganizationBlockDocument = gql`
    mutation resolveOrganizationBlock($organizationId: String!) {
  resolveOrganizationBlock(organizationId: $organizationId) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;
export type ResolveOrganizationBlockMutationFn = Apollo.MutationFunction<ResolveOrganizationBlockMutation, ResolveOrganizationBlockMutationVariables>;

/**
 * __useResolveOrganizationBlockMutation__
 *
 * To run a mutation, you first call `useResolveOrganizationBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveOrganizationBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveOrganizationBlockMutation, { data, loading, error }] = useResolveOrganizationBlockMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useResolveOrganizationBlockMutation(baseOptions?: Apollo.MutationHookOptions<ResolveOrganizationBlockMutation, ResolveOrganizationBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveOrganizationBlockMutation, ResolveOrganizationBlockMutationVariables>(ResolveOrganizationBlockDocument, options);
      }
export type ResolveOrganizationBlockMutationHookResult = ReturnType<typeof useResolveOrganizationBlockMutation>;
export type ResolveOrganizationBlockMutationResult = Apollo.MutationResult<ResolveOrganizationBlockMutation>;
export type ResolveOrganizationBlockMutationOptions = Apollo.BaseMutationOptions<ResolveOrganizationBlockMutation, ResolveOrganizationBlockMutationVariables>;
export const GetNewNotificationsCountDocument = gql`
    query getNewNotificationsCount {
  getNewNotificationsCount
}
    `;

/**
 * __useGetNewNotificationsCountQuery__
 *
 * To run a query within a React component, call `useGetNewNotificationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewNotificationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewNotificationsCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewNotificationsCountQuery(baseOptions?: Apollo.QueryHookOptions<GetNewNotificationsCountQuery, GetNewNotificationsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewNotificationsCountQuery, GetNewNotificationsCountQueryVariables>(GetNewNotificationsCountDocument, options);
      }
export function useGetNewNotificationsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewNotificationsCountQuery, GetNewNotificationsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewNotificationsCountQuery, GetNewNotificationsCountQueryVariables>(GetNewNotificationsCountDocument, options);
        }
export type GetNewNotificationsCountQueryHookResult = ReturnType<typeof useGetNewNotificationsCountQuery>;
export type GetNewNotificationsCountLazyQueryHookResult = ReturnType<typeof useGetNewNotificationsCountLazyQuery>;
export type GetNewNotificationsCountQueryResult = Apollo.QueryResult<GetNewNotificationsCountQuery, GetNewNotificationsCountQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($notificationInput: NotificationInput!, $pagination: PaginationInput) {
  results: getNotifications(notificationInput: $notificationInput, pagination: $pagination) {
    totalCount
    allCount
    readCount
    unreadCount
    flaggedCount
    items {
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      notificationInput: // value for 'notificationInput'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const UpdateNotificationStatusDocument = gql`
    mutation updateNotificationStatus($id: String!, $data: UpdateNotificationInput!) {
  updateNotificationStatus(id: $id, data: $data) {
    ...NotificationStatus
  }
}
    ${NotificationStatusFragmentDoc}`;
export type UpdateNotificationStatusMutationFn = Apollo.MutationFunction<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>;

/**
 * __useUpdateNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationStatusMutation, { data, loading, error }] = useUpdateNotificationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>(UpdateNotificationStatusDocument, options);
      }
export type UpdateNotificationStatusMutationHookResult = ReturnType<typeof useUpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationResult = Apollo.MutationResult<UpdateNotificationStatusMutation>;
export type UpdateNotificationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationStatusMutation, UpdateNotificationStatusMutationVariables>;
export const MarkAllAsDocument = gql`
    mutation markAllAs($updateNotificationInput: UpdateNotificationInput!) {
  markAllAs(updateNotificationInput: $updateNotificationInput)
}
    `;
export type MarkAllAsMutationFn = Apollo.MutationFunction<MarkAllAsMutation, MarkAllAsMutationVariables>;

/**
 * __useMarkAllAsMutation__
 *
 * To run a mutation, you first call `useMarkAllAsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsMutation, { data, loading, error }] = useMarkAllAsMutation({
 *   variables: {
 *      updateNotificationInput: // value for 'updateNotificationInput'
 *   },
 * });
 */
export function useMarkAllAsMutation(baseOptions?: Apollo.MutationHookOptions<MarkAllAsMutation, MarkAllAsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkAllAsMutation, MarkAllAsMutationVariables>(MarkAllAsDocument, options);
      }
export type MarkAllAsMutationHookResult = ReturnType<typeof useMarkAllAsMutation>;
export type MarkAllAsMutationResult = Apollo.MutationResult<MarkAllAsMutation>;
export type MarkAllAsMutationOptions = Apollo.BaseMutationOptions<MarkAllAsMutation, MarkAllAsMutationVariables>;
export const DeleteAllDocument = gql`
    mutation deleteAll($notificationInput: NotificationInput!) {
  deleteAll(notificationInput: $notificationInput)
}
    `;
export type DeleteAllMutationFn = Apollo.MutationFunction<DeleteAllMutation, DeleteAllMutationVariables>;

/**
 * __useDeleteAllMutation__
 *
 * To run a mutation, you first call `useDeleteAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllMutation, { data, loading, error }] = useDeleteAllMutation({
 *   variables: {
 *      notificationInput: // value for 'notificationInput'
 *   },
 * });
 */
export function useDeleteAllMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllMutation, DeleteAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllMutation, DeleteAllMutationVariables>(DeleteAllDocument, options);
      }
export type DeleteAllMutationHookResult = ReturnType<typeof useDeleteAllMutation>;
export type DeleteAllMutationResult = Apollo.MutationResult<DeleteAllMutation>;
export type DeleteAllMutationOptions = Apollo.BaseMutationOptions<DeleteAllMutation, DeleteAllMutationVariables>;
export const RemoveNotificationDocument = gql`
    mutation removeNotification($id: String!) {
  removeNotification(id: $id)
}
    `;
export type RemoveNotificationMutationFn = Apollo.MutationFunction<RemoveNotificationMutation, RemoveNotificationMutationVariables>;

/**
 * __useRemoveNotificationMutation__
 *
 * To run a mutation, you first call `useRemoveNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNotificationMutation, { data, loading, error }] = useRemoveNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveNotificationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveNotificationMutation, RemoveNotificationMutationVariables>(RemoveNotificationDocument, options);
      }
export type RemoveNotificationMutationHookResult = ReturnType<typeof useRemoveNotificationMutation>;
export type RemoveNotificationMutationResult = Apollo.MutationResult<RemoveNotificationMutation>;
export type RemoveNotificationMutationOptions = Apollo.BaseMutationOptions<RemoveNotificationMutation, RemoveNotificationMutationVariables>;
export const OnNotificationDocument = gql`
    subscription onNotification {
  notificationSubscription {
    ...NotificationSubscription
  }
}
    ${NotificationSubscriptionFragmentDoc}`;

/**
 * __useOnNotificationSubscription__
 *
 * To run a query within a React component, call `useOnNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnNotificationSubscription, OnNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnNotificationSubscription, OnNotificationSubscriptionVariables>(OnNotificationDocument, options);
      }
export type OnNotificationSubscriptionHookResult = ReturnType<typeof useOnNotificationSubscription>;
export type OnNotificationSubscriptionResult = Apollo.SubscriptionResult<OnNotificationSubscription>;
export const GetOrganizationDocument = gql`
    query GetOrganization($id: String!) {
  organization(id: $id) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const OrganizationSearchDocument = gql`
    query organizationSearch($searchTerm: String!, $isCompany: Boolean, $sort: SortInput, $pagination: PaginationInput) {
  results: searchOrganizations(searchTerm: $searchTerm, isCompany: $isCompany, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...Organization
    }
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useOrganizationSearchQuery__
 *
 * To run a query within a React component, call `useOrganizationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      isCompany: // value for 'isCompany'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrganizationSearchQuery(baseOptions: Apollo.QueryHookOptions<OrganizationSearchQuery, OrganizationSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSearchQuery, OrganizationSearchQueryVariables>(OrganizationSearchDocument, options);
      }
export function useOrganizationSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSearchQuery, OrganizationSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSearchQuery, OrganizationSearchQueryVariables>(OrganizationSearchDocument, options);
        }
export type OrganizationSearchQueryHookResult = ReturnType<typeof useOrganizationSearchQuery>;
export type OrganizationSearchLazyQueryHookResult = ReturnType<typeof useOrganizationSearchLazyQuery>;
export type OrganizationSearchQueryResult = Apollo.QueryResult<OrganizationSearchQuery, OrganizationSearchQueryVariables>;
export const SearchOnboardingOrganizationsDocument = gql`
    query searchOnboardingOrganizations($searchTerm: String!, $parentId: String, $sort: SortInput) {
  results: searchOnboardingOrganizations(searchTerm: $searchTerm, parentId: $parentId, sort: $sort) {
    ...Organization
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useSearchOnboardingOrganizationsQuery__
 *
 * To run a query within a React component, call `useSearchOnboardingOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOnboardingOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOnboardingOrganizationsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      parentId: // value for 'parentId'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchOnboardingOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<SearchOnboardingOrganizationsQuery, SearchOnboardingOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchOnboardingOrganizationsQuery, SearchOnboardingOrganizationsQueryVariables>(SearchOnboardingOrganizationsDocument, options);
      }
export function useSearchOnboardingOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchOnboardingOrganizationsQuery, SearchOnboardingOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchOnboardingOrganizationsQuery, SearchOnboardingOrganizationsQueryVariables>(SearchOnboardingOrganizationsDocument, options);
        }
export type SearchOnboardingOrganizationsQueryHookResult = ReturnType<typeof useSearchOnboardingOrganizationsQuery>;
export type SearchOnboardingOrganizationsLazyQueryHookResult = ReturnType<typeof useSearchOnboardingOrganizationsLazyQuery>;
export type SearchOnboardingOrganizationsQueryResult = Apollo.QueryResult<SearchOnboardingOrganizationsQuery, SearchOnboardingOrganizationsQueryVariables>;
export const SearchOnboardingCompaniesDocument = gql`
    query searchOnboardingCompanies($searchTerm: String!, $sort: SortInput, $pagination: PaginationInput) {
  results: searchOnboardingCompanies(searchTerm: $searchTerm, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...Organization
    }
  }
}
    ${OrganizationFragmentDoc}`;

/**
 * __useSearchOnboardingCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchOnboardingCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOnboardingCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOnboardingCompaniesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchOnboardingCompaniesQuery(baseOptions: Apollo.QueryHookOptions<SearchOnboardingCompaniesQuery, SearchOnboardingCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchOnboardingCompaniesQuery, SearchOnboardingCompaniesQueryVariables>(SearchOnboardingCompaniesDocument, options);
      }
export function useSearchOnboardingCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchOnboardingCompaniesQuery, SearchOnboardingCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchOnboardingCompaniesQuery, SearchOnboardingCompaniesQueryVariables>(SearchOnboardingCompaniesDocument, options);
        }
export type SearchOnboardingCompaniesQueryHookResult = ReturnType<typeof useSearchOnboardingCompaniesQuery>;
export type SearchOnboardingCompaniesLazyQueryHookResult = ReturnType<typeof useSearchOnboardingCompaniesLazyQuery>;
export type SearchOnboardingCompaniesQueryResult = Apollo.QueryResult<SearchOnboardingCompaniesQuery, SearchOnboardingCompaniesQueryVariables>;
export const CompanyExistsDocument = gql`
    mutation companyExists($data: ValidateOrganizationInput!) {
  companyExists(data: $data) {
    organization: result {
      ...Organization
    }
    errorMessage
  }
}
    ${OrganizationFragmentDoc}`;
export type CompanyExistsMutationFn = Apollo.MutationFunction<CompanyExistsMutation, CompanyExistsMutationVariables>;

/**
 * __useCompanyExistsMutation__
 *
 * To run a mutation, you first call `useCompanyExistsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyExistsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyExistsMutation, { data, loading, error }] = useCompanyExistsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompanyExistsMutation(baseOptions?: Apollo.MutationHookOptions<CompanyExistsMutation, CompanyExistsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyExistsMutation, CompanyExistsMutationVariables>(CompanyExistsDocument, options);
      }
export type CompanyExistsMutationHookResult = ReturnType<typeof useCompanyExistsMutation>;
export type CompanyExistsMutationResult = Apollo.MutationResult<CompanyExistsMutation>;
export type CompanyExistsMutationOptions = Apollo.BaseMutationOptions<CompanyExistsMutation, CompanyExistsMutationVariables>;
export const LeaveOrganizationDocument = gql`
    mutation leaveOrganization($organizationId: String!) {
  response: leaveOrganization(organizationId: $organizationId) {
    updatedUser: user {
      ...CurrentUser
    }
    organization {
      ...OrganizationSummary
    }
    leaveSuccessful
    errorMessage
  }
}
    ${CurrentUserFragmentDoc}
${OrganizationSummaryFragmentDoc}`;
export type LeaveOrganizationMutationFn = Apollo.MutationFunction<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>;

/**
 * __useLeaveOrganizationMutation__
 *
 * To run a mutation, you first call `useLeaveOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveOrganizationMutation, { data, loading, error }] = useLeaveOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useLeaveOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>(LeaveOrganizationDocument, options);
      }
export type LeaveOrganizationMutationHookResult = ReturnType<typeof useLeaveOrganizationMutation>;
export type LeaveOrganizationMutationResult = Apollo.MutationResult<LeaveOrganizationMutation>;
export type LeaveOrganizationMutationOptions = Apollo.BaseMutationOptions<LeaveOrganizationMutation, LeaveOrganizationMutationVariables>;
export const CanLeaveOrganizationDocument = gql`
    query canLeaveOrganization($organizationId: String!) {
  result: canLeaveOrganization(organizationId: $organizationId) {
    organization {
      ...OrganizationSummary
    }
    leaveStatus
  }
}
    ${OrganizationSummaryFragmentDoc}`;

/**
 * __useCanLeaveOrganizationQuery__
 *
 * To run a query within a React component, call `useCanLeaveOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanLeaveOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanLeaveOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCanLeaveOrganizationQuery(baseOptions: Apollo.QueryHookOptions<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>(CanLeaveOrganizationDocument, options);
      }
export function useCanLeaveOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>(CanLeaveOrganizationDocument, options);
        }
export type CanLeaveOrganizationQueryHookResult = ReturnType<typeof useCanLeaveOrganizationQuery>;
export type CanLeaveOrganizationLazyQueryHookResult = ReturnType<typeof useCanLeaveOrganizationLazyQuery>;
export type CanLeaveOrganizationQueryResult = Apollo.QueryResult<CanLeaveOrganizationQuery, CanLeaveOrganizationQueryVariables>;
export const JoinOrganizationDocument = gql`
    mutation joinOrganization($data: JoinAffiliationInput!) {
  response: joinOrganization(data: $data) {
    updatedUser: user {
      ...CurrentUser
    }
    organization {
      ...OrganizationSummary
    }
    responseStatus
    errorMessage
  }
}
    ${CurrentUserFragmentDoc}
${OrganizationSummaryFragmentDoc}`;
export type JoinOrganizationMutationFn = Apollo.MutationFunction<JoinOrganizationMutation, JoinOrganizationMutationVariables>;

/**
 * __useJoinOrganizationMutation__
 *
 * To run a mutation, you first call `useJoinOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinOrganizationMutation, { data, loading, error }] = useJoinOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useJoinOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<JoinOrganizationMutation, JoinOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinOrganizationMutation, JoinOrganizationMutationVariables>(JoinOrganizationDocument, options);
      }
export type JoinOrganizationMutationHookResult = ReturnType<typeof useJoinOrganizationMutation>;
export type JoinOrganizationMutationResult = Apollo.MutationResult<JoinOrganizationMutation>;
export type JoinOrganizationMutationOptions = Apollo.BaseMutationOptions<JoinOrganizationMutation, JoinOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($id: String!) {
  updatedUser: deleteOrganization(id: $id) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type DeleteOrganizationMutationFn = Apollo.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = Apollo.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const LikeOrganizationDocument = gql`
    mutation LikeOrganization($organization: String!) {
  likeOrganization(organization: $organization) {
    id
    user {
      id
      name
    }
    organization {
      id
      name
    }
  }
}
    `;
export type LikeOrganizationMutationFn = Apollo.MutationFunction<LikeOrganizationMutation, LikeOrganizationMutationVariables>;

/**
 * __useLikeOrganizationMutation__
 *
 * To run a mutation, you first call `useLikeOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeOrganizationMutation, { data, loading, error }] = useLikeOrganizationMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useLikeOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<LikeOrganizationMutation, LikeOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeOrganizationMutation, LikeOrganizationMutationVariables>(LikeOrganizationDocument, options);
      }
export type LikeOrganizationMutationHookResult = ReturnType<typeof useLikeOrganizationMutation>;
export type LikeOrganizationMutationResult = Apollo.MutationResult<LikeOrganizationMutation>;
export type LikeOrganizationMutationOptions = Apollo.BaseMutationOptions<LikeOrganizationMutation, LikeOrganizationMutationVariables>;
export const DeleteOrganizationLikeDocument = gql`
    mutation DeleteOrganizationLike($organization: String!) {
  unlikeOrganization(organization: $organization)
}
    `;
export type DeleteOrganizationLikeMutationFn = Apollo.MutationFunction<DeleteOrganizationLikeMutation, DeleteOrganizationLikeMutationVariables>;

/**
 * __useDeleteOrganizationLikeMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationLikeMutation, { data, loading, error }] = useDeleteOrganizationLikeMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useDeleteOrganizationLikeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationLikeMutation, DeleteOrganizationLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationLikeMutation, DeleteOrganizationLikeMutationVariables>(DeleteOrganizationLikeDocument, options);
      }
export type DeleteOrganizationLikeMutationHookResult = ReturnType<typeof useDeleteOrganizationLikeMutation>;
export type DeleteOrganizationLikeMutationResult = Apollo.MutationResult<DeleteOrganizationLikeMutation>;
export type DeleteOrganizationLikeMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationLikeMutation, DeleteOrganizationLikeMutationVariables>;
export const GetOrganizationDetailsDocument = gql`
    query GetOrganizationDetails($id: String!) {
  organizationDetails(id: $id) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

/**
 * __useGetOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>(GetOrganizationDetailsDocument, options);
      }
export function useGetOrganizationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>(GetOrganizationDetailsDocument, options);
        }
export type GetOrganizationDetailsQueryHookResult = ReturnType<typeof useGetOrganizationDetailsQuery>;
export type GetOrganizationDetailsLazyQueryHookResult = ReturnType<typeof useGetOrganizationDetailsLazyQuery>;
export type GetOrganizationDetailsQueryResult = Apollo.QueryResult<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($id: String!, $data: UpdateOrganizationInput!) {
  updateOrganization(id: $id, data: $data) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const UpdateOrganizationEmailDomainsDocument = gql`
    mutation updateOrganizationEmailDomains($id: String!, $data: UpdateOrgEmailDomainsInput!) {
  updateOrganizationEmailDomains(id: $id, data: $data) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;
export type UpdateOrganizationEmailDomainsMutationFn = Apollo.MutationFunction<UpdateOrganizationEmailDomainsMutation, UpdateOrganizationEmailDomainsMutationVariables>;

/**
 * __useUpdateOrganizationEmailDomainsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationEmailDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationEmailDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationEmailDomainsMutation, { data, loading, error }] = useUpdateOrganizationEmailDomainsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationEmailDomainsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationEmailDomainsMutation, UpdateOrganizationEmailDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationEmailDomainsMutation, UpdateOrganizationEmailDomainsMutationVariables>(UpdateOrganizationEmailDomainsDocument, options);
      }
export type UpdateOrganizationEmailDomainsMutationHookResult = ReturnType<typeof useUpdateOrganizationEmailDomainsMutation>;
export type UpdateOrganizationEmailDomainsMutationResult = Apollo.MutationResult<UpdateOrganizationEmailDomainsMutation>;
export type UpdateOrganizationEmailDomainsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationEmailDomainsMutation, UpdateOrganizationEmailDomainsMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($data: CreateOrganizationInput!) {
  response: createOrganization(data: $data) {
    user {
      ...CurrentUser
    }
    organization {
      ...Organization
    }
  }
}
    ${CurrentUserFragmentDoc}
${OrganizationFragmentDoc}`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const ShareOrganizationDocument = gql`
    mutation shareOrganization($data: ShareInput!) {
  shareOrganization(data: $data)
}
    `;
export type ShareOrganizationMutationFn = Apollo.MutationFunction<ShareOrganizationMutation, ShareOrganizationMutationVariables>;

/**
 * __useShareOrganizationMutation__
 *
 * To run a mutation, you first call `useShareOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareOrganizationMutation, { data, loading, error }] = useShareOrganizationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useShareOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ShareOrganizationMutation, ShareOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareOrganizationMutation, ShareOrganizationMutationVariables>(ShareOrganizationDocument, options);
      }
export type ShareOrganizationMutationHookResult = ReturnType<typeof useShareOrganizationMutation>;
export type ShareOrganizationMutationResult = Apollo.MutationResult<ShareOrganizationMutation>;
export type ShareOrganizationMutationOptions = Apollo.BaseMutationOptions<ShareOrganizationMutation, ShareOrganizationMutationVariables>;
export const UpdateOrganizationEmployeesDocument = gql`
    mutation updateOrganizationEmployees($data: UpdateOrganizationEmployeesInput!) {
  updateOrganizationEmployees(data: $data)
}
    `;
export type UpdateOrganizationEmployeesMutationFn = Apollo.MutationFunction<UpdateOrganizationEmployeesMutation, UpdateOrganizationEmployeesMutationVariables>;

/**
 * __useUpdateOrganizationEmployeesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationEmployeesMutation, { data, loading, error }] = useUpdateOrganizationEmployeesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationEmployeesMutation, UpdateOrganizationEmployeesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationEmployeesMutation, UpdateOrganizationEmployeesMutationVariables>(UpdateOrganizationEmployeesDocument, options);
      }
export type UpdateOrganizationEmployeesMutationHookResult = ReturnType<typeof useUpdateOrganizationEmployeesMutation>;
export type UpdateOrganizationEmployeesMutationResult = Apollo.MutationResult<UpdateOrganizationEmployeesMutation>;
export type UpdateOrganizationEmployeesMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationEmployeesMutation, UpdateOrganizationEmployeesMutationVariables>;
export const FollowOrganizationDocument = gql`
    mutation followOrganization($organizationId: String!) {
  followOrganization(organizationId: $organizationId) {
    id
    user {
      id
      name
    }
    organization {
      id
      name
    }
  }
}
    `;
export type FollowOrganizationMutationFn = Apollo.MutationFunction<FollowOrganizationMutation, FollowOrganizationMutationVariables>;

/**
 * __useFollowOrganizationMutation__
 *
 * To run a mutation, you first call `useFollowOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followOrganizationMutation, { data, loading, error }] = useFollowOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useFollowOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<FollowOrganizationMutation, FollowOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowOrganizationMutation, FollowOrganizationMutationVariables>(FollowOrganizationDocument, options);
      }
export type FollowOrganizationMutationHookResult = ReturnType<typeof useFollowOrganizationMutation>;
export type FollowOrganizationMutationResult = Apollo.MutationResult<FollowOrganizationMutation>;
export type FollowOrganizationMutationOptions = Apollo.BaseMutationOptions<FollowOrganizationMutation, FollowOrganizationMutationVariables>;
export const UnFollowOrganizationDocument = gql`
    mutation unFollowOrganization($organizationId: String!) {
  unFollowOrganization(organizationId: $organizationId)
}
    `;
export type UnFollowOrganizationMutationFn = Apollo.MutationFunction<UnFollowOrganizationMutation, UnFollowOrganizationMutationVariables>;

/**
 * __useUnFollowOrganizationMutation__
 *
 * To run a mutation, you first call `useUnFollowOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowOrganizationMutation, { data, loading, error }] = useUnFollowOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUnFollowOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UnFollowOrganizationMutation, UnFollowOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnFollowOrganizationMutation, UnFollowOrganizationMutationVariables>(UnFollowOrganizationDocument, options);
      }
export type UnFollowOrganizationMutationHookResult = ReturnType<typeof useUnFollowOrganizationMutation>;
export type UnFollowOrganizationMutationResult = Apollo.MutationResult<UnFollowOrganizationMutation>;
export type UnFollowOrganizationMutationOptions = Apollo.BaseMutationOptions<UnFollowOrganizationMutation, UnFollowOrganizationMutationVariables>;
export const GetOrganizationFollowsDocument = gql`
    query GetOrganizationFollows($sort: SortInput, $pagination: PaginationInput) {
  getOrganizationFollows(sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...OrganizationFollow
    }
  }
}
    ${OrganizationFollowFragmentDoc}`;

/**
 * __useGetOrganizationFollowsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationFollowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationFollowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationFollowsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetOrganizationFollowsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationFollowsQuery, GetOrganizationFollowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationFollowsQuery, GetOrganizationFollowsQueryVariables>(GetOrganizationFollowsDocument, options);
      }
export function useGetOrganizationFollowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationFollowsQuery, GetOrganizationFollowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationFollowsQuery, GetOrganizationFollowsQueryVariables>(GetOrganizationFollowsDocument, options);
        }
export type GetOrganizationFollowsQueryHookResult = ReturnType<typeof useGetOrganizationFollowsQuery>;
export type GetOrganizationFollowsLazyQueryHookResult = ReturnType<typeof useGetOrganizationFollowsLazyQuery>;
export type GetOrganizationFollowsQueryResult = Apollo.QueryResult<GetOrganizationFollowsQuery, GetOrganizationFollowsQueryVariables>;
export const GetTechnologyByIdDocument = gql`
    query GetTechnologyById($id: String!) {
  technology(id: $id) {
    ...Technology
  }
}
    ${TechnologyFragmentDoc}`;

/**
 * __useGetTechnologyByIdQuery__
 *
 * To run a query within a React component, call `useGetTechnologyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTechnologyByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTechnologyByIdQuery, GetTechnologyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologyByIdQuery, GetTechnologyByIdQueryVariables>(GetTechnologyByIdDocument, options);
      }
export function useGetTechnologyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologyByIdQuery, GetTechnologyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologyByIdQuery, GetTechnologyByIdQueryVariables>(GetTechnologyByIdDocument, options);
        }
export type GetTechnologyByIdQueryHookResult = ReturnType<typeof useGetTechnologyByIdQuery>;
export type GetTechnologyByIdLazyQueryHookResult = ReturnType<typeof useGetTechnologyByIdLazyQuery>;
export type GetTechnologyByIdQueryResult = Apollo.QueryResult<GetTechnologyByIdQuery, GetTechnologyByIdQueryVariables>;
export const GetTechnologiesByOrganizationDocument = gql`
    query getTechnologiesByOrganization($organizationId: String!) {
  getTechnologiesByOrganization(organizationId: $organizationId) {
    ...Technology
  }
}
    ${TechnologyFragmentDoc}`;

/**
 * __useGetTechnologiesByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetTechnologiesByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologiesByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologiesByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetTechnologiesByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetTechnologiesByOrganizationQuery, GetTechnologiesByOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologiesByOrganizationQuery, GetTechnologiesByOrganizationQueryVariables>(GetTechnologiesByOrganizationDocument, options);
      }
export function useGetTechnologiesByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologiesByOrganizationQuery, GetTechnologiesByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologiesByOrganizationQuery, GetTechnologiesByOrganizationQueryVariables>(GetTechnologiesByOrganizationDocument, options);
        }
export type GetTechnologiesByOrganizationQueryHookResult = ReturnType<typeof useGetTechnologiesByOrganizationQuery>;
export type GetTechnologiesByOrganizationLazyQueryHookResult = ReturnType<typeof useGetTechnologiesByOrganizationLazyQuery>;
export type GetTechnologiesByOrganizationQueryResult = Apollo.QueryResult<GetTechnologiesByOrganizationQuery, GetTechnologiesByOrganizationQueryVariables>;
export const ExplorerSearchDocument = gql`
    query explorerSearch($searchTerm: String!, $technologyFilter: [ID!]!, $trlFilter: RangeFilterInput, $mrlFilter: RangeFilterInput, $sort: SortInput, $pagination: PaginationInput) {
  results: searchTechnologies(searchTerm: $searchTerm, trlFilter: $trlFilter, mrlFilter: $mrlFilter, technologyFilter: {ids: $technologyFilter}, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...QueryTechnology
    }
  }
}
    ${QueryTechnologyFragmentDoc}`;

/**
 * __useExplorerSearchQuery__
 *
 * To run a query within a React component, call `useExplorerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorerSearchQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      technologyFilter: // value for 'technologyFilter'
 *      trlFilter: // value for 'trlFilter'
 *      mrlFilter: // value for 'mrlFilter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useExplorerSearchQuery(baseOptions: Apollo.QueryHookOptions<ExplorerSearchQuery, ExplorerSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExplorerSearchQuery, ExplorerSearchQueryVariables>(ExplorerSearchDocument, options);
      }
export function useExplorerSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExplorerSearchQuery, ExplorerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExplorerSearchQuery, ExplorerSearchQueryVariables>(ExplorerSearchDocument, options);
        }
export type ExplorerSearchQueryHookResult = ReturnType<typeof useExplorerSearchQuery>;
export type ExplorerSearchLazyQueryHookResult = ReturnType<typeof useExplorerSearchLazyQuery>;
export type ExplorerSearchQueryResult = Apollo.QueryResult<ExplorerSearchQuery, ExplorerSearchQueryVariables>;
export const CreateTechnologyDocument = gql`
    mutation createTechnology($data: TechnologyUpsertInput!, $files: [Upload!]!) {
  technology: createTechnology(data: $data, files: $files) {
    id
  }
}
    `;
export type CreateTechnologyMutationFn = Apollo.MutationFunction<CreateTechnologyMutation, CreateTechnologyMutationVariables>;

/**
 * __useCreateTechnologyMutation__
 *
 * To run a mutation, you first call `useCreateTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTechnologyMutation, { data, loading, error }] = useCreateTechnologyMutation({
 *   variables: {
 *      data: // value for 'data'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<CreateTechnologyMutation, CreateTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTechnologyMutation, CreateTechnologyMutationVariables>(CreateTechnologyDocument, options);
      }
export type CreateTechnologyMutationHookResult = ReturnType<typeof useCreateTechnologyMutation>;
export type CreateTechnologyMutationResult = Apollo.MutationResult<CreateTechnologyMutation>;
export type CreateTechnologyMutationOptions = Apollo.BaseMutationOptions<CreateTechnologyMutation, CreateTechnologyMutationVariables>;
export const DeleteTechnologyDocument = gql`
    mutation deleteTechnology($id: String!) {
  deleteTechnology(id: $id)
}
    `;
export type DeleteTechnologyMutationFn = Apollo.MutationFunction<DeleteTechnologyMutation, DeleteTechnologyMutationVariables>;

/**
 * __useDeleteTechnologyMutation__
 *
 * To run a mutation, you first call `useDeleteTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTechnologyMutation, { data, loading, error }] = useDeleteTechnologyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTechnologyMutation, DeleteTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTechnologyMutation, DeleteTechnologyMutationVariables>(DeleteTechnologyDocument, options);
      }
export type DeleteTechnologyMutationHookResult = ReturnType<typeof useDeleteTechnologyMutation>;
export type DeleteTechnologyMutationResult = Apollo.MutationResult<DeleteTechnologyMutation>;
export type DeleteTechnologyMutationOptions = Apollo.BaseMutationOptions<DeleteTechnologyMutation, DeleteTechnologyMutationVariables>;
export const UpdateTechnologyDocument = gql`
    mutation updateTechnology($id: String!, $data: TechnologyUpsertInput!, $files: [Upload!]!) {
  updateTechnology(id: $id, data: $data, files: $files) {
    ...Technology
  }
}
    ${TechnologyFragmentDoc}`;
export type UpdateTechnologyMutationFn = Apollo.MutationFunction<UpdateTechnologyMutation, UpdateTechnologyMutationVariables>;

/**
 * __useUpdateTechnologyMutation__
 *
 * To run a mutation, you first call `useUpdateTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTechnologyMutation, { data, loading, error }] = useUpdateTechnologyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTechnologyMutation, UpdateTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTechnologyMutation, UpdateTechnologyMutationVariables>(UpdateTechnologyDocument, options);
      }
export type UpdateTechnologyMutationHookResult = ReturnType<typeof useUpdateTechnologyMutation>;
export type UpdateTechnologyMutationResult = Apollo.MutationResult<UpdateTechnologyMutation>;
export type UpdateTechnologyMutationOptions = Apollo.BaseMutationOptions<UpdateTechnologyMutation, UpdateTechnologyMutationVariables>;
export const LikeTechnologyDocument = gql`
    mutation LikeTechnology($technology: String!) {
  likeTechnology(technology: $technology) {
    id
    user {
      id
      name
    }
    technology {
      id
      name
    }
  }
}
    `;
export type LikeTechnologyMutationFn = Apollo.MutationFunction<LikeTechnologyMutation, LikeTechnologyMutationVariables>;

/**
 * __useLikeTechnologyMutation__
 *
 * To run a mutation, you first call `useLikeTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeTechnologyMutation, { data, loading, error }] = useLikeTechnologyMutation({
 *   variables: {
 *      technology: // value for 'technology'
 *   },
 * });
 */
export function useLikeTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<LikeTechnologyMutation, LikeTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeTechnologyMutation, LikeTechnologyMutationVariables>(LikeTechnologyDocument, options);
      }
export type LikeTechnologyMutationHookResult = ReturnType<typeof useLikeTechnologyMutation>;
export type LikeTechnologyMutationResult = Apollo.MutationResult<LikeTechnologyMutation>;
export type LikeTechnologyMutationOptions = Apollo.BaseMutationOptions<LikeTechnologyMutation, LikeTechnologyMutationVariables>;
export const DeleteTechnologyLikeDocument = gql`
    mutation DeleteTechnologyLike($technology: String!) {
  unlikeTechnology(technology: $technology)
}
    `;
export type DeleteTechnologyLikeMutationFn = Apollo.MutationFunction<DeleteTechnologyLikeMutation, DeleteTechnologyLikeMutationVariables>;

/**
 * __useDeleteTechnologyLikeMutation__
 *
 * To run a mutation, you first call `useDeleteTechnologyLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTechnologyLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTechnologyLikeMutation, { data, loading, error }] = useDeleteTechnologyLikeMutation({
 *   variables: {
 *      technology: // value for 'technology'
 *   },
 * });
 */
export function useDeleteTechnologyLikeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTechnologyLikeMutation, DeleteTechnologyLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTechnologyLikeMutation, DeleteTechnologyLikeMutationVariables>(DeleteTechnologyLikeDocument, options);
      }
export type DeleteTechnologyLikeMutationHookResult = ReturnType<typeof useDeleteTechnologyLikeMutation>;
export type DeleteTechnologyLikeMutationResult = Apollo.MutationResult<DeleteTechnologyLikeMutation>;
export type DeleteTechnologyLikeMutationOptions = Apollo.BaseMutationOptions<DeleteTechnologyLikeMutation, DeleteTechnologyLikeMutationVariables>;
export const GetTechnologyDocumentDocument = gql`
    mutation GetTechnologyDocument($technologyId: String!, $documentId: String!) {
  getDocumentDownloadLink(technologyId: $technologyId, documentId: $documentId) {
    url
  }
}
    `;
export type GetTechnologyDocumentMutationFn = Apollo.MutationFunction<GetTechnologyDocumentMutation, GetTechnologyDocumentMutationVariables>;

/**
 * __useGetTechnologyDocumentMutation__
 *
 * To run a mutation, you first call `useGetTechnologyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTechnologyDocumentMutation, { data, loading, error }] = useGetTechnologyDocumentMutation({
 *   variables: {
 *      technologyId: // value for 'technologyId'
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useGetTechnologyDocumentMutation(baseOptions?: Apollo.MutationHookOptions<GetTechnologyDocumentMutation, GetTechnologyDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetTechnologyDocumentMutation, GetTechnologyDocumentMutationVariables>(GetTechnologyDocumentDocument, options);
      }
export type GetTechnologyDocumentMutationHookResult = ReturnType<typeof useGetTechnologyDocumentMutation>;
export type GetTechnologyDocumentMutationResult = Apollo.MutationResult<GetTechnologyDocumentMutation>;
export type GetTechnologyDocumentMutationOptions = Apollo.BaseMutationOptions<GetTechnologyDocumentMutation, GetTechnologyDocumentMutationVariables>;
export const GetTechnologyCategoriesDocument = gql`
    query getTechnologyCategories {
  technologyCategories {
    ...ParentCategory
  }
}
    ${ParentCategoryFragmentDoc}`;

/**
 * __useGetTechnologyCategoriesQuery__
 *
 * To run a query within a React component, call `useGetTechnologyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologyCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTechnologyCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetTechnologyCategoriesQuery, GetTechnologyCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologyCategoriesQuery, GetTechnologyCategoriesQueryVariables>(GetTechnologyCategoriesDocument, options);
      }
export function useGetTechnologyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologyCategoriesQuery, GetTechnologyCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologyCategoriesQuery, GetTechnologyCategoriesQueryVariables>(GetTechnologyCategoriesDocument, options);
        }
export type GetTechnologyCategoriesQueryHookResult = ReturnType<typeof useGetTechnologyCategoriesQuery>;
export type GetTechnologyCategoriesLazyQueryHookResult = ReturnType<typeof useGetTechnologyCategoriesLazyQuery>;
export type GetTechnologyCategoriesQueryResult = Apollo.QueryResult<GetTechnologyCategoriesQuery, GetTechnologyCategoriesQueryVariables>;
export const ShareTechnologyDocument = gql`
    mutation shareTechnology($data: ShareInput!) {
  shareTechnology(data: $data)
}
    `;
export type ShareTechnologyMutationFn = Apollo.MutationFunction<ShareTechnologyMutation, ShareTechnologyMutationVariables>;

/**
 * __useShareTechnologyMutation__
 *
 * To run a mutation, you first call `useShareTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareTechnologyMutation, { data, loading, error }] = useShareTechnologyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useShareTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<ShareTechnologyMutation, ShareTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareTechnologyMutation, ShareTechnologyMutationVariables>(ShareTechnologyDocument, options);
      }
export type ShareTechnologyMutationHookResult = ReturnType<typeof useShareTechnologyMutation>;
export type ShareTechnologyMutationResult = Apollo.MutationResult<ShareTechnologyMutation>;
export type ShareTechnologyMutationOptions = Apollo.BaseMutationOptions<ShareTechnologyMutation, ShareTechnologyMutationVariables>;
export const FollowTechnologyDocument = gql`
    mutation followTechnology($technology: String!) {
  followTechnology(technology: $technology) {
    id
    user {
      id
      name
    }
    technology {
      id
      name
    }
  }
}
    `;
export type FollowTechnologyMutationFn = Apollo.MutationFunction<FollowTechnologyMutation, FollowTechnologyMutationVariables>;

/**
 * __useFollowTechnologyMutation__
 *
 * To run a mutation, you first call `useFollowTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followTechnologyMutation, { data, loading, error }] = useFollowTechnologyMutation({
 *   variables: {
 *      technology: // value for 'technology'
 *   },
 * });
 */
export function useFollowTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<FollowTechnologyMutation, FollowTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FollowTechnologyMutation, FollowTechnologyMutationVariables>(FollowTechnologyDocument, options);
      }
export type FollowTechnologyMutationHookResult = ReturnType<typeof useFollowTechnologyMutation>;
export type FollowTechnologyMutationResult = Apollo.MutationResult<FollowTechnologyMutation>;
export type FollowTechnologyMutationOptions = Apollo.BaseMutationOptions<FollowTechnologyMutation, FollowTechnologyMutationVariables>;
export const UnFollowTechnologyDocument = gql`
    mutation unFollowTechnology($technology: String!) {
  unFollowTechnology(technology: $technology)
}
    `;
export type UnFollowTechnologyMutationFn = Apollo.MutationFunction<UnFollowTechnologyMutation, UnFollowTechnologyMutationVariables>;

/**
 * __useUnFollowTechnologyMutation__
 *
 * To run a mutation, you first call `useUnFollowTechnologyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnFollowTechnologyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unFollowTechnologyMutation, { data, loading, error }] = useUnFollowTechnologyMutation({
 *   variables: {
 *      technology: // value for 'technology'
 *   },
 * });
 */
export function useUnFollowTechnologyMutation(baseOptions?: Apollo.MutationHookOptions<UnFollowTechnologyMutation, UnFollowTechnologyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnFollowTechnologyMutation, UnFollowTechnologyMutationVariables>(UnFollowTechnologyDocument, options);
      }
export type UnFollowTechnologyMutationHookResult = ReturnType<typeof useUnFollowTechnologyMutation>;
export type UnFollowTechnologyMutationResult = Apollo.MutationResult<UnFollowTechnologyMutation>;
export type UnFollowTechnologyMutationOptions = Apollo.BaseMutationOptions<UnFollowTechnologyMutation, UnFollowTechnologyMutationVariables>;
export const GetTechnologyFollowsDocument = gql`
    query GetTechnologyFollows($sort: SortInput, $pagination: PaginationInput) {
  getTechnologyFollows(sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...TechnologyFollow
    }
  }
}
    ${TechnologyFollowFragmentDoc}`;

/**
 * __useGetTechnologyFollowsQuery__
 *
 * To run a query within a React component, call `useGetTechnologyFollowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechnologyFollowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechnologyFollowsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetTechnologyFollowsQuery(baseOptions?: Apollo.QueryHookOptions<GetTechnologyFollowsQuery, GetTechnologyFollowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTechnologyFollowsQuery, GetTechnologyFollowsQueryVariables>(GetTechnologyFollowsDocument, options);
      }
export function useGetTechnologyFollowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTechnologyFollowsQuery, GetTechnologyFollowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTechnologyFollowsQuery, GetTechnologyFollowsQueryVariables>(GetTechnologyFollowsDocument, options);
        }
export type GetTechnologyFollowsQueryHookResult = ReturnType<typeof useGetTechnologyFollowsQuery>;
export type GetTechnologyFollowsLazyQueryHookResult = ReturnType<typeof useGetTechnologyFollowsLazyQuery>;
export type GetTechnologyFollowsQueryResult = Apollo.QueryResult<GetTechnologyFollowsQuery, GetTechnologyFollowsQueryVariables>;
export const SingleUploadDocument = gql`
    mutation singleUpload($file: Upload!) {
  singleUpload(file: $file)
}
    `;
export type SingleUploadMutationFn = Apollo.MutationFunction<SingleUploadMutation, SingleUploadMutationVariables>;

/**
 * __useSingleUploadMutation__
 *
 * To run a mutation, you first call `useSingleUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSingleUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [singleUploadMutation, { data, loading, error }] = useSingleUploadMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSingleUploadMutation(baseOptions?: Apollo.MutationHookOptions<SingleUploadMutation, SingleUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SingleUploadMutation, SingleUploadMutationVariables>(SingleUploadDocument, options);
      }
export type SingleUploadMutationHookResult = ReturnType<typeof useSingleUploadMutation>;
export type SingleUploadMutationResult = Apollo.MutationResult<SingleUploadMutation>;
export type SingleUploadMutationOptions = Apollo.BaseMutationOptions<SingleUploadMutation, SingleUploadMutationVariables>;
export const MultipleUploadDocument = gql`
    mutation multipleUpload($files: [Upload!]!) {
  multipleUpload(files: $files)
}
    `;
export type MultipleUploadMutationFn = Apollo.MutationFunction<MultipleUploadMutation, MultipleUploadMutationVariables>;

/**
 * __useMultipleUploadMutation__
 *
 * To run a mutation, you first call `useMultipleUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultipleUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multipleUploadMutation, { data, loading, error }] = useMultipleUploadMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useMultipleUploadMutation(baseOptions?: Apollo.MutationHookOptions<MultipleUploadMutation, MultipleUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultipleUploadMutation, MultipleUploadMutationVariables>(MultipleUploadDocument, options);
      }
export type MultipleUploadMutationHookResult = ReturnType<typeof useMultipleUploadMutation>;
export type MultipleUploadMutationResult = Apollo.MutationResult<MultipleUploadMutation>;
export type MultipleUploadMutationOptions = Apollo.BaseMutationOptions<MultipleUploadMutation, MultipleUploadMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetUserDocument = gql`
    query getUser {
  user {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const OnBoardUserDocument = gql`
    mutation onBoardUser($data: OnboardUserInput!) {
  onBoardUser(data: $data) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type OnBoardUserMutationFn = Apollo.MutationFunction<OnBoardUserMutation, OnBoardUserMutationVariables>;

/**
 * __useOnBoardUserMutation__
 *
 * To run a mutation, you first call `useOnBoardUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnBoardUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onBoardUserMutation, { data, loading, error }] = useOnBoardUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOnBoardUserMutation(baseOptions?: Apollo.MutationHookOptions<OnBoardUserMutation, OnBoardUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnBoardUserMutation, OnBoardUserMutationVariables>(OnBoardUserDocument, options);
      }
export type OnBoardUserMutationHookResult = ReturnType<typeof useOnBoardUserMutation>;
export type OnBoardUserMutationResult = Apollo.MutationResult<OnBoardUserMutation>;
export type OnBoardUserMutationOptions = Apollo.BaseMutationOptions<OnBoardUserMutation, OnBoardUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AddUserIdentityDocument = gql`
    mutation addUserIdentity($emailAddress: String!) {
  addUserIdentity(emailAddress: $emailAddress) {
    result {
      ...CurrentUser
    }
    errorMessage
  }
}
    ${CurrentUserFragmentDoc}`;
export type AddUserIdentityMutationFn = Apollo.MutationFunction<AddUserIdentityMutation, AddUserIdentityMutationVariables>;

/**
 * __useAddUserIdentityMutation__
 *
 * To run a mutation, you first call `useAddUserIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserIdentityMutation, { data, loading, error }] = useAddUserIdentityMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useAddUserIdentityMutation(baseOptions?: Apollo.MutationHookOptions<AddUserIdentityMutation, AddUserIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserIdentityMutation, AddUserIdentityMutationVariables>(AddUserIdentityDocument, options);
      }
export type AddUserIdentityMutationHookResult = ReturnType<typeof useAddUserIdentityMutation>;
export type AddUserIdentityMutationResult = Apollo.MutationResult<AddUserIdentityMutation>;
export type AddUserIdentityMutationOptions = Apollo.BaseMutationOptions<AddUserIdentityMutation, AddUserIdentityMutationVariables>;
export const SetPrimaryEmailDocument = gql`
    mutation setPrimaryEmail($emailAddress: String!) {
  response: setPrimaryEmail(emailAddress: $emailAddress) {
    errorMessage
    result {
      ...CurrentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;
export type SetPrimaryEmailMutationFn = Apollo.MutationFunction<SetPrimaryEmailMutation, SetPrimaryEmailMutationVariables>;

/**
 * __useSetPrimaryEmailMutation__
 *
 * To run a mutation, you first call `useSetPrimaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryEmailMutation, { data, loading, error }] = useSetPrimaryEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useSetPrimaryEmailMutation(baseOptions?: Apollo.MutationHookOptions<SetPrimaryEmailMutation, SetPrimaryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPrimaryEmailMutation, SetPrimaryEmailMutationVariables>(SetPrimaryEmailDocument, options);
      }
export type SetPrimaryEmailMutationHookResult = ReturnType<typeof useSetPrimaryEmailMutation>;
export type SetPrimaryEmailMutationResult = Apollo.MutationResult<SetPrimaryEmailMutation>;
export type SetPrimaryEmailMutationOptions = Apollo.BaseMutationOptions<SetPrimaryEmailMutation, SetPrimaryEmailMutationVariables>;
export const SetPrimaryOrganizationDocument = gql`
    mutation setPrimaryOrganization($organizationId: String!) {
  response: setPrimaryOrganization(organizationId: $organizationId) {
    errorMessage
    result {
      ...CurrentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;
export type SetPrimaryOrganizationMutationFn = Apollo.MutationFunction<SetPrimaryOrganizationMutation, SetPrimaryOrganizationMutationVariables>;

/**
 * __useSetPrimaryOrganizationMutation__
 *
 * To run a mutation, you first call `useSetPrimaryOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryOrganizationMutation, { data, loading, error }] = useSetPrimaryOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useSetPrimaryOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SetPrimaryOrganizationMutation, SetPrimaryOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPrimaryOrganizationMutation, SetPrimaryOrganizationMutationVariables>(SetPrimaryOrganizationDocument, options);
      }
export type SetPrimaryOrganizationMutationHookResult = ReturnType<typeof useSetPrimaryOrganizationMutation>;
export type SetPrimaryOrganizationMutationResult = Apollo.MutationResult<SetPrimaryOrganizationMutation>;
export type SetPrimaryOrganizationMutationOptions = Apollo.BaseMutationOptions<SetPrimaryOrganizationMutation, SetPrimaryOrganizationMutationVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($id: String!) {
  userProfile(id: $id) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetModeratorSuperAdminsDocument = gql`
    query getModeratorSuperAdmins {
  getModeratorSuperAdmins {
    ...AdminUser
  }
}
    ${AdminUserFragmentDoc}`;

/**
 * __useGetModeratorSuperAdminsQuery__
 *
 * To run a query within a React component, call `useGetModeratorSuperAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModeratorSuperAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModeratorSuperAdminsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModeratorSuperAdminsQuery(baseOptions?: Apollo.QueryHookOptions<GetModeratorSuperAdminsQuery, GetModeratorSuperAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModeratorSuperAdminsQuery, GetModeratorSuperAdminsQueryVariables>(GetModeratorSuperAdminsDocument, options);
      }
export function useGetModeratorSuperAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModeratorSuperAdminsQuery, GetModeratorSuperAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModeratorSuperAdminsQuery, GetModeratorSuperAdminsQueryVariables>(GetModeratorSuperAdminsDocument, options);
        }
export type GetModeratorSuperAdminsQueryHookResult = ReturnType<typeof useGetModeratorSuperAdminsQuery>;
export type GetModeratorSuperAdminsLazyQueryHookResult = ReturnType<typeof useGetModeratorSuperAdminsLazyQuery>;
export type GetModeratorSuperAdminsQueryResult = Apollo.QueryResult<GetModeratorSuperAdminsQuery, GetModeratorSuperAdminsQueryVariables>;
export const GetAutoAppUsersDocument = gql`
    query getAutoAppUsers {
  getAutoAppUsers {
    id
    userEmail
    appRole {
      roleName
    }
  }
}
    `;

/**
 * __useGetAutoAppUsersQuery__
 *
 * To run a query within a React component, call `useGetAutoAppUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoAppUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoAppUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutoAppUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAutoAppUsersQuery, GetAutoAppUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAutoAppUsersQuery, GetAutoAppUsersQueryVariables>(GetAutoAppUsersDocument, options);
      }
export function useGetAutoAppUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAutoAppUsersQuery, GetAutoAppUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAutoAppUsersQuery, GetAutoAppUsersQueryVariables>(GetAutoAppUsersDocument, options);
        }
export type GetAutoAppUsersQueryHookResult = ReturnType<typeof useGetAutoAppUsersQuery>;
export type GetAutoAppUsersLazyQueryHookResult = ReturnType<typeof useGetAutoAppUsersLazyQuery>;
export type GetAutoAppUsersQueryResult = Apollo.QueryResult<GetAutoAppUsersQuery, GetAutoAppUsersQueryVariables>;
export const GetUsersByOrganizationDocument = gql`
    query getUsersByOrganization($organizationId: String!) {
  getUsersByOrganization(organizationId: $organizationId) {
    ...OrgUser
  }
}
    ${OrgUserFragmentDoc}`;

/**
 * __useGetUsersByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUsersByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
      }
export function useGetUsersByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
        }
export type GetUsersByOrganizationQueryHookResult = ReturnType<typeof useGetUsersByOrganizationQuery>;
export type GetUsersByOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationLazyQuery>;
export type GetUsersByOrganizationQueryResult = Apollo.QueryResult<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>;
export const GetUsersByOrganizationWithRolesDocument = gql`
    query getUsersByOrganizationWithRoles($organizationId: String!) {
  getUsersByOrganizationWithRoles(organizationId: $organizationId) {
    ...OrgUserWithRoles
  }
}
    ${OrgUserWithRolesFragmentDoc}`;

/**
 * __useGetUsersByOrganizationWithRolesQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationWithRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationWithRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationWithRolesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetUsersByOrganizationWithRolesQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationWithRolesQuery, GetUsersByOrganizationWithRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationWithRolesQuery, GetUsersByOrganizationWithRolesQueryVariables>(GetUsersByOrganizationWithRolesDocument, options);
      }
export function useGetUsersByOrganizationWithRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationWithRolesQuery, GetUsersByOrganizationWithRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationWithRolesQuery, GetUsersByOrganizationWithRolesQueryVariables>(GetUsersByOrganizationWithRolesDocument, options);
        }
export type GetUsersByOrganizationWithRolesQueryHookResult = ReturnType<typeof useGetUsersByOrganizationWithRolesQuery>;
export type GetUsersByOrganizationWithRolesLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationWithRolesLazyQuery>;
export type GetUsersByOrganizationWithRolesQueryResult = Apollo.QueryResult<GetUsersByOrganizationWithRolesQuery, GetUsersByOrganizationWithRolesQueryVariables>;
export const GetPendingApprovalsDocument = gql`
    query getPendingApprovals($organizationId: String!) {
  getPendingApprovals(organizationId: $organizationId) {
    ...PendingApprovalUser
  }
}
    ${PendingApprovalUserFragmentDoc}`;

/**
 * __useGetPendingApprovalsQuery__
 *
 * To run a query within a React component, call `useGetPendingApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingApprovalsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetPendingApprovalsQuery(baseOptions: Apollo.QueryHookOptions<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>(GetPendingApprovalsDocument, options);
      }
export function useGetPendingApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>(GetPendingApprovalsDocument, options);
        }
export type GetPendingApprovalsQueryHookResult = ReturnType<typeof useGetPendingApprovalsQuery>;
export type GetPendingApprovalsLazyQueryHookResult = ReturnType<typeof useGetPendingApprovalsLazyQuery>;
export type GetPendingApprovalsQueryResult = Apollo.QueryResult<GetPendingApprovalsQuery, GetPendingApprovalsQueryVariables>;
export const UpdateUserPreferencesDocument = gql`
    mutation UpdateUserPreferences($data: UpdateUserPreferencesInput!) {
  updateUserPreferences(data: $data)
}
    `;
export type UpdateUserPreferencesMutationFn = Apollo.MutationFunction<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;

/**
 * __useUpdateUserPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferencesMutation, { data, loading, error }] = useUpdateUserPreferencesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>(UpdateUserPreferencesDocument, options);
      }
export type UpdateUserPreferencesMutationHookResult = ReturnType<typeof useUpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationResult = Apollo.MutationResult<UpdateUserPreferencesMutation>;
export type UpdateUserPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateUserPreferencesMutation, UpdateUserPreferencesMutationVariables>;
export const ApproveUserDocument = gql`
    mutation approveUser($data: PendingApprovalInput!) {
  approveUser(data: $data)
}
    `;
export type ApproveUserMutationFn = Apollo.MutationFunction<ApproveUserMutation, ApproveUserMutationVariables>;

/**
 * __useApproveUserMutation__
 *
 * To run a mutation, you first call `useApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUserMutation, { data, loading, error }] = useApproveUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useApproveUserMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUserMutation, ApproveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUserMutation, ApproveUserMutationVariables>(ApproveUserDocument, options);
      }
export type ApproveUserMutationHookResult = ReturnType<typeof useApproveUserMutation>;
export type ApproveUserMutationResult = Apollo.MutationResult<ApproveUserMutation>;
export type ApproveUserMutationOptions = Apollo.BaseMutationOptions<ApproveUserMutation, ApproveUserMutationVariables>;
export const RemoveRoleDocument = gql`
    mutation removeRole($data: UpdateRoleInput!) {
  removeRole(data: $data)
}
    `;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const AddRoleDocument = gql`
    mutation addRole($data: UpdateRoleInput!) {
  addRole(data: $data)
}
    `;
export type AddRoleMutationFn = Apollo.MutationFunction<AddRoleMutation, AddRoleMutationVariables>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddRoleMutation, AddRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRoleMutation, AddRoleMutationVariables>(AddRoleDocument, options);
      }
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<AddRoleMutation, AddRoleMutationVariables>;
export const RemoveAutoAppUserDocument = gql`
    mutation removeAutoAppUser($id: String!) {
  removeAutoAppUser(id: $id)
}
    `;
export type RemoveAutoAppUserMutationFn = Apollo.MutationFunction<RemoveAutoAppUserMutation, RemoveAutoAppUserMutationVariables>;

/**
 * __useRemoveAutoAppUserMutation__
 *
 * To run a mutation, you first call `useRemoveAutoAppUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAutoAppUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAutoAppUserMutation, { data, loading, error }] = useRemoveAutoAppUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAutoAppUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAutoAppUserMutation, RemoveAutoAppUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAutoAppUserMutation, RemoveAutoAppUserMutationVariables>(RemoveAutoAppUserDocument, options);
      }
export type RemoveAutoAppUserMutationHookResult = ReturnType<typeof useRemoveAutoAppUserMutation>;
export type RemoveAutoAppUserMutationResult = Apollo.MutationResult<RemoveAutoAppUserMutation>;
export type RemoveAutoAppUserMutationOptions = Apollo.BaseMutationOptions<RemoveAutoAppUserMutation, RemoveAutoAppUserMutationVariables>;
export const AddAutoAppUserDocument = gql`
    mutation addAutoAppUser($data: AddAutoRoleUserInput!) {
  addAutoAppUser(data: $data)
}
    `;
export type AddAutoAppUserMutationFn = Apollo.MutationFunction<AddAutoAppUserMutation, AddAutoAppUserMutationVariables>;

/**
 * __useAddAutoAppUserMutation__
 *
 * To run a mutation, you first call `useAddAutoAppUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAutoAppUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAutoAppUserMutation, { data, loading, error }] = useAddAutoAppUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAutoAppUserMutation(baseOptions?: Apollo.MutationHookOptions<AddAutoAppUserMutation, AddAutoAppUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAutoAppUserMutation, AddAutoAppUserMutationVariables>(AddAutoAppUserDocument, options);
      }
export type AddAutoAppUserMutationHookResult = ReturnType<typeof useAddAutoAppUserMutation>;
export type AddAutoAppUserMutationResult = Apollo.MutationResult<AddAutoAppUserMutation>;
export type AddAutoAppUserMutationOptions = Apollo.BaseMutationOptions<AddAutoAppUserMutation, AddAutoAppUserMutationVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($searchTerm: String, $isGovernment: Boolean, $isOnboarded: Boolean, $sort: SortInput, $pagination: PaginationInput) {
  results: searchUsers(searchTerm: $searchTerm, isGovernment: $isGovernment, isOnboarded: $isOnboarded, sort: $sort, pagination: $pagination) {
    totalCount
    items {
      ...Users
    }
  }
}
    ${UsersFragmentDoc}`;

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      isGovernment: // value for 'isGovernment'
 *      isOnboarded: // value for 'isOnboarded'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const CreateInvitesDocument = gql`
    mutation CreateInvites($data: CreateInviteInput!) {
  createInvites(data: $data) {
    ...InvitedUser
  }
}
    ${InvitedUserFragmentDoc}`;
export type CreateInvitesMutationFn = Apollo.MutationFunction<CreateInvitesMutation, CreateInvitesMutationVariables>;

/**
 * __useCreateInvitesMutation__
 *
 * To run a mutation, you first call `useCreateInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitesMutation, { data, loading, error }] = useCreateInvitesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvitesMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvitesMutation, CreateInvitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvitesMutation, CreateInvitesMutationVariables>(CreateInvitesDocument, options);
      }
export type CreateInvitesMutationHookResult = ReturnType<typeof useCreateInvitesMutation>;
export type CreateInvitesMutationResult = Apollo.MutationResult<CreateInvitesMutation>;
export type CreateInvitesMutationOptions = Apollo.BaseMutationOptions<CreateInvitesMutation, CreateInvitesMutationVariables>;
export const ShareUserDocument = gql`
    mutation shareUser($data: ShareInput!) {
  shareUser(data: $data)
}
    `;
export type ShareUserMutationFn = Apollo.MutationFunction<ShareUserMutation, ShareUserMutationVariables>;

/**
 * __useShareUserMutation__
 *
 * To run a mutation, you first call `useShareUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareUserMutation, { data, loading, error }] = useShareUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useShareUserMutation(baseOptions?: Apollo.MutationHookOptions<ShareUserMutation, ShareUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareUserMutation, ShareUserMutationVariables>(ShareUserDocument, options);
      }
export type ShareUserMutationHookResult = ReturnType<typeof useShareUserMutation>;
export type ShareUserMutationResult = Apollo.MutationResult<ShareUserMutation>;
export type ShareUserMutationOptions = Apollo.BaseMutationOptions<ShareUserMutation, ShareUserMutationVariables>;
export const GetUserLookupDocument = gql`
    query getUserLookup($name: String!, $pagination: PaginationInput, $includeSelf: Boolean) {
  getUserLookup(name: $name, pagination: $pagination, includeSelf: $includeSelf) {
    totalCount
    items {
      ...UserLookup
    }
  }
}
    ${UserLookupFragmentDoc}`;

/**
 * __useGetUserLookupQuery__
 *
 * To run a query within a React component, call `useGetUserLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLookupQuery({
 *   variables: {
 *      name: // value for 'name'
 *      pagination: // value for 'pagination'
 *      includeSelf: // value for 'includeSelf'
 *   },
 * });
 */
export function useGetUserLookupQuery(baseOptions: Apollo.QueryHookOptions<GetUserLookupQuery, GetUserLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserLookupQuery, GetUserLookupQueryVariables>(GetUserLookupDocument, options);
      }
export function useGetUserLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserLookupQuery, GetUserLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserLookupQuery, GetUserLookupQueryVariables>(GetUserLookupDocument, options);
        }
export type GetUserLookupQueryHookResult = ReturnType<typeof useGetUserLookupQuery>;
export type GetUserLookupLazyQueryHookResult = ReturnType<typeof useGetUserLookupLazyQuery>;
export type GetUserLookupQueryResult = Apollo.QueryResult<GetUserLookupQuery, GetUserLookupQueryVariables>;
export const GetDiscussionsByUserDocument = gql`
    query getDiscussionsByUser($sort: SortInput, $pagination: PaginationInput) {
  getDiscussionsByUser(sort: $sort, pagination: $pagination) {
    items {
      ...Discussion
    }
    totalCount
  }
}
    ${DiscussionFragmentDoc}`;

/**
 * __useGetDiscussionsByUserQuery__
 *
 * To run a query within a React component, call `useGetDiscussionsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionsByUserQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetDiscussionsByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetDiscussionsByUserQuery, GetDiscussionsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscussionsByUserQuery, GetDiscussionsByUserQueryVariables>(GetDiscussionsByUserDocument, options);
      }
export function useGetDiscussionsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscussionsByUserQuery, GetDiscussionsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscussionsByUserQuery, GetDiscussionsByUserQueryVariables>(GetDiscussionsByUserDocument, options);
        }
export type GetDiscussionsByUserQueryHookResult = ReturnType<typeof useGetDiscussionsByUserQuery>;
export type GetDiscussionsByUserLazyQueryHookResult = ReturnType<typeof useGetDiscussionsByUserLazyQuery>;
export type GetDiscussionsByUserQueryResult = Apollo.QueryResult<GetDiscussionsByUserQuery, GetDiscussionsByUserQueryVariables>;
export const GetUserFollowCountsDocument = gql`
    query getUserFollowCounts {
  getUserFollowCounts {
    technologyFollows
    organizationFollows
    discussionFollows
  }
}
    `;

/**
 * __useGetUserFollowCountsQuery__
 *
 * To run a query within a React component, call `useGetUserFollowCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFollowCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFollowCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFollowCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFollowCountsQuery, GetUserFollowCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserFollowCountsQuery, GetUserFollowCountsQueryVariables>(GetUserFollowCountsDocument, options);
      }
export function useGetUserFollowCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFollowCountsQuery, GetUserFollowCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserFollowCountsQuery, GetUserFollowCountsQueryVariables>(GetUserFollowCountsDocument, options);
        }
export type GetUserFollowCountsQueryHookResult = ReturnType<typeof useGetUserFollowCountsQuery>;
export type GetUserFollowCountsLazyQueryHookResult = ReturnType<typeof useGetUserFollowCountsLazyQuery>;
export type GetUserFollowCountsQueryResult = Apollo.QueryResult<GetUserFollowCountsQuery, GetUserFollowCountsQueryVariables>;
export const ResendEmailValidationDocument = gql`
    mutation resendEmailValidation($identityId: String!) {
  response: resendEmailValidation(identityId: $identityId) {
    errorMessage
    result {
      ...CurrentUser
    }
  }
}
    ${CurrentUserFragmentDoc}`;
export type ResendEmailValidationMutationFn = Apollo.MutationFunction<ResendEmailValidationMutation, ResendEmailValidationMutationVariables>;

/**
 * __useResendEmailValidationMutation__
 *
 * To run a mutation, you first call `useResendEmailValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailValidationMutation, { data, loading, error }] = useResendEmailValidationMutation({
 *   variables: {
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useResendEmailValidationMutation(baseOptions?: Apollo.MutationHookOptions<ResendEmailValidationMutation, ResendEmailValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendEmailValidationMutation, ResendEmailValidationMutationVariables>(ResendEmailValidationDocument, options);
      }
export type ResendEmailValidationMutationHookResult = ReturnType<typeof useResendEmailValidationMutation>;
export type ResendEmailValidationMutationResult = Apollo.MutationResult<ResendEmailValidationMutation>;
export type ResendEmailValidationMutationOptions = Apollo.BaseMutationOptions<ResendEmailValidationMutation, ResendEmailValidationMutationVariables>;