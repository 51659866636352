import React, { ReactElement, useState } from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useKeycloak } from '@react-keycloak/web';

import { createUploadLink } from 'apollo-upload-client';

// import { createUploadLink } from 'apollo-upload-client';

interface Props {
    children: ReactElement;
}

export const Providers = ({ children }: Props): ReactElement => {
    const [hasNetworkError, setHasNetworkError] = useState(false);
    const { keycloak, initialized } = useKeycloak();

    const { NODE_ENV, REACT_APP_TECHSCOUT_WS, REACT_APP_TECHSUITE_ID } = process.env;
    const TECHSUITE_ID = REACT_APP_TECHSUITE_ID || '37003fee-0516-40c0-9073-33146354db97';

    const httpLink = createUploadLink({
        uri: '/api',
        credentials: 'include',
        headers: {
            authorization: keycloak.token ? `Bearer ${keycloak.token}` : '',
            appId: TECHSUITE_ID,
        },
    });

    const wsLink = new WebSocketLink({
        uri: NODE_ENV === 'production' ? `wss://${window.location.host}/api` : REACT_APP_TECHSCOUT_WS || '',
        options: {
            reconnect: true,
            lazy: true,
            timeout: 60000,
            minTimeout: 30000,
            connectionParams() {
                return {
                    authorization: keycloak.token ? `Bearer ${keycloak.token}` : '',
                    appId: TECHSUITE_ID,
                };
            },
        },
    });

    const getVersionNumber = new ApolloLink((operation, forward) => {
        return forward(operation).map(result => {
            const { headers } = operation.getContext().response;
            const backendVersion = headers.get('x-version-backend');
            const backendEnvironment = headers.get('x-environment-backend');
            // eslint-disable-next-line no-console
            localStorage.setItem('x-version-backend', backendVersion);
            localStorage.setItem('x-environment-backend', backendEnvironment);
            return result;
        });
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(async ({ message, locations, path }) => {
                // eslint-disable-next-line no-console
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
                );
                if (message.includes('Error fetching account')) {
                    await keycloak.logout({
                        redirectUri: window.location.origin,
                    });
                    keycloak.clearToken();
                    // history.replace('/'); // the redirect uri is not getting set as expected
                }
                // if (message.includes('Message: Access denied!') && path?.includes('getUser')) {
                if (message.includes('Message: Access denied!')) {
                    await keycloak.login({
                        redirectUri: window.location.origin,
                    });
                }
            });

        if (networkError) {
            // eslint-disable-next-line no-console
            console.log(`[Network error]: ${networkError}`);
            // history.replace('/');
            setHasNetworkError(true);
        }
    });

    const link = ApolloLink.split(
        ({ query }) => {
            const {
                kind,
                operation,
            }: {
                kind: string;
                operation?: string;
            } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        ApolloLink.from([getVersionNumber, errorLink, httpLink])
    );

    // Steve: need to research this better
    // something to consider: https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    // const defaultOptions = {
    //     watchQuery: {
    //         fetchPolicy: 'cache-and-network' as WatchQueryFetchPolicy,
    //         errorPolicy: 'ignore' as ErrorPolicy,
    //     },
    //     query: {
    //         fetchPolicy: 'network-only' as FetchPolicy,
    //         errorPolicy: 'all' as ErrorPolicy,
    //     },
    //     mutate: {
    //         errorPolicy: 'all' as ErrorPolicy,
    //     },
    // };

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache({
            typePolicies: {
                GetMessagesByConversationResponse: {
                    keyFields: [],
                },
                // CurrentUser: {
                // fields: {
                //     userAffiliations: {
                //         merge(existing = [], incoming: any[]) {
                //             return [...existing, ...incoming];
                //         },
                //     },
                //     userOrgAccess: {
                //         merge(existing = [], incoming: any[]) {
                //             return [...existing, ...incoming];
                //         },
                //     },
                // },
                // },
                // Identity: {
                //     // keyFields: [],
                //     fields: {
                //         affiliations: {
                //             merge(existing = [], incoming: any[]) {
                //                 return [...existing, ...incoming];
                //             },
                //         },
                //     },
                // },
            },
        }),
        // defaultOptions,
        connectToDevTools: true,
    });
    if (initialized && !hasNetworkError) {
        return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
    }
    if (hasNetworkError) {
        return <div>A network error has occurred - unable to contact the API</div>;
    }
    return <div>Initializing</div>;
};

export default Providers;
