import React, { ReactElement, ReactNode } from 'react';
import { Button } from '@mui/material';

import { NotificationStatus } from 'models/NotificationModels';
import { EditOrganizationSteps } from 'pages/organization-edit/EditOrganization';
import { EditProfileSteps } from 'pages/personal-profile/EditPersonProfile';
import { TechnologyWizardSteps } from 'pages/technology-wizard-steps/AddTechnologyStepWizard';
import { MergeProfileSteps } from 'pages/merge-profiles/MergeProfilesStepWizard';
import { AfwerxOnbardingSteps } from 'pages/afwerx/AfwerxOnboardingStepWizard';
import { EditDiscussionSteps } from '../discussion-editor/DiscussionEditor';

import './StepButton.scss';

interface StepButtonProps {
    currentPage?: number;
    title: ReactNode;
    step:
        | TechnologyWizardSteps
        | EditProfileSteps
        | EditOrganizationSteps
        | EditDiscussionSteps
        | NotificationStatus
        | MergeProfileSteps
        | AfwerxOnbardingSteps
        | number;
    testid?: string;
    disabled?: boolean;
    inValid?: boolean;
    showBorder?: boolean;
    withPadding?: boolean;
    onSelected?: (pageIndex: number) => void;
}

export const StepButton = ({
    testid,
    currentPage,
    title,
    step,
    disabled,
    inValid,
    showBorder,
    withPadding,
    onSelected,
}: StepButtonProps): ReactElement => {
    let classname = currentPage === step ? 'step-button active' : 'step-button';
    if (inValid) {
        classname += ' required';
    }

    if (showBorder) {
        classname += ' with-border';
    }

    if (withPadding) {
        classname += ' with-padding';
    }

    return (
        <Button
            fullWidth
            sx={{
                justifyContent: 'flex-start',
                '&:hover': {
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                },
                '&.active': {
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                },
            }}
            disabled={disabled}
            type="button"
            data-testid={testid || 'default-step-button'}
            className={classname}
            onClick={(): any => !disabled && onSelected && onSelected(step)}
        >
            {title}
        </Button>
    );
};

StepButton.defaultProps = {
    currentPage: false,
    disabled: false,
    testid: null,
    inValid: null,
    showBorder: true,
    withPadding: true,
    onSelected: null,
};
export default StepButton;
