import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import logo from 'assets/images/afwerx-logo.svg';
import MobileNavMenu from 'components/common/mobile-menu/MobileNavMenu';
import { IconContainer } from 'pageComponents/navigation/nav-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
    addNotificationCount,
    addUnreadMessageCount,
    setNewNotificationsCount,
    setUnreadMessageCount,
    setUnresolvedReportCount,
} from 'state/store/actions/Counts';
import { RootStore } from 'state/store/reducers';
import {
    useGetNewNotificationsCountLazyQuery,
    useGetUnReadMessageCountLazyQuery,
    useGetUnresolvedReportCountLazyQuery,
    useOnMessageSubscription,
    useOnNotificationSubscription,
} from 'typings/generated';
import { hasModeratorOrSuperAdminRole, isUserBlocked } from 'utilities/utils';

import './Header.scss';

const Header = (): any => {
    const user = useSelector((storeState: RootStore) => storeState.User);
    const [versionNumber] = useState(process.env.REACT_APP_VERSION || 'unknown');
    const [menuVisible, setMenuVisible] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    const [getNewNotificationsCount] = useGetNewNotificationsCountLazyQuery({
        onCompleted: result => {
            dispatch(setNewNotificationsCount(result.getNewNotificationsCount));
        },
        fetchPolicy: 'cache-and-network',
    });

    const [getUnReadMessageCount] = useGetUnReadMessageCountLazyQuery({
        onCompleted: result => dispatch(setUnreadMessageCount(result.getUnReadMessageCount)),
        fetchPolicy: 'cache-and-network',
    });

    const [getUnresolvedReportCount] = useGetUnresolvedReportCountLazyQuery({
        onCompleted: result => {
            dispatch(setUnresolvedReportCount(result.getUnresolvedReportCount));
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (user) {
            getNewNotificationsCount();
            getUnReadMessageCount();
        }
        if (user && hasModeratorOrSuperAdminRole(user)) {
            getUnresolvedReportCount();
        }
        const showMenu = !isUserBlocked(user);
        setMenuVisible(showMenu);
    }, [getNewNotificationsCount, getUnReadMessageCount, getUnresolvedReportCount, user]);

    useOnNotificationSubscription({
        onSubscriptionData: () => {
            // eslint-disable-next-line no-console
            if (!location.pathname.includes('notifications')) {
                dispatch(addNotificationCount());
            }
        },
    });

    useOnMessageSubscription({
        onSubscriptionData: result => {
            const initiatorAndMuted =
                user.id === result.subscriptionData.data?.messageSubscription?.conversation?.initiator.id &&
                result.subscriptionData.data?.messageSubscription?.conversation?.initiatorHasMuted;

            const participantAndMuted =
                user.id === result.subscriptionData.data?.messageSubscription?.conversation?.participant.id &&
                result.subscriptionData.data?.messageSubscription?.conversation?.participantHasMuted;

            const isSender = user.id === result.subscriptionData.data?.messageSubscription?.sender.id;

            if (!isSender && !initiatorAndMuted && !participantAndMuted) {
                if (!location.pathname.includes('messages')) {
                    dispatch(addUnreadMessageCount());
                }
            }
        },
    });

    return (
        <Box
            component="header"
            role="banner"
            className="main-head"
            sx={{
                backgroundColor: 'grey.900',
            }}
        >
            <Box sx={{ alignItems: 'center', display: 'flex', pt: '4px', px: '20px', height: '60px' }}>
                {menuVisible && <MobileNavMenu />}
                <Link to="/" className="logo-link">
                    <img
                        className="logo"
                        src={logo}
                        alt={`${process.env.REACT_APP_NAME} logo`}
                        title={`Version ${versionNumber}`}
                    />
                </Link>
                {menuVisible && (
                    <>
                        <div className="filler" />
                        <Box
                            component="div"
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'block',
                                    lg: 'block',
                                    xl: 'block',
                                },
                                boxShadow: {
                                    xs: 'box-shadow: 0px 3px 6px #00000029',
                                    sm: 'box-shadow: 0px 3px 6px #00000029',
                                    md: 'none',
                                    lg: 'none',
                                    xl: 'none',
                                },
                            }}
                        >
                            <IconContainer />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Header;
