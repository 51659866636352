import { AnyAction } from 'redux';
import {
    ADD_NOTIFICATION_COUNT,
    ADD_UNREAD_MESSAGE_COUNT,
    ADD_UNRESOLVED_REPORT_COUNT,
    SET_NOTIFICATION_COUNT,
    SET_UNREAD_MESSAGE_COUNT,
    SET_UNRESOLVED_REPORT_COUNT,
} from '../actions/Counts';

export interface CountState {
    newNotificationsCount: number;
    unreadMessageCount: number;
    unresolvedReportCount: number;
}

const initialState: CountState = {
    newNotificationsCount: 0,
    unreadMessageCount: 0,
    unresolvedReportCount: 0,
};

export default (state = initialState, action: AnyAction): CountState => {
    const { type, payload } = action;
    switch (type) {
        case ADD_NOTIFICATION_COUNT: {
            const updatedCounts = {
                ...state,
                newNotificationsCount: state.newNotificationsCount + 1,
            };
            return updatedCounts;
        }
        case SET_NOTIFICATION_COUNT: {
            const updatedCounts = { ...state, newNotificationsCount: payload };
            return updatedCounts;
        }
        case ADD_UNREAD_MESSAGE_COUNT: {
            const updatedCounts = {
                ...state,
                unreadMessageCount: state.unreadMessageCount + 1,
            };
            return updatedCounts;
        }
        case SET_UNREAD_MESSAGE_COUNT: {
            const updatedCounts = { ...state, unreadMessageCount: payload };
            return updatedCounts;
        }
        case ADD_UNRESOLVED_REPORT_COUNT: {
            const updatedCounts = {
                ...state,
                unresolvedReportCount: state.unresolvedReportCount + 1,
            };
            return updatedCounts;
        }
        case SET_UNRESOLVED_REPORT_COUNT: {
            const updatedCounts = { ...state, unresolvedReportCount: payload };
            return updatedCounts;
        }
        default:
            return state;
    }
};
