/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import { UserState } from 'state/store/reducers/User';
import {
    CommunitySummaryFragment,
    DiscussionFragment,
    GroupMembership,
    Maybe,
    MessageUserFragment,
    OrganizationSummaryFragment,
    OrgUserWithRolesFragment,
    UserFragment,
    UserSummaryFragment,
} from 'typings/generated';

export function redirectToLogin(history: { push: (route: string) => void }): void {
    history.push('/login');
}

export function redirectToSignUp(history: { push: (route: string) => void }): void {
    history.push('/signup');
}
/* eslint-disable no-restricted-globals */
export function toString(value: string): string {
    return value !== undefined && value !== null ? `${value}` : '';
}

export function regExpEscape(text: string): string {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function highlight(result: string, searchTextValue: string): Array<string> {
    const terms: Array<string> = [searchTextValue];
    const escapedTerms = terms.map((term: string) => regExpEscape(toString(term))).filter((term: any) => term);
    return escapedTerms.length ? result.split(new RegExp(`(${escapedTerms.join('|')})`, 'gmi')) : [result];
}

export type Delegate<T> = (...args: any[]) => T;

export function truncateText(maxLength: number, text: string | null): string | null {
    return text && text?.length > maxLength ? `${text.trim().substring(0, maxLength)}  ...` : text;
}

export function float2int(value: number): number {
    // eslint-disable-next-line no-bitwise
    return value | 0;
}

export function hasRole(user: OrgUserWithRolesFragment | UserState, roles: string[], organizationId?: string): boolean {
    let hasUserRole = false;
    if (user && user.userOrgAccess) {
        user.userOrgAccess
            .filter(x => organizationId === undefined || x?.organizationId === organizationId)
            .forEach(access => {
                if (access.role?.roleName && roles.includes(access.role.roleName)) {
                    hasUserRole = true;
                }
            });
    }
    return hasUserRole;
}

export function hasModeratorOrSuperAdminRole(user?: UserState): boolean {
    if (user) {
        return hasRole(user, ['Moderator', 'Super Admin']);
    }
    return false;
}

export function hasCapability(user: UserState, capabilities?: string[], orgId?: string): boolean {
    if (user) {
        if (capabilities) {
            let canView = false;
            if (user.userOrgAccess) {
                const filterByOrgId = orgId
                    ? user.userOrgAccess.filter(uo => uo.organizationId === orgId)
                    : user.userOrgAccess;
                const userCapabilites = filterByOrgId
                    .flatMap(orgAccess => orgAccess.role?.appRoleCapabilities)
                    .map(x => x?.appCapabilities?.capabilityName)
                    .filter(x => x !== null);
                canView = capabilities.some(capability => userCapabilites.includes(capability));
            }
            return canView;
        }
        return true;
    }
    return false;
}

export function getGroupMembershipByGroupKey(user: UserState, groupKey: string): GroupMembership | undefined {
    if (user && user.onboarded) {
        return user.groupPermissions.find(membership => membership.groupKey === groupKey);
    }
    return undefined;
}
export function getGroupMembershipByCommunityId(user: UserState, communityId?: string): GroupMembership | undefined {
    if (user && user.onboarded) {
        return user.groupPermissions.find(membership => membership.communityId === communityId);
    }
    return undefined;
}

export function canManageCommunity(user: UserState, communityId?: string): boolean {
    const isSuperAdmin = hasRole(user, ['Super Admin']);

    const membership = getGroupMembershipByCommunityId(user, communityId);
    const hasGroupPermissions = membership?.groupRole === 'manager' || membership?.groupRole === 'owner';
    return isSuperAdmin || hasGroupPermissions;
}

export function hasGroupMembership(user: UserState, groupKey: string): boolean {
    return user && user.onboarded && !!getGroupMembershipByGroupKey(user, groupKey);
}

export function getDownloadHrefFromBase64(base64Data: string): string {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/octet-stream;base64' });
    return window.URL.createObjectURL(blob);
}

export function isUserBlocked(user: UserState): boolean {
    if (user) {
        const userBlocked = user.currentlyBlocked;
        const orgBlocked = user.organization && user.organization.currentlyBlocked && !hasRole(user, ['Super Admin']);
        if (userBlocked || orgBlocked) {
            return true;
        }
    }
    return false;
}
export function userRequiresOnboarding(user: UserState): boolean {
    if (user) {
        return !user.onboarded;
    }
    return false;
}

export function getRole(
    user: OrgUserWithRolesFragment | UserState,
    exclusions?: Array<string>,
    organizationId?: string
): string {
    let role = '';
    if (user && user.userOrgAccess) {
        user.userOrgAccess
            .filter(x => organizationId === undefined || x?.organizationId === organizationId)
            .forEach(access => {
                if (access.role?.roleName) {
                    if (exclusions && exclusions.includes(access.role?.roleName)) {
                        role = '';
                    } else {
                        role = access.role?.roleName;
                    }
                }
            });
    } else {
        // eslint-disable-next-line no-console
        console.warn('getRole: User is missing userOrgAccess');
    }

    if (role === '') {
        role = user.isGovernment ? 'Govt User' : 'Company User';
    }
    return role;
}

export function canViewEmail(user: UserState, person: UserFragment): boolean {
    if (!user.isGovernment && person.govtOnlyDisplayEmail) {
        return false;
    }
    return true;
}

export function canViewPhoneNumber(user: UserState, person: UserFragment): boolean {
    if (!user.isGovernment && person.govtOnlyDisplayPhoneNumber) {
        return false;
    }
    return true;
}

export function formatUrl(currentValue: string): string {
    let inputValue = currentValue;
    if (!currentValue.startsWith('http') && currentValue.length > 0) {
        inputValue = `http://${currentValue}`;
    }
    return inputValue;
}

export function getGovernmentLevel(value: string | undefined | null): string {
    switch (value) {
        case '1': {
            return 'Service';
        }
        case '2': {
            return 'Major Command';
        }
        case '3':
            return 'Unit';
        case '4':
            return 'Directorate';
        case '5':
            return 'Division';
        default:
            return 'Government Organization';
    }
}

export function useLocationQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}

type formatType = 'date' | 'weekdayDateTime' | 'weekdayDate' | 'time' | 'dateTime';

export function formatDateTime(date: string, type: formatType = 'dateTime'): string {
    switch (type) {
        case 'date': {
            return DateTime.fromISO(date).toFormat('d LLL yyyy');
        }
        case 'weekdayDateTime':
            return DateTime.fromISO(date).toFormat("cccc, d LLL, yyyy '-' t");
        case 'weekdayDate':
            return DateTime.fromISO(date).toFormat('cccc, d LLL, yyyy');
        case 'time':
            return DateTime.fromISO(date).toFormat('t');
        case 'dateTime':
            return DateTime.fromISO(date).toFormat("d LLL yyyy '-' t");
        default:
            return DateTime.fromISO(date).toFormat("d LLL yyyy '-' t");
    }
}

export const sortByDate = (inputArray: DiscussionFragment[]): DiscussionFragment[] => {
    const sortedInputArray = [...inputArray];
    sortedInputArray.sort((a, b) => +new Date(a.createDate) - +new Date(b.createDate)).reverse();
    return sortedInputArray;
};

export const rawMarkup = (html: string): any => {
    return { __html: html };
};

export const setRaw = (text: string): string => {
    const originalText = text;
    if (originalText.substring(2, 8) !== 'blocks') {
        return `{"blocks":[{"key":"f7puv","text":"${originalText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
    }
    return originalText;
};

interface RichText {
    blocks: { text: string }[];
}

export const isRichTextEmpty = (unformatted: string | Maybe<string>): boolean => {
    if (unformatted === undefined || unformatted === null || unformatted.length === 0) {
        return true;
    }
    if (unformatted.includes('"blocks":')) {
        const richText: RichText = JSON.parse(unformatted);
        const text = richText.blocks.map(block => (!block.text.trim() && '') || block.text).join('\n');
        return text.length === 0;
    }
    return false;
};

export const isJSON = (str: string): boolean => {
    str = typeof str !== 'string' ? JSON.stringify(str) : str;

    try {
        str = JSON.parse(str);
    } catch (e) {
        return false;
    }

    if (typeof str === 'object' && str !== null) {
        return true;
    }

    return false;
};

// TODO: give this better typing - also confirm we return in expected format
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function swapInFetchMoreItems(prev: any, { fetchMoreResult }: any): any {
    window.scrollTo(0, 0);
    if (!fetchMoreResult) return prev;
    const items = [...fetchMoreResult.results.items];
    return {
        results: {
            ...prev.results,
            items,
        },
    };
}

export const getRoleAndOrgName = (user: UserFragment | UserState): string => {
    if (user.profession) {
        return user.organization ? `${user.profession} at ${user.organization?.name}` : user.profession;
    }
    return user.organization?.name || '';
};

export const getRelationAndPrimaryOrg = (user: UserFragment | UserState): string => {
    if (user.primaryAffiliation) {
        return user.primaryAffiliation.relation
            ? `${user.primaryAffiliation.relation} at ${user.primaryAffiliation.organization.name}`
            : user.primaryAffiliation.organization.name;
    }
    return user.organization?.name || '';
};

export const getFirstAndLastNameInitials = (
    user: UserFragment | Maybe<UserSummaryFragment> | UserSummaryFragment | MessageUserFragment | undefined
): string => {
    let firstInitial = '';
    let lastInitial = '';

    if (user?.firstName) {
        firstInitial = `${user.firstName[0]}`;
    }
    if (user?.lastName) {
        lastInitial = `${user.lastName[0]}`;
    }
    return `${firstInitial + lastInitial}`.toUpperCase();
};

export const getOrganizationInitial = (org: any | OrganizationSummaryFragment | undefined): string => {
    let initial = '';

    if (org?.name) {
        initial = `${org.name[0]}`;
    }

    return initial.toUpperCase();
};

export const getTechnologyInitial = (tech: any | undefined): string => {
    let initial = '';

    if (tech?.name) {
        initial = `${tech.name[0]}`;
    }

    return initial.toUpperCase();
};

export const getCommunityInitial = (community: CommunitySummaryFragment | undefined): string => {
    let initial = '';

    if (community?.name) {
        initial = `${community.name[0]}`;
    }

    return initial.toUpperCase();
};

export const getUserTypeTitle = (user: UserFragment): string => {
    if (user.isGovernment) {
        return user.isCtr ? 'Government Contractor' : 'Government Personnel';
    }
    return 'Commercial Personnel';
};
export const getTimeSince = (time: string, now: Date, shortFormat = true): string => {
    const dateTime = new Date(time);
    const msPassed = now.getTime() - dateTime.getTime();
    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;
    const msInWeek = 7 * msInDay;
    let unitNumber = 1;
    let label = shortFormat ? 'm' : 'minute';
    if (msPassed >= msInWeek) {
        unitNumber = Math.floor(msPassed / msInWeek);
        label = shortFormat ? 'w' : 'week';
    } else if (msPassed >= msInDay) {
        unitNumber = Math.floor(msPassed / msInDay);
        label = shortFormat ? 'd' : 'day';
    } else if (msPassed >= msInHour) {
        unitNumber = Math.floor(msPassed / msInHour);
        label = shortFormat ? 'h' : 'hour';
    } else if (msPassed >= msInMinute) {
        unitNumber = Math.floor(msPassed / msInMinute);
        label = shortFormat ? 'm' : 'minute';
    }

    return shortFormat ? `${unitNumber}${label}` : `${unitNumber} ${label}${unitNumber > 1 ? 's' : ''}`;
};
export const getDaysSince = (time: string, now: Date): number => {
    const dateTime = new Date(time);
    const msPassed = now.getTime() - dateTime.getTime();
    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;
    if (msPassed >= msInDay) {
        return Math.floor(msPassed / msInDay);
    }
    return 0;
};
