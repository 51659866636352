import React, { createRef, ReactElement, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

import {
    AboutIcon,
    AddIcon,
    AdminIcon,
    CollapseIcon,
    CommunityIcon,
    HamburgerIcon,
    HandshakeIcon,
    HomeIcon,
    LogoutIcon,
    MessagesIcon,
    NotificationsIcon,
    OnboardingIcon,
    ProfileIcon,
    SignUpIcon,
} from 'assets/icons';
import { linkTo } from 'components/providers/ReactRouter';
import FeedbackModal from 'pages/modals/FeedbackModal';
import InviteModal from 'pages/modals/InviteModal';
import { useDispatch, useSelector } from 'react-redux';
// import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useHistory } from 'react-router-dom';
import { setCategory } from 'state/store/actions/ExplorerSearch';
import { RootStore } from 'state/store/reducers';
import { useLogoutMutation } from 'typings/generated';
import { hasGroupMembership, hasRole } from 'utilities/utils';
import Viewable from '../viewable/Viewable';
import { MobileNavContainer } from './MobileNavContainer';
import { MobileNavItem } from './MobileNavItem';

import './MobileNavMenu.scss';

export const MobileNavMenu = (): ReactElement => {
    const { keycloak } = useKeycloak();
    const dispatch = useDispatch();
    const user = useSelector((storeState: RootStore) => storeState.User);
    const count = useSelector((storeState: RootStore) => storeState.Count);

    const [logout] = useLogoutMutation();

    const hamburgerMenu = createRef<HTMLDivElement>();
    // const targetElement: HTMLDivElement = hamburgerMenu.current as HTMLDivElement;

    const history = useHistory();

    const [showMenu, setShowMenu] = useState(false);
    const [showInviteModal, toggleShowInviteModal] = useState(false);
    const [showFeedbackModal, toggleShowFeedbackModal] = useState(false);

    const toggleShowMenu = (show: boolean): void => {
        setShowMenu(show);
    };

    const handler = (path: string, category?: string): void => {
        // clearAllBodyScrollLocks();
        if (category) {
            dispatch(setCategory(category));
        }
        history.push(path);
        setShowMenu(false);
    };

    const handleInviteModal = () => {
        toggleShowInviteModal(true);
        setShowMenu(false);
    };

    const loginHandler = (): void => {
        // clearAllBodyScrollLocks();
        history.push('/login');
    };

    const signUpHandler = (): void => {
        // clearAllBodyScrollLocks();
        history.push('/signup');
    };

    const signOutHandler = async () => {
        logout();
        keycloak.logout({
            redirectUri: window.location.origin,
        }); // don't use await - this rediects back to the login page just fine
    };

    const canVisitOnboarding = (): boolean => {
        if (user && user.googleGroupPermissions && user.onboarded) {
            return hasGroupMembership(user, 'rg.newhire@afwerx.af.mil');
        }
        return false;
    };

    return (
        <>
            <InviteModal isOpen={showInviteModal} onClose={(): any => toggleShowInviteModal(false)} />
            <FeedbackModal isOpen={showFeedbackModal} onClose={(): any => toggleShowFeedbackModal(false)} />
            <div className="mobile-nav-menu">
                <div className="hamburger" ref={hamburgerMenu}>
                    <button className="btn-icon" onClick={(): any => toggleShowMenu(true)} data-testid="showMenuButton">
                        <HamburgerIcon style={{ fill: 'white', height: '28px', width: '28px' }} />
                    </button>
                </div>
                {showMenu && (
                    <div className="mobile menu-overlay" data-testid="menu-overlay">
                        <div className="menu-overlay-mask">&nbsp;</div>
                        <div className="menu-overlay_content">
                            <div id="hamburger-top" className="burger-top">
                                <Box
                                    sx={{ px: '1rem', py: '0.5rem', m: '1rem', width: '100%' }}
                                    className="burger-top-label"
                                >
                                    Menu
                                </Box>
                                <IconButton
                                    data-testid="hideMenuButton"
                                    onClick={(): any => toggleShowMenu(false)}
                                    style={{ color: 'white' }}
                                >
                                    <CollapseIcon />
                                </IconButton>
                            </div>
                            {user ? (
                                <div className="menu-content">
                                    <>
                                        <MobileNavItem
                                            icon={<HomeIcon />}
                                            handler={(): void => handler('/')}
                                            title="Home"
                                        />
                                        <Viewable capabilities={['SuperAdmin', 'Moderator']}>
                                            <div className="mobile-nav-menu-dividers">
                                                <MobileNavItem
                                                    icon={<AdminIcon />}
                                                    handler={(): void => handler('/admin', 'Admin')}
                                                    badgeCount={count?.unresolvedReportCount}
                                                    color="error"
                                                    title={hasRole(user, ['Super Admin']) ? 'Admin' : 'Moderation'}
                                                />
                                            </div>
                                        </Viewable>
                                        {canVisitOnboarding() && (
                                            <MobileNavItem
                                                icon={<OnboardingIcon />}
                                                handler={(): void =>
                                                    handler(linkTo.onboarding.checklist, 'AFWERX Onboarding')
                                                }
                                                title="Onboarding"
                                            />
                                        )}
                                        <div className="mobile-nav-menu-dividers">
                                            <MobileNavItem
                                                icon={<CommunityIcon />}
                                                handler={(): void => handler('/communities', 'Communities')}
                                                title="Communities"
                                            />
                                        </div>
                                        <div className="mobile-nav-menu-dividers">
                                            <div className="indented-container">
                                                <MobileNavContainer icon={<HandshakeIcon />} title="TechScout">
                                                    <div className="indented-items">
                                                        <MobileNavItem
                                                            datatestid="navButtonCommercialCompanies"
                                                            handler={(): void =>
                                                                handler('/explorer/Companies', 'Companies')
                                                            }
                                                            title="Commercial Organizations"
                                                        />

                                                        <MobileNavItem
                                                            datatestid="navButtonGovernment"
                                                            handler={(): void =>
                                                                handler('/explorer/Organizations', 'Organizations')
                                                            }
                                                            title="Government Organizations"
                                                        />

                                                        <MobileNavItem
                                                            datatestid="navButtonCommercialPeople"
                                                            handler={(): void =>
                                                                handler('/explorer/CompanyPeople', 'CompanyPeople')
                                                            }
                                                            title="Commercial Members"
                                                        />

                                                        <MobileNavItem
                                                            handler={(): void =>
                                                                handler(
                                                                    '/explorer/GovernmentPeople',
                                                                    'GovernmentPeople'
                                                                )
                                                            }
                                                            title="Government Members"
                                                        />
                                                    </div>
                                                </MobileNavContainer>
                                            </div>
                                        </div>
                                        <div className="mobile-nav-menu-dividers">
                                            <MobileNavItem
                                                icon={<AboutIcon />}
                                                handler={(): void => handler('/about')}
                                                title="About"
                                            />
                                        </div>
                                        <div className="mobile-nav-menu-dividers">
                                            <MobileNavItem
                                                icon={<NotificationsIcon />}
                                                handler={(): void =>
                                                    handler(linkTo.profile.view.notifications(user?.id))
                                                }
                                                badgeCount={count?.newNotificationsCount}
                                                color="primary"
                                                title="Notifications"
                                            />
                                        </div>
                                        <div className="mobile-nav-menu-dividers">
                                            <MobileNavItem
                                                icon={<MessagesIcon />}
                                                handler={(): void => handler(`/messages`)}
                                                badgeCount={count?.unreadMessageCount}
                                                color="primary"
                                                title="Messages"
                                            />
                                        </div>
                                        <div className="indented-container">
                                            <MobileNavContainer icon={<ProfileIcon />} title="Profile">
                                                <div className="indented-items">
                                                    <MobileNavItem
                                                        handler={(): void =>
                                                            handler(linkTo.profile.view.root(user?.id))
                                                        }
                                                        title="View Profile"
                                                    />
                                                    <MobileNavItem
                                                        handler={(): void =>
                                                            handler(
                                                                user.isGovernment
                                                                    ? linkTo.organization.view.root(
                                                                          user?.organization?.id || ''
                                                                      )
                                                                    : linkTo.company.view.root(
                                                                          user?.organization?.id || ''
                                                                      )
                                                            )
                                                        }
                                                        title={
                                                            user.isGovernment === true
                                                                ? 'View Organization'
                                                                : 'View Company'
                                                        }
                                                    />
                                                </div>
                                            </MobileNavContainer>
                                        </div>
                                        <div className="mobile-nav-menu-dividers">
                                            <div className="indented-container">
                                                <MobileNavContainer icon={<AddIcon />} title="Actions">
                                                    <div className="indented-items">
                                                        <MobileNavItem
                                                            handler={(): void => handler(linkTo.organization.join)}
                                                            title="Join Organization"
                                                        />
                                                        <MobileNavItem
                                                            handler={(): void => handler(linkTo.organization.add)}
                                                            title="Create Organization"
                                                        />
                                                        <MobileNavItem
                                                            handler={(): void => handler(linkTo.forum.add())}
                                                            title="Start Discussion"
                                                        />
                                                        <MobileNavItem
                                                            handler={(): void => handleInviteModal()}
                                                            title="Invite Members"
                                                        />
                                                        <MobileNavItem
                                                            handler={(): void => toggleShowFeedbackModal(true)}
                                                            title="Give Feedback"
                                                        />
                                                    </div>
                                                </MobileNavContainer>
                                            </div>
                                        </div>
                                    </>

                                    <MobileNavItem icon={<LogoutIcon />} handler={signOutHandler} title="Sign Out" />
                                </div>
                            ) : (
                                <div className="menu-content">
                                    <div className="mobile-nav-menu-dividers">
                                        <MobileNavItem icon={<LogoutIcon />} handler={loginHandler} title="Login" />
                                    </div>
                                    <div className="mobile-nav-menu-dividers">
                                        <MobileNavItem icon={<SignUpIcon />} handler={signUpHandler} title="Signup" />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default MobileNavMenu;
